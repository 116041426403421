import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { Block } from '../../common/layout';
import {
  loadProductsBatchNext,
  searchProducts,
} from '../../actions/shop/product.actions';
import {
  IProductPreview,
  IProductPreviewOrder,
} from '../../interfaces/shop/IProduct';
import { PageHead } from '../../common/typos';
import { setOrderForCatalog } from '../../actions/shop/shop.actions';
import { sendPageEvent } from '../../utils/analytics';
import { loadIDsByFoundations } from '../../actions/account/sellers.actions';
import { loadPartnerSellerIDs } from '../../actions/account/partnerSeller.actions';
import { Order } from '../../common/forms/Order';
import { DataBatchWide } from '../../interfaces/system/data';
import { BatchRows } from '../../common/moderation/BatchRows';
import { SearchRows } from '../../common/moderation/SearchRows';
import { renderProductPreviewItem } from '../../components/shop/catalog/catalog';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface ICatalogPageProps {
  loadProductsBatchNext: (order: IProductPreviewOrder) => any;
  loadIDsByFoundations: () => any;
  loadPartnerSellerIDs: () => any;
  setOrderForCatalog: (order: null | IProductPreviewOrder) => any;
  order: null | IProductPreviewOrder;
  searchProducts: (search: string) => any;
  set: Record<string, IProductPreview>;
  batch: Record<IProductPreviewOrder, DataBatchWide>;
  IDsBySearch: Record<string, string[]>;
}

function CatalogPage(props: ICatalogPageProps) {
  const { t } = useTranslation();

  const [order, setOrder] = useState<IProductPreviewOrder>('new');

  useEffect(() => sendPageEvent('Catalog'), []);

  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'ShopTitleCatalog'} />
        </PageHead>
        <SearchRows
          title={'lot'}
          search={props.searchProducts}
          IDsBySearch={props.IDsBySearch}
          renderItem={renderProductPreviewItem}
          placeholderTitle={'name'}
        />

        <Order
          items={[
            {
              value: 'new',
              title: t('OrderNew'),
            },
            {
              value: 'popular',
              title: t('OrderPopular'),
            },
            {
              value: 'liked',
              title: t('OrderLiked'),
            },
          ]}
          order={order}
          cb={setOrder}
        />

        <BatchRows
          title={'lot'}
          batch={{
            batchKey: order,
            byBatchKey: props.batch,
            loadBatch: () => props.loadProductsBatchNext(order),
          }}
          renderItem={renderProductPreviewItem}
        />
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    order: state.ShopProduct.order,
    set: state.ShopProduct.batchPreview.set,
    batch: state.ShopProduct.batchPreview.batch,
    IDsBySearch: state.ShopProduct.batchPreview.IDsBySearch,
  }),
  {
    loadProductsBatchNext,
    loadIDsByFoundations,
    loadPartnerSellerIDs,
    searchProducts,
    setOrderForCatalog,
  }
)(CatalogPage);
