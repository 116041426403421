import { apiConfig } from '../config/api.config';
import { IApiError, IApiHeaders } from '../interfaces/system/IApi';
import { IDispatch, IState } from '../interfaces/system/IState';
import { ACCOUNT_LOGOUT } from '../actions/types';
import { getToken } from '../actions/account/auth.actions';
import { signOut } from '../actions/account/user.actions';
import { IServiceError } from '../interfaces/system/IError';
import { getPlatformRole } from '../actions/account/role.actions';
import { dispatchError } from './error';
import { checkIsExample } from '../config/platform.config';

export function callApi<Data>(
  path: string,
  params?: Record<string, undefined | string>,
  body?: Record<
    string,
    undefined | string | string[] | number | boolean | null | object
  >,
  method?: 'POST' | 'DELETE',
  isNotAuth?: boolean
): Promise<Data> {
  // const token: string | null = getToken();
  // if (!isNotAuth && !token) {
  //   return Promise.reject({ message: 'Not authorised' });
  // }

  const query: Record<string, string> = {};
  if (params) {
    Object.keys(params).forEach((key: string) => {
      const value: string | undefined = params[key];
      if (value) {
        query[key] = value;
      }
    });
  }

  let url: string =
    `${apiConfig.host}${path}` +
    (params ? '?' + new URLSearchParams(query) : '');

  const headers: IApiHeaders = {
    Authorization: 'Basic ' + getToken(),
    PlatformRole: '',
  };

  if (checkIsExample()) {
    headers.PlatformRole = getPlatformRole();
  }

  const requestOptions: RequestInit = body
    ? {
        method: method || 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    : {
        method: method || 'GET',
        headers,
      };

  let err: null | Error | IApiError = null;
  return new Promise<Data>(
    (resolve: (data: Data) => void, reject: (e: Error | IApiError) => void) => {
      fetch(url, requestOptions)
        .then((response: Response) =>
          response.json().then((json) => {
            if (!response.ok) {
              throw json;
            }

            return json;
          })
        )
        .then((data: Data) => resolve(data))
        .catch((e: Error) => {
          err = e;
          reject(e);
        })
        .finally(() => {
          // if (path !== 'system/log') {
          //   sendLog({
          //     path,
          //     method: method || 'GET',
          //     token: getToken() ? '+' : '-'
          //     error: err ? JSON.stringify(err) : '',
          //   });
          // }
        });
    }
  );
}

export function catchError(
  dispatch: IDispatch<IServiceError>,
  error: Error | IApiError,
  getState: () => IState,
  dontDispatch?: boolean
) {
  const { account } = getState().Account;
  if (error.message !== 'Not authorised') {
    fetch(`${apiConfig.host}${apiConfig.endpoints.system.error}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ error, accountID: (account && account.id) || '' }),
    }).catch(() => void 0);
  }

  if (error.message === 'Not authorised') {
    signOut();
    dispatch({ type: ACCOUNT_LOGOUT, payload: { message: '', params: {} } });
  }

  const err: IServiceError = {
    message: error.message,
    params: {},
  };
  if ('value' in error) {
    err.params.value = error.value;
  }
  if ('description' in error) {
    err.params.description = error.description;
  }
  if (!dontDispatch) {
    dispatchError(dispatch, err);
  }
}
