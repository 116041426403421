import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { SHOP_ANALYTICS_LOAD } from '../types';
import { IShopAnalytics, IShopAnalyticsResponse } from '../../interfaces/shop/IShopAnalytics';
import { IServiceError } from '../../interfaces/system/IError';

const { main } = apiConfig.endpoints.shop.shopAnalytics;

export const getShopAnalytics =
  () =>
    (
      dispatch: IDispatch<null | IShopAnalytics | IServiceError>,
      getState: () => IState,
    ) => {
      callApi<IShopAnalyticsResponse>(main)
        .then((data: IShopAnalyticsResponse) =>
          dispatch({ type: SHOP_ANALYTICS_LOAD, payload: data.analytics }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
