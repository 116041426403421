import { appConfig } from './app.config';

const hostName: string = appConfig.isDev
  ? 'http://localhost:3002'
  : 'https://auction-api.create.auction';

export const apiConfig = {
  hostName,
  host: `${hostName}/v1/`,
  socketHost: `${hostName}`,
  endpoints: {
    auctions: {
      auction: {
        main: 'auctions/auction/main',
        mode: 'auctions/auction/mode',
        byPath: 'auctions/auction/byPath',
        weekEnd: 'auctions/auction/weekEnd',
      },
      bids: {
        ids: 'auctions/bid/ids',
        one: 'auctions/bid/one',
        last: 'auctions/bid/last',
        main: 'auctions/bid/main',
      },
      lotsPublic: {
        ids: 'auctions/lot/public/ids',
        one: 'auctions/lot/public/one',
        main: 'auctions/lot/public/main',
        cnt: 'auctions/lot/public/cnt',
      },
      lotsAdmin: {
        start: 'auctions/lot/admin/start',
        win: 'auctions/lot/admin/win',
        restart: 'auctions/lot/admin/restart',
        nextLot: 'auctions/lot/admin/next/lot',
        nextStep: 'auctions/lot/admin/next/step',
      },
      lotsModeration: {
        ids: 'auctions/lot/moderation/ids',
        one: 'auctions/lot/moderation/one',
        main: 'auctions/lot/moderation/main',
        sort: 'auctions/lot/moderation/sort',
      },
      report: {
        lot: 'auctions/report/lot',
        auction: 'auctions/report/auction',
        broadcastAuction: 'auctions/report/auction/broadcast',
      },
    },
    account: {
      admins: 'account/admins',
      foundations: 'account/foundations',
      main: 'account/main',
      set: 'account/users',
      search: 'account/search',
      batch: 'account/batch',
      auth: {
        token: 'account/auth',
        tokenGoogle: 'account/auth/google/token',
        google: 'account/auth/google',
      },
      notifications: {
        ids: 'account/notification/ids',
        one: 'account/notification/one',
        main: 'account/notification',
        moderation: 'account/notification/moderation',
        moderationList: 'account/notification/moderation/get',
      },
      sellers: {
        analytics: 'sellers/analytics',
        list: 'sellers/list',
        search: 'sellers/search',
        foundations: 'foundations',
      },
      partnerPromo: {
        main: 'partner/promo',
        ids: 'partner/promo/ids',
        one: 'partner/promo/one',
        search: 'partner/promo/search',
      },
      partnerSeller: {
        oneByID: 'partner/seller/id',
        oneByPath: 'partner/seller/path',
        main: 'partner/seller',
        ids: 'partner/seller/ids',
        batch: 'partner/seller/batch',
        search: 'partner/seller/search',
      },
      settings: 'account/settings',
    },
    analytics: {
      retention: {
        main: 'analytics/retention/main',
      },
      techRoute: {
        main: 'analytics/tech/route/main',
        platform: 'analytics/tech/route/platform',
        extended: 'analytics/tech/route/extended',
        options: 'analytics/tech/route/options',
        cache: 'analytics/tech/route/cache',
        cacheAll: 'analytics/tech/route/cache/all',
      },
      techCron: {
        main: 'analytics/tech/cron/main',
        platform: 'analytics/tech/cron/platform',
        cache: 'analytics/tech/cron/cache',
        cacheAll: 'analytics/tech/cron/cache/all',
      },
      techDb: {
        main: 'analytics/tech/db/main',
        platform: 'analytics/tech/db/platform',
        extended: 'analytics/tech/db/extended',
        options: 'analytics/tech/db/options',
        cache: 'analytics/tech/db/cache',
        cacheAll: 'analytics/tech/db/cache/all',
      },
      behavioralRetention: {
        main: 'analytics/behavioral/retention/main',
        platform: 'analytics/behavioral/retention/platform',
        cache: 'analytics/behavioral/retention/cache',
        cacheAll: 'analytics/behavioral/retention/cache/all',
        online: 'analytics/behavioral/retention/online',
        onlinePlatform: 'analytics/behavioral/retention/online/platform',
      },
    },
    archive: {
      reportLotOne: 'archive/report/lot',
      reportLotSet: 'archive/report/lot/set',
      reportLotBatch: 'archive/report/lot/batch',
      reportLotPath: 'archive/report/lot/byPath',
      reportLotSearch: 'archive/report/lot/search',
      reportUserBatch: 'archive/report/user/batch',
      reportUserPath: 'archive/report/user/byPath',
      reportUserAll: 'archive/report/user/all',
      reportUserSearch: 'archive/report/user/search',
    },
    archiveDonation: {
      admin: {
        byAccountID: 'donation/admin/account',
        byAuctionID: 'donation/admin/auction',
      },
      donationAccount: 'donation/item/main',
      donationBank: 'donation/bank/main',
    },
    hall: {
      legendsBatch: 'hall/legends/batch',
      legendsPath: 'hall/legends/byPath',
    },
    shop: {
      product: {
        path: 'shop/product/byPath',
        seller: 'shop/product/bySeller',
        batchIDs: 'shop/product/batch',
        details: 'shop/product/details',
        byIDs: 'shop/product/ids',
        search: 'shop/product/search',
      },
      productAnalytics: {
        byProduct: 'shop/product/analytics/byProduct',
        byAccount: 'shop/product/analytics/byAccount',
        byAccountAll: 'shop/product/analytics/byAccount/all',
        byAccountList: 'shop/product/analytics/byAccount/list',
        view: 'shop/product/analytics/view',
        like: 'shop/product/analytics/like',
        willBid: 'shop/product/analytics/willBid',
      },
      shopAnalytics: {
        main: 'shop/analytics/main',
      },
      productDraft: {
        main: 'shop/product/draft',
        cnt: 'shop/product/draft/cnt',
        byProductID: 'shop/product/draft/byProductID',
      },
      productModeration: {
        main: 'shop/product/moderation',
        decline: 'shop/product/decline',
        onBehalf: 'shop/product/onBehalf',
        batch: 'shop/product/moderation/batch',
        logChange: 'shop/product/log/change',
        logModeration: 'shop/product/log/moderation',
      },
      productSeller: {},
    },
    communication: {
      // emails: {
      //   newProducts: 'system/emails/newProducts',
      //   moderationStarted: 'system/emails/moderationStarted',
      //   moderationProduct: 'system/emails/moderationProduct',
      //   moderationProductDeclined: 'system/emails/moderationProduct/Declined',
      //   moderationOnBehalf: 'system/emails/moderationOnBehalf',
      //   weekAuction: 'system/emails/weekAuction',
      //   weekLotLost: 'system/emails/weekLotLost',
      //   donationDetails: 'system/emails/donationDetails',
      //   systemModeration: 'system/emails/systemModeration',
      //   systemError: 'system/emails/systemError',
      //   systemLead: 'system/emails/systemLead',
      //   auctionFirstAttendee: 'system/emails/auctionFirstAttendee',
      //   auctionFirstLegend: 'system/emails/auctionFirstLegend',
      //   partnerAuction: 'system/emails/partnerAuction',
      // },
      mailing: {
        main: 'communication/mailing',
        ids: 'communication/mailing/ids',
        one: 'communication/mailing/one',
        settings: 'communication/mailing/settings',
        batch: 'communication/mailing/batch',
        preview: 'communication/mailing/preview',
      },
      // recommendation: {
      //   main: 'communication/recommendation/main',
      //   bulk: 'communication/recommendation/bulk',
      //   ids: 'communication/recommendation/ids',
      //   one: 'communication/recommendation/one',
      //   set: 'communication/recommendation/set',
      //   batch: 'communication/recommendation/batch',
      //   search: 'communication/recommendation/search',
      //   params: 'communication/recommendation/params',
      //   paramsSet: 'communication/recommendation/params/set',
      //   paramsBatch: 'communication/recommendation/params/batch',
      // },
      recommendation: {
        main: 'app/communication/recommendation/main',
        bulk: 'app/communication/recommendation/bulk',
        ids: 'app/communication/recommendation/ids',
        one: 'app/communication/recommendation/one',
        set: 'app/communication/recommendation/set',
        batch: 'app/communication/recommendation/batch',
        search: 'app/communication/recommendation/search',
      },
      templateParams: {
        main: 'communication/template/params/main',
        ids: 'communication/template/params/ids',
        one: 'communication/template/params/one',
        set: 'communication/template/params/set',
        batch: 'communication/template/params/batch',
        search: 'communication/template/params/search',
      },
      log: {
        ids: 'communication/log/ids',
        set: 'communication/log/set',
        batch: 'communication/log/batch',
      },
    },
    parsing: {
      items: {
        main: 'parsing/items/main',
        bulk: 'parsing/items/bulk',
        ids: 'parsing/items/ids',
        one: 'parsing/items/one',
        set: 'parsing/items/set',
        batch: 'parsing/items/batch',
        search: 'parsing/items/search',
        byRecommendation: 'parsing/items/by/recommendation',
        byParsed: 'parsing/items/by/parsed',
        fromRecommendation: 'parsing/items/from/recommendation',
        parseSite: 'parsing/items/parse/site',
      },
    },
    system: {
      error: 'system/error',
      errorCache: 'system/error/cache',
      errorCacheAll: 'system/error/cache/all',
      log: 'system/log',
    },
  },
};
