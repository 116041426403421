import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  PARSED_ITEM_ACTION,
  PARSED_ITEM_LOAD_BATCH,
  PARSED_ITEM_LOAD_BULK,
  PARSED_ITEM_LOAD_IDS,
  PARSED_ITEM_LOAD_SEARCH,
  PARSED_ITEM_LOAD_SET,
  PARSED_ITEM_PARSE_BY,
  PARSED_ITEM_PARSE_SITE,
} from '../../actions/types';
import { ActionCase, IStateParsing } from '../../interfaces/system/IState';
import {
  IParsedItemAction,
  IParsedItemParsingData,
  IParsedItemSet,
} from '../../interfaces/parsing/IParsedItem';
import {
  DataBatchKey,
  DataBatchWide,
  DataSearch,
} from '../../interfaces/system/data';
import { IParsedItemStats } from '../../interfaces/parsing/IParsedItemStats';
import { IParsedItemFromSiteResponse } from '../../interfaces/parsing/IParsedItemSite';

const initialStateCommunication: IStateParsing = {
  items: {
    byItemID: {},
    itemIDs: [],
    limit: 10,
    byBatchKey: {},
    IDsBySearch: {},
    actions: [],
    actionsSetByUrl: {},
    parsing: null,
    site: null,
  },
  bulk: {
    stats: null,
  },
};

export const parsingReducer: Reducer = createReducer(
  initialStateCommunication,
  (builder: ActionReducerMapBuilder<IStateParsing>) => {
    builder.addCase<string, ActionCase<IParsedItemSet>>(
      PARSED_ITEM_LOAD_SET,
      (state: Draft<IStateParsing>, action: ActionCase<IParsedItemSet>) => {
        Object.keys(action.payload).forEach((recommendationID: string) => {
          state.items.byItemID[recommendationID] =
            action.payload[recommendationID];
        });
      }
    );
    builder.addCase<string, ActionCase<string[]>>(
      PARSED_ITEM_LOAD_IDS,
      (state: Draft<IStateParsing>, action: ActionCase<string[]>) => {
        state.items.itemIDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<DataBatchKey<string>>>(
      PARSED_ITEM_LOAD_BATCH,
      (
        state: Draft<IStateParsing>,
        action: ActionCase<DataBatchKey<string>>
      ) => {
        const { cnt, items, batchKey } = action.payload;

        const { byBatchKey } = state.items;
        if (!byBatchKey[batchKey]) {
          byBatchKey[batchKey] = {
            loaded: 0,
            IDs: [],
            cnt: 0,
          };
        }

        const batchSet: DataBatchWide = byBatchKey[batchKey];
        batchSet.loaded += items.length;
        batchSet.IDs = batchSet.IDs.concat(items);
        batchSet.cnt = cnt;
      }
    );
    builder.addCase<string, ActionCase<IParsedItemStats>>(
      PARSED_ITEM_LOAD_BULK,
      (state: Draft<IStateParsing>, action: ActionCase<IParsedItemStats>) => {
        state.bulk.stats = action.payload;
      }
    );
    builder.addCase<string, ActionCase<DataSearch<string>>>(
      PARSED_ITEM_LOAD_SEARCH,
      (state: Draft<IStateParsing>, action: ActionCase<DataSearch<string>>) => {
        const { search, items } = action.payload;
        state.items.IDsBySearch[search] = items;
      }
    );
    builder.addCase<string, ActionCase<IParsedItemParsingData>>(
      PARSED_ITEM_PARSE_BY,
      (
        state: Draft<IStateParsing>,
        action: ActionCase<IParsedItemParsingData>
      ) => {
        state.items.parsing = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IParsedItemFromSiteResponse>>(
      PARSED_ITEM_PARSE_SITE,
      (
        state: Draft<IStateParsing>,
        action: ActionCase<IParsedItemFromSiteResponse>
      ) => {
        state.items.site = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IParsedItemAction>>(
      PARSED_ITEM_ACTION,
      (state: Draft<IStateParsing>, action: ActionCase<IParsedItemAction>) => {
        state.items.actions.unshift(action.payload);
        const { url } = action.payload;
        if (url) {
          if (!state.items.actionsSetByUrl[url]) {
            state.items.actionsSetByUrl[url] = [];
          }
          state.items.actionsSetByUrl[url].unshift(action.payload.action);
        }
      }
    );
  }
);
