import React, { useEffect, useState } from 'react';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
  PageHead,
} from '../../../common/typos';
import { IProduct } from '../../../interfaces/shop/IProduct';
import { ProductGallery } from './ProductGallery';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import {
  setLike,
  setView,
} from '../../../actions/shop/productAnalytics.actions';
import {
  IProductAnalytics,
  IProductAnalyticsByAccount,
} from '../../../interfaces/shop/IProductAnalytics';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { appConfig } from '../../../config/app.config';
import { makePathFromName } from '../../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { GalleryBlock, ImageIcon } from '../../../common/images/Image.styles';
import { margins } from '../../../common/sizes';
import { editProduct } from '../../../actions/moderation/admin-shop-moderation.actions';
import { IProductLink } from '../../../interfaces/shop/IProductDraft';
import { ActionLink } from '../../../common/styles';
import { savePathBeforeLogin } from '../../../actions/account/user.actions';
import {
  IPartnerSellerPublic,
  IPartnerSellerSet,
} from '../../../interfaces/account/IPartnerSeller';
import { loadPartnerSellerByAccountID } from '../../../actions/account/partnerSeller.actions';
import { Block } from '../../../common/layout';
import { AdminAction } from '../../auctions/live/Lot.styles';
import { AnalyticsIconItem } from '../shop.styles';
import { L } from '../../../common/locale';

interface IProductItemProps {
  loadPartnerSellerByAccountID: (accountID: string) => void;
  account: null | IAccount;
  product: IProduct;
  analyticsByProductID: Record<string, null | IProductAnalytics>;
  analyticsByProductIDSelf: Record<string, null | IProductAnalyticsByAccount>;
  accounts: IAccountSet;
  setView: (productID: string, mode: 'catalog' | 'details') => void;
  setLike: (productID: string) => void;
  editProduct: (productID: string) => void;
  isAdmin: boolean;
  partnerIDByAccountID: Record<string, null | string>;
  set: IPartnerSellerSet;
}

function ProductItem(props: IProductItemProps) {
  const navigate = useNavigate();
  const [analyticsProduct, setAnalyticsProduct] =
    useState<null | IProductAnalytics>(null);
  const [analyticsSelf, setAnalyticsSelf] =
    useState<null | IProductAnalyticsByAccount>(null);
  const [seller, setSeller] = useState<null | IAccount>(null);
  const [partner, setPartner] = useState<null | IPartnerSellerPublic>(null);

  useEffect(() => {
    props.setView(props.product.id, 'details');
    props.loadPartnerSellerByAccountID(props.product.sellerID);
  }, []);
  useEffect(() => resetAnalytics(), [props.analyticsByProductID]);
  useEffect(() => resetAnalytics(), [props.analyticsByProductIDSelf]);
  useEffect(() => {
    if (seller) {
      return;
    }
    const { accounts } = props;
    const { sellerID } = props.product;
    if (accounts[sellerID]) {
      setSeller(accounts[sellerID]);
    }
  }, [props.accounts]);
  useEffect(() => {
    const partnerID: null | string =
      props.partnerIDByAccountID[props.product.sellerID];
    if (partnerID) {
      setPartner(props.set[partnerID] || null);
    }
  }, [props.partnerIDByAccountID, props.set]);
  const resetAnalytics = () => {
    const id: string = props.product.id;
    setAnalyticsProduct(props.analyticsByProductID[id] || null);
    setAnalyticsSelf(props.analyticsByProductIDSelf[id] || null);
  };

  const like = () => {
    if (!props.account) {
      goToLogin();
      return;
    }

    props.setLike(props.product.id);
  };

  const goToProductsBySeller = () => {
    if (!seller) {
      return;
    }

    const accountPath: string = makePathFromName(partner?.name || seller?.name);
    navigate(
      appConfig.routes.shopProducts.bySeller.replace(
        appConfig.params.accountPath,
        accountPath
      )
    );
  };

  const goToLogin = () => {
    savePathBeforeLogin(window.location.pathname);
    navigate(appConfig.routes.main.login);
  };

  const edit = () => {
    navigate(appConfig.routes.Moderation.shop.edit);
    props.editProduct(props.product.id);
  };

  return (
    <Block>
      <PageHead>{props.product.name}</PageHead>

      {!props.account && (
        <ContentDesc className="fail right" onClick={goToLogin}>
          <ContentBold>
            <ActionLink className="fail">
              <ContentBold>
                <L k={'ActionLogIn'} />
              </ContentBold>
            </ActionLink>{' '}
            <L k={'ActionLogInToLike'} />
          </ContentBold>
        </ContentDesc>
      )}

      {analyticsProduct && (
        <>
          <ContentDesc style={{ textAlign: 'right', margin: margins.item }}>
            <L k={'AnalyticsViewAdmin'} />
          </ContentDesc>
          <Block>
            <AnalyticsIconItem>
              <ImageIcon
                className={'small widthL'}
                src="/icons/view.png"
                alt="view"
              />
              <ContentDescInline>
                {analyticsProduct.views} ({analyticsProduct.viewsUnique})
              </ContentDescInline>
            </AnalyticsIconItem>
            <AnalyticsIconItem onClick={like} className="clickable">
              {(analyticsSelf && analyticsSelf.isLiked && (
                <ImageIcon
                  className={'small'}
                  src="/icons/like-full.png"
                  alt="view"
                />
              )) || (
                <ImageIcon
                  className={'small'}
                  src="/icons/like.png"
                  alt="view"
                />
              )}
              <ContentDescInline>
                {analyticsProduct.likes} <L k={'AnalyticsLikes'} />
              </ContentDescInline>
            </AnalyticsIconItem>
          </Block>
        </>
      )}

      <GalleryBlock>
        <ProductGallery productImagesURL={props.product.imagesURL} />
      </GalleryBlock>

      {props.isAdmin && <AdminAction onClick={edit}>Edit</AdminAction>}

      {seller && (
        <>
          <ContentDesc onClick={goToProductsBySeller} className="clickable">
            <L k={'ProductBy'} />{' '}
            <ActionLink>{partner?.name || seller?.name}</ActionLink>
          </ContentDesc>
          <br />
        </>
      )}

      <ContentDesc>{props.product.description}</ContentDesc>

      {props.product.links &&
        props.product.links.map((link: IProductLink) => (
          <a href={link.url} target="_blank" key={link.url}>
            {link.name}
          </a>
        ))}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    isAdmin: state.Account.isAdmin,
    analyticsByProductID: state.ShopProductAnalytics.byProductID,
    analyticsByProductIDSelf: state.ShopProductAnalytics.byProductIDSelf,
    partnerIDByAccountID: state.Account.partnerSeller.IDByAccountID,
    set: state.Account.partnerSeller.set,
    accounts: state.Account.accounts.set,
  }),
  {
    loadPartnerSellerByAccountID,
    setView,
    setLike,
    editProduct,
  }
)(ProductItem);
