import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { DataBatch, DataBatchKey } from '../../interfaces/system/data';
import {
  IArchiveReportUser,
  IArchiveReportUserResponse,
  IArchiveReportUserSearch,
  IArchiveReportUserSet,
  IReportUserOrder,
} from '../../interfaces/archive/IArchiveReportUser';
import {
  ARCHIVE_REPORT_USER_LOAD,
  ARCHIVE_REPORT_USERS_BATCH_LOAD,
  ARCHIVE_REPORT_USERS_SEARCH,
} from '../types';
import { loadAccountsSet } from '../account/accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { loadArchiveReportLotSet } from './archiveReportLot.actions';
import { getComplexPathFromPath } from '../../utils/archive';
import { IServiceError } from '../../interfaces/system/IError';
import { IArchiveReportLotSetData } from '../../interfaces/archive/IArchiveReportLot';
import { IAuctionDataSet } from '../../interfaces/auctions/IAuction';

const { reportUserPath, reportUserBatch, reportUserAll, reportUserSearch } =
  apiConfig.endpoints.archive;

export function prepareReportUsersBatchKey(
  auctionID: string,
  order: IReportUserOrder
): string {
  return JSON.stringify({
    auctionID,
    order,
  });
}

export const loadReportUsersBatchNext =
  (auctionID: string, order: IReportUserOrder) =>
  (
    dispatch: IDispatch<
      | DataBatchKey<IArchiveReportUser>
      | IAuctionDataSet
      | IAccountSet
      | IArchiveReportLotSetData
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const batchKey: string = prepareReportUsersBatchKey(auctionID, order);
    const { limit, byBatchKey } = getState().Archive.reportUsers;

    const skip: number =
      (byBatchKey[batchKey] && byBatchKey[batchKey].loaded) || 0;

    callApi<DataBatch<IArchiveReportUser>>(reportUserBatch, {
      limit: limit.toString(),
      skip: skip.toString(),
      order,
      auctionID,
    })
      .then((data: DataBatch<IArchiveReportUser>) => {
        const payload: DataBatchKey<IArchiveReportUser> = {
          ...data,
          batchKey,
        };
        dispatch({ type: ARCHIVE_REPORT_USERS_BATCH_LOAD, payload });

        const accountIDs: string[] = [];
        const lotIDSet: Record<string, null> = {};
        const lotIDs: string[] = [];
        data.items.forEach((report: IArchiveReportUser) => {
          accountIDs.push(report.accountID);

          report.lotsBought.forEach(
            (lotID: string) => (lotIDSet[lotID] = null)
          );
          report.lotsSold.forEach((lotID: string) => (lotIDSet[lotID] = null));
        });
        loadArchiveReportLotSet(lotIDs)(dispatch, getState);
        loadAccountsSet(accountIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

const operateUserResponse = (
  dispatch: IDispatch<
    | IArchiveReportUserSet
    | IAuctionDataSet
    | IAccountSet
    | IArchiveReportLotSetData
    | IServiceError
  >,
  getState: () => IState,
  auctionPath: string,
  data: IArchiveReportUserResponse
) => {
  const payload: IArchiveReportUserSet = {
    auctionPath,
    reports: data.reports,
  };
  dispatch({ type: ARCHIVE_REPORT_USER_LOAD, payload });

  postResponseLoads(dispatch, getState, data);
};

const postResponseLoads = (
  dispatch: IDispatch<
    IAuctionDataSet | IAccountSet | IArchiveReportLotSetData | IServiceError
  >,
  getState: () => IState,
  data: IArchiveReportUserResponse
) => {
  const accountIDSet: Record<string, null> = {};
  const lotIDSet: Record<string, null> = {};

  data.reports.forEach((report: IArchiveReportUser) => {
    accountIDSet[report.accountID] = null;
    report.lotsSold.forEach((lotID: string) => (lotIDSet[lotID] = null));
    report.lotsBought.forEach((lotID: string) => (lotIDSet[lotID] = null));
  });

  loadArchiveReportLotSet(Object.keys(lotIDSet))(dispatch, getState);
  loadAccountsSet(Object.keys(accountIDSet))(dispatch, getState);
};

export const loadReportUserAll =
  (accountPath: string) =>
  (
    dispatch: IDispatch<
      | IArchiveReportUserSet
      | IAuctionDataSet
      | IAccountSet
      | IArchiveReportLotSetData
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const complexPath: string = getComplexPathFromPath('', accountPath);
    const { IDByComplexPath, set } = getState().Archive.reportUsers;
    const IDs: string[] = IDByComplexPath[complexPath];
    if (IDs && IDs[0]) {
      const reports: IArchiveReportUser[] = [];
      IDs.forEach((id: string) => {
        reports.push(set[id]);
      });
      postResponseLoads(dispatch, getState, { reports });
      return;
    }

    callApi<IArchiveReportUserSet>(reportUserAll, { accountPath })
      .then((data: IArchiveReportUserResponse) =>
        operateUserResponse(dispatch, getState, '', data)
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadReportUserByPath =
  (auctionPath: string, accountPath: string) =>
  (
    dispatch: IDispatch<
      | IArchiveReportUserSet
      | IAuctionDataSet
      | IAccountSet
      | IArchiveReportLotSetData
      | IServiceError
    >,
    getState: () => IState
  ) => {
    callApi<IArchiveReportUserSet>(reportUserPath, { accountPath, auctionPath })
      .then((data: IArchiveReportUserResponse) =>
        operateUserResponse(dispatch, getState, auctionPath, data)
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const searchReportUser =
  (search: string, auctionID: string) =>
  (
    dispatch: IDispatch<
      | IArchiveReportUserSearch
      | IAuctionDataSet
      | IAccountSet
      | IArchiveReportLotSetData
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (search === '') {
      return;
    }
    if (
      getState().Archive.reportUsers.IDsBySearch[`${auctionID}-${search}`] !==
      undefined
    ) {
      return;
    }

    callApi<IArchiveReportUserSet>(reportUserSearch, { search, auctionID })
      .then((data: IArchiveReportUserResponse) => {
        const payload: IArchiveReportUserSearch = {
          search,
          auctionID,
          reports: data.reports,
        };

        dispatch({ type: ARCHIVE_REPORT_USERS_SEARCH, payload });
        postResponseLoads(dispatch, getState, data);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
