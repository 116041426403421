import { IFormItem } from './FormCommon';
import { PlatformTypeProdList } from '../../config/platform.config';

export interface IValueRecordItem {
  key: string;
  keyName?: string;
  value: string;
  valueName?: string;
}

export type IValueRecord = IValueRecordItem[];

export type IFormsFieldsKey =
  | 'platformType'
  | 'date'
  | 'name'
  | 'description'
  | 'geo'
  | 'social'
  | 'params'
  | 'emails'
  | 'cnt'
  | 'url'
  | 'imageURL';

export const FormsFields: Record<IFormsFieldsKey, IFormItem> = {
  platformType: {
    mode: 'select',
    isRequired: true,
    defaultSelect: PlatformTypeProdList,
  },
  date: {
    mode: 'date',
    isRequired: false,
  },
  name: {
    mode: 'short',
    isRequired: true,
    min: 3,
    max: 100,
  },
  description: {
    mode: 'long',
    isRequired: true,
    min: 10,
    max: 1000,
  },
  geo: {
    mode: 'short',
    isRequired: true,
    min: 3,
    max: 25,
  },
  social: {
    mode: 'short',
    isRequired: false,
    min: 3,
    max: 100,
    valueMode: 'object',
    defaultObject: {
      linkedin: '',
      fb: '',
      x: '',
      inst: '',
      t: '',
    },
  },
  params: {
    mode: 'short',
    isRequired: true,
    min: 3,
    max: 100,
    valueMode: 'record',
  },
  emails: {
    mode: 'short',
    isRequired: true,
    min: 3,
    max: 150,
    valueMode: 'array',
  },
  cnt: {
    mode: 'input',
    modeInput: 'number',
    isRequired: true,
  },
  url: {
    mode: 'input',
    modeInput: 'url',
    isRequired: false,
    min: 3,
    max: 50,
  },
  imageURL: {
    mode: 'images',
    isRequired: true,
    min: 1,
    max: 1,
  },
};

export function transformToRecord(
  object: null | undefined | Record<string, string>
): IValueRecord {
  const record: IValueRecord = [];
  if (object) {
    Object.keys(object).forEach((key: string) => {
      record.push({ key, value: object[key] });
    });
  }

  return record;
}

export function transformRecordToSave(
  record: null | undefined | IValueRecord
): null | Record<string, string> {
  if (!record) {
    return null;
  }
  const object: Record<string, string> = {};

  record.forEach((item: IValueRecordItem) => {
    if (item.key === '') {
      return;
    }
    object[item.key] = item.value;
  });

  return object;
}

export interface IFormCb {
  cbCancel?: () => void;
  cbCreate?: () => void;
  cbUpdate?: () => void;
  cbDelete?: () => void;
  cbValidation?: (isValid: boolean) => void;
  cbFieldUpdates?: () => void;
}

export interface IFormAction {
  isUpdate: boolean;
  isDisabled?: boolean;
  isSending: boolean;
  updateAll?: boolean;
  createActionTitle?: string;
  editActionTitle?: string;
  removeActionTitle?: string;
  cancelActionTitle?: string;
}
