import React from 'react';
import { ContentBold, PageDesc } from '../typos';
import { IAuction } from '../../interfaces/auctions/IAuction';

interface IStartSoonProps {
  auctionNext: undefined | null | IAuction;
}

export const StartSoon = (props: IStartSoonProps) => {
  return (
    <>
      <PageDesc>Will start soon ...</PageDesc>

      {props.auctionNext && props.auctionNext.dateAuction && (
        <ContentBold>
          {new Date(props.auctionNext.dateAuction).toLocaleDateString()}
          <br />
          {new Date(props.auctionNext.dateAuction).toLocaleTimeString()}
        </ContentBold>
      )}
    </>
  );
};
