import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { DataBatch } from '../../interfaces/system/data';
import {
  DataBatchSetHallLegend,
  IHallLegend,
  IHallLegendByNameResponse,
  IHallLegendByNameSet,
  IHallLegendOrder,
} from '../../interfaces/hall/IHallLegends';
import { HALL_BATCH_IS_LOAD, HALL_LEGEND_IS_LOAD } from '../types';
import { loadAccountsSet } from '../account/accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { IServiceError } from '../../interfaces/system/IError';

const { legendsBatch, legendsPath } = apiConfig.endpoints.hall;

export const loadLegendsNext =
  (order: IHallLegendOrder) =>
  (
    dispatch: IDispatch<DataBatch<IHallLegend> | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    const { batchLimit, batch } = getState().Hall;

    callApi<DataBatch<IHallLegend>>(legendsBatch, {
      limit: batchLimit.toString(),
      skip: ((batch[order] && batch[order].loaded) || 0).toString(),
      order,
    })
      .then((data: DataBatch<IHallLegend>) => {
        const payload: DataBatchSetHallLegend = {
          ...data,
          order,
        };
        dispatch({ type: HALL_BATCH_IS_LOAD, payload });

        const accountIDs: string[] = [];
        data.items.forEach((legend: IHallLegend) => {
          accountIDs.push(legend.accountID);
        });
        loadAccountsSet(accountIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadLegend =
  (accountPath: string) =>
  (
    dispatch: IDispatch<IHallLegendByNameSet | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    if (getState().Hall.IDByPath[accountPath]) {
      return;
    }

    callApi<IHallLegendByNameResponse>(legendsPath, { accountPath })
      .then((data: IHallLegendByNameResponse) => {
        const payload: IHallLegendByNameSet = {
          accountPath,
          legends: data.legends,
        };
        dispatch({ type: HALL_LEGEND_IS_LOAD, payload });
        loadAccountsSet(
          data.legends.map((legend: IHallLegend) => legend.accountID)
        )(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
