import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  IProductDraft,
  IProductLink,
} from '../../interfaces/shop/IProductDraft';
import { DataBatch, DataBatchKeySpecific } from '../../interfaces/system/data';
import {
  IProductLogChange,
  IProductLogChangeResponse,
  IProductLogModeration,
  IProductLogModerationResponse,
  IProductModerationCreate,
  IProductModerationDecline,
  IProductModerationOnBehalfCreate,
  IProductModerationUpdate,
} from '../../interfaces/shop/IProductModeration';
import {
  ITEM_CREATED,
  ITEM_UPDATED,
  LOT_ADMIN_ACTION,
  SHOP_MODERATION_BATCH_LOAD,
  SHOP_MODERATION_LOG_CHANGE_LOAD,
  SHOP_MODERATION_LOG_MODERATION_LOAD,
  SHOP_MODERATION_UPDATE,
  SHOP_PRODUCT_EDIT,
} from '../types';
import { loadAccountsSet } from '../account/accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { IServiceError } from '../../interfaces/system/IError';
import { getNotificationsIDs } from '../account/notification.actions';
import {
  IAccountNotification,
  INotificationSet,
} from '../../interfaces/account/IAccountNotification';
import {
  loadProductsBatchNext,
  loadProductsByIDs,
  reloadDetailsByID,
  reloadProductsByID,
} from '../shop/product.actions';
import {
  IProduct,
  IProductPreview,
  IProductPreviewOrder,
} from '../../interfaces/shop/IProduct';
import { IProductAnalyticsByAccount } from '../../interfaces/shop/IProductAnalytics';
import { getShopAnalytics } from '../shop/shopAnalytics.actions';
import { IShopAnalytics } from '../../interfaces/shop/IShopAnalytics';
import { reloadByProductID } from '../shop/productDraft.actions';
import { dispatchError } from '../../service/error';

const { batch, main, decline, onBehalf, logModeration, logChange } =
  apiConfig.endpoints.shop.productModeration;

// export const loadProductModerationNext =
//   () => (dispatch: IDispatch<IProductDraftResponse | string>) => {
//     callApi<IProductDraftResponse>(productModeration.main)
//       .then((data: IProductDraftResponse) => {})
//       .catch((error: Error | IApiError) => catchError(dispatch, error, getState));
//   };

export const editProduct =
  (productID: null | string) =>
  (
    dispatch: IDispatch<
      | null
      | string
      | IProductDraft[]
      | IProductPreview[]
      | IProductAnalyticsByAccount[]
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    if (productID) {
      reloadByProductID(productID)(dispatch, getState);
      loadProductsByIDs([productID])(dispatch, getState);
    }
    dispatch({
      type: SHOP_PRODUCT_EDIT,
      payload: productID,
    });
  };

export const loadLogModeration =
  (productDraftID: string) =>
  (
    dispatch: IDispatch<IProductLogModeration[] | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IProductLogModerationResponse>(logModeration, { productDraftID })
      .then((data: IProductLogModerationResponse) => {
        dispatch({
          type: SHOP_MODERATION_LOG_MODERATION_LOAD,
          payload: data.logs,
        });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadLogChange =
  (productDraftID: string) =>
  (
    dispatch: IDispatch<IProductLogChange[] | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IProductLogChangeResponse>(logChange, { productDraftID })
      .then((data: IProductLogChangeResponse) => {
        dispatch({ type: SHOP_MODERATION_LOG_CHANGE_LOAD, payload: data.logs });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadProductModerationBatchNext =
  (hardReload?: boolean) =>
  (
    dispatch: IDispatch<DataBatch<IProductDraft> | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const { limit, loaded } = getState().ShopModeration.products;
    callApi<DataBatch<IProductDraft>>(batch, {
      limit: limit.toString(),
      skip: hardReload ? '0' : loaded.toString(),
    })
      .then((data: DataBatch<IProductDraft>) => {
        dispatch({ type: SHOP_MODERATION_BATCH_LOAD, payload: data });

        const accountIDSet: Record<string, null> = {};
        data.items.forEach(
          (draft: IProductDraft) => (accountIDSet[draft.sellerID] = null)
        );
        loadAccountsSet(Object.keys(accountIDSet))(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const updateProductModeration =
  (
    productID: string,
    productDraftID: string,
    name: null | string,
    description: null | string,
    isHide: null | boolean,
    imagesURL: null | string[],
    links: undefined | null | IProductLink[]
  ) =>
  (
    dispatch: IDispatch<
      | string
      | IAccountNotification[]
      | IProductPreview[]
      | IProduct
      | IProductAnalyticsByAccount[]
      | string[]
      | INotificationSet
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IProductModerationUpdate = {
      productID,
      productDraftID,
    };

    if (name) {
      body.name = name;
    }
    if (description) {
      body.description = description;
    }
    if (isHide !== null) {
      body.isHide = isHide;
    }
    if (imagesURL) {
      body.imagesURL = imagesURL;
    }
    if (links) {
      body.links = links;
    }

    callApi(main, {}, body, 'POST')
      .then(() => dispatch({ type: LOT_ADMIN_ACTION, payload: ITEM_UPDATED }))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Lot is updated',
          params: {},
          type: 'Info',
        })
      )
      .then(() =>
        dispatch({ type: SHOP_MODERATION_UPDATE, payload: productDraftID })
      )
      .then(() => reloadProductsByID(productID)(dispatch, getState))
      .then(() => reloadDetailsByID(productID)(dispatch, getState))
      .then(() => getNotificationsIDs()(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const createProductModeration =
  (
    auctionID: string,
    productDraftID: string,
    sellerID: string,
    name: string,
    description: string,
    isHide: boolean,
    imagesURL: string[],
    links: undefined | null | IProductLink[]
  ) =>
  (
    dispatch: IDispatch<
      | string
      | null
      | IShopAnalytics
      | IAccountNotification[]
      | DataBatchKeySpecific<string, IProductPreviewOrder>
      | IProductPreview[]
      | IProductAnalyticsByAccount[]
      | string[]
      | INotificationSet
      | DataBatch<IProductDraft>
      | IAccountSet
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IProductModerationCreate = {
      auctionID,
      productDraftID,
      sellerID,
      name,
      description,
      isHide,
      imagesURL,
      links: links || undefined,
    };
    callApi(main, {}, body)
      .then(() =>
        dispatchError(dispatch, {
          message: 'Lot is created',
          params: {},
          type: 'Info',
        })
      )
      .then(() => dispatch({ type: LOT_ADMIN_ACTION, payload: ITEM_CREATED }))
      .then(() =>
        dispatch({ type: SHOP_MODERATION_UPDATE, payload: productDraftID })
      )
      .then(() => loadProductsBatchNext('new', true)(dispatch, getState))
      .then(() => loadProductModerationBatchNext(true)(dispatch, getState))
      .then(() => getShopAnalytics()(dispatch, getState))
      .then(() => getNotificationsIDs()(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const createProductOnBehalfModeration =
  (
    auctionID: string,
    sellerID: string,
    name: string,
    description: string,
    isHide: boolean,
    imagesBase64: string[],
    links: undefined | null | IProductLink[]
  ) =>
  (
    dispatch: IDispatch<
      | string
      | null
      | IShopAnalytics
      | IAccountNotification[]
      | DataBatchKeySpecific<string, IProductPreviewOrder>
      | IProductPreview[]
      | IProductAnalyticsByAccount[]
      | string[]
      | INotificationSet
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IProductModerationOnBehalfCreate = {
      auctionID,
      sellerID,
      name,
      description,
      isHide,
      imagesBase64,
      links: links || undefined,
    };
    callApi(onBehalf, {}, body)
      .then(() => dispatch({ type: LOT_ADMIN_ACTION, payload: ITEM_CREATED }))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Lot is created',
          params: {},
          type: 'Info',
        })
      )
      .then(() => loadProductsBatchNext('new', true)(dispatch, getState))
      .then(() => getShopAnalytics()(dispatch, getState))
      .then(() => getNotificationsIDs()(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const declineProduct =
  (productDraftID: string, reason: string) =>
  (
    dispatch: IDispatch<
      | string
      | IAccountNotification[]
      | string[]
      | INotificationSet
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IProductModerationDecline = {
      productDraftID,
      reason,
    };
    callApi(decline, {}, body)
      .then(() =>
        dispatch({ type: SHOP_MODERATION_UPDATE, payload: productDraftID })
      )
      .then(() => dispatch({ type: LOT_ADMIN_ACTION, payload: ITEM_CREATED }))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Lot is declined',
          params: {},
          type: 'Info',
        })
      )
      .then(() => getNotificationsIDs()(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
