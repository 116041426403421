import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { loadLegend } from '../../actions/hall/hallLegends.actions';
import { IHallLegend } from '../../interfaces/hall/IHallLegends';
import { Block } from '../../common/layout';
import { ContentBold, PageDesc, PageHead } from '../../common/typos';
import { appConfig } from '../../config/app.config';
import AccountImage from '../../components/account/AccountImage';
import { LegendItemPrice } from '../../components/hall/LegendItemPrice';
import { loadReportUserAll } from '../../actions/archive/archiveReportUser.actions';
import { getComplexPathFromPath } from '../../utils/archive';
import { IArchiveReportUser } from '../../interfaces/archive/IArchiveReportUser';
// import ArchiveReportLotItem from '../../components/archive/ArchiveReportLotItem';
import { getAuctionList } from '../../actions/auctions/auction.actions';
import { sendPageEvent } from '../../utils/analytics';
import { IAuction } from '../../interfaces/auctions/IAuction';
import AuctionItemModeration from '../../components/auctions/AuctionItemModeration';
import ReportLotItem from '../../components/archive/ReportLotItem';
import { IParamsItemView } from '../../common/common';
import { L } from '../../common/locale';
import { useTranslation } from 'react-i18next';

interface IHallLegendPageProps {
  getAuctionList: () => any;
  loadLegend: (accountPath: string) => any;
  loadReportUserAll: (accountPath: string) => any;
  set: Record<string, IHallLegend>;
  IDByPath: Record<string, string[]>;
  accounts: IAccountSet;
  reportUsersSet: Record<string, IArchiveReportUser>;
  IDByComplexPath: Record<string, string[]>;
  auctionsByID: Record<string, IAuction>;
}

function HallLegendPage(props: IHallLegendPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountPath } = useParams();
  // const [users, setUsers] = useState<IAccount[]>(); // multiple accounts with one name
  const [accounts, setAccounts] = useState<Record<string, IAccount>>(); // multiple accounts with one name
  const [legends, setLegends] = useState<IHallLegend[]>(); // multiple accounts with one name
  const [reports, setReports] = useState<null | IArchiveReportUser[]>(null);

  const view: IParamsItemView = {
    sellerID: false,
    buyerList: false,
  };

  useEffect(() => sendPageEvent('HallLegend'), []);
  useEffect(() => {
    props.getAuctionList();
    if (accountPath) {
      props.loadLegend(accountPath);
      props.loadReportUserAll(accountPath);
    } else {
      goToHall();
    }
  }, []);

  useEffect(() => {
    if (!accountPath) {
      return;
    }
    const legendsIDsLocal: string[] = props.IDByPath[accountPath] || [];
    const legendsLocal: IHallLegend[] = [];
    legendsIDsLocal.forEach((legendsID: string) => {
      const legend: IHallLegend = props.set[legendsID];
      if (legend) {
        legendsLocal.push(legend);
      }
    });
    if (!legendsLocal[0]) {
      return;
    }
    setLegends(legendsLocal);
    const accountsLocal: Record<string, IAccount> = {};
    legendsLocal.forEach((legend: IHallLegend) => {
      const { accountID } = legend;
      accountsLocal[accountID] = props.accounts[accountID];
    });

    setAccounts(accountsLocal);
  }, [props.IDByPath, props.set, props.accounts]);

  useEffect(() => {
    if (reports) {
      return;
    }

    if (!accountPath) {
      return;
    }

    const complexPath: string = getComplexPathFromPath('', accountPath);
    const reportIDs: undefined | string[] = props.IDByComplexPath[complexPath];
    const preReports: IArchiveReportUser[] = [];
    if (reportIDs) {
      reportIDs.forEach((reportID: string) => {
        const preReport: undefined | IArchiveReportUser =
          props.reportUsersSet[reportID];
        if (preReport) {
          preReports.push(preReport);
        }
      });
    }

    if (preReports[0]) {
      setReports(preReports);
    }
  }, [props.IDByComplexPath, props.reportUsersSet]);

  const goToHall = () => {
    navigate(appConfig.routes.hall.legends);
  };

  const renderDonated = (legend: IHallLegend) => {
    return (
      <Block>
        <LegendItemPrice
          price={(legend && legend.buyTotal) || 0}
          title={t('HallSortDonated')}
          isBold={true}
        />
      </Block>
    );
  };

  const renderSold = (legend: IHallLegend) => {
    return (
      <Block>
        <LegendItemPrice
          price={(legend && legend.sellTotal) || 0}
          title={t('HallSortSold')}
          isBold={true}
        />
      </Block>
    );
  };

  return (
    <AppContent>
      <Block>
        <PageHead onClick={goToHall} style={{ cursor: 'pointer' }}>
          <L k={'HallTitle'} />
        </PageHead>

        {legends &&
          legends.map((legend: IHallLegend) => (
            <>
              {accounts && accounts[legend.accountID] && (
                <>
                  <AccountImage accountID={legend.accountID} />
                  <PageDesc>{accounts[legend.accountID].name}</PageDesc>
                </>
              )}

              {legend && (
                <>
                  {legend.buyTotal > legend.sellTotal ? (
                    <>
                      {renderDonated(legend)}
                      {renderSold(legend)}
                    </>
                  ) : (
                    <>
                      {renderSold(legend)}
                      {renderDonated(legend)}
                    </>
                  )}
                </>
              )}
            </>
          ))}

        {reports &&
          reports.map((report: IArchiveReportUser) => (
            <Block key={report.auctionID}>
              {props.auctionsByID[report.auctionID] && (
                <AuctionItemModeration
                  auction={props.auctionsByID[report.auctionID]}
                  isClickable={true}
                  showInline={false}
                />
              )}

              {(report.lotsSold[0] ||
                (report.weekLotsSold && report.weekLotsSold[0])) && (
                <ContentBold>
                  <L k={'AccountLotsOrderSold'} />
                </ContentBold>
              )}

              {report.lotsSold[0] &&
                report.lotsSold.map((lotID: string) => (
                  <ReportLotItem
                    lotID={lotID}
                    key={lotID}
                    showInline={true}
                    ParamsItemView={view}
                  />
                ))}
              {report.weekLotsSold &&
                report.weekLotsSold[0] &&
                report.weekLotsSold.map((weekLotID: string) => (
                  <ReportLotItem
                    lotID={weekLotID}
                    key={weekLotID}
                    showInline={true}
                    ParamsItemView={view}
                  />
                ))}

              {(report.lotsBought[0] ||
                (report.weekLotsBought && report.weekLotsBought[0])) && (
                <ContentBold>
                  <L k={'AccountLotsOrderDonated'} />
                </ContentBold>
              )}

              {report.lotsBought[0] &&
                report.lotsBought.map((lotID: string) => (
                  <ReportLotItem
                    lotID={lotID}
                    key={lotID}
                    showInline={true}
                    ParamsItemView={view}
                  />
                ))}
              {report.weekLotsBought &&
                report.weekLotsBought[0] &&
                report.weekLotsBought.map((weekLotID: string) => (
                  <ReportLotItem
                    lotID={weekLotID}
                    key={weekLotID}
                    showInline={true}
                    ParamsItemView={view}
                  />
                ))}
            </Block>
          ))}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Hall.set,
    IDByPath: state.Hall.IDByPath,
    accounts: state.Account.accounts.set,
    reportUsersSet: state.Archive.reportUsers.set,
    IDByComplexPath: state.Archive.reportUsers.IDByComplexPath,
    auctionsByID: state.Auction.auctions.set,
  }),
  { loadLegend, loadReportUserAll, getAuctionList }
)(HallLegendPage);
