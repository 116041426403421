import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { ActionButton, ActionLink } from '../../../common/styles';
import { ContentDesc } from '../../../common/typos';
import { IProductPreview } from '../../../interfaces/shop/IProduct';
import LotForm from './LotForm';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { IProductAnalytics } from '../../../interfaces/shop/IProductAnalytics';
import { getAnalytics } from '../../../actions/shop/productAnalytics.actions';
import { margins } from '../../../common/sizes';
import { ILotIDs } from '../../../interfaces/auctions/ILotAdmin';
import { ILotType } from '../../../interfaces/auctions/ILotPublic';
import { IAccount } from '../../../interfaces/account/IAccount';
import { Block } from '../../../common/layout';
import { Image } from '../../../common/images/Image.styles';

interface ILotShopItemProps {
  account: null | IAccount;
  lotType: ILotType;
  product: IProductPreview;
  getAnalytics: (productID: string) => void;
  analyticsByProductID: Record<string, null | IProductAnalytics>;
  liveLotIDs: ILotIDs[];
  weekLotIDs: ILotIDs[];
  liveLotIDByProductID: Record<string, string>;
  weekLotIDByProductID: Record<string, string>;
}

function LotShopItem(props: ILotShopItemProps) {
  const navigate = useNavigate();
  const [lotExistsMode, setLotExistsMode] = useState<null | ILotType>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<null | IProductAnalytics>(null);

  useEffect(() => {
    const { id } = props.product;
    const analyticsLocal: null | IProductAnalytics =
      props.analyticsByProductID[id];
    if (analyticsLocal) {
      setAnalytics(analyticsLocal);
    } else {
      if (!isLoad) {
        setIsLoad(true);
        props.getAnalytics(id);
      }
    }
  }, [props.analyticsByProductID]);

  useEffect(() => {
    const productID: string = props.product.id;
    let mode: null | ILotType = null;
    if (props.liveLotIDByProductID[productID]) {
      mode = 'Live';
    } else if (props.weekLotIDByProductID[productID]) {
      mode = 'Week';
    }

    setLotExistsMode(mode);
  }, [
    props.product.id,
    props.liveLotIDByProductID,
    props.weekLotIDByProductID,
  ]);

  const goToProduct = (route: string) => {
    navigate(
      appConfig.routes.shopProducts.one.replace(
        `${appConfig.params.accountPath}/${appConfig.params.productPath}`,
        route
      )
    );
  };

  return (
    <Block key={props.product.id}>
      <Block className={'flexTop'}>
        <Block>
          <Image
            className={'inline S radius'}
            src={props.product.imageURL}
            alt={props.product.name}
          />
        </Block>

        <Block>
          {lotExistsMode === 'Live' && (
            <ContentDesc
              style={{ marginRight: margins.block }}
              className="success"
            >
              * Live Auction
            </ContentDesc>
          )}
          {lotExistsMode === 'Week' && (
            <ContentDesc
              style={{ marginRight: margins.block }}
              className="fail"
            >
              Week Auction
            </ContentDesc>
          )}
          {lotExistsMode === null && (
            <ActionButton onClick={() => setIsOpen(true)} disabled={isOpen}>
              Create {props.lotType === 'Week' ? 'Week' : 'Live'} Lot
            </ActionButton>
          )}
        </Block>
      </Block>

      <ActionLink
        onClick={() => goToProduct(props.product.route)}
        className="clickable"
      >
        {props.product.name}
      </ActionLink>

      {analytics && (
        <>
          <ContentDesc>Views: {analytics.views}</ContentDesc>
          <ContentDesc>Unique: {analytics.viewsUnique}</ContentDesc>
          <ContentDesc>Likes: {analytics.likes}</ContentDesc>
          <ContentDesc>WillBids: {analytics.willBids}</ContentDesc>
        </>
      )}

      {isOpen && (
        <LotForm
          product={props.product}
          lotType={props.lotType}
          cbCancel={() => setIsOpen(false)}
        />
      )}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    analyticsByProductID: state.ShopProductAnalytics.byProductID,
    liveLotIDs: state.Lot.moderation.LiveIDs,
    weekLotIDs: state.Lot.moderation.WeekIDs,
    liveLotIDByProductID: state.Lot.moderation.LiveLotIDByProductID,
    weekLotIDByProductID: state.Lot.moderation.WeekLotIDByProductID,
  }),
  {
    getAnalytics,
  }
)(LotShopItem);
