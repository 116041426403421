const params = {
  auctionPath: `:auctionPath`,
  reportPath: `:reportPath`,
  accountPath: `:accountPath`,
  productDraftID: `:productDraftID`,
  productPath: `:productPath`,
  token: `:token`,
  itemID: `:itemID`,
};

export const appConfig = {
  isDev: process.env.NODE_ENV === `development`,
  devPlatform: process.env.REACT_APP_PLATFORM || null,
  params,
  routes: {
    main: {
      home: '/',
      login: `/login`,
      loginToken: `/login/${params.token}`,
    },
    live: {
      auction: `/auction`,
      presenter: `/presenter`,
      resultAuction: `/result/auction`,
      resultLot: `/result/lot`,
      resultFinal: `/result/final`,
      moderation: `/live/moderation`,
    },
    week: {
      auction: `/week/auction`,
      lot: `/week/auction/${params.productPath}`,
      // presenter: `/presenter`,
      // resultAuction: `/result/auction`,
      // resultLot: `/result/lot`,
      // resultFinal: `/result/final`,
      moderation: `/week/moderation`,
    },
    account: {
      myLots: {
        main: `/account/myLots`,
        edit: `/account/myLots/edit`,
      },
      settings: `/account/settings`,
      partnerSettings: `/account/partner/settings`,
      unsubscribe: `/unsubscribe`,
      moderation: `/account/moderation`,
    },
    hall: {
      legends: `/legends`,
      legendByName: `/legends/${params.accountPath}`,
    },
    auctions: {
      list: `/auctions`,
      one: `/auctions/${params.auctionPath}`,
      donations: `/auctions/${params.auctionPath}/donations`,
      lotsList: `/auctions/${params.auctionPath}/lots`,
      lotsOne: `/auctions/${params.auctionPath}/lots/${params.reportPath}`,
      weekLotsList: `/auctions/${params.auctionPath}/week-lots`,
      weekLotsOne: `/auctions/${params.auctionPath}/week-lots/${params.reportPath}`,
      usersList: `/auctions/${params.auctionPath}/accounts`,
      usersOne: `/auctions/${params.auctionPath}/accounts/${params.accountPath}`,
    },
    shop: {
      addLot: `/shop/add-lot`,
    },
    shopProducts: {
      main: `/shop`,
      list: `/shop/products`,
      one: `/shop/products/${params.accountPath}/${params.productPath}`,
      sellers: `/shop/sellers`,
      bySeller: `/shop/products/by/${params.accountPath}`,
      foundations: `/shop/partners`,
    },
    admin: {
      main: '/admin/main',
      // todo implement
      auctions: '/admin/auctions',
      products: '/admin/products',
    },
    analytics: {
      main: '/analytics/main',
    },
    donations: {
      main: '/donations/main',
      moderationBills: `/donations/moderation/bills`,
      moderationBank: `/donations/moderation/bank`,
    },
    //
    Moderation: {
      main: '/moderation',
      CreateAuction: '/moderation/CreateAuction',
      auctions: '/moderation/auctions',
      communication: {
        mailing: '/moderation/communication/mailing',
        mailingOne: `/moderation/communication/mailing/${params.itemID}`,
        users: '/moderation/communication/users',
        recommendation: '/moderation/communication/recommendation',
        templateParams: '/moderation/communication/template/params',
        logs: '/moderation/communication/logs',
      },
      partners: {
        auction: '/moderation/partners/auction',
        feedbacks: '/moderation/partners/feedbacks',
        lot: '/moderation/partners/lot',
        seller: `/moderation/partners/seller`,
      },
      shop: {
        products: `/moderation/shop/products`,
        edit: `/moderation/shop/products/edit`,
        onBehalf: `/moderation/shop/products/onBehalf`,
      },
    },
    Analytics: {
      main: '/analytics',
      cache: '/analytics/cache',
      tech: '/analytics/tech',
    },
  },
};
