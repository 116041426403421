import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { IBid, IBidSet } from '../../../interfaces/auctions/IBid';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { currencyConvert } from '../../../utils/price';
import { Loading } from '../../../common/animation/Loading';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
} from '../../../common/typos';
import { ActionLink } from '../../../common/styles';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { loadBidIDsLast } from '../../../actions/auctions/bid.actions';
import {
  ILotShort,
  ILotShortSet,
  ILotType,
} from '../../../interfaces/auctions/ILotPublic';
import { loadLotOne } from '../../../actions/auctions/lotPublic.actions';
import { Block } from '../../../common/layout';
import { L } from '../../../common/locale';

interface IWeekBidsLastProps {
  loadBidIDsLast: () => void;
  loadLotOne: (lotType: ILotType, lotID: string, hardReload: boolean) => void;
  bidIDsLast: string[];
  bidByID: IBidSet;
  account: null | IAccount;
  users: IAccountSet;
  lotSet: ILotShortSet;
}

function WeekBidsLast(props: IWeekBidsLastProps) {
  const navigate = useNavigate();
  const [bids, setBids] = useState<IBid[]>([]);

  useEffect(() => props.loadBidIDsLast(), []);

  useEffect(() => {
    const { bidIDsLast, bidByID } = props;
    const bidsLocal: IBid[] = [];
    bidIDsLast.forEach((id: string) => {
      bidsLocal.push(bidByID[id] || null);
    });

    setBids(bidsLocal);
  }, [props.bidIDsLast, props.bidByID]);

  const renderLotName = (lotID: string) => {
    const lot: null | ILotShort = props.lotSet[lotID] || null;
    if (!lot) {
      props.loadLotOne('Week', lotID, false);
    }

    return lot ? (
      <ActionLink>{
        lot.name.length > 20 ? lot.name.slice(0, 20) + '...' : lot.name
      }</ActionLink>
    ) : (
      lotID.slice(0, 3) + '... ' + <Loading size="small" />
    );
  };

  function renderUser(accountID: string) {
    const user: IAccount = props.users[accountID];
    return (
      <ContentDescInline>
        {user ? user.name : accountID && accountID.slice(0, 5) + '...'}
      </ContentDescInline>
    );
  }

  function renderBlock(bid: IBid, index: number) {
    return bid ? (
      <Block
        key={index}
        className="clickable noMargin"
        onClick={() => goToWeekLot(bid.lotID)}
      >
        <ContentDescInline>{renderLotName(bid.lotID)}</ContentDescInline>
        <ContentDescInline> - </ContentDescInline>
        {renderUser(bid.accountID)}
        <ContentDescInline> - </ContentDescInline>
        <ContentDescInline>
          {bid.price}
          <ContentDescInline>
            {' '}
            (${currencyConvert(bid.price)})
          </ContentDescInline>
        </ContentDescInline>
      </Block>
    ) : (
      <Loading key={index} />
    );
  }

  const goToWeekLot = (lotID: string): void => {
    const lot: null | ILotShort = props.lotSet[lotID] || null;
    if (!lot) {
      props.loadLotOne('Week', lotID, false);
      return;
    }

    navigate(
      appConfig.routes.week.lot.replace(
        `${appConfig.params.productPath}`,
        encodeURIComponent(lot.name),
      ),
    );
  };

  return (
    <Block>
      <ContentDesc>
        <ContentBold>
          <L k={'ReportsBidsLast'} />
        </ContentBold>
      </ContentDesc>
      {bids.slice(0, 10).map((bid: IBid, index) => renderBlock(bid, index))}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    bidIDsLast: state.Bid.IDsLast,
    bidIDsByLotID: state.Bid.IDsByLotID,
    bidByID: state.Bid.byID,
    account: state.Account.account,
    users: state.Account.accounts.set,
    lotSet: state.Lot.short.set,
  }),
  {
    loadBidIDsLast,
    loadLotOne,
  },
)(WeekBidsLast);
