import { connect } from 'socket.io-client';
import { Socket } from 'socket.io-client/build/esm/socket';
import { apiConfig } from '../../config/api.config';
import { socketRoutes } from '../../config/socket.config';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  IBid,
  IBidIDsData,
  IBidSetData,
  IBidUpdate,
} from '../../interfaces/auctions/IBid';
import {
  AUCTION_CLEAR,
  AUCTION_MODE_SET,
  AUCTION_NEXT_LOAD,
  BID_ADDED,
  LOT_SHORT_CURRENT_ID_LOAD,
  LOT_SHORT_CURRENT_UPDATE,
  REPORT_AUCTION_LOAD,
  REPORT_LOT_LOAD,
  SHOP_PRODUCT_CLEAR,
  WEEK_COMPLETE,
} from '../types';
// import { IReportPresenter } from '../../interfaces/account/IPresenter';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { loadBidIDs } from '../auctions/bid.actions';
import { IServiceError } from '../../interfaces/system/IError';
import { dispatchError } from '../../service/error';
import { IReportLot } from '../../interfaces/auctions/IReportLot';
import { IReportAuctionData } from '../../interfaces/auctions/IReportAuction';
import { loadLotOne } from '../auctions/lotPublic.actions';
import {
  ILotIDsData,
  ILotShortSet,
} from '../../interfaces/auctions/ILotPublic';
import { getAuctionMode } from '../auctions/auction.actions';
import {
  IAuctionMode,
  IAuctionModeResponse,
} from '../../interfaces/auctions/IAuction';
import { getPlatformType } from '../../config/platform.config';

let socket: Socket;

function connectToServer() {
  socket = connect(apiConfig.socketHost, {
    multiplex: true,
  });
}

let disconnectInterval: any;

export const initSocket =
  () =>
  (
    dispatch: IDispatch<
      | IAuctionMode
      | IAuctionModeResponse
      | IReportLot
      | IReportAuctionData
      | IBidSetData
      | IBidUpdate
      | IAccount
      | string
      | null
      | number
      | IServiceError
      // bids
      | IBid[]
      | IAccountSet
      | boolean
      // lots
      | ILotIDsData
      | ILotShortSet
      | IBidIDsData
      | Record<string, number>
    >,
    getState: () => IState
  ) => {
    if (!socket) {
      connectToServer();
    }
    // live lot
    socket.on(socketRoutes.lot.next, (nextLotID: string) => {
      loadLotOne('Live', nextLotID)(dispatch, getState);
      const payload: IReportAuctionData = {
        lotType: 'Live',
        report: null,
      };
      dispatch({ type: REPORT_AUCTION_LOAD, payload });
      dispatch({ type: AUCTION_MODE_SET, payload: 'lot' });
      dispatch({ type: LOT_SHORT_CURRENT_ID_LOAD, payload: nextLotID });
    });
    socket.on(socketRoutes.lot.win, (reportLot: IReportLot) => {
      dispatch({ type: LOT_SHORT_CURRENT_ID_LOAD, payload: null });
      dispatch({ type: REPORT_LOT_LOAD, payload: reportLot });
      dispatch({ type: AUCTION_MODE_SET, payload: 'reportLot' });
    });
    socket.on(socketRoutes.lot.freshBid, (accountID: string) => {
      const account: null | IAccount =
        getState().Account.accounts.set[accountID] || null;
      const params: Record<string, string> = {};
      if (account) {
        params[''] = 'Current winner is ' + account.name;
      }
      dispatchError(dispatch, {
        type: 'Info',
        message: `The last bid in the game<br/> the auction is ongoing!`,
        params,
      });
    });
    socket.on(socketRoutes.lot.restart, (prevLotID: null | string) => {
      if (prevLotID) {
        loadLotOne('Live', prevLotID)(dispatch, getState);
        dispatch({ type: AUCTION_MODE_SET, payload: 'lot' });
      } else {
        dispatch({ type: AUCTION_MODE_SET, payload: 'reportAuction' });
      }
      dispatch({ type: LOT_SHORT_CURRENT_ID_LOAD, payload: prevLotID });
    });
    socket.on(socketRoutes.lot.winError, () => {});
    //
    socket.on(socketRoutes.report.auction, (reportData: IReportAuctionData) => {
      dispatch({ type: REPORT_AUCTION_LOAD, payload: reportData });
      dispatch({ type: REPORT_LOT_LOAD, payload: null });
      if (reportData.lotType === 'Live') {
        dispatch({ type: AUCTION_MODE_SET, payload: 'reportAuction' });
      }
    });
    socket.on(socketRoutes.report.final, () => {
      getAuctionMode()(dispatch, getState);
      dispatch({ type: SHOP_PRODUCT_CLEAR, payload: null });
      dispatch({ type: AUCTION_CLEAR, payload: null });
      dispatch({ type: AUCTION_MODE_SET, payload: 'reportFinal' });

      // todo open (and close) header with menu
      dispatch({ type: AUCTION_NEXT_LOAD, payload: null });
    });
    socket.on(socketRoutes.bid.updates, (bidResponse: IBidUpdate) => {
      if (bidResponse.isBot) {
        dispatchError(dispatch, {
          type: 'Info',
          message: `Bid from our bot`,
          params: {
            '': 'Less than 3 bids in row - just to show you Auction functionality',
          },
        });
      }

      dispatch({ type: BID_ADDED, payload: bidResponse });
      if (bidResponse.lotType === 'Live') {
        dispatch({ type: LOT_SHORT_CURRENT_UPDATE, payload: bidResponse });
      } else {
        loadBidIDs('Week', bidResponse.lotID)(dispatch, getState);
      }
    });
    // week

    socket.on(socketRoutes.week.complete, () => {
      dispatchError(dispatch, {
        type: 'Info',
        message: `Week Auction is Complete`,
        params: {},
      });
      dispatch({ type: AUCTION_MODE_SET, payload: null });
      dispatch({ type: WEEK_COMPLETE, payload: null });
      window.location.reload();
    });

    socket.on('connect', () => {
      socket.emit(socketRoutes.auth.connect, getPlatformType());
      getAuctionMode()(dispatch, getState);
      if (disconnectInterval) {
        clearInterval(disconnectInterval);
        disconnectInterval = null;
      }
    });

    socket.on('disconnect', () => () => {
      if (disconnectInterval) {
        clearInterval(disconnectInterval);
      }
      getAuctionMode()(dispatch, getState);
      disconnectInterval = setInterval(() => {
        getAuctionMode()(dispatch, getState);
      }, 1000);
    });
  };

// export const initPresenterSocket: () => (
//   dispatch: IDispatch<IReportPresenter>,
//   getState: () => IState
// ) => void =
//   () => (dispatch: IDispatch<IReportPresenter>, getState: () => IState) => {
//     if (!socket) {
//       connectToServer();
//     }
//
//     socket.on('connect', () => {
//       socket.emit(socketRoutes.auth.connect, getPlatformType());
//       socket.emit(socketRoutes.presenter.on);
//     });
//
//     socket.on(socketRoutes.presenter.report, (info: IReportPresenter) => {
//       dispatch({
//         type: PRESENTER_REPORT,
//         payload: info,
//       });
//     });
//   };
