import React, { useEffect, useState } from 'react';
import { RenderItemInRowFunction } from '../common';
import { Block } from '../layout';
import { getCatalogItemsCnt, makeBatches } from '../../utils/catalog';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { ISizeSet } from '../sizes';

interface IModerationItemRowsProps {
  itemIDs: string[];
  renderItem: RenderItemInRowFunction;
  clientWidthSize: null | keyof ISizeSet<any>;
}

function ModerationItemRows(props: IModerationItemRowsProps) {
  const [cnt, setCnt] = useState<null | number>(null);
  const [batches, setBatches] = useState<null | string[][]>(null);

  useEffect(() => {
    const cntLocal: number = getCatalogItemsCnt(props.clientWidthSize);
    const batchesLocal: string[][] = makeBatches(cntLocal, props.itemIDs);

    setCnt(cntLocal);
    setBatches(batchesLocal);
  }, [props.itemIDs]);

  return (
    <>
      {batches &&
        cnt &&
        batches.map((batch: string[], index: number) => (
          <Block key={index}>
            {batch.map((itemID: string) => props.renderItem(itemID, cnt))}
          </Block>
        ))}
    </>
  );
}

export default connect(
  (state: IState) => ({
    clientWidthSize: state.System.clientWidthSize,
  }),
  {}
)(ModerationItemRows);
