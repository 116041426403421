import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { createProductDraft } from '../../actions/shop/productDraft.actions';
import { ContentDesc, ContentHead, PageHead } from '../../common/typos';
import { Tooltip } from '../../common/styles';
import { Block } from '../../common/layout';
import { sendPageEvent } from '../../utils/analytics';
import { IAuction } from '../../interfaces/auctions/IAuction';
import { IProductLink } from '../../interfaces/shop/IProductDraft';
import CheckIsLogged from '../../components/account/auth/CheckIsLogged';
import DraftFormPublic from '../../components/shop/draft/DraftFormPublic';
import { L } from '../../common/locale';

interface ILotCreatePage {
  createProductDraft: (
    name: string,
    description: string,
    imagesBase64: string[],
    links: undefined | IProductLink[]
  ) => void;
  IDByName: Record<string, string>;
  errors: string[];
  auctionNext: undefined | null | IAuction;
}

function LotCreatePage(props: ILotCreatePage) {
  useEffect(() => sendPageEvent('LotCreate'), []);

  return (
    <AppContent>
      <CheckIsLogged />
      <Block>
        <PageHead>
          <L k={'AccountLotCreateAction'} />
        </PageHead>
        <Tooltip>
          <ContentHead>
            <L k={'AccountLotCreateTitle'} />
          </ContentHead>
          <ContentDesc>
            <L k={'AccountLotCreateDescription'} />
          </ContentDesc>
        </Tooltip>

        {/*<Tooltip>
          <ContentHead>
            * Submit lots that align with auction target in donation
          </ContentHead>
          {props.auctionNext && (
            <ContentHead>{props.auctionNext.description}</ContentHead>
          )}
        </Tooltip>*/}

        <DraftFormPublic />
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    IDByName: state.ShopProduct.drafts.IDByName,
    errors: state.System.errors,
    auctionNext: state.Auction.auctionNext,
  }),
  {
    createProductDraft,
  }
)(LotCreatePage);
