import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { Block } from '../../common/layout';
import { PageHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { useParams } from 'react-router-dom';
import { Loading } from '../../common/animation/Loading';
import AuctionInfo from '../../components/auctions/AuctionInfo';
import { getAuctionIDByPath } from '../../utils/archive';
import {
  loadReportUsersBatchNext,
  prepareReportUsersBatchKey,
  searchReportUser,
} from '../../actions/archive/archiveReportUser.actions';
import {
  IArchiveReportUser,
  IReportUserOrder,
} from '../../interfaces/archive/IArchiveReportUser';
import { ReportUserItem } from '../../components/archive/ReportUserItem';
import { sendPageEvent } from '../../utils/analytics';
import { Search } from '../../common/moderation/Search';
import { Order } from '../../common/forms/Order';
import { Batch } from '../../common/moderation/Batch';
import { DataBatchWide } from '../../interfaces/system/data';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface IReportUsersPageProps {
  searchReportUser: (search: string, auctionID: string) => void;
  loadReportUsersBatchNext: (
    auctionID: string,
    order: IReportUserOrder
  ) => void;
  IDByPath: Record<string, string>;
  set: Record<string, IArchiveReportUser>;
  byBatchKey: Record<string, DataBatchWide>;
  IDsBySearch: Record<string, string[]>;
}

function ReportUsersPage(props: IReportUsersPageProps) {
  const { t } = useTranslation();
  const { auctionPath } = useParams();
  const [auctionID, setAuctionID] = useState<null | string>();
  const [order, setOrder] = useState<IReportUserOrder>('buyTotal');
  const [batchKey, setBatchKey] = useState<string>('');

  useEffect(() => sendPageEvent('ArchiveReportUserList'), []);
  useEffect(() => {
    if (!auctionID) {
      return;
    }
    setBatchKey(prepareReportUsersBatchKey(auctionID, order));
  }, [auctionID, order]);
  useEffect(
    () => setAuctionID(getAuctionIDByPath(auctionPath, props.IDByPath)),
    [props.IDByPath]
  );

  return (
    <AppContent>
      <Block>
        <AuctionInfo />

        <PageHead>
          <L k={'ReportsUserTitle'} />
        </PageHead>
        <Search
          searchPrefix={auctionID + '-'}
          search={(search: string) =>
            props.searchReportUser(search, auctionID || '')
          }
          set={props.set}
          IDsBySearch={props.IDsBySearch}
          renderItemPublic={(reportUser: IArchiveReportUser) => (
            <ReportUserItem
              showInline={true}
              reportUser={reportUser}
              order={'buyTotal'}
            />
          )}
          placeholderTitle={t('FieldsName')}
        />

        <Order
          items={[
            {
              value: 'buyTotal',
              title: t('ReportsLotOrderBuyTotal'),
            },
            {
              value: 'sellTotal',
              title: t('ReportsLotOrderSellTotal'),
            },
          ]}
          order={order}
          cb={setOrder}
        />

        {auctionID && (
          <Batch
            type={'public'}
            set={props.set}
            batch={{
              batchKey,
              byBatchKey: props.byBatchKey,
              loadBatch: () => props.loadReportUsersBatchNext(auctionID, order),
            }}
            renderItemPublic={(reportUser: IArchiveReportUser) => (
              <ReportUserItem
                isClickable={true}
                showInline={true}
                reportUser={reportUser}
                order={order}
              />
            )}
            isAdmin={false}
          />
        )}

        {!auctionID && <Loading size={'big'} />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    IDByPath: state.Auction.auctions.IDByPath,
    byBatchKey: state.Archive.reportUsers.byBatchKey,
    set: state.Archive.reportUsers.set,
    IDsBySearch: state.Archive.reportUsers.IDsBySearch,
  }),
  {
    searchReportUser,
    loadReportUsersBatchNext,
  }
)(ReportUsersPage);
