import React from 'react';
import { IReportTopBidder } from '../../../interfaces/auctions/IReportAuction';
import { connect } from 'react-redux';
import TopAccount from './TopAccount';
import { ContentDesc, ContentDescInline } from '../../../common/typos';
import { ImageIcon } from '../../../common/images/Image.styles';

interface IAuctionResultProps {
  topBidders: IReportTopBidder[];
}

function TopBidders(props: IAuctionResultProps) {
  return (
    <>
      {props.topBidders.map((bidder: IReportTopBidder, index: number) => (
        <ContentDesc key={index}>
          <TopAccount accountID={bidder.accountID} />
          <ContentDescInline>{bidder.count}</ContentDescInline>
          {index < 3 && (
            <ImageIcon className={'small'} src={'/icons/medal-scratch.png'} />
          )}
        </ContentDesc>
      ))}
    </>
  );
}

export default connect(() => ({}), {})(TopBidders);
