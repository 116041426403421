import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { getNotificationsIDs } from '../../../actions/account/notification.actions';
import {
  loadBidIDs,
  loadBidIDsLast,
  popWeekBidUpdates,
} from '../../../actions/auctions/bid.actions';
import { ILotType } from '../../../interfaces/auctions/ILotPublic';
import { IBidUpdate } from '../../../interfaces/auctions/IBid';
import { loadLotOne } from '../../../actions/auctions/lotPublic.actions';

interface IWeekBidWatcherProps {
  loadLotOne: (lotType: ILotType, lotID: string, hardReload: boolean) => void;
  loadBidIDs: (lotType: ILotType, lotID: string) => void;
  popWeekBidUpdates: () => void;
  getNotificationsIDs: () => void;
  loadBidIDsLast: () => void;
  updates: IBidUpdate[];
}

function WeekBidWatcher(props: IWeekBidWatcherProps) {
  const [bidIDs, setBidIDs] = useState<string[]>([]);

  useEffect(() => {
    const bidIDsNew: string[] = [];

    props.updates.forEach(({ lotID, bidID }) => {
      if (bidIDs.indexOf(bidID) === -1) {
        props.loadLotOne('Week', lotID, true);
        props.loadBidIDs('Week', lotID);
        props.popWeekBidUpdates();
        props.getNotificationsIDs();
        props.loadBidIDsLast();
      }
    });

    setBidIDs(bidIDs.concat(bidIDsNew));
  }, [props.updates]);

  return <></>;
}

export default connect(
  (state: IState) => ({
    updates: state.Bid.updates,
  }),
  {
    loadLotOne,
    loadBidIDs,
    popWeekBidUpdates,
    getNotificationsIDs,
    loadBidIDsLast,
  }
)(WeekBidWatcher);
