import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { ADMIN_IDS_LOADED } from '../types';
import {
  IAccountAdminIDsResponse,
  IAccountAdminUpdate,
  IAccountSet,
} from '../../interfaces/account/IAccount';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { IServiceError } from '../../interfaces/system/IError';
import { loadAccountsSet } from './accounts.actions';
import { dispatchError } from '../../service/error';

const { account } = apiConfig.endpoints;

export const loadAdminIDs =
  () =>
  (
    dispatch: IDispatch<IAccountSet | string[] | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IAccountAdminIDsResponse>(account.admins, {})
      .then((data: IAccountAdminIDsResponse) => {
        dispatch({ type: ADMIN_IDS_LOADED, payload: data.adminIDs });
        loadAccountsSet(data.adminIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const updateAdmin =
  (accountID: string, isAdmin: boolean) =>
  (
    dispatch: IDispatch<string[] | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IAccountAdminUpdate = { accountID, isAdmin };
    callApi(account.admins, {}, body, 'POST')
      .then(() =>
        dispatchError(dispatch, {
          message: 'Admins are updated',
          params: {},
          type: 'Info',
        })
      )
      .then(() => loadAdminIDs()(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
