import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { CacheRequests } from '../../service/cacheRequests';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import {
  ITemplateParamsBatchBody,
  ITemplateParamsCreateBody,
  ITemplateParamsDeleteQuery,
  ITemplateParamsGroup,
  ITemplateParamsIDsResponse,
  ITemplateParamsSearchData,
  ITemplateParamsSearchQuery,
  ITemplateParamsSearchResponse,
  ITemplateParamsSet,
  ITemplateParamsSetQuery,
  ITemplateParamsSetResponse,
  ITemplateParamsUpdateBody,
} from '../../interfaces/communication/ITemplateParams';
import { dispatchInfo } from '../../service/error';
import {
  ITEM_CREATED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  TEMPLATE_PARAMS_ACTION,
  TEMPLATE_PARAMS_LOAD_BATCH,
  TEMPLATE_PARAMS_LOAD_IDS,
  TEMPLATE_PARAMS_LOAD_SET,
  TEMPLATE_PARAMS_SEARCH_LOAD,
} from '../types';
import { DataBatch, DataBatchKey } from '../../interfaces/system/data';
import { IEmailMode } from '../../interfaces/communication/IEmailLog';
import { IAppRecommendationType } from '../../interfaces/communication/IAppRecommendation';

export function prepareTemplateParamsBatchKey(
  group: null | ITemplateParamsGroup,
  mode: null | IEmailMode,
  type: null | IAppRecommendationType,
  params: null | Record<string, string>,
): string {
  return JSON.stringify({
    group,
    mode,
    type,
    params,
  });
}

const { templateParams } = apiConfig.endpoints.communication;
const cacheByID: CacheRequests = new CacheRequests();

export const loadTemplateParamsIDs =
  (hardReload?: boolean) =>
    (
      dispatch: IDispatch<string | string[] | ITemplateParamsSet | IServiceError>,
      getState: () => IState,
    ) => {
      const { Communication } = getState();
      if (!hardReload && Communication.TemplateParams.IDs[0]) {
        return;
      }

      callApi<ITemplateParamsIDsResponse>(templateParams.ids)
        .then((data: ITemplateParamsIDsResponse) => {
          loadTemplateParamsSet(data.itemIDs)(dispatch, getState);

          dispatch({
            type: TEMPLATE_PARAMS_LOAD_IDS,
            payload: data.itemIDs,
          });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadTemplateParamsSet =
  (itemIDs: string[], hardReload?: boolean) =>
    (
      dispatch: IDispatch<string | ITemplateParamsSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!itemIDs || !itemIDs[0]) {
        return;
      }
      const set: ITemplateParamsSet = getState().Communication.TemplateParams.set;
      let IDsToLoad: string[] = [];
      if (!hardReload) {
        const toLoad: string[] = [];
        itemIDs.forEach((itemID: string) => {
          if (!set[itemID]) {
            toLoad.push(itemID);
          }
        });
        if (!toLoad[0]) {
          return;
        }

        IDsToLoad = cacheByID.getToLoad(toLoad);
        if (!IDsToLoad[0]) {
          return;
        }
      }

      const query: ITemplateParamsSetQuery = {
        itemIDs: hardReload ? itemIDs.join(',') : IDsToLoad.join(','),
      };
      callApi<ITemplateParamsSetResponse>(templateParams.set, query)
        .then((data: ITemplateParamsSetResponse) =>
          dispatch({ type: TEMPLATE_PARAMS_LOAD_SET, payload: data.set }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadTemplateParamsBatch =
  (
    group: null | ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    params: null | Record<string, string>,
    extraLimit?: number,
  ) =>
    (
      dispatch: IDispatch<
        string | ITemplateParamsSet | DataBatchKey<string> | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const batchKey: string = prepareTemplateParamsBatchKey(
        group,
        mode,
        type,
        params,
      );
      const { limit, byBatchKey } = getState().Communication.TemplateParams;
      const body: ITemplateParamsBatchBody = {
        limit: extraLimit || limit,
        skip: (byBatchKey[batchKey] && byBatchKey[batchKey].loaded) || 0,
        group,
        mode,
        type,
        params,
      };

      callApi<DataBatch<string>>(templateParams.batch, {}, body, 'POST')
        .then((data: DataBatch<string>) => {
          const payload: DataBatchKey<string> = {
            ...data,
            batchKey,
          };
          loadTemplateParamsSet(data.items)(dispatch, getState);
          dispatch({
            type: TEMPLATE_PARAMS_LOAD_BATCH,
            payload,
          });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const searchTemplateParams =
  (search: string) =>
    (
      dispatch: IDispatch<
        ITemplateParamsSearchData | string | ITemplateParamsSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (search === '') {
        return;
      }

      const { IDsBySearch } = getState().Communication.TemplateParams;
      if (IDsBySearch[search]) {
        return;
      }

      const query: ITemplateParamsSearchQuery = {
        search,
      };

      callApi<ITemplateParamsSearchResponse>(templateParams.search, query)
        .then((data: ITemplateParamsSearchResponse) => {
          const payload: ITemplateParamsSearchData = {
            search,
            itemIDs: data.itemIDs,
          };

          loadTemplateParamsSet(data.itemIDs)(dispatch, getState);
          dispatch({ type: TEMPLATE_PARAMS_SEARCH_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const updateTemplateParams =
  (
    itemID: string,
    group: null | ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    value: null | string,
    params: undefined | null | Record<string, string>,
  ) =>
    (
      dispatch: IDispatch<string | ITemplateParamsSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const body: ITemplateParamsUpdateBody = {
        itemID,
      };
      if (group) {
        body.group = group;
      }
      if (mode) {
        body.mode = mode;
      }
      if (type) {
        body.type = type;
      }
      if (params !== undefined) {
        body.params = params;
      }
      if (value) {
        body.value = value;
      }

      callApi(templateParams.main, {}, body, 'POST')
        .then(() =>
          dispatch({ type: TEMPLATE_PARAMS_ACTION, payload: ITEM_UPDATED }),
        )
        .then(() => loadTemplateParamsSet([itemID], true)(dispatch, getState))
        .then(() => dispatchInfo(dispatch, 'TemplateParams is updated'))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const createTemplateParams =
  (
    group: ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    value: string,
    params: null | Record<string, string>,
  ) =>
    (
      dispatch: IDispatch<string | string[] | ITemplateParamsSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const body: ITemplateParamsCreateBody = {
        group,
        mode,
        type,
        value,
        params,
      };
      callApi(templateParams.main, {}, body)
        .then(() =>
          dispatch({ type: TEMPLATE_PARAMS_ACTION, payload: ITEM_CREATED }),
        )
        .then(() => loadTemplateParamsIDs(true)(dispatch, getState))
        .then(() => dispatchInfo(dispatch, 'TemplateParams is created'))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const deleteTemplateParams =
  (itemID: string) =>
    (
      dispatch: IDispatch<string | string[] | ITemplateParamsSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const query: ITemplateParamsDeleteQuery = {
        itemID: itemID,
      };
      callApi(templateParams.main, query, undefined, 'DELETE')
        .then(() =>
          dispatch({ type: TEMPLATE_PARAMS_ACTION, payload: ITEM_REMOVED }),
        )
        .then(() => loadTemplateParamsIDs(true)(dispatch, getState))
        .then(() => dispatchInfo(dispatch, 'TemplateParams is removed'))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
