import { apiConfig } from '../../config/api.config';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import {
  ANALYTICS_RETENTION_LOAD,
  ANALYTICS_RETENTION_LOAD_CACHE,
  ANALYTICS_RETENTION_ONLINE_NOW_LOAD,
} from '../types';
import {
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  loadAnalyticsCacheWrapper,
  loadAnalyticsMainWrapper,
  saveAnalyticsCacheWrapper,
} from './analyticsWrapper.actions';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IBehavioralAnalyticsRetentionOnlineNowResponse } from '../../interfaces/analytics/IBehavioralAnalyticsRetention';

const { main, cache, online } =
  apiConfig.endpoints.analytics.behavioralRetention;

export const loadAnalyticsRetentionMain =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      IBaseAnalyticsAggregatedModelGetResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainWrapper(
      main,
      ANALYTICS_RETENTION_LOAD,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsRetentionCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheWrapper(
      cache,
      ANALYTICS_RETENTION_LOAD_CACHE,
      hardReload
    )(dispatch, getState);
  };

export const loadAnalyticsRetentionOnlineNow =
  () =>
  (
    dispatch: IDispatch<number | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    callApi<IBehavioralAnalyticsRetentionOnlineNowResponse>(online)
      .then((data: IBehavioralAnalyticsRetentionOnlineNowResponse) =>
        dispatch({
          type: ANALYTICS_RETENTION_ONLINE_NOW_LOAD,
          payload: data.cnt,
        })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const saveAnalyticsRetentionCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheWrapper(cache)(dispatch, getState);
  };
