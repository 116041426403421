import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { BID_ADDED_COMPLETED, BID_IDS_LAST_LOAD, BID_IDS_LOAD, BID_SET_LOAD } from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  IBidCreateBody,
  IBidIDsData,
  IBidIDsResponse,
  IBidSet,
  IBidSetData,
  IBidSetResponse,
} from '../../interfaces/auctions/IBid';
import { IServiceError } from '../../interfaces/system/IError';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { dispatchErrorAnAuth } from '../../service/error';
import { CacheRequests } from '../../service/cacheRequests';
import { loadAccountsSet } from '../account/accounts.actions';
import { ILotType } from '../../interfaces/auctions/ILotPublic';

const { ids, main, last } = apiConfig.endpoints.auctions.bids;
const cacheByID: CacheRequests = new CacheRequests();

export const loadBidOne =
  (lotType: ILotType, lotID: string, bidID: string) =>
    (
      dispatch: IDispatch<IBidSetData | IAccountSet | IServiceError>,
      getState: () => IState,
    ) => {
      loadBidSet(lotType, lotID, [bidID])(dispatch, getState);
    };

export const loadBidSet =
  (lotType: ILotType, lotID: null | string, bidIDs: string[]) =>
    (
      dispatch: IDispatch<IBidSetData | IAccountSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!bidIDs || !bidIDs[0]) {
        return;
      }
      const set: IBidSet = getState().Bid.byID;
      const toLoad: string[] = [];
      bidIDs.forEach((bidID: string) => {
        if (!set[bidID]) {
          toLoad.push(bidID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }

      callApi<IBidSetResponse>(main, { bidIDs: IDsToLoad.join(','), lotType })
        .then((data: IBidSetResponse) => {
          const accountSet: Record<string, null> = {};
          bidIDs.map((bidID: string) => {
            if (!data.set[bidID]) {
              return;
            }
            accountSet[data.set[bidID].accountID] = null;
          });
          const accountIDs: string[] = Object.keys(accountSet);
          if (accountIDs[0]) {
            loadAccountsSet(accountIDs)(dispatch, getState);
          }

          const payload: IBidSetData = {
            lotType,
            lotID,
            set: data.set,
          };

          dispatch({ type: BID_SET_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadBidIDs =
  (lotType: ILotType, currentLotID?: string) =>
    (
      dispatch: IDispatch<
        IBidIDsData | IBidSetData | IAccountSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      const lotID: null | string =
        currentLotID || getState().Lot.short.currentLotID || null;
      if (!lotID) {
        return;
      }
      callApi<IBidIDsResponse>(ids, { lotID, lotType })
        .then((data: IBidIDsResponse) => {
          loadBidSet(lotType, lotID, data.bidIDs)(dispatch, getState);
          const payload: IBidIDsData = {
            lotType,
            lotID,
            bidIDs: data.bidIDs,
          };
          dispatch({ type: BID_IDS_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadBidIDsLast =
  () =>
    (
      dispatch: IDispatch<
        IBidIDsData | IBidSetData | IBidSet | IAccountSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      callApi<IBidIDsResponse>(last)
        .then((data: IBidIDsResponse) => {
          const payload: IBidIDsData = {
            lotID: '',
            lotType: 'Week',
            bidIDs: data.bidIDs,
          };
          loadBidSet('Week', null, data.bidIDs)(dispatch, getState);
          dispatch({ type: BID_IDS_LAST_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const createBid =
  (lotType: ILotType, lotID: string, price: number) =>
    (dispatch: IDispatch<string | IServiceError>, getState: () => IState) => {
      const { account } = getState().Account;
      if (!account) {
        return dispatchErrorAnAuth(dispatch, 'createBid');
      }

      const body: IBidCreateBody = {
        lotType,
        lotID,
        price,
      };
      callApi(main, {}, body).catch((error: Error | IApiError) => {
        // todo check error message - could be not best offer
        catchError(dispatch, error, getState);
      });
    };

export const popWeekBidUpdates = () => (dispatch: IDispatch<null>) => {
  dispatch({ type: BID_ADDED_COMPLETED, payload: null });
};
