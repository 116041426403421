import styled from 'styled-components';
import { colors } from '../common/colors';
import { margins, sizesBorder } from '../common/sizes';

export const BlockLiveIsStarted = styled.div`
  background-color: ${colors.background};
  position: fixed;
  border-radius: ${sizesBorder.radius};
  z-index: 99;
  right: 100px;
  top: 100px;
`;

export const Item = styled.div`
  position: relative;
  border: solid ${sizesBorder.widthM} ${colors.win};
  border-radius: ${sizesBorder.radius};
  padding: ${margins.item} ${margins.block};
  margin-bottom: ${margins.block};
  color: ${colors.win};

  & button {
    display: block;
    margin: ${margins.block} 0;
  }
`;

export const Counts = styled.span`
  color: ${colors.lost};
  margin-right: ${margins.block};
`;
