import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  SHOP_MODERATION_BATCH_LOAD,
  SHOP_MODERATION_LOG_CHANGE_LOAD,
  SHOP_MODERATION_LOG_MODERATION_LOAD,
  SHOP_MODERATION_UPDATE,
  SHOP_PRODUCT_EDIT,
} from '../../actions/types';
import { ActionCase, IStateShopModeration } from '../../interfaces/system/IState';
import { DataBatch } from '../../interfaces/system/data';
import { IProductDraft } from '../../interfaces/shop/IProductDraft';
import { IProductLogChange, IProductLogModeration } from '../../interfaces/shop/IProductModeration';

const initialStateArchive: IStateShopModeration = {
  products: {
    limit: 10,
    cnt: null,
    loaded: 0,
    IDs: [],
    set: {},
    editProductID: null,
  },
  logsModeration: {
    logByID: {},
    IDsByDraftID: {},
  },
  logsChange: {
    logByID: {},
    IDsByDraftID: {},
  },
};

export const shopModerationReducer: Reducer = createReducer(
  initialStateArchive,
  (builder: ActionReducerMapBuilder<IStateShopModeration>) => {
    builder.addCase<string, ActionCase<DataBatch<IProductDraft>>>(
      SHOP_MODERATION_BATCH_LOAD,
      (
        state: Draft<IStateShopModeration>,
        action: ActionCase<DataBatch<IProductDraft>>,
      ) => {
        const { IDs, set } = state.products;
        const { cnt, items } = action.payload;

        state.products.cnt = cnt;
        items.forEach((productDraft: IProductDraft) => {
          const { id } = productDraft;
          IDs.push(id);
          set[id] = productDraft;
        });
        state.products.loaded = IDs.length;
      },
    );
    builder.addCase<string, ActionCase<IProductLogModeration[]>>(
      SHOP_MODERATION_LOG_MODERATION_LOAD,
      (
        state: Draft<IStateShopModeration>,
        action: ActionCase<IProductLogModeration[]>,
      ) => {
        const { IDsByDraftID, logByID } = state.logsModeration;
        action.payload.forEach((log: IProductLogModeration) => {
          const { id, productDraftID } = log;
          logByID[id] = log;

          if (!IDsByDraftID[productDraftID]) {
            IDsByDraftID[productDraftID] = [];
          }
          IDsByDraftID[productDraftID].push(id);
        });
      },
    );
    builder.addCase<string, ActionCase<IProductLogChange[]>>(
      SHOP_MODERATION_LOG_CHANGE_LOAD,
      (
        state: Draft<IStateShopModeration>,
        action: ActionCase<IProductLogChange[]>,
      ) => {
        const { IDsByDraftID, logByID } = state.logsChange;
        action.payload.forEach((log: IProductLogChange) => {
          const { id, productDraftID } = log;
          logByID[id] = log;

          if (!IDsByDraftID[productDraftID]) {
            IDsByDraftID[productDraftID] = [];
          }
          IDsByDraftID[productDraftID].push(id);
        });
      },
    );
    builder.addCase<string, ActionCase<string>>(
      SHOP_MODERATION_UPDATE,
      (state: Draft<IStateShopModeration>, action: ActionCase<string>) => {
        const draftID: string = action.payload;
        const { set, IDs } = state.products;

        delete set[draftID];
        IDs.splice(IDs.indexOf(draftID), 1);
        if (state.products.cnt) {
          state.products.cnt -= 1;
        }
        state.products.loaded -= 1;
      },
    );
    builder.addCase<string, ActionCase<string>>(
      SHOP_PRODUCT_EDIT,
      (state: Draft<IStateShopModeration>, action: ActionCase<string>) => {
        state.products.editProductID = action.payload;
      },
    );
  },
);
