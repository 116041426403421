import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { Loading } from '../../common/animation/Loading';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { ImagePerson } from '../../common/images/ImagePerson';

interface IAccountImageProps {
  accountID: string;
  accounts: IAccountSet;
  size?: 'S' | 'M' | 'XL';
  isInline?: boolean;
}

function AccountImage(props: IAccountImageProps) {
  const [account, setAccount] = useState<null | IAccount>(null);
  const [imgURL, setImgURL] = useState<null | string>(null);

  useEffect(() => {
    if (!props.accounts[props.accountID]) {
      return;
    }

    setAccount(props.accounts[props.accountID]);
    setImgURL(props.accounts[props.accountID].imgURL || null);
  }, [props.accounts[props.accountID]]);

  return (
    <>
      {!account && <Loading />}

      {account && (
        <ImagePerson
          imgURL={imgURL}
          name={account.name}
          size={props.size}
          isInline={props.isInline}
        />
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
  }),
  {}
)(AccountImage);
