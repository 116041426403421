import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { savePathBeforeLogin } from '../../../actions/account/user.actions';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { IState } from '../../../interfaces/system/IState';
import { IAccount } from '../../../interfaces/account/IAccount';
import { getToken } from '../../../actions/account/auth.actions';

interface ICheckIsLoggedProps {
  account: null | IAccount;
}

function CheckIsLogged(props: ICheckIsLoggedProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.account && getToken()) {
      return;
    }
    if (!props.account) {
      goToLogin();
    }
  }, [props.account]);

  const goToLogin = () => {
    savePathBeforeLogin(window.location.pathname);
    navigate(appConfig.routes.main.login);
  };

  return <></>;
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
  }),
  {},
)(CheckIsLogged);
