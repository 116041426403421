import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { IAccount } from '../../interfaces/account/IAccount';
import { LoginBlock } from './LoginPage.styles';
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import { googleConfig } from '../../config/google.config';
import { AppContent } from '../../App.styles';
import {
  checkToken,
  checkTokenGoogle,
  getLoginAction,
  getPathBeforeLogin,
} from '../../actions/account/user.actions';
import { appConfig } from '../../config/app.config';
import { useNavigate, useParams } from 'react-router-dom';
import { sendEvent, sendPageEvent } from '../../utils/analytics';
import { apiConfig } from '../../config/api.config';
import { setError } from '../../actions/system/system.actions';
import { ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAuctionMode } from '../../interfaces/auctions/IAuction';
import { BlockActions } from '../../common/styles';
import { getElementId } from '../../utils/layout.utils';
import { getPlatformType } from '../../config/platform.config';
import { L } from '../../common/locale';

interface ILoginPageProps {
  checkToken: (token: string) => void;
  checkTokenGoogle: (token: string) => void;
  setError: (message: string) => void;
  account: null | IAccount;
  auctionMode: undefined | null | IAuctionMode;
}

function LoginPage(props: ILoginPageProps) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [tokenParam, setTokenParam] = useState<string>();
  const [title, setTitle] = useState<null | string>(null);

  useEffect(() => sendPageEvent('Login'), []);
  useEffect(() => {
    switch (getPathBeforeLogin()) {
      case appConfig.routes.shop.addLot:
        setTitle('add your lot');
        break;
      default:
        setTitle(null);
        break;
    }
  }, []);
  useEffect(() => {
    if (token) {
      setTokenParam(token);
    }
  }, [token]);
  useEffect(() => {
    if (!props.account && (token || tokenParam)) {
      props.checkToken(token || tokenParam || '');
      return;
    }

    if (props.auctionMode && props.auctionMode !== 'weekAuction') {
      return;
    }

    if (props.account && props.account.id) {
      sendEvent('Login-complete');

      const addToCalendar: null | string = getLoginAction();
      if (addToCalendar) {
        window.open(addToCalendar, '_blank');
        navigate(appConfig.routes.main.home);
        return;
      }
      const pathBeforeLogin: null | string = getPathBeforeLogin();
      if (pathBeforeLogin && !pathBeforeLogin.startsWith('/login')) {
        window.location.pathname = pathBeforeLogin;
      } else {
        navigate(appConfig.routes.main.home);
      }
    }
  }, [props.account]);

  const onSuccess = (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      props.checkTokenGoogle(credentialResponse.credential);
    }
  };

  const onError = () => {
    props.setError('Login is failed');
  };

  return (
    <AppContent>
      <LoginBlock id={getElementId('block-login')}>
        <Block>
          {props.auctionMode && (
            <ContentHead style={{ margin: 'auto' }}>
              <L k={'AccountLoginCallAuction'} />
            </ContentHead>
          )}

          {!props.auctionMode && !title && (
            <ContentHead style={{ margin: 'auto' }}>
              <L k={'AccountLoginCall'} />
            </ContentHead>
          )}
          {!props.auctionMode && title && (
            <ContentHead style={{ margin: 'auto' }}>
              <L k={'AccountLoginCallAction'} />
              {title}
            </ContentHead>
          )}
        </Block>
        <GoogleOAuthProvider clientId={googleConfig.clientId}>
          <BlockActions>
            <GoogleLogin
              onSuccess={onSuccess}
              onError={onError}
              size={'large'}
              ux_mode={'redirect'}
              useOneTap={true}
              theme={'filled_blue'}
              shape={'rectangular'}
              login_uri={
                apiConfig.host +
                apiConfig.endpoints.account.auth.google +
                '?platform=' +
                getPlatformType()
              }
            />
          </BlockActions>
        </GoogleOAuthProvider>
      </LoginBlock>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    auctionMode: state.Auction.auctionMode,
  }),
  {
    checkToken,
    checkTokenGoogle,
    setError,
  }
)(LoginPage);
