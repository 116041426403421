import React, { useEffect, useState } from 'react';
import { ContentDesc, ContentHead, PageHead } from '../../common/typos';
import {
  IArchiveReportUser,
  IReportUserOrder,
} from '../../interfaces/archive/IArchiveReportUser';
import AccountImage from '../account/AccountImage';
import { LegendItemPrice } from '../hall/LegendItemPrice';
import { appConfig } from '../../config/app.config';
import { makePathFromName } from '../../utils/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { Block } from '../../common/layout';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface IReportUserItemProps {
  reportUser: IArchiveReportUser;
  order: IReportUserOrder;
  isNameOnTop?: boolean;
  isClickable?: boolean;
  showInline?: boolean;
}

export function ReportUserItem(props: IReportUserItemProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auctionPath } = useParams();

  const [lotsCnt, setLotsCnt] = useState<number>(0);

  useEffect(() => {
    setLotsCnt(
      props.reportUser.lotsSold.length +
        props.reportUser.lotsBought.length +
        ((props.reportUser.weekLotsSold &&
          props.reportUser.weekLotsSold.length) ||
          0) +
        ((props.reportUser.weekLotsBought &&
          props.reportUser.weekLotsBought.length) ||
          0)
    );
  }, [props.reportUser]);

  const renderUserDonation = () => {
    return (
      <>
        {props.order === 'buyTotal' ? (
          <>
            <LegendItemPrice
              price={props.reportUser.buyTotal}
              title={t('HallSortDonatedShort')}
              isBold={true}
            />
            <LegendItemPrice
              price={props.reportUser.sellTotal}
              title={t('HallSortSoldShort')}
              isBold={true}
            />
          </>
        ) : (
          <>
            <LegendItemPrice
              price={props.reportUser.sellTotal}
              title={t('HallSortSoldShort')}
              isBold={true}
            />
            <LegendItemPrice
              price={props.reportUser.buyTotal}
              title={t('HallSortDonatedShort')}
              isBold={true}
            />
          </>
        )}
      </>
    );
  };

  const renderUserResult = () => {
    return (
      <>
        {!props.isNameOnTop && (
          <ContentHead>{props.reportUser.accountName}</ContentHead>
        )}
        {renderUserDonation()}
        <ContentDesc>
          <L k={'ReportsBids'} /> {props.reportUser.bidTotal}
        </ContentDesc>
        <ContentDesc>
          <L k={'ReportsLots'} /> {lotsCnt}
        </ContentDesc>
      </>
    );
  };

  const goToReportUser = () => {
    if (!props.isClickable || !props.reportUser) {
      return;
    }

    const { accountName } = props.reportUser;
    const path = appConfig.routes.auctions.usersOne
      .replace(appConfig.params.auctionPath, auctionPath || '')
      .replace(appConfig.params.accountPath, makePathFromName(accountName));

    navigate(path);
  };

  return (
    <>
      {props.showInline && (
        <Block
          onClick={() => goToReportUser()}
          className={(props.isClickable ? 'clickable' : '') + ' flexTop'}
        >
          <AccountImage accountID={props.reportUser.accountID} size={'S'} />
          <Block>{renderUserResult()}</Block>
        </Block>
      )}
      {!props.showInline && (
        <Block
          onClick={() => goToReportUser()}
          className={props.isClickable ? 'clickable' : ''}
        >
          {props.isNameOnTop && (
            <PageHead>{props.reportUser.accountName}</PageHead>
          )}
          <AccountImage accountID={props.reportUser.accountID} size={'XL'} />
          {renderUserResult()}
        </Block>
      )}
    </>
  );
}
