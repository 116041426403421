import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { Block, BlockHalf } from '../../common/layout';
import { appConfig } from '../../config/app.config';
import { loadProductsBySellers } from '../../actions/shop/product.actions';
import { IProduct } from '../../interfaces/shop/IProduct';
import { Loading } from '../../common/animation/Loading';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import {
  ContentDesc,
  ContentDescInline,
  ContentHead,
} from '../../common/typos';
import { reloadSeller } from '../../actions/account/sellers.actions';
import { ISellerAnalytics } from '../../interfaces/shop/ISeller';
import { sendPageEvent } from '../../utils/analytics';
import { loadPartnerSellerByPath } from '../../actions/account/partnerSeller.actions';
import {
  IPartnerSellerPublic,
  IPartnerSellerSet,
} from '../../interfaces/account/IPartnerSeller';
import { ISizeSet } from '../../common/sizes';
import { Image, ImageIcon } from '../../common/images/Image.styles';
import ModerationItemRows from '../../common/moderation/ModerationItemRows';
import { renderProductPreviewItem } from '../../components/shop/catalog/catalog';
import { AnalyticsIconItem } from '../../components/shop/shop.styles';
import { L } from '../../common/locale';

interface IProductsBySellerPageProps {
  loadProductsBySellers: (accountPath: string) => void;
  loadPartnerSellerByPath: (accountPath: string) => void;
  reloadSeller: (accountID: string) => void;
  IDsBySeller: Record<string, string[]>;
  productsSet: Record<string, IProduct>;
  sellerIDByPath: Record<string, string[]>;
  analyticsByID: Record<string, ISellerAnalytics>;
  accounts: IAccountSet;
  partnerIDByPath: Record<string, null | string>;
  set: IPartnerSellerSet;
  clientWidthSize: null | keyof ISizeSet<any>;
}

function ProductsBySellerPage(props: IProductsBySellerPageProps) {
  const productsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { accountPath } = useParams();
  const [size, setSize] = useState<'small' | 'big'>('big');
  const [productWidth, setProductWidth] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [partner, setPartner] = useState<null | IPartnerSellerPublic>(null);
  const [productIDs, setProductIDs] = useState<string[]>([]);
  const [seller, setSeller] = useState<null | IAccount>(null); // multiple products with one name
  const [analytics, setAnalytics] = useState<null | ISellerAnalytics>(null);

  useEffect(() => {
    setSize(
      ['tablet', 'laptop'].includes(props.clientWidthSize || '')
        ? 'big'
        : 'small'
    );
  }, [props.clientWidthSize]);
  useEffect(() => sendPageEvent('ProductsBySeller'), []);
  useEffect(() => {
    if (accountPath) {
      props.loadProductsBySellers(accountPath);
      props.loadPartnerSellerByPath(accountPath);
    } else {
      goToCatalog();
    }
  }, []);
  useEffect(() => {
    setProductWidth(
      (productsRef && productsRef.current && productsRef.current.clientWidth) ||
        null
    );
  }, [productsRef, productsRef.current, partner]);
  useEffect(() => {
    if (!accountPath) {
      return goToCatalog();
    }
    const IDs: string[] = props.IDsBySeller[accountPath] || [];
    setIsLoading(false);
    setProductIDs(IDs);
  }, [props.IDsBySeller, props.productsSet]);
  useEffect(() => {
    if (!accountPath) {
      return goToCatalog();
    }

    const partnerID: null | string = props.partnerIDByPath[accountPath];
    if (partnerID) {
      setPartner(props.set[partnerID] || null);
    }
  }, [props.partnerIDByPath, props.set]);
  useEffect(() => {
    if (!accountPath) {
      return;
    }

    const sellerIDs: undefined | string[] = props.sellerIDByPath[accountPath];
    if (!sellerIDs || !sellerIDs[0]) {
      return;
    }

    props.reloadSeller(sellerIDs[0]);
    setSeller(props.accounts[sellerIDs[0]] || null);
  }, [props.IDsBySeller, props.accounts]);
  useEffect(() => {
    const sellerID: null | string = (seller && seller.id) || null;
    if (!sellerID) {
      return;
    }

    const analyticsLocal: undefined | ISellerAnalytics =
      props.analyticsByID[sellerID];
    if (!analyticsLocal) {
      return;
    }

    setAnalytics(analyticsLocal);
  }, [seller, props.analyticsByID]);

  const goToCatalog = () => {
    navigate(appConfig.routes.shopProducts.list);
  };
  const renderTitle = (withMargin?: boolean) => {
    return (
      <ContentHead className={withMargin ? 'item' : ''}>
        <L k={'ProductBy'} /> {partner?.name || seller?.name}
      </ContentHead>
    );
  };
  const renderSeller = () => {
    const imgURL: null | string = partner?.imgURL || seller?.imgURL || null;

    return (
      <>
        {imgURL && <Image src={imgURL} alt={partner?.name || seller?.name} />}

        {partner && (
          <ContentDesc className="item">{partner.description}</ContentDesc>
        )}

        {partner && partner.instagramLink && (
          <ContentDesc className="item">
            <a
              href={'https://www.instagram.com/' + partner.instagramLink}
              target="_blank"
              style={{
                display: 'flex !important',
                alignItems: 'center',
              }}
            >
              <ImageIcon className={'small inline'} src="/icons/insta.png" />@
              {partner.instagramLink}
            </a>
          </ContentDesc>
        )}
      </>
    );
  };
  const renderAnalytics = (isLeft?: boolean) => {
    if (!analytics) {
      return <></>;
    }

    return (
      <Block>
        <Block className={isLeft ? 'left' : ''}>
          <ContentDesc>
            <L k={'AnalyticsTotalProducts'} />
          </ContentDesc>
          <AnalyticsIconItem className={isLeft ? 'left' : ''}>
            <ImageIcon
              className={'small widthL'}
              src="/icons/view.png"
              alt="view"
            />
            <ContentDescInline>
              {analytics.views} ({analytics.viewsUnique})
            </ContentDescInline>
          </AnalyticsIconItem>

          <AnalyticsIconItem className={isLeft ? 'left' : ''}>
            <ImageIcon className={'small'} src="/icons/like.png" alt="like" />
            <ContentDescInline>{analytics.likes}</ContentDescInline>
          </AnalyticsIconItem>
        </Block>
      </Block>
    );
  };
  const renderProducts = () => {
    return (
      <ModerationItemRows
        itemIDs={productIDs}
        renderItem={renderProductPreviewItem}
      />
    );
  };

  return (
    <AppContent>
      <Block>
        {isLoading && <Loading />}

        {partner && size === 'small' && (
          <>
            {renderTitle(true)}
            {renderSeller()}
            {renderAnalytics(true)}
            {renderProducts()}
          </>
        )}
        {partner && size === 'big' && (
          <>
            <BlockHalf ref={productsRef}>
              {renderTitle(true)}
              {renderProducts()}
            </BlockHalf>
            <BlockHalf>
              {renderSeller()}
              {renderAnalytics(true)}
            </BlockHalf>
          </>
        )}

        {!partner && (
          <>
            <Block className={'flex flexBetween'}>
              {renderTitle()}
              {renderSeller()}
            </Block>
            <Block>{renderAnalytics()}</Block>
            <Block>{renderProducts()}</Block>
          </>
        )}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    IDsBySeller: state.ShopProduct.products.IDsBySeller,
    sellerIDByPath: state.ShopProduct.products.sellerIDByPath,
    productsSet: state.ShopProduct.products.set,
    analyticsByID: state.Account.sellers.analyticsByID,
    partnerIDByPath: state.Account.partnerSeller.IDByPath,
    set: state.Account.partnerSeller.set,
    accounts: state.Account.accounts.set,
    clientWidthSize: state.System.clientWidthSize,
  }),
  {
    loadPartnerSellerByPath,
    loadProductsBySellers,
    reloadSeller,
  }
)(ProductsBySellerPage);
