import { Reducer } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
// import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
// import { PRESENTER_REPORT } from '../../actions/types';
import { IStatePresenter } from '../../interfaces/system/IState';
// import { IReportPresenter } from '../../interfaces/account/IPresenter';

const initialStatePresenter: IStatePresenter = {
  // report: {
  //   online: 0, // by socket
  //   attenders: 0, // all authorisations
  //   bidders: 0, // >= 1 bids
  //   bids: 0, // total bids count
  // },
};
export const presenterReducer: Reducer = createReducer(
  initialStatePresenter,
  () =>
    // builder: ActionReducerMapBuilder<IStatePresenter>
  {
    // builder.addCase<string, ActionCase<IReportPresenter>>(
    //   PRESENTER_REPORT,
    //   (state: Draft<IStatePresenter>, action: ActionCase<IReportPresenter>) => {
    //     state.report = action.payload;
    //   }
    // );
  },
);
