import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { ActionButton, BlockActions, ButtonMain } from '../../common/styles';
import { Block } from '../../common/layout';
import {
  IAccountSettings,
  IAccountSettingsSubscription,
} from '../../interfaces/account/IAccountSettings';
import {
  getSettings,
  updateSettings,
} from '../../actions/account/settings.actions';
import { ContentDesc, ContentHead, PageHead } from '../../common/typos';
import { Loading } from '../../common/animation/Loading';
import { IAccount } from '../../interfaces/account/IAccount';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { sendPageEvent } from '../../utils/analytics';
import { Switch } from '../../common/forms/Switch';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface IUnsubscribe {
  getSettings: () => void;
  updateSettings: (
    auctionUpdates: boolean,
    yourLotUpdates: boolean,
    yourBidUpdates: boolean,
    email: boolean
  ) => void;
  account: null | IAccount;

  settings: undefined | null | IAccountSettings;
}

type SubscriptionsMode = 'auctionUpdates' | 'yourLotUpdates' | 'yourBidUpdates';

function Unsubscribe(props: IUnsubscribe) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] =
    useState<IAccountSettingsSubscription>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => sendPageEvent('Unsubscribe'), []);
  useEffect(() => props.getSettings(), []);
  useEffect(() => {
    if (isLoading && subscriptions) {
      navigate(appConfig.routes.main.home);
      return;
    }

    setSubscriptions(
      props.settings?.subscriptions || initSettingsSubscriptions
    );
    setIsLoading(false);
  }, [props.settings]);

  const initSettingsSubscriptions = (): IAccountSettingsSubscription => {
    return {
      auctionUpdates: true,
      yourLotUpdates: true,
      yourBidUpdates: true,
    };
  };

  const setChange = (mode: SubscriptionsMode, isChecked: boolean) => {
    const subscriptionsLocal: IAccountSettingsSubscription = subscriptions
      ? { ...subscriptions }
      : initSettingsSubscriptions();

    if (mode === 'auctionUpdates') {
      subscriptionsLocal.auctionUpdates = isChecked;
    } else if (mode === 'yourLotUpdates') {
      subscriptionsLocal.yourLotUpdates = isChecked;
    } else if (mode === 'yourBidUpdates') {
      subscriptionsLocal.yourBidUpdates = isChecked;
    }
    setSubscriptions(subscriptionsLocal);
  };
  const unsubscribeAll = () => {
    setSubscriptions({
      auctionUpdates: false,
      yourLotUpdates: false,
      yourBidUpdates: false,
    });
  };
  const resubscribeAll = () => {
    setSubscriptions({
      auctionUpdates: true,
      yourLotUpdates: true,
      yourBidUpdates: true,
    });
  };
  const handleSubmit = () => {
    const subscriptionsLocal: IAccountSettingsSubscription = subscriptions
      ? { ...subscriptions }
      : initSettingsSubscriptions();

    props.updateSettings(
      subscriptionsLocal.auctionUpdates,
      subscriptionsLocal.yourLotUpdates,
      subscriptionsLocal.yourBidUpdates,
      true
    );
    setIsLoading(true);
  };

  const renderItem = (mode: SubscriptionsMode) => {
    return (
      <Switch
        isChecked={subscriptions ? subscriptions[mode] : true}
        cb={(isChecked: boolean) => setChange(mode, isChecked)}
        options={[t('ActionNo'), t('ActionYes')]}
      />
    );
  };

  return (
    <AppContent>
      <Block>
        <PageHead>Your subscription preferences</PageHead>

        {(isLoading && <Loading size={'big'} />) || (
          <>
            <Block className={'marginBlock'}>
              <ContentHead>
                <L k={'AccountSettingsUnsubscribeTitle'} />
              </ContentHead>
              <ContentDesc>
                <L k={'AccountSettingsUnsubscribeDesc'} />
              </ContentDesc>
            </Block>

            <Block>
              <Block>
                <L k={'AccountSettingsAuction'} />{' '}
                {renderItem('auctionUpdates')}
              </Block>
              <Block>
                <L k={'AccountSettingsLot'} /> {renderItem('yourLotUpdates')}
              </Block>
              <Block>
                <L k={'AccountSettingsBid'} /> {renderItem('yourBidUpdates')}
              </Block>
              <BlockActions>
                <ButtonMain onClick={handleSubmit}>
                  <L k={'ButtonSave'} />
                </ButtonMain>
              </BlockActions>
            </Block>

            <BlockActions>
              <ActionButton className="fail" onClick={unsubscribeAll}>
                <L k={'ActionUnsubscribe'} />
              </ActionButton>
              <ActionButton className="success" onClick={resubscribeAll}>
                <L k={'ActionSubscribe'} />
              </ActionButton>
            </BlockActions>
            <BlockActions>
              <ButtonMain onClick={handleSubmit}>
                <L k={'ButtonSave'} />
              </ButtonMain>
            </BlockActions>
          </>
        )}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    settings: state.Account.settings,
  }),
  {
    getSettings,
    updateSettings,
  }
)(Unsubscribe);
