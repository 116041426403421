import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { Block } from '../../common/layout';
import { Loading } from '../../common/animation/Loading';
import { ContentHead, PageHead } from '../../common/typos';
import { loadIDsByFoundations } from '../../actions/account/sellers.actions';
import { sendPageEvent } from '../../utils/analytics';
import ModerationItemRows from '../../common/moderation/ModerationItemRows';
import { renderProductPreviewItem } from '../../components/shop/catalog/catalog';
import SellerItem from '../../components/shop/catalog/SellerItem';
import { L } from '../../common/locale';

interface IFoundationsPageProps {
  loadIDsByFoundations: () => any;
  sellerIDs: string[];
  productIDs: string[];
}

function FoundationsPage(props: IFoundationsPageProps) {
  useEffect(() => sendPageEvent('Foundations'), []);
  useEffect(() => props.loadIDsByFoundations(), []);

  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'ShopTitlePartners'} />
        </PageHead>

        <Block className={'m'}>
          {props.sellerIDs && (
            <ModerationItemRows
              itemIDs={props.sellerIDs}
              renderItem={(sellerID: string, cnt: number) => (
                <SellerItem sellerID={sellerID} cnt={cnt} />
              )}
            />
          )}
        </Block>

        <ContentHead>
          <L k={'ProductByPartners'} />
        </ContentHead>
        <ModerationItemRows
          itemIDs={props.productIDs}
          renderItem={renderProductPreviewItem}
        />

        {props.sellerIDs === null && <Loading size={'big'} />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    sellerIDs: state.ShopProduct.foundation.sellerIDs,
    productIDs: state.ShopProduct.foundation.productIDs,
  }),
  {
    loadIDsByFoundations,
  }
)(FoundationsPage);
