import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { DataBatch, DataBatchKey } from '../../interfaces/system/data';
import {
  IArchiveReportLot,
  IArchiveReportLotData,
  IArchiveReportLotOrder,
  IArchiveReportLotResponse,
  IArchiveReportLotSearch,
  IArchiveReportLotSearchResponse,
  IArchiveReportLotSetData,
  IArchiveReportLotSetResponse,
} from '../../interfaces/archive/IArchiveReportLot';
import {
  ARCHIVE_REPORT_LOT_BATCH_LOAD,
  ARCHIVE_REPORT_LOT_LOAD,
  ARCHIVE_REPORT_LOT_SEARCH,
  ARCHIVE_REPORT_LOT_SET_LOAD,
} from '../types';
import { getComplexPathFromPath } from '../../utils/archive';
import { IServiceError } from '../../interfaces/system/IError';
import { loadAccountsSet } from '../account/accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { ILotBuyer, ILotType } from '../../interfaces/auctions/ILotPublic';
import { getAuctionList } from '../auctions/auction.actions';
import { IAuctionDataSet } from '../../interfaces/auctions/IAuction';

const { reportLotBatch, reportLotSet, reportLotPath, reportLotSearch } =
  apiConfig.endpoints.archive;

export function prepareReportLotBatchKey(
  lotType: ILotType,
  auctionID: string,
  reportLotOrder: IArchiveReportLotOrder
): string {
  return JSON.stringify({
    lotType,
    auctionID,
    reportLotOrder,
  });
}

export const loadArchiveReportLot =
  (auctionPath: string, reportPath: string) =>
  (
    dispatch: IDispatch<
      IAuctionDataSet | IArchiveReportLotData | IAccountSet | IServiceError
    >,
    getState: () => IState
  ) => {
    const complexPath: string = getComplexPathFromPath(auctionPath, reportPath);
    const { IDByComplexPath, set } = getState().Archive.reportLots;
    const reportID: undefined | string = IDByComplexPath[complexPath];
    if (reportID && set[reportID]) {
      return;
    }

    callApi<IArchiveReportLotResponse>(reportLotPath, {
      auctionPath,
      reportPath,
    })
      .then((data: IArchiveReportLotResponse) => {
        const payload: IArchiveReportLotData = {
          auctionPath,
          reportPath,
          report: data.report,
        };

        const { sellerID, buyerList } = data.report;
        const accountIDs: string[] = [sellerID];
        if (buyerList) {
          buyerList.forEach((buyerItem: ILotBuyer) => {
            if (buyerItem.buyerID !== sellerID) {
              accountIDs.push(buyerItem.buyerID);
            }
          });
        }

        getAuctionList()(dispatch, getState);
        loadAccountsSet(accountIDs)(dispatch, getState);

        dispatch({ type: ARCHIVE_REPORT_LOT_LOAD, payload });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadArchiveReportLotSet =
  (lotIDs: string[]) =>
  (
    dispatch: IDispatch<
      IArchiveReportLotSetData | IAuctionDataSet | IAccountSet | IServiceError
    >,
    getState: () => IState
  ) => {
    const IDs: string[] = [];
    const { set } = getState().Archive.reportLots;
    lotIDs.forEach((lotID: string) => {
      if (!set[lotID]) {
        IDs.push(lotID);
      }
    });

    if (!IDs[0]) {
      return;
    }

    callApi<IArchiveReportLotSetResponse>(reportLotSet, {
      lotIDs: IDs.join(','),
    })
      .then((data: IArchiveReportLotSetResponse) => {
        const payload: IArchiveReportLotSetData = {
          auctionsIDByPath: getState().Auction.auctions.IDByPath,
          lotIDs: IDs,
          set: data.set,
        };

        const accountIDSet: Record<string, boolean> = {};
        IDs.forEach((id: string) => {
          const report: IArchiveReportLot = data.set[id];
          if (!report) {
            return;
          }

          const { sellerID, buyerList } = report;
          accountIDSet[sellerID] = true;
          if (buyerList) {
            buyerList.forEach((buyerItem: ILotBuyer) => {
              if (buyerItem.buyerID !== sellerID) {
                accountIDSet[buyerItem.buyerID] = true;
              }
            });
          }
        });

        getAuctionList()(dispatch, getState);
        loadAccountsSet(Object.keys(accountIDSet))(dispatch, getState);
        dispatch({ type: ARCHIVE_REPORT_LOT_SET_LOAD, payload });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadArchiveReportLotBatchNext =
  (
    lotType: ILotType,
    auctionID: string,
    reportLotOrder: IArchiveReportLotOrder
  ) =>
  (
    dispatch: IDispatch<
      | DataBatchKey<string>
      | IArchiveReportLotSetData
      | IAuctionDataSet
      | IAccountSet
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const batchKey: string = prepareReportLotBatchKey(
      lotType,
      auctionID,
      reportLotOrder
    );

    const { limit, byBatchKey } = getState().Archive.reportLots;

    const skip: number =
      (byBatchKey[batchKey] && byBatchKey[batchKey].loaded) || 0;

    callApi<DataBatch<string>>(reportLotBatch, {
      auctionID,
      lotType,
      limit: limit.toString(),
      skip: skip.toString(),
      reportLotOrder,
    })
      .then((data: DataBatch<string>) => {
        const payload: DataBatchKey<string> = {
          ...data,
          batchKey,
        };

        loadArchiveReportLotSet(data.items)(dispatch, getState);
        dispatch({ type: ARCHIVE_REPORT_LOT_BATCH_LOAD, payload });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const searchReportLot =
  (auctionID: string, search: string) =>
  (
    dispatch: IDispatch<
      | IArchiveReportLotSearch
      | IArchiveReportLotSetData
      | IAuctionDataSet
      | IAccountSet
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (search === '') {
      return;
    }
    if (
      getState().Archive.reportLots.IDsBySearch[`${auctionID}-${search}`] !==
      undefined
    ) {
      return;
    }

    callApi<IArchiveReportLotSearchResponse>(reportLotSearch, { search })
      .then((data: IArchiveReportLotSearchResponse) => {
        const payload: IArchiveReportLotSearch = {
          auctionID,
          search,
          reportIDs: data.reportIDs,
        };

        loadArchiveReportLotSet(data.reportIDs)(dispatch, getState);
        dispatch({ type: ARCHIVE_REPORT_LOT_SEARCH, payload });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
