import { getFromSession, setToSession } from './sessionStorage';

export type ICacheCntType = 'ViewCatalog' | 'ViewDetail';

export class CacheCnt {
  private set: Record<string, number> = {};
  private type: ICacheCntType;
  private limit: number;

  constructor(type: ICacheCntType, limit: number) {
    this.type = type;
    this.limit = limit;
    this.load();
  }

  inc(id: string): boolean {
    if (!this.set[id]) {
      this.set[id] = 0;
    }
    this.set[id]++;
    this.save();
    return this.set[id] <= this.limit;
  }

  get(id: string): number {
    return this.set[id];
  }

  clean(): void {
    this.set = {};
  }

  private load() {
    const data: null | string = getFromSession(this.type);
    if (!data) {
      return;
    }
    try {
      const set: Record<string, number> = JSON.parse(data);
      Object.keys(set).forEach((id: string) => {
        const cnt: number = set[id];
        if (!this.set[id]) {
          this.set[id] = 0;
        }
        this.set[id] += cnt;
      });
    } catch (e) {
    }
  }

  private save() {
    setToSession(this.type, JSON.stringify(this.set));
  }
}
