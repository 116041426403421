import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { Block } from '../../common/layout';
import { Loading } from '../../common/animation/Loading';
import { PageHead } from '../../common/typos';
import { ISellerAnalytics } from '../../interfaces/shop/ISeller';
import { loadSellerIDs } from '../../actions/account/sellers.actions';
import { sendPageEvent } from '../../utils/analytics';
import { loadPartnerSellerIDs } from '../../actions/account/partnerSeller.actions';
import ModerationItemRows from '../../common/moderation/ModerationItemRows';
import SellerItem from '../../components/shop/catalog/SellerItem';
import { L } from '../../common/locale';

interface ISellerPageProps {
  loadSellerIDs: () => any;
  loadPartnerSellerIDs: () => any;
  IDs: null | string[];
  analyticsByID: Record<string, ISellerAnalytics>;
}

function SellerPage(props: ISellerPageProps) {
  useEffect(() => sendPageEvent('Seller'), []);
  useEffect(() => {
    props.loadSellerIDs();
    props.loadPartnerSellerIDs();
  }, []);

  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'ShopTitleSellers'} />
        </PageHead>

        <Block>
          {props.IDs && (
            <ModerationItemRows
              itemIDs={props.IDs}
              renderItem={(sellerID: string, cnt: number) => (
                <SellerItem sellerID={sellerID} cnt={cnt} />
              )}
            />
          )}
        </Block>

        {props.IDs === null && <Loading size={'big'} />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    IDs: state.Account.sellers.IDs,
    analyticsByID: state.Account.sellers.analyticsByID,
  }),
  {
    loadSellerIDs,
    loadPartnerSellerIDs,
  }
)(SellerPage);
