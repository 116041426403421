import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { IProductAnalytics } from '../../../interfaces/shop/IProductAnalytics';
import { loadProductsByIDs } from '../../../actions/shop/product.actions';
import { getAnalytics } from '../../../actions/shop/productAnalytics.actions';
import { IProductPreview } from '../../../interfaces/shop/IProduct';
import { ActionButton, BlockActions } from '../../../common/styles';
import { ContentBold, ContentDesc, ContentDescInline } from '../../../common/typos';
import { Loading } from '../../../common/animation/Loading';
import { currencyConvert } from '../../../utils/price';
import LotItemRemove from './LotFormRemove';
import {
  ILotShort,
  ILotShortSet,
  ILotType,
} from '../../../interfaces/auctions/ILotPublic';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { ProductGallery } from '../product/ProductGallery';
import { GalleryBlockSmall } from '../../../common/images/Image.styles';
import { Block } from '../../../common/layout';
import LotForm from './LotForm';

interface ILotAuctionItemProps {
  lotType: ILotType;
  lotID: string;
  productID: null | string;
  loadProductsByIDs: (previewIDs: string[]) => void;
  getAnalytics: (productID: string) => void;
  set: Record<string, IProductPreview>;
  analyticsByProductID: Record<string, null | IProductAnalytics>;
  lotSetModeration: ILotShortSet;
  lotSet: ILotShortSet;
  accounts: IAccountSet;
  foundations: string[];
}

function LotAuctionItem(props: ILotAuctionItemProps) {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<null | IProductAnalytics>(null);

  const [product, setProduct] = useState<null | IProductPreview>(null);
  const [lot, setLot] = useState<null | ILotShort>(null);
  const [sellerMail, setSellerMail] = useState<null | string>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [isFoundation, setIsFoundation] = useState<boolean>(false);

  useEffect(() => {
    const { productID } = props;
    if (productID) {
      props.loadProductsByIDs([productID]);
    }
  }, []);
  useEffect(() => {
    setIsFoundation(
      (props.productID && props.foundations.indexOf(props.productID) !== -1) ||
      false,
    );
  }, [props.foundations, props.productID]);
  useEffect(() => {
    const { productID } = props;
    if (!productID || product) {
      return;
    }

    setProduct(props.set[productID]);
  }, [props.set]);
  useEffect(() => {
    const { productID } = props;
    if (!productID) {
      return;
    }
    const analyticsLocal: null | IProductAnalytics =
      props.analyticsByProductID[productID];
    if (analyticsLocal) {
      setAnalytics(analyticsLocal);
    } else {
      if (!isLoad) {
        setIsLoad(true);
        props.getAnalytics(productID);
      }
    }
  }, [props.analyticsByProductID]);
  useEffect(() => {
    setLot(props.lotSetModeration[props.lotID]);
  }, [props.lotID, props.lotSetModeration]);
  useEffect(() => {
    if (!lot || !lot.sellerID) {
      return;
    }
    const { accounts } = props;
    const seller: null | IAccount = accounts[lot.sellerID] || null;
    if (seller) {
      setSellerMail(seller.mail);
    }
  }, [props.accounts, lot]);

  return (
    <Block className={isFoundation ? 'inline isFoundation' : 'inline'}>
      {(lot && (
        <>
          <Block>
            {/*<ProductItemImage src={lot.imageURL} alt={lot.name} />*/}
            <GalleryBlockSmall>
              <ProductGallery productImagesURL={lot.imagesURL} />
            </GalleryBlockSmall>
            <Block>
              <ContentDesc>{lot.name}</ContentDesc>
              <ContentDesc>{lot.description}</ContentDesc>
              <ContentDesc>
                <>
                  <ContentDescInline>{lot.price}</ContentDescInline>
                  {lot.price !== 0 && (
                    <ContentDescInline>
                      {' '}
                      (${currencyConvert(lot.price)})
                    </ContentDescInline>
                  )}
                </>
              </ContentDesc>
              {sellerMail && <ContentDesc>Seller: {sellerMail}</ContentDesc>}
              {lot.byAlias && <ContentDesc><ContentBold>By: {lot.byAlias}</ContentBold></ContentDesc>}

              {analytics && (
                <>
                  <ContentDesc>Views: {analytics.views}</ContentDesc>
                  <ContentDesc>Unique: {analytics.viewsUnique}</ContentDesc>
                  <ContentDesc>Likes: {analytics.likes}</ContentDesc>
                  <ContentDesc>WillBids: {analytics.willBids}</ContentDesc>
                </>
              )}
            </Block>
          </Block>
          <BlockActions>
            <ActionButton
              onClick={() => setIsEdit(true)}
              disabled={isEdit || isRemove}
            >
              Edit
            </ActionButton>

            <ActionButton
              onClick={() => setIsRemove(true)}
              disabled={isEdit || isRemove}
            >
              Remove
            </ActionButton>
          </BlockActions>
          {isEdit && (
            <LotForm
              lot={lot}
              lotType={props.lotType}
              cbCancel={() => setIsEdit(false)}
              cbDelete={() => setIsRemove(true)}
            />
          )}
          {isRemove && (
            <LotItemRemove
              lot={lot}
              cb={() => setIsRemove(false)}
              lotType={props.lotType}
            />
          )}
        </>
      )) || <Loading />}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    analyticsByProductID: state.ShopProductAnalytics.byProductID,
    set: state.ShopProduct.batchPreview.set,
    foundations: state.ShopProduct.foundation.productIDs,
    lotSetModeration: state.Lot.moderation.set,
    lotSet: state.Lot.short.set,
    accounts: state.Account.accounts.set,
  }),
  {
    loadProductsByIDs,
    getAnalytics,
  },
)(LotAuctionItem);
