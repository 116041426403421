import React from 'react';
import { connect } from 'react-redux';
import { goToLotFromAuction } from '../../../actions/auctions/lotAdmin.actions';
import { ILotType } from '../../../interfaces/auctions/ILotPublic';
import { AdminAction, AdminActionBlock } from '../live/Lot.styles';
import { L } from '../../../common/locale';

interface ILotAdminActionsProps {
  goToLotFromAuction: (lotType: ILotType) => void;
}

function LotAdminActions(props: ILotAdminActionsProps) {
  function sendLot() {
    props.goToLotFromAuction('Live');
  }

  return (
    <AdminActionBlock>
      <AdminAction onClick={sendLot}>
        <L k={'AuctionAdminActionLot'} />
      </AdminAction>
    </AdminActionBlock>
  );
}

export default connect(() => ({}), {
  goToLotFromAuction,
})(LotAdminActions);
