export type KeyLocal = 'AuthToken' | 'PlatformRole';

export function getFromLocal(key: KeyLocal): null | string {
  return window.localStorage.getItem(key);
}

export function setToLocal(key: KeyLocal, value: string) {
  window.localStorage.setItem(key, value);
}

export function removeFromLocal(key: KeyLocal) {
  window.localStorage.removeItem(key);
}
