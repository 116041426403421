import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { ACCOUNT_LOADED, SET_LOGIN_ACTION } from '../types';
import {
  IAccount,
  IAccountAuthBody,
  IAccountAuthResponse,
} from '../../interfaces/account/IAccount';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { getToken, removeToken, setToken } from './auth.actions';
import {
  getFromSession,
  removeFromSession,
  setToSession,
} from '../../service/sessionStorage';
import { IServiceError } from '../../interfaces/system/IError';
import {
  getPlatformRole,
  removePlatformRole,
  setPlatformRole,
} from './role.actions';
import { IPlatformRole } from '../../interfaces/Example/IRole';
import { INotificationSet } from '../../interfaces/account/IAccountNotification';

const { auth, main } = apiConfig.endpoints.account;

export const getPathBeforeLogin = (): null | string => {
  return getFromSession('PathBeforeLogin');
};
export const savePathBeforeLogin = (path: string) => {
  setToSession('PathBeforeLogin', path);
};

export const removePathBeforeLogin = (): void => {
  removeFromSession('PathBeforeLogin');
};
export const getLoginAction = (): null | string => {
  const action: null | string = getFromSession('LoginAction');
  if (!action) {
    return null;
  }
  return action.split('addToCalendar---')[1] || null;
};

export const checkIsLogged =
  () =>
  (
    dispatch: IDispatch<
      IAccount | IPlatformRole | string[] | INotificationSet | IServiceError
    >,
    getState: () => IState
  ) => {
    const token: null | string = getToken();
    if (token) {
      checkToken(token)(dispatch, getState);
    }
  };

export const checkToken =
  (token: string) =>
  (
    dispatch: IDispatch<
      IAccount | IPlatformRole | string[] | INotificationSet | IServiceError
    >,
    getState: () => IState
  ) => {
    const body: IAccountAuthBody = {
      token,
    };

    setToken(token);
    callApi<IAccountAuthResponse>(auth.token, {}, body, 'POST', true)
      .then((data: IAccountAuthResponse) => {
        dispatch({ type: ACCOUNT_LOADED, payload: data.account });
        setToken(token);
        const role: IPlatformRole = getPlatformRole();
        if (role === 'admin') {
          setPlatformRole(role)(dispatch, getState);
        }
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const checkTokenGoogle =
  (token: string) =>
  (
    dispatch: IDispatch<
      IAccount | IPlatformRole | string[] | INotificationSet | IServiceError
    >,
    getState: () => IState
  ) => {
    const body: IAccountAuthBody = {
      token,
    };

    callApi<IAccountAuthResponse>(auth.tokenGoogle, {}, body, 'POST', true)
      .then((data: IAccountAuthResponse) => {
        dispatch({ type: ACCOUNT_LOADED, payload: data.account });
        setToken(data.token);
        const role: IPlatformRole = getPlatformRole();
        if (role === 'admin') {
          setPlatformRole(role)(dispatch, getState);
        }
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const signOut = () => {
  removePlatformRole();
  removeToken();
};

export const goToAddToCalendar =
  (path: string) => (dispatch: IDispatch<string | IServiceError>) => {
    const action: string = `addToCalendar---${path}`;
    dispatch({ type: SET_LOGIN_ACTION, payload: action });
    setToSession('LoginAction', action);
  };
