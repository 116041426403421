import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { ContentBold, ContentDesc } from '../../../common/typos';
import { loadReportAuction } from '../../../actions/auctions/report.actions';
import { Loading } from '../../../common/animation/Loading';
import { ActionLink } from '../../../common/styles';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { IReportAuctionUpdate } from '../../../interfaces/auctions/IReportAuction';
import {
  ILotShort,
  ILotShortSet,
  ILotType,
} from '../../../interfaces/auctions/ILotPublic';
import { loadLotOne } from '../../../actions/auctions/lotPublic.actions';
import TopDonators from '../reports/TopDonators';
import TopBidders from '../reports/TopBidders';
import TopSellers from '../reports/TopSellers';
import { Block } from '../../../common/layout';
import { L } from '../../../common/locale';
import { getRewriteConfig } from '../../../config/platform.config';

interface IWeekReportAuctionProps {
  loadReportAuction: (lotType: ILotType) => void;
  loadLotOne: (lotType: ILotType, lotID: string, hardReload: boolean) => void;
  reportAuctionWeek: undefined | null | IReportAuctionUpdate;
  lotSet: ILotShortSet;
}

function WeekReportAuction(props: IWeekReportAuctionProps) {
  const navigate = useNavigate();
  const lotType: ILotType = 'Week';
  const [report, setReport] = useState<null | IReportAuctionUpdate>(null);

  useEffect(() => props.loadReportAuction(lotType), []);
  useEffect(
    () => setReport(props.reportAuctionWeek || null),
    [props.reportAuctionWeek],
  );

  const goToWeekLot = (lotID: string): void => {
    const lot: null | ILotShort = props.lotSet[lotID] || null;
    if (!lot) {
      props.loadLotOne(lotType, lotID, false);
      return;
    }

    navigate(
      appConfig.routes.week.lot.replace(
        `${appConfig.params.productPath}`,
        encodeURIComponent(lot.name),
      ),
    );
  };

  const renderLotName = (lotID: string) => {
    const lot: null | ILotShort = props.lotSet[lotID] || null;
    if (!lot) {
      props.loadLotOne(lotType, lotID, false);
    }

    return lot ? (
      <ActionLink>{
        lot.name.length > 20 ? lot.name.slice(0, 20) + '...' : lot.name
      }</ActionLink>
    ) : (
      lotID.slice(0, 3) + '... ' + <Loading size="small" />
    );
  };

  return (
    <Block>
      <ContentDesc>
        <ContentBold>
          <L k={'ReportsCurrentWeek'} />
        </ContentBold>
      </ContentDesc>
      {report && (
        <>
          <ContentDesc>
            <ContentBold>
              <L k={'ReportsCurrent'} />{' '}
            </ContentBold>
            {report.total} <L k={'ReportsCurrencyUAH'} />
          </ContentDesc>
          <ContentDesc>
            <ContentBold>
              <L k={'ReportsLots'} />{' '}
            </ContentBold>
            {report.lotCnt}
          </ContentDesc>
          <ContentDesc>
            <ContentBold>
              <L k={'ReportsBids'} />{' '}
            </ContentBold>
            {report.bidCnt}
          </ContentDesc>
          {report.topValuable && (
            <ContentDesc
              className="clickable"
              onClick={() =>
                goToWeekLot(
                  (report.topValuable && report.topValuable.lotID) || '',
                )
              }
            >
              <ContentBold>
                <L k={'ReportsTopLotPrice'} />{' '}
              </ContentBold>
              {renderLotName(report.topValuable.lotID)} -{' '}
              {report.topValuable.value} <L k={'ReportsCurrencyUAH'} />
            </ContentDesc>
          )}
          {report.topPopular && (
            <ContentDesc
              className="clickable"
              onClick={() =>
                goToWeekLot(
                  (report.topPopular && report.topPopular.lotID) || '',
                )
              }
            >
              <ContentBold>
                <L k={'ReportsTopLotPopular'} />{' '}
              </ContentBold>
              {renderLotName(report.topPopular.lotID)} -{' '}
              {report.topPopular.value} <L k={'AnalyticsBids'} />
            </ContentDesc>
          )}
          <ContentDesc>
            <ContentBold>
              <L k={'ReportsDonatorsCurrent'} />
            </ContentBold>
          </ContentDesc>
          <TopDonators topDonators={report.topDonators}></TopDonators>
          <ContentDesc>
            <ContentBold>
              <L k={'ReportsTopBidders'} />
            </ContentBold>
          </ContentDesc>
          <TopBidders topBidders={report.topBidders}></TopBidders>
          {!getRewriteConfig()?.hideWeekReportsTopSellers
            && <>
              <ContentDesc>
                <ContentBold>
                  <L k={'ReportsTopSellers'} />
                </ContentBold>
              </ContentDesc>
              <TopSellers topSellers={report.topSellers}></TopSellers>
            </>}
        </>
      )}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    reportAuctionWeek: state.Report.auctionWeek,
    lotSet: state.Lot.short.set,
  }),
  {
    loadReportAuction,
    loadLotOne,
  },
)(WeekReportAuction);
