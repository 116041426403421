export const ITEM_LOADED = 'ITEM_LOADED';
export const ITEM_CREATED = 'ITEM_CREATED';
export const ITEM_UPDATED = 'ITEM_UPDATED';
export const ITEM_REMOVED = 'ITEM_REMOVED';
export const LOT_SHORT_CURRENT_UPDATE = 'LOT_SHORT_CURRENT_UPDATE';
export const LOT_SHORT_CURRENT_ID_LOAD = 'LOT_SHORT_CURRENT_ID_LOAD';
export const LOT_WIN_CNT_SET = 'LOT_WIN_CNT_SET';
export const LOT_SHORT_SET_LOAD = 'LOT_SHORT_SET_LOAD';
export const LOT_SHORT_IDS_LOAD = 'LOT_SHORT_IDS_LOAD';
export const LOT_DETAILS_SET_CLEAR = 'LOT_DETAILS_SET_CLEAR';
export const LOT_DETAILS_SET_LOAD = 'LOT_DETAILS_SET_LOAD';
export const LOT_DETAILS_IDS_LOAD = 'LOT_DETAILS_IDS_LOAD';
export const LOT_COUNT_LOAD = 'LOT_COUNT_LOAD';
export const LOT_ADMIN_ACTION = 'LOT_ADMIN_ACTION';
export const BID_SET_LOAD = 'BID_SET_LOAD';
export const BID_IDS_LOAD = 'BID_IDS_LOAD';
export const BID_IDS_LAST_LOAD = 'BID_IDS_LAST_LOAD';
export const BID_ADDED_COMPLETED = 'BID_ADDED_COMPLETED';
export const BID_ADDED = 'BID_ADDED';
export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
export const ACCOUNT_ACTION = 'ACCOUNT_ACTION';
export const ACCOUNT_ROLE_SET = 'ACCOUNT_ROLE_SET';
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';
export const ACCOUNTS_LOAD_SET = 'ACCOUNTS_LOAD_SET';
export const ACCOUNTS_LOAD_BATCH = 'ACCOUNTS_LOAD_BATCH';
export const ACCOUNTS_SEARCH_LOAD = 'ACCOUNTS_SEARCH_LOAD';
export const ADMIN_IDS_LOADED = 'ADMIN_IDS_LOADED';
export const FOUNDATION_IDS_LOADED = 'FOUNDATION_IDS_LOADED';
export const API_ERROR = 'API_ERROR';
export const API_ERROR_POP = 'API_ERROR_POP';
export const REPORT_AUCTION_LOAD = 'REPORT_AUCTION';
export const REPORT_LOT_LOAD = 'REPORT_LOT';
export const PRESENTER_REPORT = 'PRESENTER_REPORT';
export const ARCHIVE_REPORT_USER_LOAD = 'ARCHIVE_REPORT_USER_LOAD';
export const ARCHIVE_REPORT_USERS_BATCH_LOAD =
  'ARCHIVE_REPORT_USERS_BATCH_LOAD';
export const ARCHIVE_REPORT_USERS_SEARCH = 'ARCHIVE_REPORT_USERS_SEARCH';
export const ARCHIVE_REPORT_LOT_LOAD = 'ARCHIVE_REPORT_LOT_LOAD';
export const ARCHIVE_REPORT_LOT_SET_LOAD = 'ARCHIVE_REPORT_LOT_SET_LOAD';
export const ARCHIVE_REPORT_LOT_BATCH_LOAD = 'ARCHIVE_REPORT_LOT_BATCH_LOAD';
export const ARCHIVE_REPORT_LOT_SEARCH = 'ARCHIVE_REPORT_LOT_SEARCH';
export const AUCTION_LOAD = 'AUCTION_LOAD';
export const AUCTION_ACTION = 'AUCTION_ACTION';
export const AUCTION_NEXT_LOAD = 'AUCTION_NEXT_LOAD';
export const AUCTION_MODE_LOAD = 'AUCTION_MODE_LOAD';
export const AUCTION_MODE_SET = 'AUCTION_MODE_SET';
export const AUCTION_NEXT_WEEK_END_LOAD = 'AUCTION_NEXT_WEEK_END_LOAD';
export const AUCTION_CLEAR = 'AUCTION_CLEAR';
export const HALL_LEGEND_IS_LOAD = 'HALL_LEGEND_IS_LOAD';
export const HALL_BATCH_IS_LOAD = 'HALL_BATCH_IS_LOAD';
export const NOTIFICATIONS_IDS_LOAD = 'NOTIFICATIONS_IDS_LOAD';
export const NOTIFICATIONS_ONE_LOAD = 'NOTIFICATIONS_ONE_LOAD';
export const NOTIFICATIONS_SET_LOAD = 'NOTIFICATIONS_SET_LOAD';
export const SETTINGS_LOAD = 'SETTINGS_LOAD';
export const NOTIFICATION_VIEW = 'NOTIFICATION_VIEW';
export const NOTIFICATIONS_FOR_MODERATION_LOAD =
  'NOTIFICATIONS_FOR_MODERATION_LOAD';
export const SHOP_MODERATION_BATCH_LOAD = 'SHOP_MODERATION_BATCH_LOAD';
export const SHOP_MODERATION_LOG_MODERATION_LOAD =
  'SHOP_MODERATION_LOG_MODERATION_LOAD';
export const SHOP_MODERATION_LOG_CHANGE_LOAD =
  'SHOP_MODERATION_LOG_CHANGE_LOAD';
export const SHOP_MODERATION_UPDATE = 'SHOP_MODERATION_UPDATE';
export const SHOP_PRODUCT_DRAFT_CREATED = 'SHOP_PRODUCT_DRAFT_CREATED';
export const SHOP_PRODUCT_DRAFT_UPDATED = 'SHOP_PRODUCT_DRAFT_UPDATED';
export const SHOP_PRODUCT_DRAFT_EDIT = 'SHOP_PRODUCT_DRAFT_EDIT';
export const SHOP_PRODUCT_DRAFT_LOAD = 'SHOP_PRODUCT_DRAFT_LOAD';
export const SHOP_PRODUCT_DRAFT_ACTION = 'SHOP_PRODUCT_DRAFT_ACTION';
export const SHOP_PRODUCT_LOAD = 'SHOP_PRODUCT_LOAD';
export const SHOP_PRODUCT_DETAILS_LOAD = 'SHOP_PRODUCT_DETAILS_LOAD';
export const SHOP_PRODUCT_BATCH_LOAD = 'SHOP_PRODUCT_BATCH_LOAD';
export const SHOP_PRODUCT_PREVIEW_LOAD = 'SHOP_PRODUCT_PREVIEW_LOAD';
export const SHOP_PRODUCT_SEARCH = 'SHOP_PRODUCT_SEARCH';
export const SHOP_PRODUCT_CLEAR = 'SHOP_PRODUCT_CLEAR';
export const SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LIST_LOAD =
  'SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LIST_LOAD';
export const SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LOAD =
  'SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LOAD';
export const SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_ALL_LOAD =
  'SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_ALL_LOAD';
export const SHOP_PRODUCT_ANALYTICS_LOAD = 'SHOP_PRODUCT_ANALYTICS_LOAD';
export const SHOP_PRODUCT_EDIT = 'SHOP_PRODUCT_EDIT';
export const SHOP_ANALYTICS_LOAD = 'SHOP_ANALYTICS_LOAD';
export const SELLERS_LOAD = 'SELLERS_LOAD';
export const SELLER_DATA_LOAD = 'SELLER_DATA_LOAD';
export const SELLER_SEARCH = 'SELLER_SEARCH';
export const SHOP_FOUNDATION_LOAD = 'SHOP_FOUNDATION_LOAD';
export const CATALOG_ORDER_SET = 'CATALOG_ORDER_SET';
export const SYSTEM_SET_WIDTH = 'SYSTEM_SET_WIDTH';
export const WEEK_COMPLETE = 'WEEK_COMPLETE';
export const DONATION_LOAD = 'DONATION_LOAD';
export const DONATION_ADMIN_LOAD = 'DONATION_ADMIN_LOAD';
export const DONATION_BANK_LOAD = 'DONATION_BANK_LOAD';
export const DONATION_ACTION = 'DONATION_ACTION';
export const ANALYTICS_AU_LOAD = 'ANALYTICS_AU_LOAD';
export const ANALYTICS_ROUTE_LOAD = 'ANALYTICS_ROUTE_LOAD';
export const ANALYTICS_ROUTE_LOAD_EXTENDED = 'ANALYTICS_ROUTE_LOAD_EXTENDED';
export const ANALYTICS_ROUTE_LOAD_OPTIONS = 'ANALYTICS_ROUTE_LOAD_OPTIONS';
export const ANALYTICS_ROUTE_LOAD_CACHE = 'ANALYTICS_ROUTE_LOAD_CACHE';
export const ANALYTICS_CRON_LOAD = 'ANALYTICS_CRON_LOAD';
export const ANALYTICS_CRON_LOAD_CACHE = 'ANALYTICS_CRON_LOAD_CACHE';
export const ANALYTICS_DB_LOAD = 'ANALYTICS_DB_LOAD';
export const ANALYTICS_DB_LOAD_EXTENDED = 'ANALYTICS_DB_LOAD_EXTENDED';
export const ANALYTICS_DB_LOAD_OPTIONS = 'ANALYTICS_DB_LOAD_OPTIONS';
export const ANALYTICS_DB_LOAD_CACHE = 'ANALYTICS_DB_LOAD_CACHE';
export const ANALYTICS_RETENTION_LOAD = 'ANALYTICS_RETENTION_LOAD';
export const ANALYTICS_RETENTION_LOAD_CACHE = 'ANALYTICS_RETENTION_LOAD_CACHE';
export const ANALYTICS_RETENTION_ONLINE_NOW_LOAD =
  'ANALYTICS_RETENTION_ONLINE_NOW_LOAD';
export const MAILING_LOAD_IDS = 'MAILING_LOAD_IDS';
export const MAILING_LOAD_SET = 'MAILING_LOAD_SET';
export const MAILING_LOAD_SETTINGS = 'MAILING_LOAD_SETTINGS';
export const MAILING_LOAD_BATCH = 'MAILING_LOAD_BATCH';
export const MAILING_LOAD_PREVIEW = 'MAILING_LOAD_PREVIEW';
export const MAILING_ACTION = 'MAILING_ACTION';
export const RECOMMENDATION_LOAD_IDS = 'RECOMMENDATION_LOAD_IDS';
export const RECOMMENDATION_LOAD_SET = 'RECOMMENDATION_LOAD_SET';
export const RECOMMENDATION_LOAD_BATCH = 'RECOMMENDATION_LOAD_BATCH';
export const RECOMMENDATION_SEARCH_LOAD = 'RECOMMENDATION_SEARCH_LOAD';
export const RECOMMENDATION_LOAD_PARAMS = 'RECOMMENDATION_LOAD_PARAMS';
export const RECOMMENDATION_LOAD_PARAMS_SET = 'RECOMMENDATION_LOAD_PARAMS_SET';
export const RECOMMENDATION_LOAD_PARAMS_BATCH =
  'RECOMMENDATION_LOAD_PARAMS_BATCH';
export const RECOMMENDATION_ACTION = 'RECOMMENDATION_ACTION';
export const TEMPLATE_PARAMS_LOAD_IDS = 'TEMPLATE_PARAMS_LOAD_IDS';
export const TEMPLATE_PARAMS_LOAD_SET = 'TEMPLATE_PARAMS_LOAD_SET';
export const TEMPLATE_PARAMS_LOAD_BATCH = 'TEMPLATE_PARAMS_LOAD_BATCH';
export const TEMPLATE_PARAMS_SEARCH_LOAD = 'TEMPLATE_PARAMS_SEARCH_LOAD';
export const TEMPLATE_PARAMS_ACTION = 'TEMPLATE_PARAMS_ACTION';
export const PARSED_ITEM_LOAD_IDS = 'PARSED_ITEM_LOAD_IDS';
export const PARSED_ITEM_LOAD_SET = 'PARSED_ITEM_LOAD_SET';
export const PARSED_ITEM_LOAD_BATCH = 'PARSED_ITEM_LOAD_BATCH';
export const PARSED_ITEM_LOAD_BULK = 'PARSED_ITEM_LOAD_BULK';
export const PARSED_ITEM_LOAD_SEARCH = 'PARSED_ITEM_LOAD_SEARCH';
export const PARSED_ITEM_PARSE_BY = 'PARSED_ITEM_PARSE_BY';
export const PARSED_ITEM_PARSE_SITE = 'PARSED_ITEM_PARSE_SITE';
export const PARSED_ITEM_ACTION = 'PARSED_ITEM_ACTION';
export const PARTNER_SELLER_LOAD_IDS = 'PARTNER_SELLER_LOAD_IDS';
export const PARTNER_SELLER_LOAD_SET = 'PARTNER_SELLER_LOAD_SET';
export const PARTNER_SELLER_LOAD_BATCH = 'PARTNER_SELLER_LOAD_BATCH';
export const PARTNER_SELLER_LOAD_BY_PATH = 'PARTNER_SELLER_LOAD_BY_PATH';
export const PARTNER_SELLER_LOAD_BY_ID = 'PARTNER_SELLER_LOAD_BY_ID';
export const PARTNER_SELLER_ACTION = 'PARTNER_SELLER_ACTION';
export const PARTNER_SELLER_SEARCH = 'PARTNER_SELLER_SEARCH';
export const EMAIL_LOG_LOAD_IDS = 'EMAIL_LOG_LOAD_IDS';
export const EMAIL_LOG_LOAD_SET = 'EMAIL_LOG_LOAD_SET';
export const EMAIL_LOG_LOAD_BATCH = 'EMAIL_LOG_LOAD_BATCH';
export const EMAIL_LOG_ACTION = 'EMAIL_LOG_ACTION';
export const SET_LOGIN_ACTION = 'SET_LOGIN_ACTION';
export const SYSTEM_ERROR_LOAD = 'SYSTEM_ERROR_LOAD';
export const SYSTEM_ERROR_LOAD_CACHE = 'SYSTEM_ERROR_LOAD_CACHE';
