import React, { ChangeEvent, useEffect, useState } from 'react';
import { Block } from '../layout';
import { ContentBold, ContentHead } from '../typos';
import { RenderItemInRowFunction } from '../common';
import { InputSearch } from '../forms/Form.styles';
import ModerationItemRows from './ModerationItemRows';

interface ISearchRowsProps {
  title?: string;
  search: (search: string) => void;
  IDsBySearch: Record<string, string[]>;
  renderItem: RenderItemInRowFunction;
  placeholderTitle: string;
}

export function SearchRows(props: ISearchRowsProps) {
  const [itemIDs, setItemIDs] = useState<string[]>([]);
  const [search, setSearch] = useState<null | string>();

  useEffect(
    () => setItemIDs(props.IDsBySearch[search || ''] || []),
    [search, props.IDsBySearch]
  );

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const localSearch = event.target.value;
    setSearch(localSearch);
    props.search(localSearch);
  };

  return (
    <Block className="marginBlock">
      <InputSearch
        type="text"
        onChange={handleOnChange}
        placeholder={'Search by ' + props.placeholderTitle}
      />
      {search && (
        <ContentHead>
          {props.title
            ? props.title[0].toUpperCase() + props.title.slice(1)
            : 'Item'}
          s by <ContentBold>{search}:</ContentBold>
        </ContentHead>
      )}

      <ModerationItemRows renderItem={props.renderItem} itemIDs={itemIDs} />
    </Block>
  );
}
