import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ISizeSet } from '../../../common/sizes';
import { getCatalogItemsCnt, makeBatches } from '../../../utils/catalog';
import { Loading } from '../../../common/animation/Loading';
import { IState } from '../../../interfaces/system/IState';
import { ILotShort, ILotStatus } from '../../../interfaces/auctions/ILotPublic';
import { Block } from '../../../common/layout';
import { WeekLotsItem } from './WeekLotsItem';
import { IAccount } from '../../../interfaces/account/IAccount';

interface IWeekLotsContentProps {
  account: null | IAccount;
  lots: ILotShort[];
  clientWidthSize: null | keyof ISizeSet<any>;
  statusByLotID: Record<string, ILotStatus>;
}

function WeekLotsContent(props: IWeekLotsContentProps) {
  const [cnt, setCnt] = useState<null | number>(null);
  const [batches, setBatches] = useState<null | ILotShort[][]>(null);

  useEffect(() => resetLots(), [props.lots]);

  const resetLots = () => {
    const cnt: number = getCatalogItemsCnt(props.clientWidthSize);
    const batchesLocal: ILotShort[][] = makeBatches(cnt, props.lots);
    setCnt(cnt);
    setBatches(batchesLocal);
  };

  return (
    <Block>
      {batches &&
        cnt &&
        batches.map((batch: ILotShort[], index: number) => (
          <Block key={index} className="flexHeight">
            {batch.map((lot: ILotShort, index: number) => (
              <WeekLotsItem lot={lot} cnt={cnt} key={index} status={props.statusByLotID[lot.id]} />
            ))}
          </Block>
        ))}

      {!batches && <Loading size={'big'} />}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    clientWidthSize: state.System.clientWidthSize,
    statusByLotID: state.Bid.statusByLotID,
  }),
  {},
)(WeekLotsContent);
