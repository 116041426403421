import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  ARCHIVE_REPORT_LOT_BATCH_LOAD,
  ARCHIVE_REPORT_LOT_LOAD,
  ARCHIVE_REPORT_LOT_SEARCH,
  ARCHIVE_REPORT_LOT_SET_LOAD,
  ARCHIVE_REPORT_USER_LOAD,
  ARCHIVE_REPORT_USERS_BATCH_LOAD,
  ARCHIVE_REPORT_USERS_SEARCH,
  AUCTION_LOAD,
} from '../../actions/types';
import { ActionCase, IStateArchive } from '../../interfaces/system/IState';
import {
  IArchiveReportUser,
  IArchiveReportUserSearch,
  IArchiveReportUserSet,
} from '../../interfaces/archive/IArchiveReportUser';
import { makePathFromName } from '../../utils/routes';
import {
  IArchiveReportLot,
  IArchiveReportLotData,
  IArchiveReportLotSearch,
  IArchiveReportLotSetData,
} from '../../interfaces/archive/IArchiveReportLot';
import { getComplexPathFromPath } from '../../utils/archive';
import { IAuction, IAuctionDataSet } from '../../interfaces/auctions/IAuction';
import { DataBatchKey, DataBatchWide } from '../../interfaces/system/data';

const initialStateArchive: IStateArchive = {
  reportUsers: {
    limit: 10,
    byBatchKey: {},
    set: {},
    IDByComplexPath: {},
    IDsBySearch: {},
  },
  reportLots: {
    limit: 10,
    byBatchKey: {},
    set: {},
    IDByComplexPath: {},
    IDsBySearch: {},
  },
};

export const archiveReducer: Reducer = createReducer(
  initialStateArchive,
  (builder: ActionReducerMapBuilder<IStateArchive>) => {
    // auction
    builder.addCase<string, ActionCase<IAuctionDataSet>>(
      AUCTION_LOAD,
      (state: Draft<IStateArchive>, action: ActionCase<IAuctionDataSet>) => {
        const { auctions } = action.payload;
        const $set: Record<string, IArchiveReportLot> = state.reportLots.set;
        const lotIDs: string[] = Object.keys($set);
        auctions.forEach((auction: IAuction) => {
          const auctionPath = makePathFromName(auction.name);
          lotIDs.forEach((lotID: string) => {
            const reportPath: string = makePathFromName($set[lotID].name);
            const complexPath: string = getComplexPathFromPath(
              auctionPath,
              reportPath
            );

            state.reportLots.IDByComplexPath[complexPath] = lotID;
          });
        });
      }
    );
    // report user
    builder.addCase<string, ActionCase<IArchiveReportUserSet>>(
      ARCHIVE_REPORT_USER_LOAD,
      (
        state: Draft<IStateArchive>,
        action: ActionCase<IArchiveReportUserSet>
      ) => {
        const { reports, auctionPath } = action.payload;
        const { set, IDByComplexPath } = state.reportUsers;

        reports.forEach((report: IArchiveReportUser) => {
          const { accountName, id } = report;

          const accountPath: string = makePathFromName(accountName);
          const complexPath: string = getComplexPathFromPath(
            auctionPath,
            accountPath
          );
          if (!IDByComplexPath[complexPath]) {
            IDByComplexPath[complexPath] = [];
          }
          IDByComplexPath[complexPath].push(id);
          set[id] = report;
        });
      }
    );
    builder.addCase<string, ActionCase<DataBatchKey<IArchiveReportUser>>>(
      ARCHIVE_REPORT_USERS_BATCH_LOAD,
      (
        state: Draft<IStateArchive>,
        action: ActionCase<DataBatchKey<IArchiveReportUser>>
      ) => {
        const { cnt, items, batchKey } = action.payload;

        const { byBatchKey, set } = state.reportUsers;
        if (!byBatchKey[batchKey]) {
          byBatchKey[batchKey] = {
            loaded: 0,
            IDs: [],
            cnt: 0,
          };
        }
        const batchSet: DataBatchWide = byBatchKey[batchKey];
        batchSet.loaded += items.length;
        batchSet.IDs = batchSet.IDs.concat(items.map((i) => i.id));
        batchSet.cnt = cnt;

        items.forEach((report: IArchiveReportUser) => {
          set[report.id] = report;
        });
      }
    );
    builder.addCase<string, ActionCase<IArchiveReportUserSearch>>(
      ARCHIVE_REPORT_USERS_SEARCH,
      (
        state: Draft<IStateArchive>,
        action: ActionCase<IArchiveReportUserSearch>
      ) => {
        const { search, reports, auctionID } = action.payload;
        const { IDsBySearch, set } = state.reportUsers;

        const IDs: string[] = [];
        reports.forEach((report: IArchiveReportUser) => {
          const { id } = report;
          set[id] = report;
          IDs.push(id);
        });
        IDsBySearch[`${auctionID}-${search}`] = IDs;
      }
    );

    // report lot
    builder.addCase<string, ActionCase<IArchiveReportLotData>>(
      ARCHIVE_REPORT_LOT_LOAD,
      (
        state: Draft<IStateArchive>,
        action: ActionCase<IArchiveReportLotData>
      ) => {
        const { auctionPath, report } = action.payload;
        const id: string = report.id;
        state.reportLots.set[id] = report;

        const reportPath: string = makePathFromName(report.name);
        if (auctionPath && reportPath) {
          const complexPath: string = getComplexPathFromPath(
            auctionPath,
            reportPath
          );
          state.reportLots.IDByComplexPath[complexPath] = id;
        }
      }
    );
    builder.addCase<string, ActionCase<IArchiveReportLotSetData>>(
      ARCHIVE_REPORT_LOT_SET_LOAD,
      (
        state: Draft<IStateArchive>,
        action: ActionCase<IArchiveReportLotSetData>
      ) => {
        const { lotIDs, set } = action.payload;
        const IDByPath: Record<string, string> =
          action.payload.auctionsIDByPath;

        lotIDs.forEach((lotID: string) => {
          const report: IArchiveReportLot = set[lotID];
          if (report) {
            state.reportLots.set[lotID] = report;

            let auctionPath = '';
            Object.keys(IDByPath).forEach((path: string) => {
              if (IDByPath[path] === report.auctionID) {
                auctionPath = path;
              }
            });

            const reportPath: string = makePathFromName(report.name);
            const complexPath: string = getComplexPathFromPath(
              auctionPath,
              reportPath
            );

            state.reportLots.IDByComplexPath[complexPath] = lotID;
          }
        });
      }
    );
    builder.addCase<string, ActionCase<DataBatchKey<string>>>(
      ARCHIVE_REPORT_LOT_BATCH_LOAD,
      (
        state: Draft<IStateArchive>,
        action: ActionCase<DataBatchKey<string>>
      ) => {
        const { cnt, items, batchKey } = action.payload;

        const { byBatchKey } = state.reportLots;
        if (!byBatchKey[batchKey]) {
          byBatchKey[batchKey] = {
            loaded: 0,
            IDs: [],
            cnt: 0,
          };
        }
        const batchSet: DataBatchWide = byBatchKey[batchKey];
        batchSet.loaded += items.length;
        batchSet.IDs = batchSet.IDs.concat(items);
        batchSet.cnt = cnt;
      }
    );
    builder.addCase<string, ActionCase<IArchiveReportLotSearch>>(
      ARCHIVE_REPORT_LOT_SEARCH,
      (
        state: Draft<IStateArchive>,
        action: ActionCase<IArchiveReportLotSearch>
      ) => {
        const { search, reportIDs, auctionID } = action.payload;
        const { IDsBySearch } = state.reportLots;

        const IDs: string[] = [];
        reportIDs.forEach((reportID: string) => {
          IDs.push(reportID);
        });
        IDsBySearch[`${auctionID}-${search}`] = IDs;
      }
    );
  }
);
