import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ActionButton, ActionLink, ButtonMain } from '../../../common/styles';
import { ContentDesc, PageDesc } from '../../../common/typos';
import { getShopAnalytics } from '../../../actions/shop/shopAnalytics.actions';
import { IState } from '../../../interfaces/system/IState';
import { IShopAnalytics } from '../../../interfaces/shop/IShopAnalytics';
import { Loading } from '../../../common/animation/Loading';
import { IProductPreviewOrder } from '../../../interfaces/shop/IProduct';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../../config/app.config';
import { setOrderForCatalog } from '../../../actions/shop/shop.actions';
import { DataBatchWide } from '../../../interfaces/system/data';
import { getPlatformType } from '../../../config/platform.config';
import { L } from '../../../common/locale';

interface IShopAnalyticsProps {
  getShopAnalytics: () => void;
  setOrderForCatalog: (order: null | IProductPreviewOrder) => any;
  shopAnalytics: undefined | null | IShopAnalytics;
  batch: Record<IProductPreviewOrder, DataBatchWide>;
  foundations: string[];
  foundationProductIDs: string[];
}

function ShopAnalytics(props: IShopAnalyticsProps) {
  const navigate = useNavigate();
  const [analytics, setAnalytics] = useState<undefined | null | IShopAnalytics>(
    undefined
  );
  const [foundations, setFoundations] = useState<number>(0);
  const [foundationProducts, setFoundationProducts] = useState<number>(0);

  useEffect(() => props.getShopAnalytics(), []);
  useEffect(() => setAnalytics(props.shopAnalytics), [props.shopAnalytics]);
  useEffect(() => {
    setFoundations(props.foundations.length);
    setFoundationProducts(props.foundationProductIDs.length);
  }, [props.foundations]);

  const goToCatalog = (order: null | IProductPreviewOrder) => {
    props.setOrderForCatalog(order);
    navigate(appConfig.routes.shopProducts.list);
  };

  const goToSellers = () => {
    navigate(appConfig.routes.shopProducts.sellers);
  };

  const goToFoundations = () => {
    navigate(appConfig.routes.shopProducts.foundations);
  };

  const goToAddLot = () => {
    navigate(appConfig.routes.shop.addLot);
  };

  const [isProductsExists, setIsProductsExists] = useState<boolean>(false);
  useEffect(() => {
    setIsProductsExists(
      (props.batch.new.IDs && !!props.batch.new.IDs[0]) ||
        (props.batch.liked.IDs && !!props.batch.liked.IDs[0]) ||
        (props.batch.popular.IDs && !!props.batch.popular.IDs[0])
    );
  }, [props.batch.new.IDs, props.batch.liked.IDs, props.batch.popular.IDs]);

  return (
    <>
      {analytics && isProductsExists && (
        <>
          <ContentDesc className="clickable" onClick={() => goToCatalog(null)}>
            <ActionLink>
              {analytics.products} <L k={'AnalyticsLots'} />
            </ActionLink>
          </ContentDesc>
          <ContentDesc
            className="clickable"
            onClick={() => goToCatalog('popular')}
          >
            <ActionLink>
              {analytics.views} <L k={'AnalyticsViews'} />
            </ActionLink>
          </ContentDesc>
          <ContentDesc
            className="clickable"
            onClick={() => goToCatalog('liked')}
          >
            <ActionLink>
              {analytics.likes} <L k={'AnalyticsLikes'} />
            </ActionLink>
          </ContentDesc>
          <ContentDesc className="clickable" onClick={() => goToSellers()}>
            <ActionLink>
              {analytics.sellers}{' '}
              {getPlatformType() === 'artukraine' ? 'creators' : 'sellers'}
            </ActionLink>
          </ContentDesc>
          <ContentDesc>
            {analytics.followers} <L k={'AnalyticsFollowers'} />
          </ContentDesc>

          {/*todo show foundations without seller as a partner*/}

          {/*{foundations !== 0 && (*/}
          {/*  <ContentDesc*/}
          {/*    className="clickable"*/}
          {/*    onClick={() => goToFoundations()}*/}
          {/*  >*/}
          {/*    <a>*/}
          {/*      {foundations} partner{foundations === 1 ? '' : 's'} with{' '}*/}
          {/*      {foundationProducts} lot{foundationProducts === 1 ? '' : 's'}*/}
          {/*    </a>*/}
          {/*  </ContentDesc>*/}
          {/*)}*/}
          {/*todo add call to action on empty page (add partner)*/}
          <br />
          <ActionButton onClick={() => goToCatalog(null)}>
            <L k={'ButtonLotsAll'} />
          </ActionButton>
          <br />
        </>
      )}
      {analytics === undefined && <Loading />}
      {(analytics === null || !isProductsExists) && (
        <>
          <PageDesc>
            <L k={'LotTitleNoLots'} />
          </PageDesc>
          <ButtonMain onClick={goToAddLot}>
            <L k={'ButtonLotAddFirst'} />
          </ButtonMain>
        </>
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    shopAnalytics: state.ShopProductAnalytics.shopAnalytics,
    batch: state.ShopProduct.batchPreview.batch,
    foundations: state.ShopProduct.foundation.sellerIDs,
    foundationProductIDs: state.ShopProduct.foundation.productIDs,
  }),
  {
    getShopAnalytics,
    setOrderForCatalog,
  }
)(ShopAnalytics);
