import React, { useState } from 'react';
import { ContentBold, ContentDesc, ContentDescInline } from '../../../common/typos';
import { Block } from '../../../common/layout';
import { ITemplateParamsPublic, ITemplateParamsView } from '../../../interfaces/communication/ITemplateParams';
import { IParamsItemView } from '../../../common/common';
import { ActionLink } from '../../../common/styles';
import { prepareAuctionDate } from '../../../utils/renderParts';

interface ITemplateParamsItemModerationProps {
  templateParams: ITemplateParamsPublic;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
  ParamsItemView?: IParamsItemView;
}

export function TemplateParamsItemModeration(
  props: ITemplateParamsItemModerationProps,
) {
  const [isParamValueShow, setIsParamValueShow] = useState<
    Record<string, boolean>
  >({});
  const templateParams: ITemplateParamsPublic = props.templateParams;
  const view: null | ITemplateParamsView = props.ParamsItemView
    ? (props.ParamsItemView as ITemplateParamsView)
    : null;

  const toggleParamValue = (key: string): void => {
    const isParamValueShowLocal: Record<string, boolean> = Object.assign(
      {},
      isParamValueShow,
    );
    isParamValueShowLocal[key] = !isParamValueShowLocal[key];
    setIsParamValueShow(isParamValueShowLocal);
  };
  // const renderParamsInline = (): JSX.Element => {
  //   const keys: string[] = Object.keys(templateParams.params);
  //   return (
  //     <>
  //       {keys.map((key: string) => (
  //         <ContentDescInline>
  //           <ContentDescInline>
  //             <ActionLink onClick={() => toggleParamValue(key)}>
  //               {!isParamValueShow[key] ? 'show ' : 'hide '}
  //               {key}
  //             </ActionLink>
  //             {'; '}
  //           </ContentDescInline>
  //
  //           {isParamValueShow[key] && (
  //             <ContentDesc>{templateParams.params[key]}</ContentDesc>
  //           )}
  //         </ContentDescInline>
  //       ))}
  //     </>
  //   );
  // };
  const renderParams = (): JSX.Element => {
    const keys: string[] = Object.keys(templateParams.params);
    return (
      <>
        {keys.map((key: string) => (
          <Block>
            <ContentDesc>
              <ActionLink onClick={() => toggleParamValue(key)}>
                {!isParamValueShow[key] ? 'show ' : 'hide '}
                {key}
              </ActionLink>
            </ContentDesc>

            {isParamValueShow[key] && (
              <ContentDesc>{templateParams.params[key]}</ContentDesc>
            )}
          </Block>
        ))}
      </>
    );
  };

  return (
    <>
      {props.showInline && (
        <>
          {!view && (
            <ContentDesc>
              <ContentBold>{templateParams.mode}</ContentBold>
              {' - '}
              <ContentBold>{templateParams.group}</ContentBold>
              {' - '}
              {templateParams.type && <ContentDescInline>({templateParams.type}). </ContentDescInline>}
              {templateParams.params && renderParams()}
              {props.inlineBlock}
            </ContentDesc>
          )}
          {view && (
            <ContentDesc>
              {view.mode && <ContentBold>{templateParams.mode}</ContentBold>}
              {view.mode && ' - '}
              {view.group && <ContentBold>{templateParams.group}</ContentBold>}
              {view.group && ' - '}
              {view.type && templateParams.type && (
                <ContentDescInline>({templateParams.type}). </ContentDescInline>
              )}
              {view.params && templateParams.params && renderParams()}
              {view.value && <ContentDesc>{templateParams.value}</ContentDesc>}
              {view.createdAt && (
                <ContentDescInline>
                  {prepareAuctionDate(templateParams.createdAt)}
                  {'. '}
                </ContentDescInline>
              )}
              {props.inlineBlock}
            </ContentDesc>
          )}
        </>
      )}
    </>
  );
}
