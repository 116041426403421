import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  IProductPreview,
  IProductSeller,
  IProductSellerResponse,
} from '../../interfaces/shop/IProduct';
import {
  SELLER_DATA_LOAD,
  SELLER_SEARCH,
  SELLERS_LOAD,
  SHOP_FOUNDATION_LOAD,
} from '../types';
import { loadAccountsSet } from './accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';
import {
  ISellerAnalyticsData,
  ISellerAnalyticsResponse,
  ISellerListResponse,
} from '../../interfaces/shop/ISeller';
import { loadProductsByIDs } from '../shop/product.actions';
import { IProductAnalyticsByAccount } from '../../interfaces/shop/IProductAnalytics';
import { IServiceError } from '../../interfaces/system/IError';
import { DataSearch } from '../../interfaces/system/data';

const { sellers } = apiConfig.endpoints.account;

export const loadSellerIDs =
  (hardReload?: boolean) =>
  (
    dispatch: IDispatch<string[] | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!hardReload && getState().Account.sellers.IDs) {
      return;
    }

    callApi<ISellerListResponse>(sellers.list)
      .then((data: ISellerListResponse) => {
        dispatch({ type: SELLERS_LOAD, payload: data.sellerIDs });
        loadAccountsSet(data.sellerIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadIDsByFoundations =
  () =>
  (
    dispatch: IDispatch<
      | IProductSeller[]
      | IAccountSet
      | IProductPreview[]
      | IProductAnalyticsByAccount[]
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (getState().ShopProduct.foundation.sellerIDs[0]) {
      return;
    }

    callApi<IProductSellerResponse>(sellers.foundations)
      .then((data: IProductSellerResponse) => {
        dispatch({ type: SHOP_FOUNDATION_LOAD, payload: data.products });
        loadProductsByIDs(data.products.map((p) => p.productID))(
          dispatch,
          getState
        );
        loadAccountsSet(data.products.map((p) => p.sellerID))(
          dispatch,
          getState
        );
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const reloadSeller =
  (accountID: string) =>
  (
    dispatch: IDispatch<
      | ISellerAnalyticsData
      | IProductPreview[]
      | IProductAnalyticsByAccount[]
      | IServiceError
    >,
    getState: () => IState
  ) => {
    callApi<ISellerAnalyticsResponse>(sellers.analytics, { accountID })
      .then((data: ISellerAnalyticsResponse) => {
        const payload: ISellerAnalyticsData = {
          sellerID: accountID,
          ...data,
        };
        dispatch({ type: SELLER_DATA_LOAD, payload });
        loadProductsByIDs(data.analytics.productIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const searchSeller =
  (search: string) =>
  (
    dispatch: IDispatch<
      string | DataSearch<string> | IAccountSet | IServiceError
    >,
    getState: () => IState
  ) => {
    if (search.length < 1) {
      return;
    }
    if (getState().Account.sellers.IDsBySearch[search] !== undefined) {
      return;
    }

    callApi<ISellerListResponse>(sellers.search, { search })
      .then((data: ISellerListResponse) => {
        const payload: DataSearch<string> = {
          search,
          items: data.sellerIDs,
        };

        dispatch({ type: SELLER_SEARCH, payload });
        loadAccountsSet(data.sellerIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
