import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateAccount } from '../../actions/account/account.actions';
import { IState } from '../../interfaces/system/IState';
import { IAccount, IAccountUpdate } from '../../interfaces/account/IAccount';
import { FormBlock } from '../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../common/forms/FormCommon';
import { FormsFields } from '../../common/forms/forms';

interface IAccountFormProps {
  updateAccount: (image: null | string, name: null | string) => void;
  account: null | IAccount;
  actions: string[];
  errors: string[];
}

function AccountForm(props: IAccountFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormFields<IAccountUpdate>>({
    name: {
      ...FormsFields.name,
      mode: 'input',
    },
    imgURL: {
      ...FormsFields.imageURL,
    },
  });

  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.actions]);
  useEffect(() => {
    if (!props.account) {
      return;
    }

    if (fields.name) {
      fields.name.$value = props.account.name;
    }
    if (fields.imgURL) {
      fields.imgURL.$valueList = props.account.imgURL
        ? [props.account.imgURL]
        : [];
    }
    setFields({ ...fields });
  }, [props.account]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbUpdate = () => {
    if (!props.account) {
      return;
    }

    setIsSending(true);
    props.updateAccount(
      (fields.imgURL &&
        fields.imgURL.$valueList &&
        fields.imgURL.$valueList[0]) ||
        null,
      (fields.name && fields.name.$value) || null
    );
  };

  return (
    <FormBlock>
      <FormCommon
        fields={fields}
        cb={{
          cbUpdate,
        }}
        action={{
          isUpdate: true,
          isSending,
        }}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    actions: state.Account.accounts.actions,
    errors: state.System.errors,
  }),
  {
    updateAccount,
  }
)(AccountForm);
