import React, { useState } from 'react';
import { Image } from './Image.styles';

interface IImagePersonProps {
  imgURL: null | string;
  name?: string;
  size?: 'S' | 'M' | 'XL';
  isInline?: boolean;
}

export function ImagePerson(props: IImagePersonProps) {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {(!props.imgURL || !loaded) && (
        <Image
          src="/icons/user.png"
          alt={props.name}
          className={
            (props.isInline ? 'inline' : '') + ' ' + (props.size || '')
          }
        />
      )}

      {props.imgURL && (
        <Image
          src={props.imgURL}
          alt={props.name}
          style={loaded ? {} : { display: 'none' }}
          className={
            (props.isInline ? 'inline' : '') + ' ' + (props.size || '')
          }
          onLoad={() => setLoaded(true)}
        />
      )}
    </>
  );
}
