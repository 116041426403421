import React from 'react';
import { ContentBold, ContentDesc, ContentDescInline } from '../common/typos';
import { getRewriteConfig } from '../config/platform.config';

export const getDateByTimezone = (date: string, timezone: string): string => {
  return new Intl.DateTimeFormat('ua-UA', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
  }).format(new Date(date));
};

export const prepareAuctionDate = (date: null | string): string => {
  let dateValue: null | string;
  if (!date) {
    dateValue = 'TBD';
  } else {
    if (!isNaN(Date.parse(date))) {
      dateValue =
        getRewriteConfig()?.auctionTimeByKyiv
          ? getDateByTimezone(date, 'Europe/Kyiv') + ' (by Kyiv time)'
          : new Date(date).toLocaleString().slice(0, -3)
      ;
    } else {
      dateValue = date;
    }
  }

  return dateValue;
};

export const renderAuctionDate = (
  date: null | string,
  opts?: {
    label?: string;
    hideLabel?: boolean;
    isBold?: boolean;
    inline?: boolean;
  },
) => {
  const dateValue: null | string = prepareAuctionDate(date);
  let label: string = '';
  if (opts && !opts.hideLabel) {
    label = opts.label || 'Auction date';
  }

  return (
    <>
      {opts && opts.inline ? (
        <ContentDescInline>{dateValue}</ContentDescInline>
      ) : (
        <ContentDesc>
          {label ? label + ': ' : ''}
          {opts && opts.isBold ? (
            <ContentBold>{dateValue}</ContentBold>
          ) : (
            <ContentDescInline>{dateValue}</ContentDescInline>
          )}
        </ContentDesc>
      )}
    </>
  );
};
