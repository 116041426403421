import styled from 'styled-components';
import { margins, sizes } from '../../common/sizes';

export const PresenterBlock = styled.div`
  padding: 0 5%;

  display: flex;
  justify-content: space-between;
`;
export const PresenterColumn = styled.div`
  width: ${sizes.half};
  padding: 0 10%;

  h2 {
    margin: 0;
  }
`;

export const PresenterGifBlock = styled.div`
  float: unset;
  display: block;
  margin: ${margins.blockX2} auto;
  width: inherit;
`;
