import styled from 'styled-components';
import { Block } from '../../common/layout';
import { device, sizes } from '../../common/sizes';

export const LoginBlock = styled.div`
  ${Block};
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: ${sizes.full};

  @media ${device.mobileL} {
    width: ${sizes.half};
  }
}
`;
