import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { HALL_BATCH_IS_LOAD, HALL_LEGEND_IS_LOAD } from '../../actions/types';
import { ActionCase, IStateHall } from '../../interfaces/system/IState';
import {
  DataBatchSetHallLegend,
  IHallLegend,
  IHallLegendByNameSet,
} from '../../interfaces/hall/IHallLegends';

const initialStateHall: IStateHall = {
  set: {},
  IDByPath: {},
  batchLimit: 10,
  batchCnt: null,
  batch: {},
};

export const hallReducer: Reducer = createReducer(
  initialStateHall,
  (builder: ActionReducerMapBuilder<IStateHall>) => {
    builder.addCase<string, ActionCase<IHallLegendByNameSet>>(
      HALL_LEGEND_IS_LOAD,
      (state: Draft<IStateHall>, action: ActionCase<IHallLegendByNameSet>) => {
        const { legends, accountPath } = action.payload;

        state.IDByPath[accountPath] = legends.map(
          (legend: IHallLegend) => legend.id
        );
        legends.forEach((legend: IHallLegend) => {
          state.set[legend.id] = legend;
        });
      }
    );
    builder.addCase<string, ActionCase<DataBatchSetHallLegend>>(
      HALL_BATCH_IS_LOAD,
      (
        state: Draft<IStateHall>,
        action: ActionCase<DataBatchSetHallLegend>
      ) => {
        const { order, cnt, items } = action.payload;
        state.batchCnt = cnt;
        if (!state.batch[order]) {
          state.batch[order] = {
            IDs: [],
            loaded: 0,
            cnt,
          };
        }

        state.batch[order].loaded += items.length;
        state.batch[order].IDs = state.batch[order].IDs.concat(
          items.map((legend: IHallLegend) => legend.id)
        );
        items.forEach((legend: IHallLegend) => {
          state.set[legend.id] = legend;
        });
      }
    );
  }
);
