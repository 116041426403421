import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import {
  AUCTION_ACTION,
  DONATION_BANK_LOAD,
  ITEM_CREATED,
  ITEM_LOADED,
  ITEM_REMOVED,
  ITEM_UPDATED,
} from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import {
  IDonationBankDetails,
  IDonationBankDetailsCreateBody,
  IDonationBankDetailsListResponse,
  IDonationBankDetailsSet,
  IDonationBankDetailsUpdateBody,
} from '../../interfaces/donation/IDonationBank';
import { loadAccountsSet } from '../account/accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { dispatchError } from '../../service/error';

const { donationBank } = apiConfig.endpoints.archiveDonation;

export const getDonationsBank =
  (auctionID: string) =>
  (
    dispatch: IDispatch<
      string | IDonationBankDetailsSet | IAccountSet | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IDonationBankDetailsListResponse>(donationBank, { auctionID })
      .then((data: IDonationBankDetailsListResponse) => {
        const payload: IDonationBankDetailsSet = {
          auctionID,
          details: data.details,
        };
        dispatch({ type: DONATION_BANK_LOAD, payload });
        const accountIDSet: Record<string, boolean> = {};
        data.details.forEach((details: IDonationBankDetails) => {
          if (details.sellerID) {
            accountIDSet[details.sellerID] = true;
          }
        });

        loadAccountsSet(Object.keys(accountIDSet))(dispatch, getState);
        dispatch({ type: AUCTION_ACTION, payload: ITEM_LOADED });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const setDonationsBank =
  (
    auctionID: string,
    sellerID: null | string, // null - is main bank details
    foundationName: null | string,
    link: string
  ) =>
  (
    dispatch: IDispatch<
      string | IDonationBankDetailsSet | IAccountSet | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IDonationBankDetailsCreateBody = {
      auctionID,
      sellerID,
      foundationName,
      link,
    };

    callApi(donationBank, {}, body)
      .then(() =>
        dispatchError(dispatch, {
          message: 'Bank details is created',
          params: {},
          type: 'Info',
        })
      )
      .then(() => dispatch({ type: AUCTION_ACTION, payload: ITEM_CREATED }))
      .then(() => getDonationsBank(auctionID)(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const updateDonationsBank =
  (
    detailsID: string,
    auctionID: string,
    sellerID: null | string, // null - is main bank details
    foundationName: null | string,
    link: string
  ) =>
  (
    dispatch: IDispatch<
      string | IDonationBankDetailsSet | IAccountSet | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IDonationBankDetailsUpdateBody = {
      detailsID,
      auctionID,
      sellerID,
      foundationName,
      link,
    };

    callApi(donationBank, {}, body, 'POST')
      .then(() =>
        dispatchError(dispatch, {
          message: 'Bank details is updated',
          params: {},
          type: 'Info',
        })
      )
      .then(() => dispatch({ type: AUCTION_ACTION, payload: ITEM_UPDATED }))
      .then(() => getDonationsBank(auctionID)(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const deleteDonationsBank =
  (auctionID: string, detailsID: string) =>
  (
    dispatch: IDispatch<
      string | IDonationBankDetailsSet | IAccountSet | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IDonationBankDetailsListResponse>(
      donationBank,
      { detailsID },
      undefined,
      'DELETE'
    )
      .then(() =>
        dispatchError(dispatch, {
          message: 'Bank details is removed',
          params: {},
          type: 'Info',
        })
      )
      .then(() => dispatch({ type: AUCTION_ACTION, payload: ITEM_REMOVED }))
      .then(() => getDonationsBank(auctionID)(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
