import { IDispatch } from '../interfaces/system/IState';
import { API_ERROR, API_ERROR_POP } from '../actions/types';
import { IServiceError } from '../interfaces/system/IError';

export function dispatchError(
  dispatch: IDispatch<IServiceError>,
  payload: IServiceError,
) {
  dispatch({ type: API_ERROR, payload });
  setTimeout(() => dispatch({ type: API_ERROR_POP, payload }), 3000);
}

export function dispatchInfo(
  dispatch: IDispatch<IServiceError>,
  message: string,
  params?: Record<string, string>,
) {
  const payload: IServiceError = {
    message,
    params: params || {},
    type: 'Info',
  };
  dispatch({ type: API_ERROR, payload });
  setTimeout(() => dispatch({ type: API_ERROR_POP, payload }), 3000);
}

export function dispatchErrorAnAuth(
  dispatch: IDispatch<IServiceError>,
  target: string,
) {
  const payload: IServiceError = {
    message: 'Not authorised',
    params: {},
  };
  console.info('Not authorised', target);
  dispatch({ type: API_ERROR, payload });
  setTimeout(() => dispatch({ type: API_ERROR_POP, payload }), 3000);
}
