import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { CacheRequests } from '../../service/cacheRequests';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAppRecommendationBatchBody,
  IAppRecommendationBatchResponse,
  IAppRecommendationCreateBody,
  IAppRecommendationDeleteQuery,
  IAppRecommendationGroups,
  IAppRecommendationIDsResponse,
  IAppRecommendationSearchQuery,
  IAppRecommendationSet,
  IAppRecommendationSetQuery,
  IAppRecommendationSetResponse,
  IAppRecommendationSocial,
  IAppRecommendationSocialView,
  IAppRecommendationType,
  IAppRecommendationUpdateBody,
  IAppReportLotSearchData,
  IAppReportLotSearchResponse,
} from '../../interfaces/communication/IAppRecommendation';
import { dispatchInfo } from '../../service/error';
import {
  ITEM_CREATED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  RECOMMENDATION_ACTION,
  RECOMMENDATION_LOAD_BATCH,
  RECOMMENDATION_LOAD_IDS,
  RECOMMENDATION_LOAD_SET,
  RECOMMENDATION_SEARCH_LOAD,
} from '../types';
import { DataBatchKeyExtend } from '../../interfaces/system/data';

export function prepareRecommendationBatchKey(
  emails: null | string[],
  categories: undefined | null | string[],
  isEngaged: null | boolean,
  isParsed: null | boolean,
  socialView: null | IAppRecommendationSocialView,
): string {
  return JSON.stringify({
    emails,
    categories,
    isEngaged,
    isParsed,
    socialView,
  });
}

const { recommendation } = apiConfig.endpoints.communication;
const cacheByID: CacheRequests = new CacheRequests();

export const loadRecommendationIDs =
  (hardReload?: boolean) =>
    (
      dispatch: IDispatch<
        string | string[] | IAppRecommendationSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      const { Communication } = getState();
      if (!hardReload && Communication.Recommendation.IDs[0]) {
        return;
      }

      callApi<IAppRecommendationIDsResponse>(recommendation.ids)
        .then((data: IAppRecommendationIDsResponse) => {
          loadRecommendationSet(data.recommendationIDs)(dispatch, getState);

          dispatch({
            type: RECOMMENDATION_LOAD_IDS,
            payload: data.recommendationIDs,
          });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadRecommendationSet =
  (recommendationIDs: string[], hardReload?: boolean) =>
    (
      dispatch: IDispatch<string | IAppRecommendationSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!recommendationIDs || !recommendationIDs[0]) {
        return;
      }
      const set: IAppRecommendationSet =
        getState().Communication.Recommendation.set;
      let IDsToLoad: string[] = [];
      if (!hardReload) {
        const toLoad: string[] = [];
        recommendationIDs.forEach((recommendationID: string) => {
          if (!set[recommendationID]) {
            toLoad.push(recommendationID);
          }
        });
        if (!toLoad[0]) {
          return;
        }

        IDsToLoad = cacheByID.getToLoad(toLoad);
        if (!IDsToLoad[0]) {
          return;
        }
      }

      const query: IAppRecommendationSetQuery = {
        recommendationIDs: hardReload
          ? recommendationIDs.join(',')
          : IDsToLoad.join(','),
      };
      callApi<IAppRecommendationSetResponse>(recommendation.set, query)
        .then((data: IAppRecommendationSetResponse) => {
          dispatch({ type: RECOMMENDATION_LOAD_SET, payload: data.set });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadRecommendationBatch =
  (
    emails: null | string[],
    categories: undefined | null | string[],
    isEngaged: null | boolean,
    isParsed: null | boolean,
    socialView: null | IAppRecommendationSocialView,
    extraLimit?: number,
  ) =>
    (
      dispatch: IDispatch<
        | string
        | IAppRecommendationSet
        | DataBatchKeyExtend<string, IAppRecommendationGroups>
        | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const batchKey: string = prepareRecommendationBatchKey(
        emails,
        categories,
        isEngaged,
        isParsed,
        socialView,
      );
      const { limit, byBatchKey } = getState().Communication.Recommendation;
      const body: IAppRecommendationBatchBody = {
        limit: extraLimit || limit,
        skip: (byBatchKey[batchKey] && byBatchKey[batchKey].loaded) || 0,
        emails,
        categories,
        isEngaged,
        isParsed,
        socialView: socialView || undefined,
      };

      callApi<IAppRecommendationBatchResponse>(
        recommendation.batch,
        {},
        body,
        'POST',
      )
        .then((data: IAppRecommendationBatchResponse) => {
          const payload: DataBatchKeyExtend<string, IAppRecommendationGroups> = {
            ...data,
            batchKey,
          };
          loadRecommendationSet(data.items)(dispatch, getState);
          dispatch({
            type: RECOMMENDATION_LOAD_BATCH,
            payload,
          });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const searchRecommendation =
  (search: string) =>
    (
      dispatch: IDispatch<
        IAppReportLotSearchData | string | IAppRecommendationSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (search === '') {
        return;
      }

      const { IDsBySearch } = getState().Communication.Recommendation;
      if (IDsBySearch[search]) {
        return;
      }

      const query: IAppRecommendationSearchQuery = {
        search,
      };

      callApi<IAppReportLotSearchResponse>(recommendation.search, query)
        .then((data: IAppReportLotSearchResponse) => {
          const payload: IAppReportLotSearchData = {
            search,
            recommendationIDs: data.recommendationIDs,
          };

          loadRecommendationSet(data.recommendationIDs)(dispatch, getState);
          dispatch({ type: RECOMMENDATION_SEARCH_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const updateRecommendation =
  (
    recommendationID: string,
    name: null | string,
    type: null | IAppRecommendationType,
    emails: null | string[],
    url: null | string,
    social: null | IAppRecommendationSocial,
    geo: null | string,
    parsedID: undefined | null | string,
    categories: undefined | null | string[],
    params: undefined | null | Record<string, string>,
  ) =>
    (
      dispatch: IDispatch<string | IAppRecommendationSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const body: IAppRecommendationUpdateBody = {
        recommendationID,
      };
      if (emails) {
        body.emails = emails;
      }
      if (geo) {
        body.geo = geo;
      }
      if (social) {
        body.social = social;
      }
      if (type) {
        body.type = type;
      }
      if (url) {
        body.url = url;
      }
      if (name) {
        body.name = name;
      }
      if (parsedID !== undefined) {
        body.parsedID = parsedID;
      }
      if (categories !== undefined) {
        body.categories = categories;
      }
      if (params !== undefined) {
        body.params = params;
      }

      callApi(recommendation.main, {}, body, 'POST')
        .then(() =>
          dispatch({ type: RECOMMENDATION_ACTION, payload: ITEM_UPDATED }),
        )
        .then(() =>
          loadRecommendationSet([recommendationID], true)(dispatch, getState),
        )
        .then(() => dispatchInfo(dispatch, 'Recommendation is updated'))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const createRecommendation =
  (
    name: string,
    type: IAppRecommendationType,
    emails: string[],
    url: string,
    social: IAppRecommendationSocial,
    geo: string,
    parsedID: undefined | string,
    categories: undefined | string[],
    params?: null | Record<string, string>,
  ) =>
    (
      dispatch: IDispatch<
        string | string[] | IAppRecommendationSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const body: IAppRecommendationCreateBody = {
        name,
        type,
        emails,
        url,
        social,
        geo,
        parsedID,
        categories,
        params,
      };
      callApi(recommendation.main, {}, body)
        .then(() =>
          dispatch({ type: RECOMMENDATION_ACTION, payload: ITEM_CREATED }),
        )
        .then(() => loadRecommendationIDs(true)(dispatch, getState))
        .then(() => dispatchInfo(dispatch, 'Recommendation is created'))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const deleteRecommendation =
  (recommendationID: string) =>
    (
      dispatch: IDispatch<
        string | string[] | IAppRecommendationSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const query: IAppRecommendationDeleteQuery = {
        recommendationID: recommendationID,
      };
      callApi(recommendation.main, query, undefined, 'DELETE')
        .then(() =>
          dispatch({ type: RECOMMENDATION_ACTION, payload: ITEM_REMOVED }),
        )
        .then(() => loadRecommendationIDs(true)(dispatch, getState))
        .then(() => dispatchInfo(dispatch, 'Recommendation is removed'))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
