import React, { HTMLInputTypeAttribute, useState } from 'react';
import { FormGroup, Label } from './Form.styles';
import { ActionButton, BlockActions, BlockDates, ButtonMain } from '../styles';
import { FormField } from './FormField';
import { FormFieldImage } from './FormFieldImages';
import { Loading } from '../animation/Loading';
import { FormFieldSelect } from './FormFieldSelect';
import { FormFieldRecord } from './FormFieldRecord';
import { IFormAction, IFormCb, IValueRecord, IValueRecordItem } from './forms';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ContentDesc } from '../typos';
import { connect } from 'react-redux';
import { setError } from '../../actions/system/system.actions';
import { useTranslation } from 'react-i18next';

export type IFormFields<T> = Partial<Record<keyof T, undefined | IFormItem>>;

export interface IFormItem {
  mode: 'short' | 'long' | 'input' | 'images' | 'select' | 'date';
  modeInput?: HTMLInputTypeAttribute;
  placeholder?: string;
  isRequired: boolean;
  min?: number;
  max?: number;
  customValidation?: () => null | string;
  valueMode?: 'object' | 'array' | 'record';
  $value?: null | string;
  $valueList?: null | string[];
  $valueObject?: Record<string, undefined | string>;
  $valueRecord?: IValueRecord;
  $valueRecordLength?: number;
  $valueSelect?: null | string[];
  $valueDate?: null | Date;
  defaultObject?: Record<string, undefined | string>;
  nullNameBeforeSelect?: string;
  defaultSelect?: string[];
  params?: {
    image?: {
      isDraggable: boolean;
      autoSelect: boolean;
      noCrop: boolean;
    };
  };
}

interface IFormCommonProps {
  setError: (message: string) => void;
  renderElements?: () => JSX.Element;
  placeholderTitle?: string;
  fields: Record<string, undefined | IFormItem>;
  cb: IFormCb;
  action: IFormAction;
}

function FormCommon(props: IFormCommonProps) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, null | string>>({});
  const [isValid, setIsValid] = useState<boolean>(true);
  const [fields] = useState<string[]>(Object.keys(props.fields));

  const validateImages = (
    skipError: boolean,
    $valueList: undefined | null | string[],
    field: string,
    isRequired: boolean
  ): null | string => {
    const error: null | string =
      isRequired && (!$valueList || !$valueList[0])
        ? 'Image is required'
        : null;

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const validateSelect = (
    skipError: boolean,
    $valueSelect: undefined | null | string[],
    field: string,
    isRequired: boolean
  ): null | string => {
    const error: null | string =
      isRequired && (!$valueSelect || !$valueSelect[0])
        ? 'Value is required'
        : null;

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const validateValue = (
    skipError: boolean,
    field: string,
    isRequired: boolean,
    value: null | string | Record<string, null | string>,
    min?: number,
    max?: number
  ): null | string => {
    let error: null | string = null;

    if (!isRequired && !value) {
      return error;
    }

    if (isRequired && !value) {
      error = 'Is required';
    } else if (min && (!value || value.toString().length < min)) {
      error = `Should be longer than ${min} symbols`;
    } else if (max && value && value.toString().length > max) {
      error = `Should be shorter than ${max} symbols`;
    }

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const validateValueList = (
    skipError: boolean,
    field: string,
    isRequired: boolean,
    valueList: string[],
    min?: number,
    max?: number
  ): null | string => {
    let error: null | string = null;

    if (!isRequired && (!valueList || !valueList[0])) {
      return error;
    }

    for (let i = 0, len = valueList.length; i < len; i++) {
      const value: string = valueList[i];
      if (isRequired && !value) {
        error = 'Is required';
      } else if (min && (!value || value.toString().length < min)) {
        error = `Should be longer than ${min} symbols`;
      } else if (max && value && value.toString().length > max) {
        error = `Should be shorter than ${max} symbols`;
      }
    }

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const prepareValue = (field: string): void => {
    const errorsLocal: Record<string, null | string> = Object.assign(
      {},
      errors
    );
    errorsLocal[field] = null;

    setErrors(errorsLocal);
    setIsValid(true);
  };

  const saveValue = (field: string, value: string, item: IFormItem) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    if (item.valueMode === 'array') {
      $fieldGroup.$valueList = value.split(', ');
    } else {
      $fieldGroup.$value = value;
    }
    props.cb.cbFieldUpdates && props.cb.cbFieldUpdates();
  };
  const saveValueList = (field: string, values: string[]) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    $fieldGroup.$valueList = values;
    props.cb.cbFieldUpdates && props.cb.cbFieldUpdates();
  };
  const saveValueSelect = (field: string, values: string[]) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    $fieldGroup.$valueSelect = values;
    props.cb.cbFieldUpdates && props.cb.cbFieldUpdates();
  };
  const saveValueComplex = (field: string, key: string, value: string) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    if (!$fieldGroup.$valueObject) {
      $fieldGroup.$valueObject = {};
    }
    if ($fieldGroup.$valueObject[key]) {
      $fieldGroup.$valueObject[key] = value;
    }
    props.cb.cbFieldUpdates && props.cb.cbFieldUpdates();
  };
  const saveValueDate = (field: string, value: Date) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    $fieldGroup.$valueDate = value;
    props.cb.cbFieldUpdates && props.cb.cbFieldUpdates();
  };

  const saveValueRecord = (
    field: string,
    index: null | number,
    key: string,
    value: string
  ) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    if (!$fieldGroup.$valueRecord) {
      $fieldGroup.$valueRecord = [];
    }

    $fieldGroup.$valueRecord[
      index === null ? $fieldGroup.$valueRecord.length : index
    ] = {
      key,
      value,
    };
    props.cb.cbFieldUpdates && props.cb.cbFieldUpdates();
  };

  const handleCreate = () => {
    if (!validateAll(false, false)) {
      return;
    }

    if (!props.cb.cbCreate) {
      return;
    }
    props.cb.cbCreate();
  };
  const handleUpdate = () => {
    if (!validateAll(true, false)) {
      return;
    }

    if (!props.cb.cbUpdate) {
      return;
    }
    props.cb.cbUpdate();
  };

  const validateAll = (isUpdate: boolean, skipError: boolean): boolean => {
    let isValid: boolean = true;
    Object.keys(props.fields).forEach((field: string) => {
      const item: undefined | IFormItem = props.fields[field];
      if (!item) {
        return;
      }

      const isRequired: boolean = isUpdate ? false : item.isRequired;
      if (item.mode === 'images') {
        if (validateImages(skipError, item.$valueList, field, isRequired)) {
          isValid = false;
        }
        return;
      }
      if (item.mode === 'select') {
        if (validateSelect(skipError, item.$valueSelect, field, isRequired)) {
          isValid = false;
        }
        return;
      }

      if (item.valueMode === 'array') {
        const value: string[] = item.$valueList || [];
        if (
          validateValueList(
            skipError,
            field,
            isRequired,
            value,
            item.min,
            item.max
          )
        ) {
          isValid = false;
        }
        return;
      }

      if (item.valueMode !== 'object') {
        const value: string | null = item.$value || null;
        if (
          validateValue(skipError, field, isRequired, value, item.min, item.max)
        ) {
          isValid = false;
        }
        return;
      }

      if (!item.defaultObject) {
        if (
          validateValue(skipError, field, isRequired, null, item.min, item.max)
        ) {
          isValid = false;
        }
        return;
      }

      Object.keys(item.defaultObject).forEach((name) => {
        if (
          validateValue(
            skipError,
            field,
            isRequired,
            (item.$valueObject && item.$valueObject[name]) || null,
            item.min,
            item.max
          )
        ) {
          isValid = false;
        }
      });
    });

    setIsValid(isValid);
    if (props.cb.cbValidation) {
      props.cb.cbValidation(isValid);
    }
    if (!isValid) {
      props.setError('Some fields are not correct');
    }
    return isValid;
  };

  const renderItem = (field: string): JSX.Element => {
    const item: undefined | IFormItem = props.fields[field];
    if (!item) {
      return <></>;
    }

    if (item.mode === 'images') {
      return (
        <FormFieldImage
          isDisabled={props.action.isDisabled}
          defaultValue={item.$valueList || null}
          valueError={errors[field] || null}
          saveValue={(images: string[]) => saveValueList(field, images)}
          isValidForm={isValid}
          title={field}
          limit={item.max || 1}
          params={item.params?.image}
        />
      );
    }

    if (item.mode === 'select') {
      return (
        <FormFieldSelect
          isDisabled={props.action.isDisabled}
          valueError={errors[field] || null}
          saveValue={(selects: string[]) => saveValueSelect(field, selects)}
          title={field}
          nullNameBeforeSelect={item.nullNameBeforeSelect}
          defaultValue={item.$valueSelect || null}
          values={item.defaultSelect || []}
        />
      );
    }

    if (item.mode === 'date') {
      return (
        <BlockDates>
          <Label>
            <ContentDesc>{field}</ContentDesc>
            <DatePicker
              selected={item.$valueDate}
              showTimeSelect
              dateFormat="Pp"
              onChange={(date: Date) => saveValueDate(field, date)}
            />
          </Label>
        </BlockDates>
      );
    }

    const mode: 'short' | 'long' | 'input' = item.mode;
    if (item.valueMode === 'object') {
      return (
        <>
          {Object.keys(item.defaultObject || item.$valueObject || {}).map(
            (name: string) => (
              <FormField
                isDisabled={props.action.isDisabled}
                mode={mode}
                modeInput={item.modeInput}
                saveValue={(value: string) =>
                  saveValueComplex(field, name, value)
                }
                defaultValue={
                  (item.$valueObject && item.$valueObject[name]) ||
                  (item.defaultObject && item.defaultObject[name]) ||
                  null
                }
                valueError={errors[field] || null}
                title={`${field} - ${name}`}
                placeholder={`Set ${
                  props.placeholderTitle || ''
                } ${field} - ${name}`}
              />
            )
          )}
        </>
      );
    }

    if (item.valueMode === 'record') {
      return (
        <>
          {(item.$valueRecord || []).map(
            (record: IValueRecordItem, index: number) => (
              <FormFieldRecord
                isDisabled={props.action.isDisabled}
                saveValue={(key: string, value: string) =>
                  saveValueRecord(field, index, key, value)
                }
                record={record}
                valueError={errors[field] || null}
                title={`${field} - ${record.key}`}
                placeholder={`Set ${props.placeholderTitle || ''} ${field} - ${
                  record.key
                }`}
              />
            )
          )}

          {!item.$valueRecordLength ||
            (item.$valueRecordLength >
              ((item.$valueRecord && item.$valueRecord.length) || 0) && (
              <BlockActions>
                <ActionButton
                  onClick={() => saveValueRecord(field, null, '', '')}
                >
                  Add one more {field}
                </ActionButton>
              </BlockActions>
            ))}
        </>
      );
    }

    return (
      <FormField
        isDisabled={props.action.isDisabled}
        mode={mode}
        modeInput={item.modeInput}
        saveValue={(value: string) => saveValue(field, value, item)}
        defaultValue={
          item.valueMode === 'array'
            ? (item.$valueList && item.$valueList.join(', ')) || null
            : item.$value || null
        }
        valueError={errors[field] || null}
        title={field}
        placeholder={
          item.placeholder || `Set ${props.placeholderTitle || ''} ${field}`
        }
      />
    );
  };

  return (
    <FormGroup>
      {fields.map((field: string) => (
        <FormGroup
          className={props.action.isDisabled ? 'disabled' : ''}
          key={field}
        >
          {renderItem(field)}
        </FormGroup>
      ))}

      {props.renderElements && props.renderElements()}

      {!props.action.isDisabled && (
        <BlockActions>
          {props.action.isUpdate ? (
            <>
              {props.cb.cbUpdate && (
                <ButtonMain
                  onClick={handleUpdate}
                  className="success"
                  disabled={props.action.isSending}
                >
                  {props.action.isSending && <Loading size={'small'} />}
                  {props.action.editActionTitle || 'Update'}
                </ButtonMain>
              )}
              {props.cb.cbDelete && (
                <ButtonMain
                  onClick={props.cb.cbDelete}
                  className="fail"
                  disabled={props.action.isSending}
                >
                  {props.action.isSending && <Loading size={'small'} />}
                  {props.action.removeActionTitle || 'Remove'}
                </ButtonMain>
              )}
              {props.cb.cbCancel && (
                <ButtonMain
                  onClick={props.cb.cbCancel}
                  className="success"
                  disabled={props.action.isSending}
                >
                  {props.action.isSending && <Loading size={'small'} />}
                  {props.action.cancelActionTitle || 'Cancel'}
                </ButtonMain>
              )}
            </>
          ) : (
            <>
              {props.cb.cbCreate && (
                <ButtonMain
                  onClick={handleCreate}
                  className="success"
                  disabled={props.action.isSending}
                >
                  {props.action.isSending && <Loading size={'small'} />}
                  {props.action.createActionTitle || 'Create'}
                </ButtonMain>
              )}
              {props.cb.cbCancel && (
                <ButtonMain
                  onClick={props.cb.cbCancel}
                  className="fail"
                  disabled={props.action.isSending}
                >
                  {props.action.isSending && <Loading size={'small'} />}
                  {props.action.cancelActionTitle || 'Cancel'}
                </ButtonMain>
              )}
            </>
          )}
        </BlockActions>
      )}
    </FormGroup>
  );
}

export default connect(() => ({}), {
  setError,
})(FormCommon);
