import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { Block } from '../../common/layout';
import {
  initEditDraft,
  updateProductDraft,
} from '../../actions/shop/productDraft.actions';
import { PageHead } from '../../common/typos';
import {
  IProductDraft,
  IProductLink,
} from '../../interfaces/shop/IProductDraft';
import { Loading } from '../../common/animation/Loading';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { sendPageEvent } from '../../utils/analytics';
import DraftFormPublic from '../../components/shop/draft/DraftFormPublic';
import { L } from '../../common/locale';

interface ILotEditPage {
  updateProductDraft: (
    productDraftID: string,
    auctionID: string,
    name: null | string,
    description: null | string,
    images: null | string[],
    isHide: null | boolean,
    baseName: string,
    links: undefined | null | IProductLink[]
  ) => void;
  initEditDraft: (draft: null | IProductDraft) => void;
  edit: null | IProductDraft;
  IDByName: Record<string, string>;

  actions: string[];
  errors: string[];
}

function LotEditPage(props: ILotEditPage) {
  const navigate = useNavigate();

  useEffect(() => sendPageEvent('LotEdit'), []);
  useEffect(() => {
    if (!props.edit) {
      navigate(appConfig.routes.account.myLots.main);
    }
  }, [props.edit]);

  const handleCancel = () => {
    props.initEditDraft(null);
    navigate(appConfig.routes.account.myLots.main);
  };

  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'ButtonLotEdit'} />
        </PageHead>

        {!props.edit && <Loading size={'big'} />}

        {props.edit && (
          <DraftFormPublic
            draft={props.edit}
            cbCancel={handleCancel}
            cbComplete={handleCancel}
          />
        )}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    edit: state.ShopProduct.drafts.edit,
    IDByName: state.ShopProduct.drafts.IDByName,
    actions: state.ShopProduct.drafts.actions,
    errors: state.System.errors,
  }),
  {
    updateProductDraft,
    initEditDraft,
  }
)(LotEditPage);
