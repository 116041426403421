import { appConfig } from '../config/app.config';
import { IAuctionMode } from '../interfaces/auctions/IAuction';

export function getAuctionPath(
  mode: undefined | null | IAuctionMode
): null | string {
  const { live, week } = appConfig.routes;
  const { auction, resultAuction, resultLot, resultFinal } = live;

  if (mode === 'lot') {
    return auction;
  } else if (mode === 'reportLot') {
    return resultLot;
  } else if (mode === 'reportAuction') {
    return resultAuction;
  } else if (mode === 'reportFinal') {
    return null;
  } else if (mode === 'weekAuction') {
    return week.auction;
  } else {
    return null;
  }
}

export function checkPageIsInAuctionMode(mode: IAuctionMode): boolean {
  const path: string = window.location.pathname;
  const auctionPath: null | string = getAuctionPath(mode);
  if (path === appConfig.routes.live.presenter) {
    return true;
  }

  if (!mode) {
    return false;
  } else if (!auctionPath) {
    return true;
  } else if (mode === 'weekAuction') {
    return (
      path.startsWith(auctionPath || '') ||
      path === appConfig.routes.live.presenter
    );
  } else {
    return auctionPath === path;
  }
}
export function checkPageIsInAdminPages(): boolean {
  const path: string = window.location.pathname;

  return (
    path.startsWith(appConfig.routes.Moderation.main) ||
    path.startsWith(appConfig.routes.Analytics.main)
  );
}
