import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { AUCTION_MODE_LOAD, REPORT_AUCTION_LOAD, REPORT_LOT_LOAD } from '../../actions/types';
import { ActionCase, IStateReport } from '../../interfaces/system/IState';
import { IReportAuctionData } from '../../interfaces/auctions/IReportAuction';
import { IReportLot } from '../../interfaces/auctions/IReportLot';
import { IAuctionModeResponse } from '../../interfaces/auctions/IAuction';

const initialStateReport: IStateReport = {
  auctionLive: undefined,
  auctionWeek: undefined,
  lot: null,
};
export const reportReducer: Reducer = createReducer(
  initialStateReport,
  (builder: ActionReducerMapBuilder<IStateReport>) => {
    builder.addCase<string, ActionCase<IAuctionModeResponse>>(
      AUCTION_MODE_LOAD,
      (
        state: Draft<IStateReport>,
        action: ActionCase<IAuctionModeResponse>,
      ) => {
        state.auctionLive = action.payload.reportAuction;
        state.lot = action.payload.reportLot;
      },
    );
    builder.addCase<string, ActionCase<IReportAuctionData>>(
      REPORT_AUCTION_LOAD,
      (state: Draft<IStateReport>, action: ActionCase<IReportAuctionData>) => {
        const { report, lotType } = action.payload;
        if (lotType === 'Week') {
          state.auctionWeek = report;
        } else {
          state.auctionLive = report;
        }
      },
    );
    builder.addCase<string, ActionCase<IReportLot>>(
      REPORT_LOT_LOAD,
      (state: Draft<IStateReport>, action: ActionCase<IReportLot>) => {
        state.lot = action.payload;
      },
    );
  },
);
