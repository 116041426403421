import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setLot,
  updateLot,
} from '../../../actions/auctions/lotModeration.actions';
import {
  IState,
  IStateLotAdminAction,
} from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import { IProduct, IProductPreview } from '../../../interfaces/shop/IProduct';
import {
  ILotCreate,
  ILotShort,
  ILotType,
} from '../../../interfaces/auctions/ILotPublic';
import { FormsFields } from '../../../common/forms/forms';
import { sendEvent } from '../../../utils/analytics';
import { loadDetailsByID } from '../../../actions/shop/product.actions';

interface IAuctionFormFormProps {
  lot?: ILotShort;
  product?: IProductPreview;
  lotType: ILotType;
  isDisabled?: boolean;
  loadDetailsByID: (productID: string) => void;
  setLot: (
    lotType: ILotType,
    productID: string,
    name: string,
    description: string,
    price: number,
    winCnt: number,
    byAlias: string | null,
  ) => void;
  updateLot: (
    lotType: ILotType,
    lotID: string,
    name: null | string,
    description: null | string,
    order: null | number,
    price: null | number,
    winCnt: null | number,
    byAlias: string | null,
  ) => void;
  cbCancel: () => void;
  cbDelete?: (auctionID: string) => void;
  set: Record<string, IProduct>;
  adminActions: IStateLotAdminAction[];
  errors: string[];
}

function LotForm(props: IAuctionFormFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormFields<ILotCreate>>({
    name: {
      ...FormsFields.name,
      isRequired: true,
    },
    byAlias: {
      ...FormsFields.name,
      mode: 'input',
      isRequired: false,
    },
    description: {
      ...FormsFields.description,
      isRequired: false,
    },
    price: {
      $value: '',
      mode: 'input',
      modeInput: 'number',
      isRequired: true,
    },
    winCnt: {
      $value: '',
      mode: 'input',
      modeInput: 'number',
      isRequired: true,
    },
  });

  useEffect(() => sendEvent('Open-LotForm'), []);
  useEffect(() => {
    console.log(props);

    if (props.product) {
      props.loadDetailsByID(props.product.id);
    }
  }, []);
  useEffect(() => {
    if (!props.adminActions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.adminActions]);
  useEffect(() => {
    if (!props.lot) {
      return;
    }

    if (fields.name) {
      fields.name.$value = props.lot.name;
    }
    if (fields.description) {
      fields.description.$value = props.lot.description;
    }
    if (fields.price && props.lot.price) {
      fields.price.$value = props.lot.price.toString();
    }
    if (fields.winCnt && props.lot.winCnt) {
      fields.winCnt.$value = props.lot.winCnt.toString();
    }
    if (fields.byAlias && props.lot.byAlias) {
      fields.byAlias.$value = props.lot.byAlias;
    }
    setFields({ ...fields });
  }, [props.lot]);
  useEffect(() => {
    if (!props.product || props.lot) {
      return;
    }
    const product: undefined | IProduct = props.set[props.product.id];
    if (!product) {
      return;
    }

    if (fields.name) {
      fields.name.$value = product.name;
    }
    if (fields.description) {
      fields.description.$value =
        product.description.length > 140
          ? product.description.slice(0, 140) + '...'
          : product.description;
    }
    if (fields.price) {
      fields.price.$value = '2500';
    }
    if (fields.winCnt) {
      fields.winCnt.$value = '1';
    }

    setFields({ ...fields });
  }, [props.product, props.set]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbCreate = () => {
    setIsSending(true);
    props.setLot(
      props.lotType,
      props.product?.id || '',
      (fields.name && fields.name.$value) || '',
      (fields.description && fields.description.$value) || '',
      (fields.price && Number(fields.price.$value)) || 0,
      (fields.winCnt && Number(fields.winCnt.$value)) || 0,
      (fields.byAlias && fields.byAlias.$value) || null,
    );
  };
  const cbUpdate = () => {
    if (!props.lot) {
      return;
    }

    setIsSending(true);
    props.updateLot(
      props.lotType,
      props.lot.id,
      (fields.name && fields.name.$value) || null,
      (fields.description && fields.description.$value) || null,
      null,
      (fields.price && Number(fields.price.$value)) || null,
      (fields.winCnt && Number(fields.winCnt.$value)) || null,
      (fields.byAlias && fields.byAlias.$value) || null,
    );
  };
  const cbDelete = () => {
    if (!props.lot || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.lot.id);
  };

  return (
    <FormBlock>
      <FormCommon
        placeholderTitle={'lot'}
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          cbCreate,
          cbUpdate,
          cbDelete,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: !!props.lot,
          isSending,
        }}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.ShopProduct.products.set,
    adminActions: state.Lot.moderation.actions,
    errors: state.System.errors,
  }),
  {
    setLot,
    updateLot,
    loadDetailsByID,
  },
)(LotForm);
