import React, { useEffect, useState } from 'react';
import { sendPageEvent } from '../../../utils/analytics';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import {
  deleteTemplateParams,
  loadTemplateParamsBatch,
  prepareTemplateParamsBatchKey,
  searchTemplateParams,
} from '../../../actions/communication/templateParams.actions';
import {
  ITemplateParamsBatchFiltersParams,
  ITemplateParamsGroup,
  ITemplateParamsGroupList,
  ITemplateParamsSet,
} from '../../../interfaces/communication/ITemplateParams';
import { DataBatchWide } from '../../../interfaces/system/data';
import { RenderBatchFilterFunction } from '../../../common/common';
import {
  defaultTemplateParamsItemView,
  renderTemplateParamsForm,
  renderTemplateParamsItemPublic,
  renderTemplateParamsItemView,
} from '../../../components/communication/TemplateParams/TemplateParams';
import {
  IEmailMode,
  IEmailModeMailingList,
} from '../../../interfaces/communication/IEmailLog';
import {
  IAppRecommendationType,
  IAppRecommendationTypeList,
} from '../../../interfaces/communication/IAppRecommendation';
import {
  BatchFilters,
  BatchFiltersItem,
} from '../../../common/moderation/BatchFilters';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';

interface ICommunicationTemplateParamsProps {
  loadTemplateParamsBatch: (
    group: null | ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    params: null | Record<string, string>,
    extraLimit?: number
  ) => void;
  deleteTemplateParams: (itemID: string) => void;
  searchTemplateParams: (search: string) => void;
  IDs: string[];
  IDsBySearch: Record<string, string[]>;
  set: ITemplateParamsSet;
  byBatchKey: Record<string, DataBatchWide>;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function CommunicationTemplateParams(props: ICommunicationTemplateParamsProps) {
  useEffect(() => sendPageEvent('moderation communication templateParams'), []);
  const [filters, setFilters] = useState<ITemplateParamsBatchFiltersParams>();
  const [batchKey, setBatchKey] = useState<string>(
    prepareTemplateParamsBatchKey(null, null, null, null)
  );

  const $params: Partial<
    Record<keyof ITemplateParamsBatchFiltersParams, BatchFiltersItem>
  > = {
    group: {
      value: null,
      values: ITemplateParamsGroupList,
    },
    mode: {
      value: null,
      values: IEmailModeMailingList,
    },
    type: {
      value: null,
      values: IAppRecommendationTypeList,
    },
  };

  const getBatchKey = (): string => {
    const filtersLocal: ITemplateParamsBatchFiltersParams = {
      group: $params.group?.value as ITemplateParamsGroup,
      mode: $params.mode?.value as IEmailMode,
      type: $params.type?.value as IAppRecommendationType,
      params: null,
    };
    setFilters(filtersLocal);

    const batchKeyLocal: string = prepareTemplateParamsBatchKey(
      filtersLocal.group,
      filtersLocal.mode,
      filtersLocal.type,
      filtersLocal.params
    );

    setBatchKey(batchKeyLocal);
    return batchKeyLocal;
  };
  useEffect(() => {
    getBatchKey();
  }, []);
  const loadBatch = (extraLimit?: number) => {
    if (!filters) {
      props.loadTemplateParamsBatch(null, null, null, null, extraLimit);
      return;
    }

    props.loadTemplateParamsBatch(
      filters.group,
      filters.mode,
      filters.type,
      filters.params,
      extraLimit
    );
  };

  const renderBatchFilter: RenderBatchFilterFunction = () => {
    return (
      <BatchFilters $params={$params} cb={() => setBatchKey(getBatchKey())} />
    );
  };

  return (
    <ModerationPage
      pageTitle={'TemplateParams moderation'}
      itemName={'this templateParams'}
      cb={{
        cbDelete: props.deleteTemplateParams,
      }}
      render={{
        renderItemPublic: renderTemplateParamsItemPublic,
        renderForm: renderTemplateParamsForm,
        renderItemView: renderTemplateParamsItemView,
      }}
      data={{
        set: props.set,
        actions: props.actions,
        errors: props.errors,
        batch: {
          batchKey,
          byBatchKey: props.byBatchKey,
          loadBatch,
          renderBatchFilter,
          showExtraLoading: true,
        },
        search: {
          search: props.searchTemplateParams,
          IDsBySearch: props.IDsBySearch,
          placeholderTitle: 'emails, name or social',
        },
      }}
      isAdmin={props.isAdmin}
      action={{
        createActionTitle: 'Create new templateParams',
        showInline: true,
      }}
      ParamsItemView={defaultTemplateParamsItemView}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    IDs: state.Communication.TemplateParams.IDs,
    IDsBySearch: state.Communication.TemplateParams.IDsBySearch,
    set: state.Communication.TemplateParams.set,
    byBatchKey: state.Communication.TemplateParams.byBatchKey,
    actions: state.Communication.TemplateParams.actions,
    errors: state.System.errors,
  }),
  {
    loadTemplateParamsBatch,
    deleteTemplateParams,
    searchTemplateParams,
  }
)(CommunicationTemplateParams);
