import styled from 'styled-components';
import { margins, sizes, sizesBorder } from '../../../common/sizes';
import { colors } from '../../../common/colors';
import { borders, shadows, transitions } from '../../../common/styles';

export const PreviewBlock = styled.div`
    position: relative;
    cursor: pointer;
    max-width: ${sizes.widthL};
    float: left;
    padding-right: ${margins.item};
    box-sizing: border-box;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    padding: ${margins.item};

    &:hover {
        & .hover {
            visibility: visible;
            opacity: 1;
        }
    }

    &.win {
        ${borders.win}
    }

    &.lost {
        ${borders.lost}
    }
`;
export const HoverBlock = styled.div`
    visibility: hidden;
    opacity: 0;
    transition: ${transitions.visibility};
    position: absolute;
    box-sizing: border-box;
    padding: ${margins.block};
    border-radius: ${sizesBorder.radius};
    box-shadow: ${shadows.main};
    margin: ${margins.block};
    background-color: ${colors.background};
    z-index: 99;
`;

export const PriceBlock = styled.div`
    position: absolute;
    padding: ${margins.item} ${margins.block};
    margin: ${margins.block};
    border-radius: ${sizesBorder.radius};
    color: white;
    background-color: ${colors.lost};
    box-sizing: border-box;

    &.win {
        //  todo implement
    }

    &.lost {
        //  todo implement
    }
`;
