import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  AUCTION_ACTION,
  AUCTION_CLEAR,
  AUCTION_LOAD,
  AUCTION_MODE_LOAD,
  AUCTION_MODE_SET,
  AUCTION_NEXT_LOAD,
  AUCTION_NEXT_WEEK_END_LOAD,
} from '../../actions/types';
import { ActionCase, IStateAuction } from '../../interfaces/system/IState';
import { IAuction, IAuctionDataSet, IAuctionMode, IAuctionModeResponse } from '../../interfaces/auctions/IAuction';
import { makePathFromName } from '../../utils/routes';

const initialStateAuction: IStateAuction = {
  weekEnd: undefined,
  auctionNext: undefined,
  auctionMode: undefined,
  auctions: {
    IDByPath: {},
    set: {},
    IDs: [],
    actions: [],
  },
};

export const auctionReducer: Reducer = createReducer(
  initialStateAuction,
  (builder: ActionReducerMapBuilder<IStateAuction>) => {
    builder.addCase<string, ActionCase<IAuctionDataSet>>(
      AUCTION_LOAD,
      (state: Draft<IStateAuction>, action: ActionCase<IAuctionDataSet>) => {
        const { auctions, auctionPath } = action.payload;
        const auctionsSorted: IAuction[] = auctions.sort((a, b) =>
          a.ind && b.ind && a.ind > b.ind ? -1 : 1,
        );

        const IDs: string[] = [];
        auctionsSorted.forEach((auction: IAuction) => {
          const { id, name } = auction;
          const path = makePathFromName(name);
          state.auctions.IDByPath[path] = id;
          state.auctions.set[id] = auction;
          IDs.push(id);
        });
        if (!auctionPath) {
          state.auctions.IDs = IDs;
        }
      },
    );
    builder.addCase<string, ActionCase<null | IAuction>>(
      AUCTION_NEXT_LOAD,
      (state: Draft<IStateAuction>, action: ActionCase<null | IAuction>) => {
        state.auctionNext = action.payload;
      },
    );
    builder.addCase<string, ActionCase<IAuctionModeResponse>>(
      AUCTION_MODE_LOAD,
      (
        state: Draft<IStateAuction>,
        action: ActionCase<IAuctionModeResponse>,
      ) => {
        state.auctionNext = action.payload.auctionNext;
        state.auctionMode = action.payload.auctionMode;
      },
    );
    builder.addCase<string, ActionCase<IAuctionMode>>(
      AUCTION_MODE_SET,
      (state: Draft<IStateAuction>, action: ActionCase<IAuctionMode>) => {
        state.auctionMode = action.payload;
      },
    );
    builder.addCase<string, ActionCase<null | string>>(
      AUCTION_NEXT_WEEK_END_LOAD,
      (state: Draft<IStateAuction>, action: ActionCase<null | string>) => {
        state.weekEnd = action.payload;
      },
    );
    builder.addCase<string, ActionCase<string>>(
      AUCTION_ACTION,
      (state: Draft<IStateAuction>, action: ActionCase<string>) => {
        state.auctions.actions.push(action.payload);
      },
    );
    builder.addCase<string, ActionCase<void>>(
      AUCTION_CLEAR,
      (state: Draft<IStateAuction>) => {
        state.auctions = {
          IDByPath: {},
          set: {},
          IDs: [],
          actions: [],
        };
      },
    );
  },
);
