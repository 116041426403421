import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SelectionPage } from '../../../common/moderation/SelectionPage';
import { IState } from '../../../interfaces/system/IState';
import { DataBatchWide } from '../../../interfaces/system/data';
import {
  IParamsItemView,
  RenderBatchFilterFunction,
  RenderItemPublicFunction,
} from '../../../common/common';
import {
  loadTemplateParamsBatch,
  prepareTemplateParamsBatchKey,
} from '../../../actions/communication/templateParams.actions';
import {
  ITemplateParamsBatchFiltersParams,
  ITemplateParamsGroup,
  ITemplateParamsGroupList,
  ITemplateParamsPublic,
  ITemplateParamsSet,
} from '../../../interfaces/communication/ITemplateParams';
import { TemplateParamsItemModeration } from './TemplateParamsItemModeration';
import {
  IEmailMode,
  IEmailModeMailingList,
} from '../../../interfaces/communication/IEmailLog';
import {
  IAppRecommendationType,
  IAppRecommendationTypeList,
} from '../../../interfaces/communication/IAppRecommendation';
import {
  BatchFilters,
  BatchFiltersItem,
} from '../../../common/moderation/BatchFilters';
import {
  defaultTemplateParamsItemView,
  renderTemplateParamsItemView,
} from './TemplateParams';
import { useTranslation } from 'react-i18next';

interface ITemplateParamsSelectProps {
  cbTemplateParams?: (accountID: string) => void;
  isAdmin: boolean;
  loadTemplateParamsBatch: (
    group: null | ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    params: null | Record<string, string>,
    extraLimit?: number
  ) => void;
  set: ITemplateParamsSet;
  byBatchKey: Record<string, DataBatchWide>;
  selectedIDs?: null | string[];
  errors: string[];
}

function TemplateParamsSelect(props: ITemplateParamsSelectProps) {
  const { t } = useTranslation();
  const [selectedIDs, setSelectedIDs] = useState<null | string[]>(null);
  const [filters, setFilters] = useState<ITemplateParamsBatchFiltersParams>();
  const [batchKey, setBatchKey] = useState<string>(
    prepareTemplateParamsBatchKey(null, null, null, null)
  );
  useEffect(
    () => setSelectedIDs(props.selectedIDs || null),
    [props.selectedIDs]
  );

  const $params: Partial<
    Record<keyof ITemplateParamsBatchFiltersParams, BatchFiltersItem>
  > = {
    group: {
      value: null,
      values: ITemplateParamsGroupList,
      nullNameBeforeSelect: 'any',
    },
    mode: {
      value: null,
      values: IEmailModeMailingList,
      nullNameBeforeSelect: 'any',
    },
    type: {
      value: null,
      values: IAppRecommendationTypeList,
      nullNameBeforeSelect: 'any',
    },
  };

  const getBatchKey = (): string => {
    const filtersLocal: ITemplateParamsBatchFiltersParams = {
      group: $params.group?.value as ITemplateParamsGroup,
      mode: $params.mode?.value as IEmailMode,
      type: $params.type?.value as IAppRecommendationType,
      params: null,
    };
    setFilters(filtersLocal);

    const batchKeyLocal: string = prepareTemplateParamsBatchKey(
      filtersLocal.group,
      filtersLocal.mode,
      filtersLocal.type,
      filtersLocal.params
    );

    setBatchKey(batchKeyLocal);
    return batchKeyLocal;
  };
  useEffect(() => {
    getBatchKey();
  }, []);
  const loadBatch = (extraLimit?: number) => {
    if (!filters) {
      props.loadTemplateParamsBatch(null, null, null, null, extraLimit);
      return;
    }

    props.loadTemplateParamsBatch(
      filters.group,
      filters.mode,
      filters.type,
      filters.params,
      extraLimit
    );
  };

  const renderBatchFilter: RenderBatchFilterFunction = () => {
    return (
      <BatchFilters $params={$params} cb={() => setBatchKey(getBatchKey())} />
    );
  };

  const cbSelected = (selectedIDsLocal: string) => {
    // todo select value

    if (!props.cbTemplateParams) {
      return;
    }
    setSelectedIDs([selectedIDsLocal]);
    props.cbTemplateParams(selectedIDsLocal);
  };

  const renderItemPublic: RenderItemPublicFunction<ITemplateParamsPublic> = (
    item: ITemplateParamsPublic,
    inlineBlock?: JSX.Element,
    ParamsItemView?: IParamsItemView
  ) => {
    return (
      <TemplateParamsItemModeration
        templateParams={item}
        showInline={true}
        inlineBlock={inlineBlock}
        ParamsItemView={ParamsItemView}
      />
    );
  };

  return (
    <SelectionPage
      title={t('FormTemplateParamsTitleShort')}
      renderItemPublic={renderItemPublic}
      renderItemView={renderTemplateParamsItemView}
      data={{
        set: props.set,
        batch: {
          batchKey,
          byBatchKey: props.byBatchKey,
          loadBatch,
          renderBatchFilter,
        },
      }}
      isAdmin={props.isAdmin}
      action={{
        showInline: true,
      }}
      selection={{
        cbSelected,
        selectedIDs,
      }}
      ParamsItemView={defaultTemplateParamsItemView}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    set: state.Communication.TemplateParams.set,
    byBatchKey: state.Communication.TemplateParams.byBatchKey,
    errors: state.System.errors,
  }),
  {
    loadTemplateParamsBatch,
  }
)(TemplateParamsSelect);
