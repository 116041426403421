import React, { useEffect, useState } from 'react';
import { ActionButton } from '../styles';
import { ContentBold, ContentDesc } from '../typos';
import { Loading } from '../animation/Loading';
import { IBatchParams, RenderItemInRowFunction } from '../common';
import { ParamsWrapper } from './ParamsWrapper';
import { Block } from '../layout';
import ModerationItemRows from './ModerationItemRows';
import { L } from '../locale';

interface IBatchRowsProps<ItemPublic> {
  title?: string;
  batch: IBatchParams<ItemPublic>;
  renderItem: RenderItemInRowFunction;
}

export function BatchRows<ItemPublic>(props: IBatchRowsProps<ItemPublic>) {
  const [itemIDs, setItemPublicIDs] = useState<string[]>([]);
  const [itemCnt, setItemPublicCnt] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (!props.batch.byBatchKey) {
      return;
    }

    if (!props.batch.byBatchKey[props.batch.batchKey]) {
      if (!isLoading) {
        loadNext();
      }
      return;
    }

    const { loaded, IDs, cnt } = props.batch.byBatchKey[props.batch.batchKey];
    if (!isLoading && !loaded) {
      loadNext();
    }

    setIsLoading(false);
    setItemPublicIDs(IDs);
    setItemPublicCnt(cnt);
    setIsShowMore((cnt || 0) > loaded);
  }, [props.batch.byBatchKey, props.batch.batchKey]);

  const loadNext = (extraLimit?: number) => {
    setIsLoading(true);
    setIsShowMore(false);

    props.batch.loadBatch(extraLimit);
  };

  return (
    <Block>
      {props.batch.renderBatchFilter ? (
        <ParamsWrapper
          title={'filter params'}
          renderParams={() =>
            props.batch.renderBatchFilter ? (
              props.batch.renderBatchFilter()
            ) : (
              <></>
            )
          }
        />
      ) : undefined}

      {itemCnt !== null && (
        <ContentDesc>
          {itemCnt} {props.title || 'item'}
          {itemCnt !== 1 ? 's' : ''}
        </ContentDesc>
      )}

      <ModerationItemRows renderItem={props.renderItem} itemIDs={itemIDs} />

      {!itemIDs[0] && (
        <ContentDesc>
          <ContentBold>
            <L k={'ItemsEmpty'} />
          </ContentBold>
        </ContentDesc>
      )}

      {isLoading && <Loading size={'big'} />}
      {isShowMore && (
        <ActionButton onClick={() => loadNext()}>
          <L k={'ButtonLoadMore'} />
        </ActionButton>
      )}
    </Block>
  );
}
