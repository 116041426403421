import styled from 'styled-components';
import { device, sizes } from '../../common/sizes';

export const BlockStats = styled.div`
  display: block;
  width: ${sizes.full};

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;
