import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { IReportLot } from '../../interfaces/auctions/IReportLot';
import LotResult from '../../components/auctions/reports/ResultLot';
import { AppContent } from '../../App.styles';
import ResultLotAdminActions from '../../components/auctions/reports/ResultLotAdminActions';
import { getGifSrc, Gif } from '../../common/animation/Gif';
import { sendPageEvent } from '../../utils/analytics';

interface ILotResultPageProps {
  isAdmin: boolean;
  currentLotID: undefined | null | string;
  report: undefined | null | IReportLot;
}

function ResultLotPage(props: ILotResultPageProps) {
  const navigate = useNavigate();
  useEffect(() => sendPageEvent('ResultLot'), []);
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    if (props.currentLotID) {
      navigate(appConfig.routes.live.auction);
    } else if (!props.report) {
      navigate(appConfig.routes.live.resultAuction);
    }
  }, [props.currentLotID, props.report]);

  const [gifSrc, setGifSrc] = useState<null | string>(null);
  useEffect(() => {
    const src = getGifSrc('resultLot');
    setGifSrc(src);
    setTimeout(() => setGifSrc(null), 3000);
  }, []);

  return (
    <AppContent>
      <LotResult />
      {gifSrc && <Gif src={gifSrc} />}
      {props.isAdmin && <ResultLotAdminActions />}
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    currentLotID: state.Lot.short.currentLotID,
    report: state.Report.lot,
  }),
  {},
)(ResultLotPage);
