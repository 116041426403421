import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  ANALYTICS_AU_LOAD,
  ANALYTICS_CRON_LOAD,
  ANALYTICS_CRON_LOAD_CACHE,
  ANALYTICS_DB_LOAD,
  ANALYTICS_DB_LOAD_CACHE,
  ANALYTICS_DB_LOAD_EXTENDED,
  ANALYTICS_DB_LOAD_OPTIONS,
  ANALYTICS_RETENTION_LOAD,
  ANALYTICS_RETENTION_LOAD_CACHE,
  ANALYTICS_RETENTION_ONLINE_NOW_LOAD,
  ANALYTICS_ROUTE_LOAD,
  ANALYTICS_ROUTE_LOAD_CACHE,
  ANALYTICS_ROUTE_LOAD_EXTENDED,
  ANALYTICS_ROUTE_LOAD_OPTIONS,
} from '../../actions/types';
import { ActionCase, IStateAnalytics } from '../../interfaces/system/IState';
import { IRetentionGetResponse } from '../../interfaces/analytics/IRetention';
import {
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetExtendedData,
  IBaseAnalyticsAggregatedModelGetOptionsResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';

const initialStateAccount: IStateAnalytics = {
  AU: {
    DAU: undefined,
    MAU: undefined,
    from: undefined,
    to: undefined,
  },
  retentionGroup: {
    main: null,
    extended: {
      byValueByParam: {},
      options: null,
    },
    cache: null,
    onlineNow: null,
  },
  routeGroup: {
    main: null,
    extended: {
      byValueByParam: {},
      options: null,
    },
    cache: null,
  },
  cronGroup: {
    main: null,
    extended: {
      byValueByParam: {},
      options: null,
    },
    cache: null,
  },
  dbGroup: {
    main: null,
    extended: {
      byValueByParam: {},
      options: null,
    },
    cache: null,
  },
};

export const analyticsReducer: Reducer = createReducer(
  initialStateAccount,
  (builder: ActionReducerMapBuilder<IStateAnalytics>) => {
    builder.addCase<string, ActionCase<IRetentionGetResponse>>(
      ANALYTICS_AU_LOAD,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IRetentionGetResponse>
      ) => {
        state.AU = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
    >(
      ANALYTICS_RETENTION_LOAD,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
      ) => {
        state.retentionGroup.main = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
    >(
      ANALYTICS_RETENTION_LOAD_CACHE,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
      ) => {
        state.retentionGroup.cache = action.payload;
      }
    );
    builder.addCase<string, ActionCase<number>>(
      ANALYTICS_RETENTION_ONLINE_NOW_LOAD,
      (state: Draft<IStateAnalytics>, action: ActionCase<number>) => {
        state.retentionGroup.onlineNow = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
    >(
      ANALYTICS_ROUTE_LOAD,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
      ) => {
        state.routeGroup.main = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetExtendedData>
    >(
      ANALYTICS_ROUTE_LOAD_EXTENDED,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetExtendedData>
      ) => {
        const { value, param, items, from, to } = action.payload;
        state.routeGroup.extended.byValueByParam[value + '-' + param] = {
          items,
          from,
          to,
        };
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetOptionsResponse>
    >(
      ANALYTICS_ROUTE_LOAD_OPTIONS,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetOptionsResponse>
      ) => {
        state.routeGroup.extended.options = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
    >(
      ANALYTICS_ROUTE_LOAD_CACHE,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
      ) => {
        state.routeGroup.cache = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
    >(
      ANALYTICS_CRON_LOAD,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
      ) => {
        state.cronGroup.main = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
    >(
      ANALYTICS_CRON_LOAD_CACHE,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
      ) => {
        state.cronGroup.cache = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
    >(
      ANALYTICS_DB_LOAD,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetResponse>
      ) => {
        state.dbGroup.main = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetExtendedData>
    >(
      ANALYTICS_DB_LOAD_EXTENDED,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetExtendedData>
      ) => {
        const { value, param, items, from, to } = action.payload;
        state.dbGroup.extended.byValueByParam[value + '-' + param] = {
          items,
          from,
          to,
        };
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetOptionsResponse>
    >(
      ANALYTICS_DB_LOAD_OPTIONS,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetOptionsResponse>
      ) => {
        state.dbGroup.extended.options = action.payload;
      }
    );
    builder.addCase<
      string,
      ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
    >(
      ANALYTICS_DB_LOAD_CACHE,
      (
        state: Draft<IStateAnalytics>,
        action: ActionCase<IBaseAnalyticsAggregatedModelGetCacheResponse>
      ) => {
        state.dbGroup.cache = action.payload;
      }
    );
  }
);
