import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  BidAdditional,
  BidExtraSmall,
  BidMain,
  BidMedium,
  BidSmall,
  CustomBidSubmit,
  CustomBox,
} from './MakeBid.styles';
import { createBid } from '../../../actions/auctions/bid.actions';
import { currencyConvert } from '../../../utils/price';
import { IState } from '../../../interfaces/system/IState';
import {
  ILotShort,
  ILotStatus,
  ILotType,
} from '../../../interfaces/auctions/ILotPublic';
import { ContentDesc } from '../../../common/typos';
import { getElementId } from '../../../utils/layout.utils';
import { InputSearch } from '../../../common/forms/Form.styles';
import { useTranslation } from 'react-i18next';
import { L } from '../../../common/locale';

interface IMakeBidProps {
  lot: ILotShort;
  lotType: ILotType;
  createBid: (lotType: ILotType, lotID: string, price: number) => void;
  status: ILotStatus;
  statusByLotID: Record<string, ILotStatus>;
}

function MakeBid(props: IMakeBidProps) {
  const { t } = useTranslation();
  const inputBidRef = useRef<HTMLInputElement>(null);
  const [isValidBid, setIsValidBid] = useState(false);
  const [status, setStatus] = useState<null | ILotStatus>(null);

  useEffect(() => {
    const lotID: string = props.lot.id;
    setStatus(props.status || props.statusByLotID[lotID]);
  }, [props.status, props.statusByLotID]);

  function makeBid(rate: null | number) {
    if (rate === null) {
      return;
    }

    const bid: number = rate + props.lot.price;
    const isFirstBid: boolean = !props.lot.rate.x08 && !props.lot.rate.x12;

    if (isFirstBid && rate === 0) {
      return props.createBid(props.lotType, props.lot.id, bid);
    }

    if (validateCustomBid(bid)) {
      props.createBid(props.lotType, props.lot.id, bid);
    }
  }

  function preValidateCustomBid() {
    const bid: number =
      (inputBidRef.current && Number(inputBidRef.current.value)) || 0;

    setIsValidBid(validateCustomBid(bid));
  }

  function makeCustomBid() {
    const bid: number =
      (inputBidRef.current && Number(inputBidRef.current.value)) || 0;

    if (validateCustomBid(bid)) {
      props.createBid(props.lotType, props.lot.id, bid);
      if (inputBidRef.current) {
        inputBidRef.current.value = '';
      }
    }
  }

  function validateCustomBid(bid: number): boolean {
    return (props.lot.price && bid > props.lot.price) || false;
  }

  function renderBid(isMain: boolean, rate: null | number) {
    const isFirstBid: boolean = !props.lot.rate.x08 && !props.lot.rate.x12;
    const bid: number = (rate || 0) + props.lot.price;
    const converted: number = currencyConvert(bid);
    const label = `${bid} ($${converted})`;

    if (isMain) {
      return (
        <BidMain
          className={'bidMain ' + (isFirstBid ? 'firstBid' : '')}
          disabled={status === 'win'}
          onClick={() => makeBid(rate)}
          id={getElementId('button-bid-main')}
        >
          <L k={'AuctionBid'} /> {label}
        </BidMain>
      );
    } else {
      return (
        rate && (
          <BidAdditional
            className="bidAdditional"
            disabled={status === 'win'}
            onClick={() => makeBid(rate)}
          >
            {label}
          </BidAdditional>
        )
      );
    }
  }

  return (
    <>
      <ContentDesc>
        <L k={'AuctionBidCustom'} />
      </ContentDesc>
      <BidExtraSmall>
        {renderBid(false, props.lot.rate.x08)}
        {renderBid(true, props.lot.rate.x10)}
        {renderBid(false, props.lot.rate.x12)}
      </BidExtraSmall>
      <BidSmall>
        <div>{renderBid(true, props.lot.rate.x10)}</div>
        <div className="additional">
          {renderBid(false, props.lot.rate.x08)}
          {renderBid(false, props.lot.rate.x12)}
        </div>
      </BidSmall>
      <BidMedium
        className={!props.lot.rate.x08 && !props.lot.rate.x12 ? 'firstBid' : ''}
      >
        <div>{renderBid(true, props.lot.rate.x10)}</div>
        <div>
          {renderBid(false, props.lot.rate.x08)}
          {renderBid(false, props.lot.rate.x12)}
        </div>
      </BidMedium>

      <CustomBox>
        <InputSearch
          ref={inputBidRef}
          onChange={preValidateCustomBid}
          type="number"
          min="0"
          // autoFocus
          placeholder={t('AuctionBidCustomPlaceholder')}
        />
        <CustomBidSubmit
          onClick={makeCustomBid}
          className={isValidBid ? 'valid' : ''}
        >
          <img src="/makeBid.png" alt="make bid" />
        </CustomBidSubmit>
      </CustomBox>
    </>
  );
}

export default connect(
  (state: IState) => ({
    status: state.Bid.status,
    statusByLotID: state.Bid.statusByLotID,
  }),
  {
    createBid,
  },
)(MakeBid);
