import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  DataBatchQueryProduct,
  DataSearchSetProduct,
  IProduct,
  IProductDetailsResponse,
  IProductListResponse,
  IProductListSet,
  IProductPreview,
  IProductPreviewOrder,
  IProductPreviewResponse,
  IProductSearchResponse,
} from '../../interfaces/shop/IProduct';
import {
  SHOP_PRODUCT_BATCH_LOAD,
  SHOP_PRODUCT_DETAILS_LOAD,
  SHOP_PRODUCT_LOAD,
  SHOP_PRODUCT_PREVIEW_LOAD,
  SHOP_PRODUCT_SEARCH,
} from '../types';
import { DataBatch, DataBatchKeySpecific } from '../../interfaces/system/data';
import { loadAccountsSet } from '../account/accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { CacheRequests } from '../../service/cacheRequests';
import { getAnalyticsList } from './productAnalytics.actions';
import { IProductAnalyticsByAccount } from '../../interfaces/shop/IProductAnalytics';
import { IServiceError } from '../../interfaces/system/IError';

const { product } = apiConfig.endpoints.shop;

const cacheByIDs: CacheRequests = new CacheRequests();
const cacheDetailsByID: CacheRequests = new CacheRequests();

export const loadProductsBatchNext =
  (productOrder: IProductPreviewOrder, hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      | DataBatchKeySpecific<string, IProductPreviewOrder>
      | IProductPreview[]
      | IProductAnalyticsByAccount[]
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const { batch, limit } = getState().ShopProduct.batchPreview;

    const query: DataBatchQueryProduct = {
      productOrder,
      limit: limit.toString(),
      skip: hardReload
        ? '0'
        : ((batch[productOrder] && batch[productOrder].loaded) || 0).toString(),
    };

    const queryID: string = `Products-${query.productOrder}-${query.limit}-${query.skip}`;
    const toLoad: string[] = cacheByIDs.getToLoad([queryID]);
    if (!toLoad[0]) {
      return;
    }

    callApi<DataBatch<string>>(product.batchIDs, query)
      .then((data: DataBatch<string>) => {
        const payload: DataBatchKeySpecific<string, IProductPreviewOrder> = {
          ...data,
          batchKey: productOrder,
        };

        dispatch({ type: SHOP_PRODUCT_BATCH_LOAD, payload });
        loadProductsByIDs(data.items)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadProductsByIDs =
  (previewIDs: string[]) =>
  (
    dispatch: IDispatch<
      IProductPreview[] | IProductAnalyticsByAccount[] | IServiceError
    >,
    getState: () => IState
  ) => {
    const productIDs: string[] = [];
    const { set } = getState().ShopProduct.batchPreview;
    previewIDs.forEach((id: string) => {
      if (!set[id]) {
        productIDs.push(id);
      }
    });

    const toLoad: string[] = cacheByIDs.getToLoad(productIDs);
    if (!toLoad[0]) {
      return;
    }

    callApi<IProductPreviewResponse>(product.byIDs, {
      productIDs: toLoad.join(','),
    })
      .then((data: IProductPreviewResponse) =>
        dispatch({ type: SHOP_PRODUCT_PREVIEW_LOAD, payload: data.products })
      )
      .then(() => getAnalyticsList(productIDs)(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const reloadProductsByID =
  (previewID: string) =>
  (
    dispatch: IDispatch<
      IProductPreview[] | IProductAnalyticsByAccount[] | IServiceError
    >,
    getState: () => IState
  ) => {
    cacheByIDs.getToLoad([previewID]);

    callApi<IProductPreviewResponse>(product.byIDs, {
      productIDs: previewID,
    })
      .then((data: IProductPreviewResponse) =>
        dispatch({ type: SHOP_PRODUCT_PREVIEW_LOAD, payload: data.products })
      )
      .then(() => getAnalyticsList([previewID])(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadDetailsByID =
  (productID: string) =>
  (dispatch: IDispatch<IProduct | IServiceError>, getState: () => IState) => {
    const { set } = getState().ShopProduct.products;
    if (set[productID]) {
      return;
    }
    const toLoad: string[] = cacheDetailsByID.getToLoad([productID]);
    if (!toLoad[0]) {
      return;
    }

    callApi<IProductDetailsResponse>(product.details, { productID })
      .then(
        (data: IProductDetailsResponse) =>
          data.product &&
          dispatch({ type: SHOP_PRODUCT_DETAILS_LOAD, payload: data.product })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const reloadDetailsByID =
  (productID: string) =>
  (dispatch: IDispatch<IProduct | IServiceError>, getState: () => IState) => {
    cacheDetailsByID.getToLoad([productID]);
    callApi<IProductDetailsResponse>(product.details, { productID })
      .then(
        (data: IProductDetailsResponse) =>
          data.product &&
          dispatch({ type: SHOP_PRODUCT_DETAILS_LOAD, payload: data.product })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadProductsByPath =
  (accountPath: string, productPath: string) =>
  (
    dispatch: IDispatch<IProductListSet | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    callApi<IProductListResponse>(product.path, { accountPath, productPath })
      .then((data: IProductListResponse) => {
        const payload: IProductListSet = {
          productPath,
          accountPath,
          productList: data.productList,
        };
        dispatch({ type: SHOP_PRODUCT_LOAD, payload });
        loadAccountsSet(
          data.productList.map((product: IProduct) => product.sellerID)
        )(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadProductsBySellers =
  (accountPath: string) =>
  (
    dispatch: IDispatch<IProductListSet | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    callApi<IProductListResponse>(product.seller, { accountPath })
      .then((data: IProductListResponse) => {
        const payload: IProductListSet = {
          productPath: null,
          accountPath,
          productList: data.productList,
        };
        dispatch({ type: SHOP_PRODUCT_LOAD, payload });

        loadAccountsSet(
          data.productList.map((product: IProduct) => product.sellerID)
        )(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const searchProducts =
  (search: string) =>
  (
    dispatch: IDispatch<
      | DataSearchSetProduct
      | IProductPreview[]
      | IProductAnalyticsByAccount[]
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (search.length < 3) {
      return;
    }
    if (getState().ShopProduct.batchPreview.IDsBySearch[search] !== undefined) {
      return;
    }

    callApi<IProductSearchResponse>(product.search, { search })
      .then((data: IProductSearchResponse) => {
        const payload: DataSearchSetProduct = {
          search,
          items: data.productIDs,
        };

        dispatch({ type: SHOP_PRODUCT_SEARCH, payload });
        loadProductsByIDs(data.productIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
