import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { sendPageEvent } from '../../utils/analytics';
import { Block } from '../../common/layout';
import WeekLotsContent from '../../components/auctions/week/WeekLotsContent';
import WeekBidWatcher from '../../components/auctions/week/WeekBidWatcher';
import { PageHead } from '../../common/typos';
import WeekBidsLast from '../../components/auctions/week/WeekBidsLast';
import { BlockStats } from './AuctionWeekPage.styles';
import WeekReportAuction from '../../components/auctions/week/WeekReportAuction';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import WeekCountDown from '../../components/auctions/week/WeekCountDown';
import { loadBidIDsLast } from '../../actions/auctions/bid.actions';
import {
  ILotShort,
  ILotShortSet,
  ILotType,
} from '../../interfaces/auctions/ILotPublic';
import { loadLotIDs } from '../../actions/auctions/lotPublic.actions';
import { L } from '../../common/locale';

interface IAuctionWeekPageProps {
  loadLotIDs: (lotType: ILotType) => void;
  loadBidIDsLast: () => void;
  weekLotIDs: undefined | string[];
  lotSet: ILotShortSet;
}

function AuctionWeekPage(props: IAuctionWeekPageProps) {
  const navigate = useNavigate();
  const [lots, setLots] = useState<ILotShort[]>([]);

  useEffect(() => sendPageEvent('AuctionWeek'), []);
  useEffect(() => props.loadLotIDs('Week'), []);
  useEffect(() => {
    const lotsLocal: ILotShort[] = [];
    const { lotSet, weekLotIDs } = props;
    if (weekLotIDs) {
      weekLotIDs.forEach((lotID: string) => {
        const lot: null | ILotShort = lotSet[lotID];
        if (lot) {
          lotsLocal.push(lot);
        }
      });
    }

    setLots(lotsLocal);
  }, [props.weekLotIDs, props.lotSet]);

  const goToWeekAuction = (): void => {
    navigate(appConfig.routes.week.auction);
  };

  return (
    <AppContent>
      <Block>
        {/*<PageHead className="clickable" onClick={goToWeekAuction}>
          <L k={'MenuAuctionWeek'} />
        </PageHead>*/}
        <WeekCountDown />
        <BlockStats>
          <WeekReportAuction />
          <WeekBidsLast />
        </BlockStats>
        <WeekBidWatcher />
        <WeekLotsContent lots={lots}></WeekLotsContent>
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    weekLotIDs: state.Lot.short.WeekIDs,
    lotSet: state.Lot.short.set,
  }),
  {
    loadLotIDs,
    loadBidIDsLast,
  }
)(AuctionWeekPage);
