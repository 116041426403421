import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { LOT_COUNT_LOAD, LOT_SHORT_IDS_LOAD, LOT_SHORT_SET_LOAD, LOT_WIN_CNT_SET } from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import {
  ILotCntResponse,
  ILotIDsData,
  ILotIDsResponse,
  ILotShort,
  ILotShortResponse,
  ILotShortSet,
  ILotShortSetResponse,
  ILotType,
} from '../../interfaces/auctions/ILotPublic';
import { CacheRequests } from '../../service/cacheRequests';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { loadAccountsSet } from '../account/accounts.actions';
import { loadBidIDs } from './bid.actions';
import { IBidIDsData, IBidSetData } from '../../interfaces/auctions/IBid';

const { ids, one, main, cnt } = apiConfig.endpoints.auctions.lotsPublic;
const cacheByID: CacheRequests = new CacheRequests();

export const loadLotCnt =
  (lotType: ILotType) =>
    (dispatch: IDispatch<number | IServiceError>, getState: () => IState) => {
      callApi<ILotCntResponse>(cnt, { lotType })
        .then((data: ILotCntResponse) =>
          dispatch({
            type: LOT_COUNT_LOAD,
            payload: data.cnt,
          }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadLotOne =
  (lotType: ILotType, lotID: null | string, hardReload?: boolean) =>
    (
      dispatch: IDispatch<
        ILotShortSet | Record<string, number> | IAccountSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!lotID) {
        return;
      }
      if (!hardReload) {
        const lotCached: null | ILotShort = getState().Lot.short.set[lotID];
        if (lotCached) {
          loadAccountsSet([lotCached.sellerID])(dispatch, getState);
          return;
        }

        const IDsToLoad: string[] = cacheByID.getToLoad([lotID]);
        if (!IDsToLoad[0]) {
          return;
        }
      }

      callApi<ILotShortResponse>(one, { lotType, lotID })
        .then((data: ILotShortResponse) => {
          const payload: ILotShortSet = {};
          payload[lotID] = data.lot;

          if (data.lot) {
            const lotWinCntSet: Record<string, number> = {};
            lotWinCntSet[lotID] = data.lot.winCnt;
            dispatch({ type: LOT_WIN_CNT_SET, payload: lotWinCntSet });
            loadAccountsSet([data.lot.sellerID])(dispatch, getState);
          }
          dispatch({ type: LOT_SHORT_SET_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadLotSet =
  (lotType: ILotType, lotIDs: string[]) =>
    (
      dispatch: IDispatch<
        ILotShortSet | Record<string, number> | IAccountSet | IBidIDsData | IBidSetData | IServiceError
      >,
      getState: () => IState,
    ) => {
      const set: ILotShortSet = getState().Lot.short.set;
      const toLoad: string[] = [];
      lotIDs.forEach((lotID: string) => {
        if (!set[lotID]) {
          toLoad.push(lotID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }

      callApi<ILotShortSetResponse>(main, {
        lotType,
        lotIDs: IDsToLoad.join(','),
      })
        .then((data: ILotShortSetResponse) => {
          const lotWinCntSet: Record<string, number> = {};
          const sellerIDsToLoad: string[] = [];
          IDsToLoad.forEach((lotID: string) => {
            const lot: null | ILotShort = data.set[lotID];
            if (lot) {
              lotWinCntSet[lotID] = lot.winCnt;
            }
          });

          loadAccountsSet(sellerIDsToLoad)(dispatch, getState);
          dispatch({ type: LOT_WIN_CNT_SET, payload: lotWinCntSet });
          dispatch({ type: LOT_SHORT_SET_LOAD, payload: data.set });
        })
        .then(() => IDsToLoad.forEach((lotID: string) =>
          loadBidIDs(lotType, lotID)(dispatch, getState),
        ))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadLotIDs =
  (lotType: ILotType) =>
    (
      dispatch: IDispatch<
        | ILotIDsData
        | ILotShortSet
        | Record<string, number>
        | IAccountSet
        | IBidIDsData
        | IBidSetData
        | IServiceError
      >,
      getState: () => IState,
    ) => {
      callApi<ILotIDsResponse>(ids, { lotType })
        .then((data: ILotIDsResponse) => {
          const payload: ILotIDsData = {
            lotType,
            lotIDs: data.lotIDs,
          };
          dispatch({ type: LOT_SHORT_IDS_LOAD, payload });
          loadLotSet(lotType, data.lotIDs)(dispatch, getState);
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
