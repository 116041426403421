export type KeySession =
  | 'PathBeforeLogin'
  | 'ViewCatalog'
  | 'ViewDetail'
  | 'LoginAction';

export function getFromSession(key: KeySession): null | string {
  return window.sessionStorage.getItem(key);
}

export function setToSession(key: KeySession, value: string) {
  window.sessionStorage.setItem(key, value);
}

export function removeFromSession(key: KeySession) {
  window.sessionStorage.removeItem(key);
}
