import React, { useState } from 'react';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
  ContentHead,
} from '../../../common/typos';
import { Block } from '../../../common/layout';
import {
  IMailingPublic,
  IMailingView,
} from '../../../interfaces/communication/IMailing';
import { IParamsItemView } from '../../../common/common';
import { prepareAuctionDate } from '../../../utils/renderParts';
import { ActionLink } from '../../../common/styles';
import { L } from '../../../common/locale';

interface IMailingItemModerationProps {
  mailing: IMailingPublic;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
  ParamsItemView?: IParamsItemView;
}

export function MailingItemModeration(props: IMailingItemModerationProps) {
  const [isParamValueShow, setIsParamValueShow] = useState<
    Record<string, boolean>
  >({});
  const mailing: IMailingPublic = props.mailing;
  const view: null | IMailingView = props.ParamsItemView
    ? (props.ParamsItemView as IMailingView)
    : null;
  const toggleParamValue = (key: string): void => {
    const isParamValueShowLocal: Record<string, boolean> = Object.assign(
      {},
      isParamValueShow
    );
    isParamValueShowLocal[key] = !isParamValueShowLocal[key];
    setIsParamValueShow(isParamValueShowLocal);
  };
  const renderParamsInline = (): JSX.Element => {
    const keys: string[] = Object.keys(mailing.params);
    return (
      <>
        {keys.map((key: string) => (
          <ContentDescInline>
            <ContentDescInline>
              <ActionLink onClick={() => toggleParamValue(key)}>
                {!isParamValueShow[key] ? 'show ' : 'hide '}
                {key}
              </ActionLink>
              {'; '}
            </ContentDescInline>

            {isParamValueShow[key] && (
              <ContentDesc>{mailing.params[key]}</ContentDesc>
            )}
          </ContentDescInline>
        ))}
      </>
    );
  };
  const renderParams = (): JSX.Element => {
    const keys: string[] = Object.keys(mailing.params);
    return (
      <>
        {keys.map((key: string) => (
          <Block>
            <ContentDesc>
              <ActionLink onClick={() => toggleParamValue(key)}>
                {!isParamValueShow[key] ? 'show ' : 'hide '}
                {key}
              </ActionLink>
            </ContentDesc>

            {isParamValueShow[key] && (
              <ContentDesc>{mailing.params[key]}</ContentDesc>
            )}
          </Block>
        ))}
      </>
    );
  };
  const renderAccountIDs = (): JSX.Element => {
    // mailing.mode === 'ToRecommendation';
    if (!mailing.accountIDs) {
      return <></>;
    }

    return (
      <>
        {mailing.accountIDs.map((accountID: string) => (
          <ContentDescInline key={accountID}>
            <ContentDescInline>{accountID}</ContentDescInline>
            {'; '}
          </ContentDescInline>
        ))}
      </>
    );
  };

  return (
    <>
      {props.showInline && (
        <>
          {!view && (
            <ContentDesc>
              <ContentBold>{mailing.mode}</ContentBold>
              {' - '}
              <ContentDescInline>{mailing.status}</ContentDescInline>{' '}
              {props.inlineBlock}
            </ContentDesc>
          )}
          {view && (
            <ContentDesc>
              {view.mode && <ContentBold>{mailing.mode}</ContentBold>}
              {view.mode && ' - '}
              {view.status && (
                <ContentDescInline>{mailing.status}. </ContentDescInline>
              )}
              {view.params && mailing.params && renderParamsInline()}
              {view.sendAt && (
                <ContentDescInline>
                  <L k={'FormLabelSend'} />:{' '}
                  {prepareAuctionDate(mailing.sendAt)}.{' '}
                </ContentDescInline>
              )}
              {view.createdAt && (
                <ContentDescInline>
                  <L k={'FormLabelCreated'} />:{' '}
                  {prepareAuctionDate(mailing.createdAt)}.{' '}
                </ContentDescInline>
              )}
              {view.updatedAt && mailing.updatedAt && (
                <ContentDescInline>
                  <L k={'FormLabelUpdated'} />:{' '}
                  {prepareAuctionDate(mailing.updatedAt)}.
                </ContentDescInline>
              )}
              {view.accountIDs && mailing.accountIDs && renderAccountIDs()}
              {props.inlineBlock}
            </ContentDesc>
          )}
        </>
      )}
      {!props.showInline && (
        <>
          {!view && (
            <Block>
              <ContentHead>{mailing.mode}</ContentHead>
              <ContentDesc>{mailing.status}</ContentDesc>
            </Block>
          )}
          {view && (
            <Block>
              {view.mode && <ContentHead>{mailing.mode}</ContentHead>}
              {view.status && <ContentDesc>{mailing.status}. </ContentDesc>}
              {view.params && mailing.params && renderParams()}
              {view.accountIDs && mailing.accountIDs && renderAccountIDs()}
              {view.sendAt && (
                <ContentDesc>
                  SendAt: {prepareAuctionDate(mailing.sendAt)}.{' '}
                </ContentDesc>
              )}
              {view.createdAt && (
                <ContentDesc>
                  CreatedAt: {prepareAuctionDate(mailing.createdAt)}.{' '}
                </ContentDesc>
              )}
              {view.updatedAt && mailing.updatedAt && (
                <ContentDesc>
                  UpdatedAt: {prepareAuctionDate(mailing.updatedAt)}.
                </ContentDesc>
              )}
              {props.inlineBlock}
            </Block>
          )}
        </>
      )}
    </>
  );
}
