import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  AUCTION_MODE_LOAD,
  LOT_ADMIN_ACTION,
  LOT_COUNT_LOAD,
  LOT_DETAILS_IDS_LOAD,
  LOT_DETAILS_SET_CLEAR,
  LOT_DETAILS_SET_LOAD,
  LOT_SHORT_CURRENT_ID_LOAD,
  LOT_SHORT_CURRENT_UPDATE,
  LOT_SHORT_IDS_LOAD,
  LOT_SHORT_SET_LOAD,
  WEEK_COMPLETE,
} from '../../actions/types';
import { ActionCase, IStateLot } from '../../interfaces/system/IState';

import { IBidUpdate } from '../../interfaces/auctions/IBid';
import {
  ILotIDsData,
  ILotShort,
  ILotShortSet,
} from '../../interfaces/auctions/ILotPublic';
import {
  ILotAdminIDsData,
  ILotAdminSet,
  ILotIDs,
} from '../../interfaces/auctions/ILotAdmin';
import { IAuctionModeResponse } from '../../interfaces/auctions/IAuction';

const initialStateLot: IStateLot = {
  short: {
    set: {},
    LiveIDs: [],
    WeekIDs: undefined,
    currentLotID: undefined,
  },
  moderation: {
    actions: [],
    LiveIDs: [],
    WeekIDs: [],
    set: {},
    LiveLotIDByProductID: {},
    WeekLotIDByProductID: {},
  },
  cnt: null,
};

export const lotReducer: Reducer = createReducer(
  initialStateLot,
  (builder: ActionReducerMapBuilder<IStateLot>) => {
    // Auction
    builder.addCase<string, ActionCase<IAuctionModeResponse>>(
      AUCTION_MODE_LOAD,
      (state: Draft<IStateLot>, action: ActionCase<IAuctionModeResponse>) => {
        state.short.currentLotID = action.payload.lotID;
        state.short.WeekIDs = action.payload.weekLotIDs;
      }
    );
    // Lot Short
    builder.addCase<string, ActionCase<null | string>>(
      LOT_SHORT_CURRENT_ID_LOAD,
      (state: Draft<IStateLot>, action: ActionCase<null | string>) => {
        state.short.currentLotID = action.payload;
      }
    );
    builder.addCase<string, ActionCase<ILotShortSet>>(
      LOT_SHORT_SET_LOAD,
      (state: Draft<IStateLot>, action: ActionCase<ILotShortSet>) => {
        Object.keys(action.payload).forEach((lotID: string) => {
          state.short.set[lotID] = action.payload[lotID];
        });
      }
    );
    builder.addCase<string, ActionCase<ILotIDsData>>(
      LOT_SHORT_IDS_LOAD,
      (state: Draft<IStateLot>, action: ActionCase<ILotIDsData>) => {
        const { lotType, lotIDs } = action.payload;
        if (lotType === 'Live') {
          state.short.LiveIDs = lotIDs;
        } else if (lotType === 'Week') {
          state.short.WeekIDs = lotIDs;
        }
      }
    );
    // Lot Details
    builder.addCase<string, ActionCase<ILotAdminSet>>(
      LOT_DETAILS_SET_LOAD,
      (state: Draft<IStateLot>, action: ActionCase<ILotAdminSet>) => {
        Object.keys(action.payload).forEach((lotID: string) => {
          state.moderation.set[lotID] = action.payload[lotID];
        });
      }
    );
    builder.addCase<string, ActionCase<ILotAdminIDsData>>(
      LOT_DETAILS_IDS_LOAD,
      (state: Draft<IStateLot>, action: ActionCase<ILotAdminIDsData>) => {
        const { lotType, lotIDs } = action.payload;
        if (lotType === 'Live') {
          state.moderation.LiveLotIDByProductID = {};
          state.moderation.LiveIDs = lotIDs;
        } else if (lotType === 'Week') {
          state.moderation.WeekLotIDByProductID = {};
          state.moderation.WeekIDs = lotIDs;
        }

        lotIDs.forEach((ids: ILotIDs) => {
          const { productID, lotID } = ids;
          if (!productID) {
            return;
          }

          if (lotType === 'Live') {
            state.moderation.LiveLotIDByProductID[productID] = lotID;
          } else if (lotType === 'Week') {
            state.moderation.WeekLotIDByProductID[productID] = lotID;
          }
        });
      }
    );
    builder.addCase<string, ActionCase<void>>(
      LOT_DETAILS_SET_CLEAR,
      (state: Draft<IStateLot>) => {
        state.moderation.set = {};
      }
    );
    builder.addCase<string, ActionCase<IBidUpdate>>(
      LOT_SHORT_CURRENT_UPDATE,
      (state: Draft<IStateLot>, action: ActionCase<IBidUpdate>) => {
        const { price, rate, lotID } = action.payload;
        if (lotID !== state.short.currentLotID) {
          return;
        }
        const $lot: null | ILotShort = lotID ? state.short.set[lotID] : null;
        if (!$lot) {
          return;
        }

        $lot.price = price;
        $lot.rate = rate;
      }
    );
    builder.addCase<string, ActionCase<string>>(
      LOT_ADMIN_ACTION,
      (state: Draft<IStateLot>, action: ActionCase<string>) => {
        // todo render for admin with timer
        state.moderation.actions.unshift({
          action: action.payload,
          time: Date.now(),
        });
      }
    );
    // lot cnt
    builder.addCase<string, ActionCase<number>>(
      LOT_COUNT_LOAD,
      (state: Draft<IStateLot>, action: ActionCase<number>) => {
        state.cnt = action.payload;
      }
    );
    // week Auction
    builder.addCase<string, ActionCase<any>>(
      WEEK_COMPLETE,
      (state: Draft<IStateLot>) => {
        state.short.WeekIDs = [];
      }
    );
  }
);
