import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  createAuction,
  updateAuction,
} from '../../actions/auctions/auctionAdmin.actions';
import {
  IAuction,
  IAuctionDonationItem,
  IAuctionSet,
} from '../../interfaces/auctions/IAuction';
import FormCommon, { IFormFields } from '../../common/forms/FormCommon';
import { FormsFields } from '../../common/forms/forms';
import { sendEvent } from '../../utils/analytics';
import { FormBlock } from '../../common/forms/Form.styles';
import { ContentHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { Block } from '../../common/layout';
import { AuctionFormDonations } from './AuctionFormDonations';
import { L } from '../../common/locale';
import { useTranslation } from 'react-i18next';

interface IAuctionFormFormProps {
  auction?: null | IAuction;
  isDisabled?: boolean;
  createAuction: (
    name: null | string,
    description: null | string,
    ind: null | number,
    dateStart: null | string,
    dateAuction: null | string,
    image: null | string,
    imageQrCode: null | string,
    linkCalendar: null | string,
    linkBroadcast: null | string
  ) => void;
  updateAuction: (
    auctionID: string,
    name: null | string,
    description: null | string,
    ind: null | number,
    dateStart: null | string,
    dateAuction: null | string,
    dateWeek: null | string,
    image: null | string,
    imageQrCode: null | string,
    donations: null | IAuctionDonationItem[],
    linkCalendar: null | string,
    linkBroadcast: null | string
  ) => void;
  cbCancel: () => void;
  cbDelete?: (auctionID: string) => void;
  set: IAuctionSet;
  actions: string[];
  errors: string[];
}

function AuctionForm(props: IAuctionFormFormProps) {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormFields<IAuction>>({
    name: {
      ...FormsFields.name,
      isRequired: false,
    },
    description: {
      ...FormsFields.description,
      isRequired: false,
    },
    ind: {
      $value: '',
      mode: 'input',
      modeInput: 'number',
      isRequired: false,
    },
    dateStart: {
      ...FormsFields.date,
      isRequired: false,
    },
    dateAuction: {
      ...FormsFields.date,
      isRequired: false,
    },
    dateWeek: {
      ...FormsFields.date,
      isRequired: false,
    },
    imgURL: {
      ...FormsFields.imageURL,
      isRequired: false,
    },
    imgURLQrCode: {
      ...FormsFields.imageURL,
      isRequired: false,
    },
    linkBroadcast: {
      ...FormsFields.url,
      isRequired: false,
    },
    linkCalendar: {
      ...FormsFields.url,
      isRequired: false,
    },
  });
  const [updatedDonations, setUpdatedDonations] = useState<
    null | IAuctionDonationItem[]
  >(null);

  useEffect(() => sendEvent('Open-AuctionForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.auction) {
      return;
    }

    if (fields.name) {
      fields.name.$value = props.auction.name;
    }
    if (fields.description) {
      fields.description.$value = props.auction.description;
    }
    if (fields.ind && props.auction.ind) {
      fields.ind.$value = props.auction.ind.toString();
    }
    if (fields.dateStart && props.auction.dateStart) {
      fields.dateStart.$valueDate = new Date(props.auction.dateStart);
    }
    if (fields.dateAuction && props.auction.dateAuction) {
      fields.dateAuction.$valueDate = new Date(props.auction.dateAuction);
    }
    if (fields.dateWeek && props.auction.dateWeek) {
      fields.dateWeek.$valueDate = new Date(props.auction.dateWeek);
    }
    if (fields.imgURL && props.auction.imgURL) {
      fields.imgURL.$valueList = [props.auction.imgURL];
    }
    if (fields.imgURLQrCode && props.auction.imgURLQrCode) {
      fields.imgURLQrCode.$valueList = [props.auction.imgURLQrCode];
    }
    if (fields.linkBroadcast) {
      fields.linkBroadcast.$value = props.auction.linkBroadcast;
    }
    if (fields.linkCalendar) {
      fields.linkCalendar.$value = props.auction.linkCalendar;
    }

    setUpdatedDonations((props.auction && props.auction.donations) || null);
    setFields({ ...fields });
  }, [props.auction]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbCreate = () => {
    setIsSending(true);
    props.createAuction(
      (fields.name && fields.name.$value) || null,
      (fields.description && fields.description.$value) || null,
      null,
      (fields.dateStart &&
        fields.dateStart.$valueDate &&
        fields.dateStart.$valueDate.toISOString()) ||
        null,
      (fields.dateAuction &&
        fields.dateAuction.$valueDate &&
        fields.dateAuction.$valueDate.toISOString()) ||
        null,
      (fields.imgURL &&
        fields.imgURL.$valueList &&
        fields.imgURL.$valueList[0]) ||
        null,
      (fields.imgURLQrCode &&
        fields.imgURLQrCode.$valueList &&
        fields.imgURLQrCode.$valueList[0]) ||
        null,
      (fields.linkCalendar && fields.linkCalendar.$value) || null,
      (fields.linkBroadcast && fields.linkBroadcast.$value) || null
    );
  };
  const cbUpdate = () => {
    if (!props.auction) {
      return;
    }

    setIsSending(true);
    props.updateAuction(
      props.auction.id,
      (fields.name && fields.name.$value) || null,
      (fields.description && fields.description.$value) || null,
      null,
      (fields.dateStart &&
        fields.dateStart.$valueDate &&
        fields.dateStart.$valueDate.toISOString()) ||
        null,
      (fields.dateAuction &&
        fields.dateAuction.$valueDate &&
        fields.dateAuction.$valueDate.toISOString()) ||
        null,
      (fields.dateWeek &&
        fields.dateWeek.$valueDate &&
        fields.dateWeek.$valueDate.toISOString()) ||
        null,
      (fields.imgURL &&
        fields.imgURL.$valueList &&
        fields.imgURL.$valueList[0]) ||
        null,
      (fields.imgURLQrCode &&
        fields.imgURLQrCode.$valueList &&
        fields.imgURLQrCode.$valueList[0]) ||
        null,
      updatedDonations,
      (fields.linkCalendar && fields.linkCalendar.$value) || null,
      (fields.linkBroadcast && fields.linkBroadcast.$value) || null
    );
  };
  const cbDelete = () => {
    if (!props.auction || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.auction.id);
  };

  const saveDonations = (donations: null | IAuctionDonationItem[]) => {
    setUpdatedDonations(donations);
  };

  const renderElements = (): JSX.Element => {
    return (
      <Block>
        {props.auction && (
          <AuctionFormDonations
            donations={updatedDonations}
            cb={saveDonations}
          />
        )}
      </Block>
    );
  };

  return (
    <FormBlock>
      {!props.auction && (
        <ContentHead>
          <L k={'FormAuctionTitleCreate'} />
        </ContentHead>
      )}
      {props.auction && (
        <ContentHead>
          <L k={'FormAuctionTitleUpdate'} />
        </ContentHead>
      )}

      <FormCommon
        placeholderTitle={t('FormPlaceHolderAuction')}
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          cbCreate,
          cbUpdate,
          cbDelete,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: !!props.auction,
          isSending,
        }}
        renderElements={renderElements}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Auction.auctions.set,
    actions: state.Auction.auctions.actions,
    errors: state.System.errors,
  }),
  {
    createAuction,
    updateAuction,
  }
)(AuctionForm);
