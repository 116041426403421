import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { PageHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { useParams } from 'react-router-dom';
import { Loading } from '../../common/animation/Loading';
import AuctionInfo from '../../components/auctions/AuctionInfo';
import { getAuctionIDByPath } from '../../utils/archive';
import {
  loadArchiveReportLotBatchNext,
  prepareReportLotBatchKey,
  searchReportLot,
} from '../../actions/archive/archiveReportLot.actions';
import {
  IArchiveReportLot,
  IArchiveReportLotOrder,
} from '../../interfaces/archive/IArchiveReportLot';
import ReportLotItem from '../../components/archive/ReportLotItem';
import { sendPageEvent } from '../../utils/analytics';
import { ILotType } from '../../interfaces/auctions/ILotPublic';
import { Block } from '../../common/layout';
import { Search } from '../../common/moderation/Search';
import { Batch } from '../../common/moderation/Batch';
import { Order } from '../../common/forms/Order';
import { DataBatchWide } from '../../interfaces/system/data';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface IReportLotsPageProps {
  lotType: ILotType;
  searchReportLot: (auctionID: string, search: string) => void;
  loadArchiveReportLotBatchNext: (
    lotType: ILotType,
    auctionID: string,
    reportLotOrder: IArchiveReportLotOrder
  ) => void;
  IDByPath: Record<string, string>;
  set: Record<string, IArchiveReportLot>;
  byBatchKey: Record<string, DataBatchWide>;
  IDsBySearch: Record<string, string[]>; // auctionID-search
}

function ReportLotsPage(props: IReportLotsPageProps) {
  const { t } = useTranslation();
  const { auctionPath } = useParams();
  const [auctionID, setAuctionID] = useState<null | string>();
  const [order, setOrder] = useState<IArchiveReportLotOrder>('order');
  const [batchKey, setBatchKey] = useState<string>('');

  useEffect(() => {
    if (props.lotType === 'Week') {
      sendPageEvent('ArchiveWeekLotsList');
    } else {
      sendPageEvent('ArchiveReportLotsList');
    }
  }, []);
  useEffect(
    () => setAuctionID(getAuctionIDByPath(auctionPath, props.IDByPath)),
    [props.IDByPath]
  );
  useEffect(() => {
    if (!auctionID) {
      return;
    }
    setBatchKey(prepareReportLotBatchKey(props.lotType, auctionID, order));
  }, [auctionID, order]);

  return (
    <AppContent>
      <Block>
        <AuctionInfo />
        <PageHead>
          {props.lotType === 'Live' && <L k={'ReportsLotTitleLive'} />}
          {props.lotType === 'Week' && <L k={'ReportsLotTitleWeek'} />}
        </PageHead>
        <Search
          searchPrefix={auctionID + '-'}
          search={(search: string) =>
            props.searchReportLot(auctionID || '', search)
          }
          set={props.set}
          IDsBySearch={props.IDsBySearch}
          renderItemPublic={(reportLot: IArchiveReportLot) => (
            <ReportLotItem showInline={true} lotID={reportLot.id} />
          )}
          placeholderTitle={'name'}
        />
        <Order
          items={[
            {
              value: 'order',
              title: t('ReportsLotOrderAuction'),
            },
            {
              value: 'Total',
              title: t('ReportsLotOrderTotal'),
            },
            {
              value: 'bidCnt',
              title: t('ReportsLotOrderBidCnt'),
            },
          ]}
          order={order}
          cb={setOrder}
        />
        {auctionID && (
          <Batch
            type={'public'}
            set={props.set}
            batch={{
              batchKey,
              byBatchKey: props.byBatchKey,
              loadBatch: () =>
                props.loadArchiveReportLotBatchNext(
                  props.lotType,
                  auctionID,
                  order
                ),
            }}
            renderItemPublic={(reportLot: IArchiveReportLot) => (
              <ReportLotItem showInline={true} lotID={reportLot.id} />
            )}
            isAdmin={false}
          />
        )}
        {!auctionID && <Loading size={'big'} />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    IDByPath: state.Auction.auctions.IDByPath,
    set: state.Archive.reportLots.set,
    byBatchKey: state.Archive.reportLots.byBatchKey,
    IDsBySearch: state.Archive.reportLots.IDsBySearch,
  }),
  {
    searchReportLot,
    loadArchiveReportLotBatchNext,
  }
)(ReportLotsPage);
