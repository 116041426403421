import { getFromLocal, KeyLocal, removeFromLocal, setToLocal } from '../../service/localStorage';

const key: KeyLocal = 'AuthToken';

export const getToken = (): null | string => {
  return getFromLocal(key);
};

export const setToken = (token: string): void => {
  return setToLocal(key, token);
};

export const removeToken = (): void => {
  return removeFromLocal(key);
};
