import React, { useState } from 'react';
import { Batch } from './Batch';
import { Search } from './Search';
import {
  IParamsItemView,
  RenderItemPublicFunction,
  RenderItemViewFunction,
} from '../common';
import { ContentBold, ContentDesc, ContentDescInline } from '../typos';
import { ModerationItemsListMultiple } from './ModerationItemsListMultiple';
import { ParamsWrapper } from './ParamsWrapper';
import {
  IModerationAction,
  IModerationData,
  IModerationSelection,
} from './Moderation';
import { ModerationItemsList } from './ModerationItemsList';

interface ISelectionPageProps<ItemPublic> {
  title?: string;
  renderItemPublic: RenderItemPublicFunction<ItemPublic>;
  renderItemView?: RenderItemViewFunction;
  data: IModerationData<ItemPublic>;
  isAdmin: boolean;
  action: IModerationAction;
  selection: IModerationSelection;
  ParamsItemView?: IParamsItemView;
}

export function SelectionPage<ItemPublic>(
  props: ISelectionPageProps<ItemPublic>
) {
  const [paramsView, setParamsView] = useState<IParamsItemView>(
    props.ParamsItemView || null
  );

  return (
    <>
      {props.title &&
        props.selection.selectedIDs &&
        props.selection.selectedIDs[0] && (
          <ContentDesc>
            Selected {props.title}
            {props.selection.selectedIDs[1] ? 's' : ''}{' '}
            <ContentDescInline>
              ({props.selection.selectedIDs.length})
            </ContentDescInline>
            :
          </ContentDesc>
        )}

      {props.title &&
        (!props.selection.selectedIDs || !props.selection.selectedIDs[0]) && (
          <ContentDesc>
            <ContentBold>No selected {props.title}s</ContentBold>
          </ContentDesc>
        )}
      {!props.action.hideSelected &&
        props.selection.selectedIDs &&
        props.selection.selectedIDs[0] && (
          <ModerationItemsListMultiple
            isDisabled={false}
            load={() => undefined}
            ParamsItemView={paramsView}
            renderItemPublic={props.renderItemPublic}
            IDs={props.selection.selectedIDs}
            set={props.data.set}
            // action={{ showInline: props.showInline }}
            action={props.action}
            selection={props.selection}
          />
        )}

      {props.renderItemView ? (
        <ParamsWrapper
          title={'view params'}
          renderParams={() =>
            props.renderItemView ? props.renderItemView(setParamsView) : <></>
          }
        />
      ) : undefined}

      {props.data.search && (
        <Search
          search={props.data.search.search}
          set={props.data.set}
          IDsBySearch={props.data.search.IDsBySearch}
          renderItemPublic={props.renderItemPublic}
          placeholderTitle={props.data.search.placeholderTitle}
          action={props.action}
          selection={props.selection}
        />
      )}

      {props.data.batch && (
        <Batch
          type={'moderation'}
          batch={props.data.batch}
          set={props.data.set}
          selection={props.selection}
          renderItemPublic={props.renderItemPublic}
          isAdmin={props.isAdmin}
          ParamsItemView={paramsView}
          action={props.action}
        />
      )}

      {props.data.list && (
        <ModerationItemsList
          load={props.data.list.load}
          renderItemPublic={props.renderItemPublic}
          IDs={props.data.list.IDs}
          set={props.data.set}
          action={props.action}
          selection={props.selection}
        />
      )}
    </>
  );
}
