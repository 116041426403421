import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import { RenderItemPublicFunction } from '../../../common/common';
import { DataBatchWide } from '../../../interfaces/system/data';
import { IEmailLogPublic, IEmailLogSet, IEmailLogView, IEmailMode } from '../../../interfaces/communication/IEmailLog';
import { useNavigate, useParams } from 'react-router-dom';
import { loadEmailLogBatch, prepareBatchKeyEmailLog } from '../../../actions/communication/emailLog.actions';
import { EmailLogModeration } from '../../../components/communication/EmailLog/EmailLogModeration';
import { ContentDesc, ContentHead } from '../../../common/typos';
import { Batch } from '../../../common/moderation/Batch';
import ModerationMainHead from '../../../common/moderation/ModerationMainHead';
import { ActionLink, BlockPage, BlockPageContent } from '../../../common/styles';
import { appConfig } from '../../../config/app.config';
import { loadMailingOne } from '../../../actions/communication/mailing.actions';
import { IMailingPublic, IMailingSet, IMailingView } from '../../../interfaces/communication/IMailing';
import { MailingItemModeration } from '../../../components/communication/Mailing/MailingItemModeration';
import { Loading } from '../../../common/animation/Loading';

interface ICommunicationMailingOneProps {
  loadEmailLogBatch: (
    mailingID: null | string,
    accountID: null | string,
    mode: null | IEmailMode,
  ) => void;
  loadMailingOne: (mailingID: string) => void;
  isAdmin: boolean;
  set: IMailingSet;
  LogSet: IEmailLogSet;
  LogByBatchKey: Record<string, DataBatchWide>;
}

function CommunicationMailingOne(props: ICommunicationMailingOneProps) {
  const navigate = useNavigate();
  const { itemID } = useParams();
  const [mailing, setMailing] = useState<null | IMailingPublic>(null);

  const keyEmailLog: string = prepareBatchKeyEmailLog(
    itemID || null,
    null,
    null,
  );
  const viewEmailLog: IEmailLogView = {
    accountID: false,
    mode: false,
    action: true,
    isAllowed: false,
    messageID: false,
    mailingID: false,
    events: true,
    date: true,
  };
  const viewMailing: IMailingView = {
    mode: true,
    status: true,
    params: true,
    sendAt: false,
    createdAt: false,
    updatedAt: true,
    accountIDs: true,
  };

  useEffect(() => sendPageEvent('CommunicationMailingOne'), []);
  useEffect(() => {
    if (!props.isAdmin) {
      return;
    }

    if (!itemID) {
      return;
    }

    props.loadMailingOne(itemID);
  }, [props.isAdmin]);
  useEffect(() => {
    setMailing(props.set[itemID || ''] || null);
  }, [props.set]);

  const loadEmailLogBatch = () => {
    if (!itemID) {
      return;
    }

    props.loadEmailLogBatch(itemID, null, null);
  };

  const renderEmailLogItem: RenderItemPublicFunction<IEmailLogPublic> = (
    item: IEmailLogPublic,
    inlineBlock?: JSX.Element,
  ) => {
    return (
      <EmailLogModeration
        emailLog={item}
        showInline={true}
        inlineBlock={inlineBlock}
        ParamsItemView={viewEmailLog}
      />
    );
  };

  const openMailings = (): void => {
    navigate(appConfig.routes.Moderation.communication.mailing);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />
        <ContentDesc>
          <ActionLink onClick={openMailings}>Open all mailings</ActionLink>
        </ContentDesc>

        {!mailing && <Loading />}
        {mailing && (
          <MailingItemModeration
            mailing={mailing}
            showInline={false}
            ParamsItemView={viewMailing}
          />
        )}

        <ContentHead>Email logs for current mailing:</ContentHead>
        <Batch
          type={'moderation'}
          batch={{
            batchKey: keyEmailLog,
            byBatchKey: props.LogByBatchKey,
            loadBatch: loadEmailLogBatch,
          }}
          set={props.LogSet}
          renderItemPublic={renderEmailLogItem}
          isAdmin={props.isAdmin}
          ParamsItemView={viewEmailLog}
          action={{ showInline: true }}
          selection={{}}
        />
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    set: state.Communication.Mailing.set,
    LogSet: state.Communication.Log.set,
    LogByBatchKey: state.Communication.Log.byBatchKey,
  }),
  {
    loadEmailLogBatch,
    loadMailingOne,
  },
)(CommunicationMailingOne);
