import React from 'react';
import ProductPreviewItem from './ProductPreviewItem';

export const renderProductPreviewItem = (
  productID: string,
  cnt: number
): JSX.Element => (
  <ProductPreviewItem
    noView={false}
    hideAnalytics={false}
    productID={productID}
    cnt={cnt}
  />
);
