import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { auctionReducer } from './auctions/auction.reducer';
import { bidReducer } from './auctions/bid.reducer';
import { lotReducer } from './auctions/lot.reducer';
import { reportReducer } from './auctions/report.reducer';
import { accountReducer } from './account/account.reducer';
import { systemReducer } from './system/system.reducer';
import { presenterReducer } from './account/presenter.reducer';
import { archiveReducer } from './archive/archive.reducer';
import { hallReducer } from './hall/hall.reducer';
import { notificationReducer } from './account/notification.reducer';
import { shopModerationReducer } from './shop/shop-moderation.reducer';
import { shopProductReducer } from './shop/shop-product.reducer';
import { shopProductAnalyticsReducer } from './shop/shop-product-analytics.reducer';
import { donationReducer } from './donation/donation.reducer';
import { analyticsReducer } from './analytics/analytics.reducer';
import { communicationReducer } from './communication/communication.reducer';
import { parsingReducer } from './parsing/parsing.reducer';

export const store: EnhancedStore = configureStore({
  reducer: combineReducers({
    Auction: auctionReducer,
    Bid: bidReducer,
    Lot: lotReducer,
    Report: reportReducer,
    Account: accountReducer,
    System: systemReducer,
    Presenter: presenterReducer,
    Archive: archiveReducer,
    Donation: donationReducer,
    Hall: hallReducer,
    Notification: notificationReducer,
    ShopModeration: shopModerationReducer,
    ShopProduct: shopProductReducer,
    Communication: communicationReducer,
    ShopProductAnalytics: shopProductAnalyticsReducer,
    Analytics: analyticsReducer,
    Parsing: parsingReducer,
  }),
});
