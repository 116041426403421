import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { SelectionPage } from '../../common/moderation/SelectionPage';
import {
  loadAccountsBatch,
  searchAccounts,
} from '../../actions/account/accounts.actions';
import { IState } from '../../interfaces/system/IState';
import { DataBatchWide } from '../../interfaces/system/data';
import { IParamsItemView, RenderItemPublicFunction } from '../../common/common';
import { AccountItemModeration } from './AccountItemModeration';
import {
  loadSellerIDs,
  searchSeller,
} from '../../actions/account/sellers.actions';
import { loadFoundationIDs } from '../../actions/account/foundations.actions';
import { useTranslation } from 'react-i18next';

interface IAccountSelectProps {
  mode: 'account' | 'seller' | 'foundation';
  cbAccount?: (accountID: string) => void;
  cbAccounts?: (accountIDs: null | string[]) => void;
  account: null | IAccount;
  isAdmin: boolean;
  loadAccountsBatch: () => void;
  searchAccounts: (search: string) => void;
  searchSeller: (search: string) => void;
  loadSellerIDs: (hardReload?: boolean) => void;
  loadFoundationIDs: () => void;
  set: IAccountSet;
  IDsBySearchAccounts: Record<string, string[]>;
  IDsBySearchSellers: Record<string, string[]>;
  byBatchKey: Record<string, DataBatchWide>;
  errors: string[];
  hideBatch?: boolean;
  selectedIDs?: null | string[];
  foundationIDs: null | string[];
}

function AccountSelect(props: IAccountSelectProps) {
  const { t } = useTranslation();
  const [selectedIDs, setSelectedIDs] = useState<null | string[]>(null);
  useEffect(
    () => setSelectedIDs(props.selectedIDs || null),
    [props.selectedIDs]
  );

  const cbSelectedList = (accountIDsLocal: string[]) => {
    if (!props.cbAccounts) {
      return;
    }
    setSelectedIDs(accountIDsLocal);
    props.cbAccounts(accountIDsLocal);
  };
  const cbSelected = (accountIDLocal: string) => {
    if (!props.cbAccount) {
      return;
    }
    setSelectedIDs([accountIDLocal]);
    props.cbAccount(accountIDLocal);
  };
  const loadBatch = () => {
    props.loadAccountsBatch();
  };

  const renderItemPublic: RenderItemPublicFunction<IAccount> = (
    item: IAccount,
    inlineBlock?: JSX.Element
  ) => {
    return (
      <AccountItemModeration
        account={item}
        showInline={true}
        inlineBlock={inlineBlock}
      />
    );
  };

  return (
    <SelectionPage
      title={'account'}
      renderItemPublic={renderItemPublic}
      data={{
        set: props.set,
        batch:
          props.mode === 'account'
            ? {
                batchKey: '',
                byBatchKey: props.byBatchKey,
                loadBatch,
                showExtraLoading: true,
              }
            : undefined,
        search:
          props.mode !== 'foundation'
            ? {
                search:
                  props.mode === 'account'
                    ? props.searchAccounts
                    : props.searchSeller,
                IDsBySearch:
                  props.mode === 'account'
                    ? props.IDsBySearchAccounts
                    : props.IDsBySearchSellers,
                placeholderTitle: t('FormPlaceHolderNameEmail'),
              }
            : undefined,
        list:
          props.mode === 'seller'
            ? {
                load: props.loadSellerIDs,
                IDs: props.selectedIDs || [],
              }
            : props.mode === 'foundation'
            ? {
                load: props.loadFoundationIDs,
                IDs: props.foundationIDs || [],
              }
            : undefined,
      }}
      isAdmin={props.isAdmin}
      action={{
        showInline: true,
      }}
      selection={{
        cbSelected: props.cbAccount ? cbSelected : undefined,
        cbSelectedList: props.cbAccounts ? cbSelectedList : undefined,
        selectedIDs,
      }}
    />
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    isAdmin: state.Account.isAdmin,
    set: state.Account.accounts.set,
    byBatchKey: state.Account.accounts.byBatchKey,
    IDsBySearchAccounts: state.Account.accounts.IDsBySearch,
    IDsBySearchSellers: state.Account.sellers.IDsBySearch,
    sellerIDs: state.Account.sellers.IDs,
    foundationIDs: state.Account.foundationIDs,
    errors: state.System.errors,
  }),
  {
    loadAccountsBatch,
    loadSellerIDs,
    loadFoundationIDs,
    searchAccounts,
    searchSeller,
  }
)(AccountSelect);
