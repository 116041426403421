// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'create-auction-app.firebaseapp.com',
  projectId: 'create-auction-app',
  storageBucket: 'create-auction-app.appspot.com',
  messagingSenderId: '844508203743',
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: 'G-LCG8ESSZYS',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analyticsApp = getAnalytics(app);
