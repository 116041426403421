import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IState,
  IStateAnalyticsRetention,
} from '../../interfaces/system/IState';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IAU } from '../../interfaces/analytics/IRetention';
import { ChartsBlock, ChartsItem } from './analytics.styles';
import { ContentDesc } from '../../common/typos';
import { colors } from '../../common/colors';

interface IRetentionChartProps {
  AU: IStateAnalyticsRetention;
  clientWidth: null | number;
}

interface IChartItem {
  name: string;
  value: number;
}

function AnalyticsRetentionChart(props: IRetentionChartProps) {
  const [MAUList, setMAUList] = useState<null | IChartItem[]>();
  const [DAUList, setDAUList] = useState<null | IChartItem[]>();
  const [chartSize, setChartSize] = useState<null | number>();

  useEffect(() => {
    if (!props.AU.MAU) {
      return;
    }
    setMAUList(
      props.AU.MAU.map(
        (item: IAU): IChartItem => ({
          name: item.date.slice(5),
          value: item.count,
        })
      )
    );
  }, [props.AU.MAU]);
  useEffect(() => {
    if (!props.AU.DAU) {
      return;
    }

    setDAUList(
      props.AU.DAU.map(
        (item: IAU): IChartItem => ({
          name: item.date.slice(5),
          value: item.count,
        })
      )
    );
  }, [props.AU.DAU]);
  useEffect(() => {
    let chartSizeLocal: null | number = props.clientWidth;
    if (!chartSizeLocal) {
      return;
    }

    chartSizeLocal = Math.floor(chartSizeLocal / 2);
    chartSizeLocal = Math.max(chartSizeLocal, 200);
    chartSizeLocal = Math.min(chartSizeLocal, 500);
    setChartSize(chartSizeLocal);
  }, [props.clientWidth]);

  return (
    <ChartsBlock>
      <ChartsItem>
        <ContentDesc>Monthly Active Users:</ContentDesc>
        {MAUList && (
          <LineChart width={chartSize || 400} height={300} data={MAUList}>
            <XAxis dataKey="name" />
            <YAxis dataKey="value" />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line
              type="monotone"
              dataKey="value"
              stroke={colors.main}
              yAxisId={0}
            />
          </LineChart>
        )}
      </ChartsItem>
      <ChartsItem>
        <ContentDesc>Daily Active Users:</ContentDesc>
        {DAUList && (
          <LineChart width={chartSize || 400} height={300} data={DAUList}>
            <XAxis dataKey="name" />
            <YAxis dataKey="value" />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line
              type="monotone"
              dataKey="value"
              stroke={colors.main}
              yAxisId={0}
            />
          </LineChart>
        )}
      </ChartsItem>
    </ChartsBlock>
  );
}

export default connect(
  (state: IState) => ({
    AU: state.Analytics.AU,
    clientWidth: state.System.clientWidth,
  }),
  {}
)(AnalyticsRetentionChart);
