import React from 'react';
import { ContentBold, ContentDesc, ContentDescInline, ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAccount } from '../../interfaces/account/IAccount';
import AccountImage from './AccountImage';

interface IAccountItemModerationProps {
  account: IAccount;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
}

export function AccountItemModeration(props: IAccountItemModerationProps) {
  const account: IAccount = props.account;

  return (
    <>
      {!props.showInline && (
        <Block>
          {account.imgURL && (
            <AccountImage accountID={props.account.id} size={'S'} />
          )}

          <ContentHead>{account.name}</ContentHead>
          <ContentDesc>{account.mail}</ContentDesc>
          {account.isAdmin && (
            <ContentDesc className={'fail'}>Admin</ContentDesc>
          )}
        </Block>
      )}
      {props.showInline && (
        <ContentDesc>
          <ContentBold>{account.name}</ContentBold>
          {' - '}
          <ContentDescInline>{account.mail}. </ContentDescInline>
          {account.isAdmin && (
            <ContentDescInline className={'fail'}>Admin</ContentDescInline>
          )}
        </ContentDesc>
      )}
    </>
  );
}
