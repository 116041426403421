import './config/locale.config';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initSocket } from './actions/system/socket.actions';
import LoginPage from './pages/account/LoginPage';
import AuctionPage from './pages/auctions/AuctionLivePage';
import { AppBlock } from './App.styles';
import PresenterPage from './pages/auctions/Presenter';
import LotCreatePage from './pages/account/LotCreatePage';
import { appConfig } from './config/app.config';
import { checkIsLogged } from './actions/account/user.actions';
import LotResultPage from './pages/auctions/ResultLotPage';
import AuctionResultPage from './pages/auctions/ResultAuctionPage';
import HallLegendsPage from './pages/hall/HallLegendsPage';
import HallLegendPage from './pages/hall/HallLegendPage';
import AuctionsPage from './pages/auctions/ArchiveAuctionsPage';
import ArchiveAuctionPage from './pages/auctions/ArchiveAuctionPage';
import ReportUsersPage from './pages/archive/ReportUsersPage';
import ReportUserPage from './pages/archive/ReportUserPage';
import Header from './components/Header';
import ShopProductPage from './pages/shop/ProductPage';
import ShopProductsBySellerPage from './pages/shop/ProductsBySellerPage';
import CatalogPage from './pages/shop/CatalogPage';
import AdminModeration from './pages/moderation/MainModeration';
import LotsPage from './pages/account/LotsPage';
import LotEditPage from './pages/account/LotEditPage';
import ArchiveAuctionDonationsPage from './pages/auctions/ArchiveAuctionDonationsPage';
import SellerPage from './pages/shop/SellerPage';
import Footer from './components/Footer';
import MainPage from './pages/main/MainPage';
import Unsubscribe from './pages/account/Unsubscribe';
import Errors from './components/Errors';
import RoleSettings from './components/Example/RoleSettings';
import ResultFinalPage from './pages/auctions/ResultFinalPage';
import FoundationsPage from './pages/shop/FoundationsPage';
import Settings from './pages/account/Settings';
import LiveIsStarted from './components/LiveIsStarted';
import AuctionWeekPage from './pages/week/AuctionWeekPage';
import AuctionWeekItemPage from './pages/week/AuctionWeekItemPage';
import ShopProductsOnBehalf from './pages/moderation/shop/ShopProductsOnBehalf';
import DonationPage from './pages/account/DonationPage';
import AdminAdminsModeration from './pages/moderation/account/AdminsModeration';
import DonationBills from './pages/moderation/donation/DonationBills';
import BankDetails from './pages/moderation/donation/BankDetails';
import PartnerSeller from './pages/moderation/partners/PartnerSeller';
import PartnerSettings from './pages/account/PartnerSettings';
import MainAnalytics from './pages/analytics/MainAnalytics';
import CommunicationMailing from './pages/moderation/communication/CommunicationMailing';
import CommunicationMailingOne from './pages/moderation/communication/CommunicationMailingOne';
import CommunicationRecommendation from './pages/moderation/communication/CommunicationRecommendation';
import CommunicationTemplateParams from './pages/moderation/communication/CommunicationTemplateParams';
import CommunicationLogs from './pages/moderation/communication/CommunicationLogs';
import CommunicationUsers from './pages/moderation/communication/CommunicationUsers';
import ShopProducts from './pages/moderation/shop/ShopProducts';
import ShopProductsEdit from './pages/moderation/shop/ShopProductsEdit';
import Auctions from './pages/moderation/auctions/Auctions';
import TechAnalytics from './pages/analytics/TechAnalytics';
import CacheAnalytics from './pages/analytics/CacheAnalytics';
import ReportLotsPage from './pages/archive/ReportLotsPage';
import ReportLotPage from './pages/archive/ReportLotPage';
import Lots from './pages/moderation/auctions/Lots';
import { checkIsExample, getPlatformHeader } from './config/platform.config';

const { routes } = appConfig;

interface IAppProps {
  initSocket: () => void;
  checkIsLogged: () => void;
}

function App(props: IAppProps) {
  const [isExample, setIsExample] = useState<boolean>();

  useEffect(() => {
    document.title = getPlatformHeader();
    setIsExample(checkIsExample());
  }, []);
  useEffect(() => props.initSocket(), []);
  useEffect(() => props.checkIsLogged(), []);

  return (
    <AppBlock>
      <Router>
        <Header />
        <Errors />
        <LiveIsStarted />
        {isExample && <RoleSettings />}

        <Routes>
          <Route path={routes.main.home} element={<MainPage />} />
          <Route path={routes.hall.legends} element={<HallLegendsPage />} />
          <Route path={routes.hall.legendByName} element={<HallLegendPage />} />
          <Route path={routes.auctions.list} element={<AuctionsPage />} />
          <Route path={routes.auctions.one} element={<ArchiveAuctionPage />} />
          <Route
            path={routes.auctions.donations}
            element={<ArchiveAuctionDonationsPage />}
          />
          <Route
            path={routes.auctions.lotsList}
            element={<ReportLotsPage lotType={'Live'} />}
          />
          <Route
            path={routes.auctions.lotsOne}
            element={<ReportLotPage lotType={'Live'} />}
          />
          <Route
            path={routes.auctions.weekLotsList}
            element={<ReportLotsPage lotType={'Week'} />}
          />
          <Route
            path={routes.auctions.weekLotsOne}
            element={<ReportLotPage lotType={'Week'} />}
          />
          <Route
            path={routes.auctions.usersList}
            element={<ReportUsersPage />}
          />
          <Route path={routes.auctions.usersOne} element={<ReportUserPage />} />
          <Route path={routes.admin.main} element={<AdminModeration />} />

          <Route
            path={routes.donations.moderationBills}
            element={<DonationBills />}
          />
          <Route
            path={routes.donations.moderationBank}
            element={<BankDetails />}
          />

          <Route
            path={routes.account.moderation}
            element={<AdminAdminsModeration />}
          />
          <Route path={routes.shopProducts.one} element={<ShopProductPage />} />
          <Route path={routes.shopProducts.main} element={<CatalogPage />} />
          <Route path={routes.shopProducts.list} element={<CatalogPage />} />
          <Route
            path={routes.shopProducts.bySeller}
            element={<ShopProductsBySellerPage />}
          />
          <Route path={routes.shopProducts.sellers} element={<SellerPage />} />
          <Route
            path={routes.shopProducts.foundations}
            element={<FoundationsPage />}
          />

          <Route path={routes.main.login} element={<LoginPage />} />
          <Route path={routes.main.loginToken} element={<LoginPage />} />

          <Route path={routes.live.auction} element={<AuctionPage />} />
          <Route path={routes.live.presenter} element={<PresenterPage />} />
          <Route
            path={routes.live.resultAuction}
            element={<AuctionResultPage />}
          />
          <Route path={routes.live.resultFinal} element={<ResultFinalPage />} />
          <Route path={routes.live.resultLot} element={<LotResultPage />} />
          <Route
            path={routes.live.moderation}
            element={<Lots lotType={'Live'} />}
          />
          <Route path={routes.week.auction} element={<AuctionWeekPage />} />
          <Route path={routes.week.lot} element={<AuctionWeekItemPage />} />
          <Route
            path={routes.week.moderation}
            element={<Lots lotType={'Week'} />}
          />
          <Route path={routes.shop.addLot} element={<LotCreatePage />} />
          <Route path={routes.donations.main} element={<DonationPage />} />
          <Route path={routes.account.myLots.main} element={<LotsPage />} />
          <Route path={routes.account.myLots.edit} element={<LotEditPage />} />
          <Route path={routes.account.settings} element={<Settings />} />
          <Route
            path={routes.account.partnerSettings}
            element={<PartnerSettings />}
          />
          <Route path={routes.account.unsubscribe} element={<Unsubscribe />} />

          <Route path={routes.Moderation.main} element={<AdminModeration />} />
          <Route
            path={routes.Moderation.communication.mailing}
            element={<CommunicationMailing />}
          />
          <Route
            path={routes.Moderation.communication.mailingOne}
            element={<CommunicationMailingOne />}
          />
          <Route
            path={routes.Moderation.communication.recommendation}
            element={<CommunicationRecommendation />}
          />
          <Route
            path={routes.Moderation.communication.templateParams}
            element={<CommunicationTemplateParams />}
          />
          <Route
            path={routes.Moderation.communication.logs}
            element={<CommunicationLogs />}
          />
          <Route
            path={routes.Moderation.communication.users}
            element={<CommunicationUsers />}
          />
          <Route
            path={routes.Moderation.partners.seller}
            element={<PartnerSeller />}
          />
          <Route
            path={routes.Moderation.shop.products}
            element={<ShopProducts />}
          />
          <Route
            path={routes.Moderation.shop.edit}
            element={<ShopProductsEdit />}
          />
          <Route
            path={routes.Moderation.shop.onBehalf}
            element={<ShopProductsOnBehalf />}
          />
          <Route path={routes.Moderation.auctions} element={<Auctions />} />
          <Route path={routes.Analytics.main} element={<MainAnalytics />} />
          <Route path={routes.Analytics.tech} element={<TechAnalytics />} />
          <Route path={routes.Analytics.cache} element={<CacheAnalytics />} />
        </Routes>
        <Footer />
      </Router>
    </AppBlock>
  );
}

export default connect(() => ({}), {
  initSocket,
  checkIsLogged,
})(App);
