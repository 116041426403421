import React, { useEffect, useState } from 'react';
import {
  IArchiveReportLot,
  IArchiveReportLotView,
} from '../../interfaces/archive/IArchiveReportLot';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../common/typos';
import { currencyConvert } from '../../utils/price';
import { makePathFromName } from '../../utils/routes';
import { appConfig } from '../../config/app.config';
import { useNavigate, useParams } from 'react-router-dom';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { loadAccountsSet } from '../../actions/account/accounts.actions';
import ReportBuyersBlock from './ReportBuyersBlock';
import { Loading } from '../../common/animation/Loading';
import { GalleryBlock, Image } from '../../common/images/Image.styles';
import { Block } from '../../common/layout';
import { ProductGallery } from '../shop/product/ProductGallery';
import { IParamsItemView } from '../../common/common';
import { IAuction } from '../../interfaces/auctions/IAuction';
import AccountImage from '../account/AccountImage';
import { loadArchiveReportLotSet } from '../../actions/archive/archiveReportLot.actions';
import { L } from '../../common/locale';

interface IReportLotItemProps {
  showInline?: boolean;
  isNameOnTop?: boolean;
  showAccounts?: boolean;
  lotID: string;
  index?: number;
  loadAccountsSet: (accountIDs: string[]) => void;
  loadArchiveReportLotSet: (lotIDs: string[]) => void;
  accounts: IAccountSet;
  auctionSet: Record<string, IAuction>;
  set: Record<string, IArchiveReportLot>;
  ParamsItemView?: IParamsItemView;
}

function ReportLotItem(props: IReportLotItemProps) {
  const navigate = useNavigate();
  const { auctionPath } = useParams();
  const [reportLot, setReportLot] = useState<null | IArchiveReportLot>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [seller, setSeller] = useState<null | IAccount>(null);
  const view: null | Partial<IArchiveReportLotView> = props.ParamsItemView
    ? (props.ParamsItemView as IArchiveReportLotView)
    : null;

  useEffect(() => {
    const reportLotLocal: IArchiveReportLot = props.set[props.lotID];
    if (!reportLotLocal) {
      props.loadArchiveReportLotSet([props.lotID]);
    }
    setReportLot(reportLotLocal);
  }, [props.lotID, props.set]);

  useEffect(() => {
    if (!reportLot) {
      return;
    }
    const { sellerID } = reportLot;

    const sellerLocal: null | IAccount = props.accounts[sellerID] || null;

    setSeller(sellerLocal);
    if (isLoaded) {
      return;
    }
    setIsLoaded(true);
    if (!sellerLocal) {
      props.loadAccountsSet([sellerID]);
    }
  }, [reportLot, props.accounts]);

  const goToReportLot = () => {
    if (!reportLot) {
      return;
    }
    const path = appConfig.routes.auctions.lotsOne
      .replace(
        appConfig.params.auctionPath,
        auctionPath ||
          makePathFromName(
            (props.auctionSet[reportLot.auctionID] &&
              props.auctionSet[reportLot.auctionID].name) ||
              ''
          )
      )
      .replace(appConfig.params.reportPath, makePathFromName(reportLot.name));
    navigate(path);
  };

  const goToUserLegend = (account: null | IAccount) => {
    if (!account) {
      return;
    }
    const accountPath: string = makePathFromName(account.name);
    const path = appConfig.routes.hall.legendByName.replace(
      appConfig.params.accountPath,
      accountPath
    );
    navigate(path);
  };

  const renderLotInfo = () => {
    if (!reportLot) {
      return <></>;
    }

    return (
      <Block className={'noMargin'}>
        {!props.isNameOnTop && (
          <ContentHead>
            {props.index && <ContentBold>{props.index}. </ContentBold>}
            {reportLot.name}
          </ContentHead>
        )}

        <ContentDesc>
          <L k={'FormReportLabelSold'} />{' '}
          <ContentBold>
            {reportLot.Total || reportLot.price} ($
            {currencyConvert(reportLot.Total || reportLot.price)})
          </ContentBold>
        </ContentDesc>
        <ContentDesc>
          <L k={'FormReportLabelFrom'} /> {reportLot.startPrice} ($
          {currencyConvert(reportLot.startPrice)})
        </ContentDesc>
        <ContentDesc>
          <L k={'ProductByShort'} /> {reportLot.bidCnt}{' '}
          <L k={'AnalyticsBids'} />.
        </ContentDesc>

        {(!view || view.buyerList) && (
          <ReportBuyersBlock
            reportLot={reportLot}
            showAccounts={props.showAccounts}
          />
        )}

        {(!view || view.sellerID) && seller && (
          <Block
            onClick={() => goToUserLegend(seller)}
            className="clickable noMargin"
          >
            <ContentDesc>
              Seller: <ContentBold>{seller.name}</ContentBold>
            </ContentDesc>

            {props.showAccounts && (
              <AccountImage accountID={seller.id} size={'S'} />
            )}
          </Block>
        )}
      </Block>
    );
  };

  return (
    <>
      {!reportLot && <Loading size="big" />}

      {props.showInline && reportLot && (
        <Block onClick={() => goToReportLot()} className={'clickable flexTop'}>
          <Image className={'S'} src={reportLot.imagesURL[0]} alt="lot" />
          {renderLotInfo()}
        </Block>
      )}
      {!props.showInline && reportLot && (
        <>
          <Block onClick={() => goToReportLot()} className={'clickable'}>
            {props.isNameOnTop && (
              <PageHead>
                {props.index && <ContentBold>{props.index}. </ContentBold>}
                {reportLot.name}
              </PageHead>
            )}

            <GalleryBlock>
              <ProductGallery productImagesURL={reportLot.imagesURL} />
            </GalleryBlock>
          </Block>

          {renderLotInfo()}
        </>
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
    set: state.Archive.reportLots.set,
    auctionSet: state.Auction.auctions.set,
  }),
  {
    loadArchiveReportLotSet,
    loadAccountsSet,
  }
)(ReportLotItem);
