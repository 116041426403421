import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { sendPageEvent } from '../../../utils/analytics';
import { getAuctionList } from '../../../actions/auctions/auction.actions';
import { IAuction, IAuctionSet } from '../../../interfaces/auctions/IAuction';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import { deleteAuction } from '../../../actions/auctions/auctionAdmin.actions';
import {
  IParamsItemView,
  RenderFormFunction,
  RenderItemPublicFunction,
} from '../../../common/common';
import AuctionForm from '../../../components/auctions/AuctionForm';
import AuctionItemModeration from '../../../components/auctions/AuctionItemModeration';

interface IAuctionModerationProps {
  getAuctionList: () => void;
  deleteAuction: (auctionID: string) => void;
  set: IAuctionSet;
  IDs: string[];
  isAdmin: boolean;
  errors: string[];
}

function Auctions(props: IAuctionModerationProps) {
  useEffect(() => sendPageEvent('AuctionModeration'), []);

  const renderItemPublic: RenderItemPublicFunction<IAuction> = (
    item: IAuction,
    inlineBlock?: JSX.Element,
    ParamsItemView?: IParamsItemView
  ) => {
    return (
      <AuctionItemModeration
        auction={item}
        showInline={true}
        inlineBlock={inlineBlock}
        ParamsItemView={ParamsItemView}
      />
    );
  };

  const renderForm: RenderFormFunction<IAuction> = (
    isDisabled: undefined | boolean,
    item: undefined | IAuction,
    cbCancel: () => void,
    cbDelete?: (partnerID: string) => void
  ) => {
    return (
      <AuctionForm
        isDisabled={isDisabled}
        auction={item}
        cbCancel={cbCancel}
        cbDelete={cbDelete}
      />
    );
  };
  const load = () => {
    props.getAuctionList();
  };

  return (
    <ModerationPage
      pageTitle={'Auction moderation'}
      itemName={'this auction'}
      cb={{
        cbDelete: props.deleteAuction,
      }}
      render={{
        renderItemPublic,
        renderForm,
      }}
      data={{
        set: props.set,
        list: {
          IDs: props.IDs,
          load,
        },
        errors: props.errors,
      }}
      isAdmin={props.isAdmin}
      action={{
        createActionTitle: 'Add new auction',
        showInline: true,
      }}
    />
  );
}

export default connect(
  (state: IState) => ({
    set: state.Auction.auctions.set,
    IDs: state.Auction.auctions.IDs,
    isAdmin: state.Account.isAdmin,
    errors: state.System.errors,
  }),
  {
    getAuctionList,
    deleteAuction,
  }
)(Auctions);
