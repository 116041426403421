import { IEmailMode } from './IEmailLog';
import { IAppRecommendationType } from './IAppRecommendation';

export interface ITemplateParamsPublic extends ITemplateParams {
  id: string;
}

export type ITemplateParamsGroup = 'text' | 'subject';

export const ITemplateParamsGroupList: ITemplateParamsGroup[] = [
  'text',
  'subject',
];

export interface ITemplateParams {
  group: ITemplateParamsGroup;
  mode: null | IEmailMode;
  type: null | IAppRecommendationType;
  params: Record<string, string>;
  value: string;
  createdAt: string;
}

export interface ITemplateParamsBatchFiltersParams {
  group: null | ITemplateParamsGroup;
  mode: null | IEmailMode;
  type: null | IAppRecommendationType;
  params: null | Record<string, string>;
}

export interface ITemplateParamsView extends Record<string, boolean> {
  group: boolean;
  mode: boolean;
  type: boolean;
  params: boolean;
  value: boolean;
  createdAt: boolean;
}

export type ITemplateParamsSet = Record<string, ITemplateParamsPublic>;

export interface ITemplateParamsIDsResponse {
  itemIDs: string[];
}

export interface ITemplateParamsOneResponse {
  templateParams: null | ITemplateParamsPublic;
}

export interface ITemplateParamsSetQuery extends Record<string, string> {
  itemIDs: string;
}

export interface ITemplateParamsSetResponse {
  set: ITemplateParamsSet;
}

export interface ITemplateParamsSearchQuery extends Record<string, string> {
  search: string;
}

export interface ITemplateParamsSearchResponse {
  itemIDs: string[];
}

export interface ITemplateParamsSearchData {
  search: string;
  itemIDs: string[];
}

export interface ITemplateParamsBatchParams {
  group: null | ITemplateParamsGroup;
  mode: null | IEmailMode;
  type: null | IAppRecommendationType;
  params: null | Record<string, string>;
}

export interface ITemplateParamsBatchBody
  extends Record<string, null | number | string | object>,
    ITemplateParamsBatchParams {
  limit: number;
  skip: number;
}

export interface ITemplateParamsCreateBody
  extends Record<string, null | number | string | object> {
  group: ITemplateParamsGroup;
  mode: null | IEmailMode;
  type: null | IAppRecommendationType;
  value: string;
  params: null | Record<string, string>;
}

export interface ITemplateParamsUpdateBody
  extends Partial<ITemplateParamsCreateBody> {
  itemID: string;
}

export interface ITemplateParamsDeleteQuery extends Record<string, string> {
  itemID: string;
}
