import React, { useEffect, useState } from 'react';
import { ContentDesc } from '../../../common/typos';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { appConfig } from '../../../config/app.config';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { Loading } from '../../../common/animation/Loading';
import AccountImage from '../../account/AccountImage';
import { makePathFromName } from '../../../utils/routes';
import { PreviewBlock } from '../shop.styles';
import {
  IPartnerSellerPublic,
  IPartnerSellerSet,
} from '../../../interfaces/account/IPartnerSeller';
import { loadPartnerSellerByAccountID } from '../../../actions/account/partnerSeller.actions';

interface ISellerItemProps {
  loadPartnerSellerByAccountID: (accountID: string) => void;
  sellerID: string;
  cnt: number;
  accounts: IAccountSet;
  partnerIDByAccountID: Record<string, null | string>;
  set: IPartnerSellerSet;
}

function SellerItem(props: ISellerItemProps) {
  const navigate = useNavigate();

  const [seller, setSeller] = useState<null | IAccount>(null);
  const [partner, setPartner] = useState<null | IPartnerSellerPublic>(null);

  useEffect(() => {
    props.loadPartnerSellerByAccountID(props.sellerID);
  }, []);
  useEffect(() => {
    const sellerLocal: undefined | IAccount = props.accounts[props.sellerID];
    if (sellerLocal) {
      setSeller(sellerLocal);
    }
  }, [props.accounts]);
  useEffect(() => {
    const partnerID: null | string =
      props.partnerIDByAccountID[props.sellerID] || null;
    const partnerLocal: null | IPartnerSellerPublic = partnerID
      ? props.set[partnerID]
      : null;

    if (partnerLocal) {
      setPartner(partnerLocal);
    }
  }, [props.sellerID, props.partnerIDByAccountID, props.set]);

  useEffect(() => {
    if (partner) {
      const sellerLocal: IAccount | null = Object.assign({}, seller);
      sellerLocal.imgURL = partner.imgURL || sellerLocal.imgURL;
      sellerLocal.name = partner.name || sellerLocal.name;
      setSeller(sellerLocal);
    }
  }, [partner]);

  const goToProduct = () => {
    if (!seller) {
      return;
    }

    const accountPath: string = makePathFromName(seller.name);
    navigate(
      appConfig.routes.shopProducts.bySeller.replace(
        appConfig.params.accountPath,
        accountPath
      )
    );
  };

  return (
    <PreviewBlock
      onClick={goToProduct}
      style={{
        width: Math.floor(100 / props.cnt) + '%',
      }}
      className={'cnt-' + props.cnt}
    >
      {(seller && (
        <>
          <AccountImage accountID={seller.id} />
          <ContentDesc>{seller.name}</ContentDesc>
        </>
      )) || <Loading />}
    </PreviewBlock>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
    partnerIDByAccountID: state.Account.partnerSeller.IDByAccountID,
    set: state.Account.partnerSeller.set,
  }),
  {
    loadPartnerSellerByAccountID,
  }
)(SellerItem);
