import styled from 'styled-components';
import { fonts, typos } from '../../../common/typos';
import { colors } from '../../../common/colors';
import { margins, sizes, sizesBorder } from '../../../common/sizes';

export const LotSellerText = styled.p`
  ${typos.desc};
  background-color: ${colors.background};
  color: black;
  border-radius: ${sizesBorder.radius};
  padding: 2px;
  display: inline-block;
  text-align: right;
  margin-top: -20px;
  margin-left: -3px;
  position: absolute;
`;

export const AdminActionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${margins.block} 0;
  width: ${sizes.full};
  float: left;
`;

export const AdminAction = styled.button`
  ${typos.desc};
  width: 40%;
  padding: ${margins.block} 0;
  font-weight: ${fonts.weightL};

  font-family: Montserrat, -apple-system, sans-serif;
  color: white;
  border-radius: ${sizesBorder.radius};
  border: ${sizesBorder.widthS} solid ${colors.lost};
  cursor: pointer;
  background-color: ${colors.lost};

  &.restart {
    color: black;
    border-color: black;
  }

  &.continue {
    color: ${colors.lost};
    background-color: ${colors.background};
  }
`;
