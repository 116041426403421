import styled, { css } from 'styled-components';
import { colors } from '../../../common/colors';
import { fonts, typos } from '../../../common/typos';
import { device, margins, sizes, sizesBorder } from '../../../common/sizes';
import { borders, transitions } from '../../../common/styles';

const button = css`
  color: ${colors.main};
  border-radius: ${sizesBorder.radius};
  border: ${borders.mainS};
  cursor: pointer;

  &[disabled] {
    border-color: rgba(19, 1, 1, 0.3) !important;
    color: rgba(19, 1, 1, 0.3) !important;
    cursor: not-allowed;
  }
}
`;

export const BidExtraSmall = styled.div`
  display: block;

  & .bidMain,
  & .bidAdditional {
    display: block;
    width: ${sizes.full};
    margin: ${margins.item} 0;
    padding: 0 ${margins.item};
  }

  @media ${device.mobileM} {
    display: none;
  }
`;
export const BidSmall = styled.div`
  display: none;
  @media ${device.mobileM} {
    display: block;
    & .bidMain {
      display: block;
      width: ${sizes.full};
      margin-bottom: ${margins.item};
    }

    & .additional {
      display: flex;
      justify-content: space-between;
    }

    & .bidAdditional {
      width: calc(50% - 3px);
    }
  }
  @media ${device.mobileL} {
    display: none;
  }
`;
export const BidMedium = styled.div`
  display: none;
  @media ${device.mobileL} {
    display: flex;
    justify-content: space-between;

    &.firstBid {
      display: block;
    }
  }

  & .bidAdditional {
    display: block;
    width: ${sizes.full};
  }
`;

export const BidAdditional = styled.button`
  min-height: 30px;
  display: block;
  margin-bottom: ${margins.item};
  ${typos.desc};
  ${button};
`;

export const BidMain = styled.button`
  ${typos.name};
  font-weight: ${fonts.weightL};
  min-height: 65px;
  padding-left: ${margins.block};
  padding-right: ${margins.block};
  ${button};

  :hover {
    padding: ${margins.block} ${margins.blockX2};
    transition: ${transitions.easy};
  }

  &.firstBid {
    width: ${sizes.full};
  }
`;

export const CustomBox = styled.div`
  margin-top: ${margins.block};
  padding-bottom: ${margins.item};
  display: flex;
  text-align: center;
  justify-content: space-between;
`;

export const CustomBidSubmit = styled.a`
  filter: opacity(0.5);

  &.valid {
    filter: opacity(1);
    cursor: pointer;
  }

  img {
    width: 20px;
  }
`;
