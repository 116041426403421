import styled from 'styled-components';
import { device, margins, sizesBorder } from '../../common/sizes';
import { colors } from '../../common/colors';
import { borders, shadows, transitions } from '../../common/styles';
import { fonts, typos } from '../../common/typos';

export const Role = styled.div`
  position: fixed;
  z-index: 99;
  left: 20px;
  top: 100px;
  background-color: ${colors.background};

  transform: translateX(-100%);
  transition-property: transform;
  transition-duration: 5s;
  transition-timing-function: ease-out;

  &:hover {
    transform: translateX(0);
  }

  @media ${device.mobileL} {
    left: 20px;
    transform: translateX(0);
    transition: none;

    &:hover {
    }
  }
`;

export const BlockRoleSettings = styled.div`
  width: 35px;
  border: ${borders.mainL};
  border-radius: ${sizesBorder.radius};
  padding: ${margins.block};
  cursor: pointer;
  box-shadow: ${shadows.main};
`;

export const Menu = styled.div`
  position: fixed;
  color: ${colors.lost};
  border: solid ${sizesBorder.widthM} ${colors.lost};
  border-radius: ${sizesBorder.radius};
  padding: ${margins.item} ${margins.block};
  margin-bottom: ${margins.block};
  z-index: 99;
  background-color: ${colors.background};
  box-shadow: ${shadows.main};
  visibility: hidden;
  opacity: 0;
  transition: ${transitions.visibility};

  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

export const MenuItem = styled.a`
  display: block;
  margin: ${margins.block} 0;
  font-weight: ${fonts.weightS};

  ${typos.name};
  font-size: inherit;
  text-decoration: none;
  color: ${colors.main};
  cursor: pointer;

  &.active {
    color: ${colors.main};
    font-weight: ${fonts.weightM};
  }
`;
