import React, { useEffect, useState } from 'react';
import { BlockRoleSettings, Menu, MenuItem, Role } from './RoleSettings.styles';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import {
  getPlatformRole,
  setPlatformRole,
} from '../../actions/account/role.actions';
import { IPlatformRole } from '../../interfaces/Example/IRole';
import { Image } from '../../common/images/Image.styles';
import { L } from '../../common/locale';

interface IRoleSettingsProps {
  setPlatformRole: (role: IPlatformRole) => void;
  isAdmin: boolean;
}

function RoleSettings(props: IRoleSettingsProps) {
  const [isActive, setIsActive] = useState<boolean>(false);
  useEffect(() => {
    const role: IPlatformRole = getPlatformRole();
    if (role === 'admin') {
      props.setPlatformRole(role);
    }
  }, []);

  const toggle = () => {
    setIsActive(!isActive);
  };

  const setRole = (role: IPlatformRole) => {
    setIsActive(false);
    props.setPlatformRole(role);
  };

  return (
    <Role>
      <BlockRoleSettings onClick={toggle}>
        <Image src="/icons/role-settings.png" alt="set role" />
      </BlockRoleSettings>

      <Menu className={isActive ? 'active' : ''}>
        <MenuItem
          onClick={() => setRole('admin')}
          className={props.isAdmin ? 'active' : ''}
        >
          <L k={'FormRoleAdmin'} />
        </MenuItem>
        <MenuItem
          onClick={() => setRole('donator')}
          className={!props.isAdmin ? 'active' : ''}
        >
          <L k={'FormRoleDonator'} />
        </MenuItem>
      </Menu>
    </Role>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
  }),
  {
    setPlatformRole,
  }
)(RoleSettings);
