import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import {
  IAccountBatchQuery,
  IAccountSearchData,
  IAccountSearchQuery,
  IAccountSearchResponse,
  IAccountSet,
  IAccountSetQuery,
  IAccountSetResponse,
} from '../../interfaces/account/IAccount';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { CacheRequests } from '../../service/cacheRequests';
import { IServiceError } from '../../interfaces/system/IError';
import { DataBatch, DataBatchKey } from '../../interfaces/system/data';
import {
  ACCOUNTS_LOAD_BATCH,
  ACCOUNTS_LOAD_SET,
  ACCOUNTS_SEARCH_LOAD,
} from '../types';

const { account } = apiConfig.endpoints;

const cacheByIDs: CacheRequests = new CacheRequests();

export const loadAccountsSet =
  (accountIDs: string[]) =>
  (
    dispatch: IDispatch<IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    const { set } = getState().Account.accounts;
    const accountIDsToLoad: string[] = [];

    accountIDs.forEach((accountID: string) => {
      if (!set[accountID]) {
        accountIDsToLoad.push(accountID);
      }
    });

    const toLoad: string[] = cacheByIDs.getToLoad(accountIDsToLoad);
    if (!toLoad[0]) {
      return;
    }

    const query: IAccountSetQuery = {
      accountIDs: toLoad.join(','),
    };
    callApi<IAccountSetResponse>(
      account.set,
      query
      // undefined, undefined, getHeadersWithPlatform()
    )
      .then((data: IAccountSetResponse) =>
        dispatch({ type: ACCOUNTS_LOAD_SET, payload: data.accountSet })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const searchAccounts =
  (search: string) =>
  (
    dispatch: IDispatch<IAccountSearchData | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    if (search.length < 2) {
      return;
    }

    if (getState().Account.accounts.IDsBySearch[search] !== undefined) {
      return;
    }

    const query: IAccountSearchQuery = {
      search,
    };
    callApi<IAccountSearchResponse>(account.search, query)
      .then((data: IAccountSearchResponse) => {
        const payload: IAccountSearchData = {
          search,
          accountIDs: data.accountIDs,
        };
        dispatch({ type: ACCOUNTS_SEARCH_LOAD, payload });
        loadAccountsSet(data.accountIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadAccountsBatch =
  () =>
  (
    dispatch: IDispatch<DataBatchKey<string> | IAccountSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const { limit, byBatchKey } = getState().Account.accounts;

    // const batchKey: string = prepareAccountsBatchKey();
    const batchKey: string = '';
    const query: IAccountBatchQuery = {
      limit: limit.toString(),
      // skip: loaded ? loaded.toString() : '0',
      skip: (
        (byBatchKey[batchKey] && byBatchKey[batchKey].loaded) ||
        0
      ).toString(),
    };
    callApi<DataBatch<string>>(account.batch, query)
      .then((data: DataBatch<string>) => {
        const payload: DataBatchKey<string> = {
          ...data,
          batchKey: '',
        };
        loadAccountsSet(data.items)(dispatch, getState);
        dispatch({
          type: ACCOUNTS_LOAD_BATCH,
          payload,
        });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
