import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HoverBlock, PreviewBlock, PriceBlock } from './WeekLotsItem.styles';
import { ContentDesc, ContentDescInline } from '../../../common/typos';
import { appConfig } from '../../../config/app.config';
import { ILotShort, ILotStatus } from '../../../interfaces/auctions/ILotPublic';
import { Image } from '../../../common/images/Image.styles';
import { L } from '../../../common/locale';

interface IWeekLotsItemProps {
  lot: ILotShort;
  status: ILotStatus;
  cnt: number;
}

export function WeekLotsItem(props: IWeekLotsItemProps) {
  const navigate = useNavigate();

  const goToWeekLot = () => {
    navigate(
      appConfig.routes.week.lot.replace(
        `${appConfig.params.productPath}`,
        encodeURIComponent(props.lot.name),
      ),
    );
  };

  return (
    <PreviewBlock
      onClick={goToWeekLot}
      style={{
        width: 'calc(' + Math.round(100 / props.cnt) + '% - 5px)',
      }}
      className={props.status || ''}
    >
      <HoverBlock className="hover">
        {props.status === 'win' && (
          <ContentDescInline className="win ">
            <L k={'AuctionStatusWin'} />
          </ContentDescInline>
        )}
        {props.status === 'lost' && (
          <ContentDescInline className="lost ">
            <L k={'AuctionStatusLost'} />
          </ContentDescInline>
        )}

        <ContentDesc>
          <L k={'AuctionLabelPrice'} /> {props.lot.price}{' '}
          <L k={'ReportsCurrencyUAH'} />
        </ContentDesc>
        <ContentDesc>
          <L k={'AuctionLabelStarted'} /> {props.lot.startPrice}{' '}
          <L k={'ReportsCurrencyUAH'} />
        </ContentDesc>
      </HoverBlock>
      <PriceBlock>
        <ContentDesc>
          {props.lot.price} <L k={'ReportsCurrencyUAH'} />
        </ContentDesc>
      </PriceBlock>
      <Image src={props.lot.imagesURL[0]} alt={props.lot.name} />
      <ContentDesc>{props.lot.name}</ContentDesc>
    </PreviewBlock>
  );
}
