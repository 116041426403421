import React, { useEffect, useState } from 'react';
import { ActionButton, ActionLink } from '../styles';
import { ContentBold, ContentDesc, ContentHead } from '../typos';
import { ButtonBlock } from '../images/Image.styles';
import { Loading } from '../animation/Loading';
import { ModerationItemsList } from './ModerationItemsList';
import { ModerationItemsListMultiple } from './ModerationItemsListMultiple';
import {
  IBatchParams,
  IParamsItemView,
  RenderItemPublicFunction,
} from '../common';
import { ParamsWrapper } from './ParamsWrapper';
import { IModerationAction, IModerationSelection } from './Moderation';
import { Block } from '../layout';

interface IBatchProps<ItemPublic> {
  type: 'moderation' | 'public';
  title?: string;
  set: Record<string, ItemPublic>;
  batch: IBatchParams<ItemPublic>;
  selection?: IModerationSelection;
  cbView?: (itemID: string) => void;
  cbEdit?: (itemID: string) => void;
  cbDelete?: (itemID: string) => void;
  renderItemPublic: RenderItemPublicFunction<ItemPublic>;
  ParamsItemView?: IParamsItemView;
  action?: IModerationAction;
  isAdmin: boolean;
}

export function Batch<ItemPublic>(props: IBatchProps<ItemPublic>) {
  const [itemIDs, setItemPublicIDs] = useState<string[]>([]);
  const [itemCnt, setItemPublicCnt] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (props.type === 'moderation' && !props.isAdmin) {
      return;
    }

    loadNext();
  }, [props.isAdmin]);
  useEffect(() => {
    if (!props.batch.batchData && !props.batch.byBatchKey) {
      return;
    }

    if (
      !props.batch.batchData &&
      (!props.batch.byBatchKey || !props.batch.byBatchKey[props.batch.batchKey])
    ) {
      if (!isLoading) {
        loadNext();
      }
      return;
    }

    const { loaded, IDs, cnt } = props.batch.batchData ||
      (props.batch.byBatchKey &&
        props.batch.byBatchKey[props.batch.batchKey]) || {
        loaded: -1,
        IDs: [],
        cnt: 0,
      };
    if (!isLoading && !loaded) {
      loadNext();
    }

    setIsLoading(false);
    setItemPublicIDs(IDs);
    setItemPublicCnt(cnt);
    setIsShowMore((cnt || 0) > (loaded || -1));
  }, [props.batch.byBatchKey, props.batch.batchKey, props.set]);

  const loadNext = (extraLimit?: number) => {
    setIsLoading(true);
    setIsShowMore(false);

    props.batch.loadBatch(extraLimit);
  };

  const selectOnly = () => {
    if (!itemIDs || !props.selection?.cbSelectedList) {
      return;
    }
    props.selection.cbSelectedList(itemIDs);
  };
  const selectAll = () => {
    if (!itemIDs || !props.selection?.cbSelectedList) {
      return;
    }

    const set: Record<string, true> = {};
    if (props.selection.selectedIDs) {
      props.selection.selectedIDs.forEach((itemID: string) => {
        set[itemID] = true;
      });
    }
    itemIDs.forEach((itemID: string) => {
      set[itemID] = true;
    });

    props.selection.cbSelectedList(Object.keys(set));
  };
  const selectNone = () => {
    if (!props.selection?.cbSelectedList) {
      return;
    }

    const set: Record<string, true> = {};
    if (props.selection.selectedIDs) {
      props.selection.selectedIDs.forEach((itemID: string) => {
        set[itemID] = true;
      });
    }
    itemIDs.forEach((itemID: string) => {
      delete set[itemID];
    });

    props.selection.cbSelectedList(Object.keys(set));
  };

  return (
    <Block>
      {props.title && <ContentHead>{props.title} in batch:</ContentHead>}

      {props.batch.renderBatchFilter ? (
        <ParamsWrapper
          title={'filter params'}
          renderParams={() =>
            props.batch.renderBatchFilter ? (
              props.batch.renderBatchFilter()
            ) : (
              <></>
            )
          }
        />
      ) : undefined}

      {itemCnt !== null && (
        <ContentDesc>
          {props.title || 'item'}s based on such filters: {itemCnt}
        </ContentDesc>
      )}

      {!props.selection?.cbSelectedList && (
        <ModerationItemsList
          // isDisabled={!!removingID || !!editedID}
          isDisabled={false}
          cbView={props.cbView}
          cbEdit={props.cbEdit}
          cbDelete={props.cbDelete}
          load={() => undefined}
          renderItemPublic={props.renderItemPublic}
          ParamsItemView={props.ParamsItemView}
          IDs={itemIDs || []}
          set={props.set}
          action={props.action}
          selection={props.selection}
        />
      )}
      {props.selection?.cbSelectedList && (
        <ModerationItemsListMultiple
          // isDisabled={!!removingID || !!editedID}
          isDisabled={false}
          cbView={props.cbView}
          cbEdit={props.cbEdit}
          cbDelete={props.cbDelete}
          load={() => undefined}
          renderItemPublic={props.renderItemPublic}
          ParamsItemView={props.ParamsItemView}
          IDs={itemIDs || []}
          set={props.set}
          action={props.action}
          selection={props.selection}
        />
      )}

      {props.selection?.cbSelectedList && (
        <ButtonBlock>
          <ActionLink onClick={selectOnly}>Select only that</ActionLink>
          <ActionLink onClick={selectAll}>Select all</ActionLink>
          <ActionLink onClick={selectNone}>Deselect all</ActionLink>
        </ButtonBlock>
      )}
      {!itemIDs[0] && (
        <ContentDesc>
          <ContentBold>No items created yet</ContentBold>
        </ContentDesc>
      )}

      {isLoading && <Loading size={'big'} />}
      {isShowMore && (
        <>
          <ActionButton onClick={() => loadNext()}>Load more</ActionButton>
          {props.batch.showExtraLoading && props.type === 'moderation' && (
            <ActionButton onClick={() => loadNext(100)}>
              Load +100 more
            </ActionButton>
          )}
        </>
      )}

      {props.batch.renderBatchActions &&
        props.batch.renderBatchActions(itemIDs || [], props.set)}
    </Block>
  );
}
