import 'react-datepicker/dist/react-datepicker.css';
import React, { useState } from 'react';
import { IAuctionDonationItem } from '../../interfaces/auctions/IAuction';
import { ActionButton, ActionLink } from '../../common/styles';
import { ContentBold, ContentDesc } from '../../common/typos';
import { Block } from '../../common/layout';
import FormCommon, { IFormFields } from '../../common/forms/FormCommon';
import { FormsFields } from '../../common/forms/forms';
import { L } from '../../common/locale';
import { useTranslation } from 'react-i18next';

interface IAuctionFormDonationsProps {
  donations: null | IAuctionDonationItem[];
  cb: (donations: null | IAuctionDonationItem[]) => void;
}

export function AuctionFormDonations(props: IAuctionFormDonationsProps) {
  const { t } = useTranslation();
  const [editIndex, setEditIndex] = useState<null | number>(null);
  const [editFields, setEditFields] =
    useState<null | IFormFields<IAuctionDonationItem>>(null);

  const cbUpdate = () => {
    if (editIndex === null || !editFields) {
      return;
    }

    const donations: IAuctionDonationItem[] =
      (props.donations && [...props.donations]) || [];
    donations[editIndex] = {
      target: (editFields.target && editFields.target.$value) || '',
      amount: (editFields.amount && Number(editFields.amount.$value)) || 0,
    };

    setEditIndex(null);
    setEditFields(null);
    props.cb(donations);
  };

  const cbCancel = () => {
    if (!editIndex) {
      return;
    }
    const donations: IAuctionDonationItem[] =
      (props.donations && [...props.donations]) || [];
    donations.splice(editIndex, 1);

    setEditIndex(null);
    setEditFields(null);

    props.cb(donations);
  };

  const add = () => {
    const donations: IAuctionDonationItem[] =
      (props.donations && [...props.donations]) || [];

    donations.push({
      target: '',
      amount: 0,
    });

    setEditIndex(donations.length - 1);
    setEditFields({
      target: {
        ...FormsFields.name,
        mode: 'input',
        $value: '',
      },
      amount: {
        ...FormsFields.name,
        mode: 'input',
        modeInput: 'number',
        $value: '',
      },
    });

    props.cb(donations);
  };

  const edit = (index: number) => {
    if (!props.donations) {
      return;
    }
    setEditIndex(index);
    setEditFields({
      target: {
        ...FormsFields.name,
        mode: 'input',
        $value: props.donations[index].target,
      },
      amount: {
        ...FormsFields.name,
        mode: 'input',
        modeInput: 'number',
        $value: props.donations[index].amount.toString(),
      },
    });
  };

  const remove = (index: number) => {
    const donations: IAuctionDonationItem[] =
      (props.donations && [...props.donations]) || [];
    donations.splice(index, 1);

    setEditIndex(null);
    setEditFields(null);
    props.cb(donations);
  };

  return (
    <>
      {props.donations &&
        props.donations.map((donation: IAuctionDonationItem, index: number) => (
          <Block key={index}>
            {editIndex !== index && (
              <Block>
                <ContentDesc>
                  <ContentBold>{donation.amount}</ContentBold> -{' '}
                  {donation.target}{' '}
                  <ActionLink onClick={() => edit(index)} className="success">
                    <L k={'FormActionEdit'} />
                  </ActionLink>{' '}
                  <ActionLink onClick={() => remove(index)} className="fail">
                    <L k={'FormActionRemove'} />
                  </ActionLink>
                </ContentDesc>
              </Block>
            )}

            {editIndex === index && editFields && (
              <Block className={'moderation'}>
                <FormCommon
                  placeholderTitle={t('FormPlaceHolderDonation')}
                  fields={editFields}
                  cb={{
                    cbCancel,
                    cbUpdate,
                  }}
                  action={{
                    isUpdate: true,
                    isSending: false,
                  }}
                />
              </Block>
            )}
          </Block>
        ))}

      <ActionButton onClick={add} className="success">
        <L k={'FormActionAdd'} /> <L k={'FormPlaceHolderDonation'} />
      </ActionButton>
    </>
  );
}
