import { apiConfig } from '../../config/api.config';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { ANALYTICS_CRON_LOAD, ANALYTICS_CRON_LOAD_CACHE } from '../types';
import {
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  loadAnalyticsCacheWrapper,
  loadAnalyticsMainWrapper,
  saveAnalyticsCacheWrapper,
} from './analyticsWrapper.actions';

const { main, platform, cache, cacheAll } =
  apiConfig.endpoints.analytics.techCron;

export const loadAnalyticsCronMain =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      IBaseAnalyticsAggregatedModelGetResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainWrapper(
      main,
      ANALYTICS_CRON_LOAD,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsCronCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheWrapper(
      cache,
      ANALYTICS_CRON_LOAD_CACHE,
      hardReload
    )(dispatch, getState);
  };

export const saveTechCronCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheWrapper(cache)(dispatch, getState);
  };
