import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { ContentHead, PageHead } from '../../../common/typos';
import LotAuctionItems from '../../../components/shop/moderation/LotAuctionItems';
import { sendPageEvent } from '../../../utils/analytics';
import { loadIDsByFoundations } from '../../../actions/account/sellers.actions';
import { ILotType } from '../../../interfaces/auctions/ILotPublic';
import ModerationMainHead from '../../../common/moderation/ModerationMainHead';
import { BlockPage, BlockPageContent } from '../../../common/styles';
import {
  IProductPreview,
  IProductPreviewOrder,
} from '../../../interfaces/shop/IProduct';
import { Search } from '../../../common/moderation/Search';
import LotShopItem from '../../../components/shop/moderation/LotShopItem';
import { Order } from '../../../common/forms/Order';
import { Batch } from '../../../common/moderation/Batch';
import {
  loadProductsBatchNext,
  searchProducts,
} from '../../../actions/shop/product.actions';
import { DataBatchWide } from '../../../interfaces/system/data';
import { loadModerationLotIDs } from '../../../actions/auctions/lotModeration.actions';

interface ILotsProps {
  lotType: ILotType;
  loadModerationLotIDs: (lotType: ILotType) => void;
  loadIDsByFoundations: () => void;
  loadProductsBatchNext: (order: IProductPreviewOrder) => void;
  searchProducts: (search: string) => any;
  set: Record<string, IProductPreview>;
  batch: Record<IProductPreviewOrder, DataBatchWide>;
  IDsBySearch: Record<string, string[]>;
  isAdmin: boolean;
}

function Lots(props: ILotsProps) {
  const [order, setOrder] = useState<IProductPreviewOrder>('new');

  useEffect(() => sendPageEvent('LotModeration'), []);
  useEffect(() => {
    props.loadModerationLotIDs('Live');
    props.loadModerationLotIDs('Week');
    props.loadIDsByFoundations();
  }, [props.isAdmin]);

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />

        <PageHead>Moderation - Lots for {props.lotType} Auction</PageHead>

        <ContentHead>Current lots:</ContentHead>
        <LotAuctionItems lotType={props.lotType} />

        <ContentHead>Lots:</ContentHead>
        <Search
          search={props.searchProducts}
          set={props.set}
          IDsBySearch={props.IDsBySearch}
          renderItemPublic={(product: IProductPreview) => (
            <LotShopItem product={product} lotType={props.lotType} />
          )}
          placeholderTitle={'name'}
        />

        <Order
          items={[
            {
              value: 'new',
              title: 'Freshest',
            },
            {
              value: 'popular',
              title: 'Top popular',
            },
            {
              value: 'liked',
              title: 'Top liked',
            },
          ]}
          order={order}
          cb={setOrder}
        />

        <Batch
          type={'moderation'}
          set={props.set}
          batch={{
            batchKey: order,
            byBatchKey: props.batch,
            loadBatch: () => props.loadProductsBatchNext(order),
          }}
          renderItemPublic={(product: IProductPreview) => (
            <LotShopItem product={product} lotType={props.lotType} />
          )}
          isAdmin={props.isAdmin}
        />
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    batch: state.ShopProduct.batchPreview.batch,
    set: state.ShopProduct.batchPreview.set,
    IDsBySearch: state.ShopProduct.batchPreview.IDsBySearch,
    isAdmin: state.Account.isAdmin,
  }),
  {
    loadModerationLotIDs,
    loadIDsByFoundations,
    loadProductsBatchNext,
    searchProducts,
  }
)(Lots);
