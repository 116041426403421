import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { SETTINGS_LOAD } from '../types';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAccountSettings,
  IAccountSettingsResponse,
  IAccountSettingsUpdateBody,
} from '../../interfaces/account/IAccountSettings';
import { IServiceError } from '../../interfaces/system/IError';

const { settings } = apiConfig.endpoints.account;

export const getSettings =
  () =>
    (
      dispatch: IDispatch<null | IAccountSettings | IServiceError>,
      getState: () => IState,
    ) => {
      callApi<IAccountSettingsResponse>(settings)
        .then((data: IAccountSettingsResponse) =>
          dispatch({
            type: SETTINGS_LOAD,
            payload: (data.settings && data.settings[0]) || null,
          }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const updateSettings =
  (
    auctionUpdates: boolean,
    yourLotUpdates: boolean,
    yourBidUpdates: boolean,
    updatesByEmail: boolean,
  ) =>
    (
      dispatch: IDispatch<null | IAccountSettings | IServiceError>,
      getState: () => IState,
    ) => {
      const body: IAccountSettingsUpdateBody = {
        auctionUpdates,
        yourLotUpdates,
        yourBidUpdates,
        updatesByEmail,
      };
      callApi(settings, {}, body, 'POST')
        .then(() => getSettings()(dispatch, getState))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
