import React, { useEffect, useState } from 'react';
import { prepareRecommendationBatchKey } from '../../../actions/communication/recommendation.actions';
import { ContentDesc } from '../../../common/typos';
import { Block } from '../../../common/layout';
import { InputInline } from '../../../common/forms/Form.styles';
import {
  IAppRecommendationSocialView,
  IAppRecommendationSocialViewParamsFilter,
} from '../../../interfaces/communication/IAppRecommendation';
import { SwitchTriple } from '../../../common/forms/SwitchTriple';
import { L } from '../../../common/locale';

export interface IAppRecommendationBatchFiltersParams {
  emails: null | string[];
  categories: undefined | null | string[];
  isEngaged: null | boolean;
  isParsed: null | boolean;
  socialView: null | IAppRecommendationSocialView;
}

interface IAppRecommendationBatchFiltersProps {
  cb: (batchKey: string, params: IAppRecommendationBatchFiltersParams) => void;
  showParsedFilter?: boolean;
}

export function RecommendationBatchFilters(
  props: IAppRecommendationBatchFiltersProps
) {
  const [emails, setEmails] = useState<null | string[]>(null);
  const [categories, setCategories] = useState<undefined | null | string[]>();
  const [isEngaged, setIsEngaged] = useState<null | boolean>(null);

  const [isSocial, setIsSocial] = useState<null | boolean>(null);
  const [isSocialParams, setIsSocialParams] =
    useState<IAppRecommendationSocialViewParamsFilter>({
      linkedin: null,
      fb: null,
      x: null,
      inst: null,
      t: null,
    });

  const [isParsed, setIsParsed] = useState<null | boolean>(null);

  useEffect(() => {
    let socialView: null | boolean | IAppRecommendationSocialView;
    if (isSocial) {
      if (isSocialParams) {
        socialView = {};
        if (isSocialParams.linkedin) {
          socialView.linkedin = isSocialParams.linkedin;
        }
        if (isSocialParams.fb) {
          socialView.fb = isSocialParams.fb;
        }
        if (isSocialParams.inst) {
          socialView.inst = isSocialParams.inst;
        }
        if (isSocialParams.t) {
          socialView.t = isSocialParams.t;
        }
        if (isSocialParams.x) {
          socialView.x = isSocialParams.x;
        }
        if (Object.keys(socialView).length === 0) {
          socialView = true;
        }
      } else {
        socialView = true;
      }
    } else {
      socialView = isSocial;
    }

    props.cb(
      prepareRecommendationBatchKey(
        emails,
        categories,
        isEngaged,
        isParsed,
        socialView
      ),
      {
        emails,
        categories,
        isEngaged,
        isParsed,
        socialView,
      }
    );
  }, [emails, categories, isEngaged, isParsed, isSocial, isSocialParams]);

  const saveSocialParams = (
    name: 'linkedin' | 'fb' | 'x' | 'inst' | 't',
    isChecked: null | boolean
  ): void => {
    const isSocialParamsLocal = Object.assign({}, isSocialParams);
    isSocialParamsLocal[name] = isChecked;
    setIsSocialParams(isSocialParamsLocal);
  };
  const renderSocialParams = (
    name: 'linkedin' | 'fb' | 'x' | 'inst' | 't'
  ): JSX.Element => {
    return (
      <SwitchTriple
        isChecked={isSocialParams[name]}
        option={'Exists ' + name[0].toUpperCase() + name.slice(1)}
        cb={(isChecked: null | boolean) => saveSocialParams(name, isChecked)}
      />
    );
  };

  return (
    <Block className="article">
      <Block>
        <ContentDesc>
          <L k={'FormFilterEngagement'} />
        </ContentDesc>
        <SwitchTriple
          isChecked={isEngaged}
          option={'Engaged'}
          cb={setIsEngaged}
        />
      </Block>

      <Block>
        <ContentDesc>
          <L k={'FormFilterSocial'} />
        </ContentDesc>
        <SwitchTriple isChecked={isSocial} option={'Exists'} cb={setIsSocial} />
        {isSocial && (
          <>
            {renderSocialParams('linkedin')}
            {renderSocialParams('fb')}
            {renderSocialParams('inst')}
            {renderSocialParams('t')}
            {renderSocialParams('x')}
          </>
        )}
      </Block>

      <Block>
        <ContentDesc>
          <L k={'FormFilterEmail'} />
        </ContentDesc>
        <InputInline
          value={emails ? emails.join(',') : ''}
          type="text"
          placeholder="email"
          onChange={(e) => setEmails(e.target.value.split(','))}
        />
      </Block>

      {props.showParsedFilter && (
        <Block>
          <ContentDesc>
            <L k={'FormFilterParsed'} />
          </ContentDesc>
          <SwitchTriple
            isChecked={isParsed}
            option={'Parsed'}
            cb={setIsParsed}
          />
        </Block>
      )}
    </Block>
  );
}
