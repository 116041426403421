import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationEN } from './locales/translation.en';
import { translationUA } from './locales/translation.ua';
import { translationFormEN } from './locales/translation.form.en';
import { translationFormUA } from './locales/translation.form.ua';

i18next.use(initReactI18next).init({
  // lng: 'en', // if you're using a language detector, do not define the lng option
  lng: 'ua', // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      translation: { ...translationEN, ...translationFormEN },
    },
    ua: {
      translation: { ...translationUA, ...translationFormUA },
    },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,

  // defaultTransParent: 'ContentBold' // a valid react element - required before react 16
});

export default i18next;
