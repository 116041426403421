import React from 'react';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
} from '../../common/typos';
import { IParamsItemView } from '../../common/common';
import { ActionLink } from '../../common/styles';
import { renderAuctionDate } from '../../utils/renderParts';
import { IAuction } from '../../interfaces/auctions/IAuction';
import { Block } from '../../common/layout';
import {
  navigateToAuction,
  navigateToAuctionDonation,
} from '../../utils/routes';
import { AuctionTotals } from './AuctionTotals';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { goToAddToCalendar } from '../../actions/account/user.actions';
import { IAccount } from '../../interfaces/account/IAccount';
import { Image } from '../../common/images/Image.styles';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';
import { getRewriteConfig } from '../../config/platform.config';

interface IAuctionItemModerationProps {
  goToAddToCalendar: (path: string) => void;
  auction: IAuction;
  account: null | IAccount;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
  ParamsItemView?: IParamsItemView;
  isClickable?: boolean;
  hideDonationLink?: boolean;
  hideImage?: boolean;
  showTotal?: boolean;
  showCalendar?: boolean;
}

function AuctionItemModeration(props: IAuctionItemModerationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auction: IAuction = props.auction;
  // const view: null | IAuctionView = props.ParamsItemView
  //   ? (props.ParamsItemView as IAuctionView)
  //   : null;
  const view: null = null;

  const addToCalendar = () => {
    if (!props.auction.linkCalendar) {
      return;
    }

    if (props.account) {
      window.open(props.auction.linkCalendar, '_blank');
    } else {
      navigate(appConfig.routes.main.login);
      props.goToAddToCalendar(props.auction.linkCalendar);
    }
  };

  return (
    <>
      {props.showInline && (
        <>
          {/*{!view && (*/}
          <ContentDesc>
            <ContentBold>{auction.name}</ContentBold>
            {/*<ContentDescInline>{auction.geo}</ContentDescInline>{' '}*/}
            {/*<ContentDescInline>({auction.type}). </ContentDescInline>*/}
            {/*<ContentDescInline>{auction.emails.join(', ')}</ContentDescInline>*/}
            {props.inlineBlock}
          </ContentDesc>
          {/*)}*/}
        </>
      )}
      {!props.showInline && (
        <Block>
          <Block
            onClick={
              props.isClickable
                ? () => navigateToAuction(props.auction, navigate)
                : undefined
            }
            className={props.isClickable ? 'clickable' : ''}
          >
            <ContentDescInline>
              <ContentBold>{props.auction.name}</ContentBold>
            </ContentDescInline>
            {props.auction.description !== '' && (
              <ContentDescInline> - {props.auction.description}</ContentDescInline>
            )}
            {!getRewriteConfig()?.hideAuctionDate && renderAuctionDate(props.auction.dateAuction, { isBold: true })}
            {props.auction.dateWeek
              ? renderAuctionDate(props.auction.dateWeek, {
                isBold: true,
                label: t('MenuAuctionWeek'),
              })
              : ''}
          </Block>
          {props.auction.donations && !props.hideDonationLink && (
            <ActionLink
              className="block"
              onClick={() => navigateToAuctionDonation(props.auction, navigate)}
            >
              <L k={'ActionGoToDonations'} />
            </ActionLink>
          )}
          <Block
            onClick={() => navigateToAuction(props.auction, navigate)}
            className="clickable"
          >
            {!props.hideImage && props.auction.imgURL && (
              <Image src={props.auction.imgURL} alt={props.auction.name} />
            )}

            {props.showCalendar && props.auction.linkCalendar && (
              <ContentDesc>
                {/*<ActionLink href={props.auction.linkCalendar} target="_blank">*/}
                <ActionLink onClick={addToCalendar}>
                  <L k={'ActionAddCalendar'} />
                </ActionLink>
              </ContentDesc>
            )}

            {props.showTotal && (
              <AuctionTotals auction={props.auction} isBig={false} />
            )}
          </Block>
        </Block>
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
  }),
  {
    goToAddToCalendar,
  },
)(AuctionItemModeration);
