import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { AdminAction, AdminActionBlock } from './Lot.styles';
import {
  adminLotNext,
  adminLotRestart,
  adminLotWin,
} from '../../../actions/auctions/lotAdmin.actions';
import { ILotType } from '../../../interfaces/auctions/ILotPublic';

interface ILotAdminActionsProps {
  adminLotWin: (lotType: ILotType) => void;
  adminLotNext: (lotType: ILotType) => void;
  adminLotRestart: (lotType: ILotType) => void;
}

function LotAdminActions(props: ILotAdminActionsProps) {
  function win() {
    props.adminLotWin('Live');
  }

  function next() {
    props.adminLotNext('Live');
  }

  function restart() {
    props.adminLotRestart('Live');
  }

  return (
    <>
      <AdminActionBlock>
        <AdminAction onClick={win}>Win</AdminAction>
        <AdminAction onClick={restart} className="restart">
          Restart
        </AdminAction>
      </AdminActionBlock>
      <AdminActionBlock>
        <AdminAction onClick={next} className="continue">
          Next
        </AdminAction>
        {/* <AdminAction onClick={restart} className="continue">
          Stop
        </AdminAction> */}
      </AdminActionBlock>
    </>
  );
}

export default connect((state: IState) => ({}), {
  adminLotWin,
  adminLotNext,
  adminLotRestart,
})(LotAdminActions);
