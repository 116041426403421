import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IAccount } from '../../../interfaces/account/IAccount';
import { IState } from '../../../interfaces/system/IState';
import {
  IAppRecommendationSet,
  IAppRecommendationSocialView,
} from '../../../interfaces/communication/IAppRecommendation';
import { DataBatchWide } from '../../../interfaces/system/data';
import {
  deleteRecommendation,
  loadRecommendationBatch,
  searchRecommendation,
} from '../../../actions/communication/recommendation.actions';
import {
  IAppRecommendationBatchFiltersParams,
  RecommendationBatchFilters,
} from './RecommendationBatchFilters';
import { RenderBatchFilterFunction } from '../../../common/common';
import { SelectionPage } from '../../../common/moderation/SelectionPage';
import {
  renderRecommendationBatchActions,
  renderRecommendationItemPublic,
  renderRecommendationItemView,
} from './Recommendation';
import { useTranslation } from 'react-i18next';

interface IAppRecommendationSelectProps {
  cbAccounts: (accountIDs: null | string[]) => void;
  account: null | IAccount;
  loadRecommendationBatch: (
    emails: null | string[],
    categories: undefined | null | string[],
    isEngaged: null | boolean,
    isParsed: null | boolean,
    socialView: null | IAppRecommendationSocialView,
    extraLimit?: number
  ) => void;
  deleteRecommendation: (recommendationID: string) => void;
  searchRecommendation: (search: string) => void;
  IDs: string[];
  IDsBySearch: Record<string, string[]>;
  set: IAppRecommendationSet;
  byBatchKey: Record<string, DataBatchWide>;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
  selectedIDs?: null | string[];
}

function RecommendationSelect(props: IAppRecommendationSelectProps) {
  const { t } = useTranslation();
  const [selectedIDs, setSelectedIDs] = useState<null | string[]>(null);
  useEffect(
    () => setSelectedIDs(props.selectedIDs || null),
    [props.selectedIDs]
  );

  const [batchKey, setBatchKey] = useState<string>('');
  const [filters, setFilters] = useState<IAppRecommendationBatchFiltersParams>({
    emails: null,
    categories: undefined,
    isEngaged: null,
    isParsed: null,
    socialView: null,
  });

  const cbSelectedList = (accountIDsLocal: string[]) => {
    setSelectedIDs(accountIDsLocal);
    props.cbAccounts(accountIDsLocal);
  };
  const loadBatch = (extraLimit?: number) => {
    if (!filters) {
      props.loadRecommendationBatch(null, null, null, null, null);
      return;
    }

    props.loadRecommendationBatch(
      filters.emails,
      filters.categories,
      filters.isEngaged,
      filters.isParsed,
      filters.socialView,
      extraLimit
    );
  };
  const renderBatchFilter: RenderBatchFilterFunction = () => {
    return (
      <RecommendationBatchFilters
        cb={(batchKey, params) => {
          setBatchKey(batchKey);
          setFilters(params);
        }}
      />
    );
  };

  return (
    <SelectionPage
      title={t('FormRecommendationShort')}
      renderItemPublic={renderRecommendationItemPublic}
      data={{
        set: props.set,
        batch: {
          batchKey,
          byBatchKey: props.byBatchKey,
          loadBatch,
          renderBatchFilter,
          showExtraLoading: true,
          renderBatchActions: renderRecommendationBatchActions,
        },
        search: {
          search: props.searchRecommendation,
          IDsBySearch: props.IDsBySearch,
          placeholderTitle: t('FormPlaceHolderNameEmail'),
        },
      }}
      renderItemView={renderRecommendationItemView}
      isAdmin={props.isAdmin}
      action={{
        showInline: true,
      }}
      selection={{
        cbSelectedList,
        selectedIDs,
      }}
    />
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    isAdmin: state.Account.isAdmin,
    IDs: state.Communication.Recommendation.IDs,
    IDsBySearch: state.Communication.Recommendation.IDsBySearch,
    set: state.Communication.Recommendation.set,
    byBatchKey: state.Communication.Recommendation.byBatchKey,
    actions: state.Communication.Recommendation.actions,
    errors: state.System.errors,
  }),
  {
    loadRecommendationBatch,
    deleteRecommendation,
    searchRecommendation,
  }
)(RecommendationSelect);
