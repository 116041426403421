import styled from 'styled-components';
import { colors } from '../common/colors';
import { margins, sizesBorder } from '../common/sizes';

export const ErrorBlock = styled.div`
  background-color: ${colors.background};
  position: fixed;
  border-radius: ${sizesBorder.radius};
  z-index: 99;
  right: 100px;
  top: 100px;
`;
export const ErrorItem = styled.div`
  position: relative;
  color: ${colors.lost};
  border: solid ${sizesBorder.widthM} ${colors.lost};
  border-radius: ${sizesBorder.radius};
  padding: ${margins.item} ${margins.block};
  margin-bottom: ${margins.block};

  &.info {
    color: ${colors.win};
    border-color: ${colors.win};
  }
`;
