import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { sendEvent } from '../../utils/analytics';
import { searchAccounts } from '../../actions/account/accounts.actions';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { Search } from '../../common/moderation/Search';
import { AccountItemModeration } from './AccountItemModeration';
import { searchSeller } from '../../actions/account/sellers.actions';
import { useTranslation } from 'react-i18next';

interface IAccountSearchProps {
  mode: 'account' | 'seller';
  searchAccounts: (search: string) => void;
  searchSeller: (search: string) => void;
  cbSelected?: (accountID: string) => void;
  cbSelectedList?: (accountIDs: string[]) => void;
  selectedIDs: null | string[];
  IDsBySearchAccounts: Record<string, string[]>;
  IDsBySearchSellers: Record<string, string[]>;
  set: IAccountSet;
  actions: string[];
}

function AccountSearch(props: IAccountSearchProps) {
  const { t } = useTranslation();
  useEffect(() => sendEvent('Open-AccountSearch'), []);

  return (
    <Search
      search={
        props.mode === 'account' ? props.searchAccounts : props.searchSeller
      }
      set={props.set}
      IDsBySearch={
        props.mode === 'account'
          ? props.IDsBySearchAccounts
          : props.IDsBySearchSellers
      }
      renderItemPublic={(
        account: IAccount,
        block,
        view,
        showInline?: boolean
      ) => <AccountItemModeration account={account} showInline={showInline} />}
      placeholderTitle={t('FormPlaceHolderNameEmail')}
      action={{
        showInline: true,
      }}
      selection={{
        cbSelected: props.cbSelected,
        cbSelectedList: props.cbSelectedList,
        selectedIDs: props.selectedIDs,
      }}
    />
  );
}

export default connect(
  (state: IState) => ({
    IDsBySearchAccounts: state.Account.accounts.IDsBySearch,
    IDsBySearchSellers: state.Account.sellers.IDsBySearch,
    set: state.Account.accounts.set,
    actions: state.Account.accounts.actions,
  }),
  {
    searchAccounts,
    searchSeller,
  }
)(AccountSearch);
