import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { initPresenterSocket } from '../../actions/system/socket.actions';
import { IState } from '../../interfaces/system/IState';
// import { IReportPresenter } from '../../interfaces/account/IPresenter';
import Bids from '../../components/auctions/live/Bids';
import {
  PresenterBlock,
  PresenterColumn,
  PresenterGifBlock,
} from './Presenter.styles';
import LotResult from '../../components/auctions/reports/ResultLot';
import AuctionResult from '../../components/auctions/reports/ResultAuction';
import { StartSoon } from '../../common/announcement/StartSoon';
import { IBid, IBidSet } from '../../interfaces/auctions/IBid';
import { getGifSrc, Gif, operateWithGif } from '../../common/animation/Gif';
import { sendPageEvent } from '../../utils/analytics';
import { PresenterContent } from '../../App.styles';
import { Block } from '../../common/layout';
import { IAuction } from '../../interfaces/auctions/IAuction';
import ResultFinal from '../../components/auctions/reports/ResultFinal';
import { ILotShort, ILotShortSet } from '../../interfaces/auctions/ILotPublic';
import { IReportAuctionUpdate } from '../../interfaces/auctions/IReportAuction';
import { IReportLot } from '../../interfaces/auctions/IReportLot';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { PageDesc } from '../../common/typos';
import { Image } from '../../common/images/Image.styles';
import LotItem from '../../components/auctions/live/LotItem';
import { L } from '../../common/locale';

interface IPresenterPageProps {
  // initPresenterSocket: Function;
  // report: IReportPresenter;
  reportAuctionLive: undefined | null | IReportAuctionUpdate;
  reportLot: undefined | null | IReportLot;
  auctionNext: undefined | null | IAuction;
  set: IBidSet;
  bidIDsByLotID: Record<string, string[]>;
  lotSet: ILotShortSet;
  currentLotID: undefined | null | string;
  accounts: IAccountSet;
}

function PresenterPage(props: IPresenterPageProps) {
  useEffect(() => sendPageEvent('Presenter'), []);
  // useEffect(() => props.initPresenterSocket(), []);
  const [bids, setBids] = useState<IBid[]>([]);
  const [lot, setLot] = useState<null | ILotShort>(null);
  const [gifBidSrc, setGifBidSrc] = useState<null | string>(null);
  const [gifResultLotSrc, setGifResultLotSrc] = useState<null | string>(null);
  const [gifResultAuctionSrc, setGifResultAuctionSrc] = useState<null | string>(
    null
  );
  const [timeoutHolderBid, setTimeoutHolderBid] = useState<any>(null);
  const [sellerMail, setSellerMail] = useState<null | string>();
  useEffect(() => {
    if (bids.length % 3 === 0) {
      operateWithGif(
        'bid',
        setGifBidSrc,
        timeoutHolderBid,
        setTimeoutHolderBid
      );
    }
  }, [bids]);
  useEffect(() => {
    setGifResultLotSrc(getGifSrc('resultLot'));
    setTimeout(() => setGifResultLotSrc(null), 3000);
  }, [props.reportLot]);
  useEffect(() => {
    const lotLocal: null | ILotShort = props.currentLotID
      ? props.lotSet[props.currentLotID]
      : null;
    setLot(lotLocal);
    const seller: null | IAccount = lotLocal
      ? props.accounts[lotLocal.sellerID] || null
      : null;

    setSellerMail(seller ? seller.mail : null);
  }, [props.currentLotID, props.lotSet, props.accounts]);
  useEffect(() => {
    setGifResultAuctionSrc(getGifSrc('resultAuction'));
    setTimeout(() => setGifResultAuctionSrc(null), 3000);
  }, [props.reportAuctionLive]);
  useEffect(() => {
    const { currentLotID, bidIDsByLotID, set } = props;
    const lotID: undefined | null | string = currentLotID;
    const bidIDs: null | string[] = lotID ? bidIDsByLotID[lotID] : null;
    const bidsLocal: IBid[] = [];
    if (bidIDs) {
      bidIDs.forEach((bidID: string) => {
        const bid: undefined | IBid = set[bidID];
        if (bid) {
          bidsLocal.push(bid);
        }
      });
    }
    setBids(bidsLocal);
  }, [props.bidIDsByLotID, props.currentLotID, props.set]);

  return (
    <PresenterContent>
      <Block>
        <PresenterBlock>
          <PresenterColumn>
            {lot && (
              <>
                <PageDesc>
                  <L k={'AuctionLotCurrent'} />
                </PageDesc>
                <LotItem lot={lot} skipStatus={true} />
                <Bids lotID={lot.id} lotType={'Live'} />
              </>
            )}
            {!lot && props.reportLot && <LotResult />}

            {!lot &&
              !props.reportLot &&
              props.reportAuctionLive &&
              props.auctionNext && <AuctionResult />}
            {!lot &&
              !props.reportLot &&
              props.reportAuctionLive &&
              props.auctionNext === null && <ResultFinal />}
            {!lot && !props.reportLot && !props.reportAuctionLive && (
              <StartSoon auctionNext={props.auctionNext} />
            )}
          </PresenterColumn>
          <PresenterColumn>
            {/*<PageDesc>Auction numbers:</PageDesc>*/}
            {/*<ContentDesc>*/}
            {/*  Online now: <ContentBold>{props.report.online}</ContentBold>*/}
            {/*</ContentDesc>*/}
            {/*<ContentDesc>*/}
            {/*  All attenders: <ContentBold>{props.report.attenders}</ContentBold>*/}
            {/*</ContentDesc>*/}
            {/*<ContentDesc>*/}
            {/*  All bids: <ContentBold>{props.report.bids}</ContentBold>*/}
            {/*</ContentDesc>*/}
            {/*<ContentDesc>*/}
            {/*  Active attenders (bidders):{' '}*/}
            {/*  <ContentBold>{props.report.bidders}</ContentBold>*/}
            {/*</ContentDesc>*/}
            {/*{props.reportAuctionLive && props.reportAuctionLive?.total && (*/}
            {/*  <ContentDesc>*/}
            {/*    Total donated:{' '}*/}
            {/*    <ContentBold>*/}
            {/*      {props.reportAuctionLive?.total} ($*/}
            {/*      {currencyConvert(props.reportAuctionLive?.total)}){' '}*/}
            {/*    </ContentBold>*/}
            {/*  </ContentDesc>*/}
            {/*)}*/}

            {props.auctionNext?.imgURLQrCode && (
              <>
                <PageDesc>
                  <L k={'ActionAuctionEnter'} />
                </PageDesc>
                <Block>
                  <Image
                    className={'half'}
                    src={props.auctionNext?.imgURLQrCode}
                    alt="qr code to service"
                  />
                </Block>
              </>
            )}

            <PageDesc>
              <L k={'AuctionTitle'} />
            </PageDesc>

            <PresenterGifBlock>
              {lot && gifBidSrc && <Gif src={gifBidSrc} blockClass={'left'} />}
              {!lot && props.reportLot && gifResultLotSrc && (
                <Gif src={gifResultLotSrc} blockClass={'left'} />
              )}
              {!lot &&
                !props.reportLot &&
                props.reportAuctionLive &&
                gifResultAuctionSrc && (
                  <Gif src={gifResultAuctionSrc} blockClass={'left'} />
                )}
            </PresenterGifBlock>
          </PresenterColumn>
        </PresenterBlock>
      </Block>
    </PresenterContent>
  );
}

export default connect(
  (state: IState) => ({
    lotSet: state.Lot.short.set,
    accounts: state.Account.accounts.set,
    currentLotID: state.Lot.short.currentLotID,
    // report: state.Presenter.report,
    reportAuctionLive: state.Report.auctionLive,
    reportLot: state.Report.lot,
    bidIDsByLotID: state.Bid.IDsByLotID,
    set: state.Bid.byID,
    auctionNext: state.Auction.auctionNext,
  }),
  {
    // initPresenterSocket,
  }
)(PresenterPage);
