import React, { useEffect, useState } from 'react';
import { ContentBold, ContentDesc, ContentDescInline, ContentHead } from '../../../common/typos';
import { Block } from '../../../common/layout';
import { IParamsItemView } from '../../../common/common';
import { IProductDraft, IProductDraftView } from '../../../interfaces/shop/IProductDraft';
import { Image } from '../../../common/images/Image.styles';
import { prepareAuctionDate } from '../../../utils/renderParts';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { loadAccountsSet } from '../../../actions/account/accounts.actions';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { Loading } from '../../../common/animation/Loading';

interface IDraftItemModerationProps {
  draft: IProductDraft;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
  ParamsItemView?: IParamsItemView;
  set: IAccountSet;
}

function DraftItemModeration(props: IDraftItemModerationProps) {
  const [account, setAccount] = useState<null | IAccount>(null);
  const draft: IProductDraft = props.draft;
  const view: null | IProductDraftView = props.ParamsItemView
    ? (props.ParamsItemView as IProductDraftView)
    : null;

  useEffect(() => {
    setAccount(props.set[props.draft.sellerID] || null);
  }, [props.set]);

  const goToProduct = () => {
    // if (!route) {
    //   return;
    // }
    // navigate(
    //   appConfig.routes.shopProducts.one.replace(
    //     `${appConfig.params.accountPath}/${appConfig.params.productPath}`,
    //     route
    //   )
    // );
  };

  const goToSeller = () => {
    // if (!route) {
    //   return;
    // }
    // navigate(
    //   appConfig.routes.shopProducts.one.replace(
    //     `${appConfig.params.accountPath}/${appConfig.params.productPath}`,
    //     route
    //   )
    // );
  };

  return (
    <>
      {!view && (
        <Block>
          <ContentHead onClick={goToProduct}>{draft.name}</ContentHead>
          <Block>
            {draft.imagesURL.map((imgURL: string) => (
              <Image src={imgURL} className={'S inline'} />
            ))}
          </Block>
          <ContentDesc>{draft.description}</ContentDesc>
          <ContentDesc className={draft.isActive ? 'fail' : 'success'}>
            <ContentDescInline style={{ fontSize: '2rem' }}>
              •
            </ContentDescInline>
            {draft.isActive ? 'On moderation' : 'Moderated'}
          </ContentDesc>
          {props.inlineBlock}
        </Block>
      )}
      {view && (
        <Block>
          {view.name && (
            <ContentHead onClick={goToProduct}>{draft.name}</ContentHead>
          )}
          {view.imagesURL && (
            <Block>
              {draft.imagesURL.map((imgURL: string) => (
                <Image src={imgURL} className={'S inline'} />
              ))}
            </Block>
          )}
          {/*productID: null | string;*/}
          {view.sellerID && account && (
            <ContentDesc>
              <ContentBold>
                {account.name} - {account.mail}
              </ContentBold>
            </ContentDesc>
          )}
          {view.sellerID && !account && <Loading />}

          {/*links?: IProductLink[]; // todo implement*/}
          {view.description && <ContentDesc>{draft.description}</ContentDesc>}
          {view.isHide && (
            <ContentDesc className={'fail'}>
              {draft.isHide ? 'Hide' : ''}
            </ContentDesc>
          )}
          {view.isActive && (
            <ContentDesc className={'success'}>
              {draft.isActive ? 'Active' : ''}
            </ContentDesc>
          )}

          {view.isDeclined && (
            <ContentDesc className={'fail'}>
              {draft.isDeclined ? 'Declined' : ''}
            </ContentDesc>
          )}
          {view.declineReason && (
            <ContentDesc>{draft.declineReason}</ContentDesc>
          )}
          {view.dateCreated && draft.dateCreated && (
            <ContentDesc>
              CreatedAt: {prepareAuctionDate(draft.dateCreated)}.
            </ContentDesc>
          )}
          {view.dateUpdated && draft.dateUpdated && (
            <ContentDesc>
              UpdatedAt: {prepareAuctionDate(draft.dateUpdated)}.
            </ContentDesc>
          )}
          {view.dateModerated && draft.dateModerated && (
            <ContentDesc>
              ModeratedAt: {prepareAuctionDate(draft.dateModerated)}.
            </ContentDesc>
          )}
          {props.inlineBlock}
        </Block>
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Account.accounts.set,
  }),
  {
    loadAccountsSet,
  },
)(DraftItemModeration);
