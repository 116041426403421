import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  ACCOUNT_ACTION,
  ACCOUNT_LOADED,
  ACCOUNT_LOGOUT,
  ACCOUNT_ROLE_SET,
  ACCOUNTS_LOAD_BATCH,
  ACCOUNTS_LOAD_SET,
  ACCOUNTS_SEARCH_LOAD,
  ADMIN_IDS_LOADED,
  FOUNDATION_IDS_LOADED,
  PARTNER_SELLER_ACTION,
  PARTNER_SELLER_LOAD_BATCH,
  PARTNER_SELLER_LOAD_BY_ID,
  PARTNER_SELLER_LOAD_BY_PATH,
  PARTNER_SELLER_LOAD_IDS,
  PARTNER_SELLER_LOAD_SET,
  PARTNER_SELLER_SEARCH,
  SELLER_DATA_LOAD,
  SELLER_SEARCH,
  SELLERS_LOAD,
  SETTINGS_LOAD,
} from '../../actions/types';
import { ActionCase, IStateAccount } from '../../interfaces/system/IState';
import {
  IAccount,
  IAccountSearchData,
  IAccountSet,
} from '../../interfaces/account/IAccount';
import { IAccountSettings } from '../../interfaces/account/IAccountSettings';
import { IPlatformRole } from '../../interfaces/Example/IRole';
import {
  DataBatch,
  DataBatchKey,
  DataSearch,
} from '../../interfaces/system/data';
import {
  IPartnerSellerByID,
  IPartnerSellerByPath,
  IPartnerSellerPublic,
  IPartnerSellerSet,
} from '../../interfaces/account/IPartnerSeller';
import { ISellerAnalyticsData } from '../../interfaces/shop/ISeller';
import { checkIsExample } from '../../config/platform.config';

const initialStateAccount: IStateAccount = {
  account: null,
  accounts: {
    limit: 15,
    set: {},
    IDsBySearch: {},
    byBatchKey: {},
    actions: [],
  },
  adminIDs: null,
  foundationIDs: null,
  isAdmin: false,
  settings: undefined,
  sellers: {
    IDs: null,
    analyticsByID: {},
    IDsBySearch: {},
  },
  partnerSeller: {
    set: {},
    IDs: [],
    limit: 10,
    cnt: null,
    loaded: null,
    IDsBySearch: {},
    IDByPath: {},
    IDByAccountID: {},
    actions: [],
  },
};

export const accountReducer: Reducer = createReducer(
  initialStateAccount,
  (builder: ActionReducerMapBuilder<IStateAccount>) => {
    builder.addCase<string, ActionCase<IAccount>>(
      ACCOUNT_LOADED,
      (state: Draft<IStateAccount>, action: ActionCase<IAccount>) => {
        const account: IAccount = action.payload;
        state.account = account;
        state.isAdmin = account.isAdmin || false;
        state.accounts.set[account.id] = { ...account };
      }
    );
    builder.addCase<string, ActionCase<IPlatformRole>>(
      ACCOUNT_ROLE_SET,
      (state: Draft<IStateAccount>, action: ActionCase<IPlatformRole>) => {
        if (!checkIsExample()) {
          return;
        }

        state.isAdmin = action.payload === 'admin';
      }
    );
    builder.addCase<string, ActionCase<string>>(
      ACCOUNT_ACTION,
      (state: Draft<IStateAccount>, action: ActionCase<string>) => {
        state.accounts.actions.unshift(action.payload);
      }
    );
    builder.addCase<string, ActionCase<null>>(
      ACCOUNT_LOGOUT,
      (state: Draft<IStateAccount>) => {
        state.account = null;
        state.isAdmin = false;
      }
    );
    // accounts
    builder.addCase<string, ActionCase<IAccountSet>>(
      ACCOUNTS_LOAD_SET,
      (state: Draft<IStateAccount>, action: ActionCase<IAccountSet>) => {
        const set: IAccountSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.accounts.set[id] = set[id];
        });
      }
    );
    builder.addCase<string, ActionCase<IAccountSearchData>>(
      ACCOUNTS_SEARCH_LOAD,
      (state: Draft<IStateAccount>, action: ActionCase<IAccountSearchData>) => {
        const { search, accountIDs } = action.payload;
        state.accounts.IDsBySearch[search] = accountIDs;
      }
    );
    builder.addCase<string, ActionCase<DataBatchKey<string>>>(
      ACCOUNTS_LOAD_BATCH,
      (
        state: Draft<IStateAccount>,
        action: ActionCase<DataBatchKey<string>>
      ) => {
        const { cnt, items, batchKey } = action.payload;

        if (!state.accounts.byBatchKey[batchKey]) {
          state.accounts.byBatchKey[batchKey] = { IDs: [], cnt: 0, loaded: 0 };
        }

        state.accounts.byBatchKey[batchKey].cnt = cnt;
        state.accounts.byBatchKey[batchKey].IDs =
          state.accounts.byBatchKey[batchKey].IDs.concat(items);
        state.accounts.byBatchKey[batchKey].loaded =
          state.accounts.byBatchKey[batchKey].IDs.length;
      }
    );
    builder.addCase<string, ActionCase<string[]>>(
      ADMIN_IDS_LOADED,
      (state: Draft<IStateAccount>, action: ActionCase<string[]>) => {
        state.adminIDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<string[]>>(
      FOUNDATION_IDS_LOADED,
      (state: Draft<IStateAccount>, action: ActionCase<string[]>) => {
        state.foundationIDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<null | IAccountSettings>>(
      SETTINGS_LOAD,
      (
        state: Draft<IStateAccount>,
        action: ActionCase<null | IAccountSettings>
      ) => {
        state.settings = action.payload;
      }
    );
    // sellers action
    builder.addCase<string, ActionCase<string[]>>(
      SELLERS_LOAD,
      (state: Draft<IStateAccount>, action: ActionCase<string[]>) => {
        state.sellers.IDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<ISellerAnalyticsData>>(
      SELLER_DATA_LOAD,
      (
        state: Draft<IStateAccount>,
        action: ActionCase<ISellerAnalyticsData>
      ) => {
        const { sellerID, analytics } = action.payload;
        state.sellers.analyticsByID[sellerID] = analytics;
      }
    );
    builder.addCase<string, ActionCase<DataSearch<string>>>(
      SELLER_SEARCH,
      (state: Draft<IStateAccount>, action: ActionCase<DataSearch<string>>) => {
        const { search, items } = action.payload;
        state.sellers.IDsBySearch[search] = items;
      }
    );
    // partnerSeller action
    builder.addCase<string, ActionCase<IPartnerSellerSet>>(
      PARTNER_SELLER_LOAD_SET,
      (state: Draft<IStateAccount>, action: ActionCase<IPartnerSellerSet>) => {
        Object.keys(action.payload).map((partnerID: string) => {
          const partner: IPartnerSellerPublic = action.payload[partnerID];
          state.partnerSeller.set[partnerID] = partner;
          state.partnerSeller.IDByAccountID[partner.accountID] = partner.id;
        });
      }
    );
    builder.addCase<string, ActionCase<string[]>>(
      PARTNER_SELLER_LOAD_IDS,
      (state: Draft<IStateAccount>, action: ActionCase<string[]>) => {
        state.partnerSeller.IDs = action.payload;
        state.partnerSeller.loaded = state.partnerSeller.IDs.length;
      }
    );
    builder.addCase<string, ActionCase<DataBatch<string>>>(
      PARTNER_SELLER_LOAD_BATCH,
      (state: Draft<IStateAccount>, action: ActionCase<DataBatch<string>>) => {
        const { cnt, items } = action.payload;
        state.partnerSeller.cnt = cnt;
        state.partnerSeller.IDs = state.partnerSeller.IDs.concat(items);
        state.partnerSeller.loaded = state.partnerSeller.IDs.length;
      }
    );
    builder.addCase<string, ActionCase<IPartnerSellerByPath>>(
      PARTNER_SELLER_LOAD_BY_PATH,
      (
        state: Draft<IStateAccount>,
        action: ActionCase<IPartnerSellerByPath>
      ) => {
        const { partner, path } = action.payload;
        if (partner) {
          state.partnerSeller.set[partner.id] = partner;
        }

        state.partnerSeller.IDByPath[path] = partner?.id || null;
      }
    );
    builder.addCase<string, ActionCase<IPartnerSellerByID>>(
      PARTNER_SELLER_LOAD_BY_ID,
      (state: Draft<IStateAccount>, action: ActionCase<IPartnerSellerByID>) => {
        const { partner, accountID } = action.payload;
        if (partner) {
          state.partnerSeller.set[partner.id] = partner;
        }

        state.partnerSeller.IDByAccountID[accountID] = partner?.id || null;
      }
    );
    builder.addCase<string, ActionCase<DataSearch<string>>>(
      PARTNER_SELLER_SEARCH,
      (state: Draft<IStateAccount>, action: ActionCase<DataSearch<string>>) => {
        const { search, items } = action.payload;
        state.partnerSeller.IDsBySearch[search] = items;
      }
    );
    builder.addCase<string, ActionCase<string>>(
      PARTNER_SELLER_ACTION,
      (state: Draft<IStateAccount>, action: ActionCase<string>) => {
        state.partnerSeller.actions.push(action.payload);
      }
    );
  }
);
