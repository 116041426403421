import React, { RefObject, useEffect, useRef, useState } from 'react';
import { BlockActions, BlockPage, BlockPageContent } from '../styles';
import { ContentHead } from '../typos';
import { sendPageEvent } from '../../utils/analytics';
import { FormRemoveReAsk } from '../forms/FormRemoveReAsk';
import ModerationMainHead from './ModerationMainHead';
import { IModerationCb, IModerationDataEdit, IModerationRender } from './Moderation';
import { Block } from '../layout';
import { Loading } from '../animation/Loading';

interface IModerationPageEditProps<ItemPublic> {
  itemID: string | null;
  pageTitle?: string;
  itemName?: string;
  cb?: IModerationCb;
  render: IModerationRender<ItemPublic>;
  dataEdit: IModerationDataEdit<ItemPublic>;
  isAdmin: boolean;
}

export function ModerationPageEdit<ItemPublic>(
  props: IModerationPageEditProps<ItemPublic>,
) {
  const FormRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [removingID, setRemovingID] = useState<null | string>(null);

  useEffect(() => {
    if (props.pageTitle) {
      sendPageEvent(props.pageTitle);
    }
  }, []);
  useEffect(() => {
    if (!props.dataEdit.actions || !props.dataEdit.actions[0] || !removingID) {
      return;
    }

    setRemovingID(null);
  }, [props.dataEdit.actions]);
  useEffect(() => {
    if (!props.dataEdit.errors || !props.dataEdit.errors[0] || !removingID) {
      return;
    }

    setRemovingID(null);
  }, [props.dataEdit.errors]);

  const cancel = () => {
    if (!props.cb || !props.cb.cbCancel) {
      return;
    }
    props.cb.cbCancel();
  };

  const handleDelete = () => {
    if (!removingID || !props.cb || !props.cb.cbDelete) {
      return;
    }
    props.cb.cbDelete(removingID);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />
        {props.pageTitle && <ContentHead>{props.pageTitle}</ContentHead>}

        {props.itemID && (
          <Block ref={FormRef}>
            {props.render.renderForm &&
              props.render.renderForm(
                removingID !== null,
                props.dataEdit.set[props.itemID],
                cancel,
                props.cb && props.cb.cbDelete
                  ? () => setRemovingID(props.itemID)
                  : undefined,
              )}
          </Block>
        )}
        {!props.itemID && <Loading />}

        {removingID &&
          props.render.renderFormRemove &&
          props.render.renderFormRemove(
            undefined,
            props.dataEdit.set[removingID],
            () => setRemovingID(null),
          )}

        {removingID && !props.render.renderFormRemove && (
          <BlockActions>
            <FormRemoveReAsk
              title={props.itemName}
              cbDelete={
                props.cb && props.cb.cbDelete ? handleDelete : undefined
              }
              cbCancel={() => setRemovingID(null)}
            />
          </BlockActions>
        )}
      </BlockPageContent>
    </BlockPage>
  );
}
