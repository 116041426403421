import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IAccountNotification,
  INotificationSet,
} from '../interfaces/account/IAccountNotification';
import { ContentBold, ContentDesc } from '../common/typos';
import { IState } from '../interfaces/system/IState';
import {
  hideNotification,
  incrementNotificationView,
  readNotification,
} from '../actions/account/notification.actions';
import { Loading } from '../common/animation/Loading';
import { filterNotificationsToRole } from '../actions/account/role.actions';
import { NavLink } from 'react-router-dom';
import { appConfig } from '../config/app.config';
import { IconArrow } from '../common/images/Icons';
import { Block } from '../common/layout';
import { prepareAuctionDate } from '../utils/renderParts';
import { BlockActions } from '../common/styles';
import { ImageIcon } from '../common/images/Image.styles';

interface INotificationsProps {
  incrementNotificationView: () => void;
  readNotification: (notificationID: null | string) => void;
  hideNotification: (notificationID: null | string) => void;
  isAdmin: boolean;
  notificationIDs: string[];
  notificationSet: INotificationSet;
}

function Notifications(props: INotificationsProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAccountNotification[]>(
    []
  );
  useEffect(() => {
    if (notifications[0]) {
      props.incrementNotificationView();
    }
  }, [notifications]);

  useEffect(() => {
    setIsLoading(false);
    const { notificationIDs, notificationSet } = props;
    const notificationsLocal: IAccountNotification[] = [];
    notificationIDs.forEach((notificationID: string) => {
      const notification: null | IAccountNotification =
        notificationSet[notificationID] || null;
      if (notification) {
        notificationsLocal.push(notification);
      }
    });

    setNotifications(
      filterNotificationsToRole(notificationsLocal, props.isAdmin)
    );
  }, [props.notificationIDs, props.notificationSet]);

  const readOne = (notificationID: string) => {
    setIsLoading(true);
    props.readNotification(notificationID);
  };
  const readAll = () => {
    setIsLoading(true);
    props.readNotification(null);
  };
  const removeOne = (notificationID: string) => {
    setIsLoading(true);
    props.hideNotification(notificationID);
  };
  const removeAll = () => {
    setIsLoading(true);
    props.hideNotification(null);
  };

  return (
    <Block className="heightHalf">
      {isLoading && <Loading />}

      {notifications[0] && (
        <BlockActions className="inline right">
          <ContentDesc className="clickable" onClick={() => readAll()}>
            read all
            <ImageIcon
              className={'small clickable'}
              src="/icons/read.png"
              alt="read all"
            />
          </ContentDesc>
          <ContentDesc className="clickable" onClick={() => removeAll()}>
            remove all
            <ImageIcon
              className={'small clickable'}
              src="/icons/delete.png"
              alt="hide all"
            />
          </ContentDesc>
        </BlockActions>
      )}

      {notifications.map((notification: IAccountNotification, index) => (
        <Block key={index}>
          {notification.isAdmin && !props.isAdmin && (
            <ContentDesc style={{ color: 'darkred' }}>
              Change role to admin to see it!
            </ContentDesc>
          )}

          {/* todo go to moderation if corresponding type */}
          <Block
            style={{
              opacity: notification.isAdmin && !props.isAdmin ? '20%' : '100%',
            }}
          >
            <ContentDesc
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <ContentBold>{notification.title}</ContentBold>

              <BlockActions className="inline right">
                {!notification.isRead && (
                  <ImageIcon
                    className={'small clickable'}
                    src="/icons/dot.png"
                    alt="unread"
                    onClick={() => readOne(notification.id)}
                  />
                )}

                <ImageIcon
                  className={'small clickable'}
                  src="/icons/delete.png"
                  alt="hide all"
                  onClick={() => removeOne(notification.id)}
                />
              </BlockActions>
            </ContentDesc>
            <ContentDesc
              style={{
                whiteSpace: 'normal',
              }}
              dangerouslySetInnerHTML={{ __html: notification.message }}
            />
            <ContentDesc>{prepareAuctionDate(notification.date)}</ContentDesc>
            {props.isAdmin &&
              notification.isAdmin &&
              notification.type === 'Moderation' && (
                <NavLink to={appConfig.routes.admin.main}>
                  Go to moderation
                  <IconArrow />
                </NavLink>
              )}
            <br />
          </Block>
        </Block>
      ))}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    notificationIDs: state.Notification.IDs,
    notificationSet: state.Notification.set,
  }),
  {
    incrementNotificationView,
    readNotification,
    hideNotification,
  }
)(Notifications);
