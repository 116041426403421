import React, { useEffect, useState } from 'react';
import { Display, Expired, Item } from './CountDown.styles';
import { Block } from '../layout';
import { ContentDesc, ContentHead } from '../typos';
import { Loading } from './Loading';

interface ILoadingProps {
  targetDate: undefined | null | string;
}

const minInMilSec: number = 1000 * 60;
const hoursInMilSec: number = minInMilSec * 60;
const dayInMilSec: number = hoursInMilSec * 24;

export function CountDown(props: ILoadingProps) {
  const [days, setDays] = useState<null | number>(null);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  const [countDown, setCountDown] = useState<number>(0);
  const [countDownDate, setCountDownDate] = useState<null | number>(0);

  useEffect(() => {
    if (!props.targetDate) {
      return;
    }

    setCountDownDate(
      props.targetDate ? new Date(props.targetDate).getTime() : 0,
    );
  }, [props.targetDate]);

  useEffect(() => {
    if (!countDownDate) {
      return;
    }

    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  useEffect(() => {
    if (!countDownDate) {
      return;
    }

    const countDownLocal = countDownDate - new Date().getTime();
    setDays(Math.floor(countDownLocal / dayInMilSec));
    setHours(Math.floor((countDownLocal % dayInMilSec) / hoursInMilSec));
    setMinutes(Math.floor((countDown % hoursInMilSec) / minInMilSec));
    setSeconds(Math.floor((countDown % minInMilSec) / 1000));
  }, [countDown]);

  const renderTimeDisplay = (
    value: number,
    type: 'day' | 'hour' | 'min' | 'sec',
    isDanger: boolean,
  ) => {
    return (
      <Item>
        <ContentHead className={isDanger ? 'fail' : 'success'}>
          {value}
        </ContentHead>
        <ContentDesc className={isDanger ? 'fail' : 'success'}>
          {type}
        </ContentDesc>
      </Item>
    );
  };

  return (
    <>
      {props.targetDate === undefined || days === null ? (
        <Loading size='big' />
      ) : (
        <>
          {((props.targetDate === null ||
            days + hours + minutes + seconds <= 0) && (
            <Expired>
              <ContentHead>Week auction is Complete</ContentHead>
            </Expired>
          )) || (
            <Block>
              <Display>
                {renderTimeDisplay(days, 'day', days <= 1)}
                <ContentHead>:</ContentHead>
                {renderTimeDisplay(hours, 'hour', days <= 1)}
                <ContentHead>:</ContentHead>
                {renderTimeDisplay(minutes, 'min', days <= 1)}
                <ContentHead>:</ContentHead>
                {renderTimeDisplay(seconds, 'sec', days <= 1)}
              </Display>
            </Block>
          )}
        </>
      )}
    </>
  );
}
