import React, { useEffect, useState } from 'react';
import { ContentDescInline } from '../typos';
import { ErrorInfo, Label } from './Form.styles';
import { Block } from '../layout';

interface IFormFieldSelectProps {
  isDisabled?: boolean;
  isMultiple?: boolean;
  values: string[];
  nullNameBeforeSelect?: string;
  defaultValue: null | string[];
  saveValue: (values: string[]) => void;
  valueError: null | string;
  title?: string;
}

export function FormFieldSelect(props: IFormFieldSelectProps) {
  const [selected, setSelected] = useState<null | string>(null);
  const [selectedSet, setSelectedSet] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!props.defaultValue) {
      return;
    }
    if (props.isMultiple) {
      const selectedSetLocal: Record<string, boolean> = {};
      props.defaultValue.forEach((value: string) => {
        selectedSetLocal[value] = true;
      });
      setSelectedSet(selectedSetLocal);
    } else {
      setSelected(props.defaultValue[0]);
    }
  }, [props.defaultValue]);

  const saveValue = (value: string) => {
    setSelected(value);
    props.saveValue([value]);
  };
  const saveValues = (value: string) => {
    const selectedSetLocal = Object.assign({}, selectedSet);
    selectedSetLocal[value] = !selectedSetLocal[value];
    setSelectedSet(selectedSetLocal);
    props.saveValue(
      Object.keys(selectedSetLocal).filter((id: string) => selectedSetLocal[id])
    );
  };

  const values: string[] = (
    props.nullNameBeforeSelect ? [props.nullNameBeforeSelect] : []
  ).concat(props.values);

  return (
    <Block className={'heightM'}>
      {props.title && (
        <ContentDescInline>
          {props.title[0].toUpperCase() + props.title.slice(1)}:{' '}
        </ContentDescInline>
      )}
      {props.valueError && <ErrorInfo>{props.valueError}</ErrorInfo>}
      {values.map((value: string) => (
        <Block key={value}>
          {!props.isMultiple && (
            <Label className={'clickable'}>
              <input
                type={'radio'}
                value={value}
                checked={value === selected}
                onChange={() => saveValue(value)}
                disabled={props.isDisabled}
              />
              {value}
            </Label>
          )}

          {props.isMultiple && (
            <Label className={'clickable'}>
              <input
                type={'checkbox'}
                value={value}
                checked={selectedSet[value] || false}
                onChange={() => saveValues(value)}
                disabled={props.isDisabled}
              />
              {value}
            </Label>
          )}
        </Block>
      ))}
    </Block>
  );
}
