import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import { dispatchError } from '../../service/error';
import { CacheRequests } from '../../service/cacheRequests';
import {
  ILotAdminIDsData,
  ILotAdminIDsResponse,
  ILotAdminResponse,
  ILotAdminSet,
  ILotAdminSetResponse,
  ILotCreateBody,
  ILotIDs,
  ILotSortBody,
  ILotSortItem,
  ILotUpdateBody,
} from '../../interfaces/auctions/ILotAdmin';
import { ILotShortSet, ILotType } from '../../interfaces/auctions/ILotPublic';
import {
  ITEM_CREATED,
  ITEM_UPDATED,
  LOT_ADMIN_ACTION,
  LOT_DETAILS_IDS_LOAD,
  LOT_DETAILS_SET_CLEAR,
  LOT_DETAILS_SET_LOAD,
} from '../types';

const { lotsModeration } = apiConfig.endpoints.auctions;
const cacheByID: CacheRequests = new CacheRequests();

export const loadModerationLotIDs =
  (lotType: ILotType) =>
    (
      dispatch: IDispatch<ILotAdminIDsData | ILotAdminSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      callApi<ILotAdminIDsResponse>(lotsModeration.ids, {
        lotType,
      })
        .then((data: ILotAdminIDsResponse) => {
          const payload: ILotAdminIDsData = {
            lotType,
            lotIDs: data.lotIDs,
          };
          dispatch({ type: LOT_DETAILS_IDS_LOAD, payload });
          loadModerationLotSet(data.lotIDs.map((i: ILotIDs) => i.lotID))(
            dispatch,
            getState,
          );
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadModerationLotOne =
  (lotID: string, hardReload?: boolean) =>
    (
      dispatch: IDispatch<ILotAdminSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      if (!hardReload) {
        if (getState().Lot.moderation.set[lotID]) {
          return;
        }

        const IDsToLoad: string[] = cacheByID.getToLoad([lotID]);
        if (!IDsToLoad[0]) {
          return;
        }
      }

      callApi<ILotAdminResponse>(lotsModeration.one, { lotID })
        .then((data: ILotAdminResponse) => {
          const payload: ILotAdminSet = {};
          payload[lotID] = data.lot;
          dispatch({ type: LOT_DETAILS_SET_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadModerationLotSet =
  (lotIDs: string[]) =>
    (
      dispatch: IDispatch<ILotAdminSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const set: ILotShortSet = getState().Lot.moderation.set;
      const toLoad: string[] = [];
      lotIDs.forEach((lotID: string) => {
        if (!set[lotID]) {
          toLoad.push(lotID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }

      callApi<ILotAdminSetResponse>(lotsModeration.main, {
        lotIDs: IDsToLoad.join(','),
      })
        .then((data: ILotAdminSetResponse) =>
          dispatch({ type: LOT_DETAILS_SET_LOAD, payload: data.set }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const setLot =
  (
    lotType: ILotType,
    productID: string,
    name: string,
    description: string,
    price: number,
    winCnt: number,
    byAlias: string | null,
  ) =>
    (
      dispatch: IDispatch<
        string | ILotAdminIDsData | ILotAdminSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const body: ILotCreateBody = {
        lotType,
        productID,
        name,
        description,
        price,
        winCnt,
      };
      if (byAlias) {
        body.byAlias = byAlias;
      }
      callApi(lotsModeration.main, {}, body)
        .then(() => loadModerationLotIDs(lotType)(dispatch, getState))
        .then(() => dispatch({ type: LOT_ADMIN_ACTION, payload: ITEM_CREATED }))
        .then(() =>
          dispatchError(dispatch, {
            message: 'Lot is created',
            params: {},
            type: 'Info',
          }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const updateLot =
  (
    lotType: ILotType,
    lotID: string,
    name: null | string,
    description: null | string,
    order: null | number,
    price: null | number,
    winCnt: null | number,
    byAlias: null | string,
  ) =>
    (
      dispatch: IDispatch<string | ILotAdminSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const body: ILotUpdateBody = {
        lotType,
        lotID,
      };
      if (name) {
        body.name = name;
      }
      if (description) {
        body.description = description;
      }
      if (order) {
        body.order = order;
      }
      if (price) {
        body.price = price;
      }
      if (winCnt) {
        body.winCnt = winCnt;
      }
      if (byAlias) {
        body.byAlias = byAlias;
      }
      callApi(lotsModeration.main, {}, body, 'POST')
        .then(() => loadModerationLotOne(lotID, true)(dispatch, getState))
        .then(() => dispatch({ type: LOT_ADMIN_ACTION, payload: ITEM_UPDATED }))
        .then(() =>
          dispatchError(dispatch, {
            message: 'Lot is updated',
            params: {},
            type: 'Info',
          }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const resortLots =
  (lotType: ILotType, lotSort: ILotSortItem[]) =>
    (
      dispatch: IDispatch<
        string | ILotAdminIDsData | ILotAdminSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const body: ILotSortBody = {
        lotType,
        lotSort,
      };
      callApi(lotsModeration.sort, {}, body, 'POST')
        .then(() => dispatch({ type: LOT_DETAILS_SET_CLEAR, payload: '' }))
        .then(() => cacheByID.clean())
        .then(() => loadModerationLotIDs(lotType)(dispatch, getState))
        .then(() =>
          dispatchError(dispatch, {
            message: 'Lots order is changed',
            params: {},
            type: 'Info',
          }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const deleteLot =
  (lotType: ILotType, lotID: string) =>
    (
      dispatch: IDispatch<
        string | ILotAdminIDsData | ILotAdminSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      callApi(lotsModeration.main, { lotID, lotType }, undefined, 'DELETE')
        .then(() => loadModerationLotIDs(lotType)(dispatch, getState))
        .then(() => dispatch({ type: LOT_ADMIN_ACTION, payload: ITEM_UPDATED }))
        .then(() =>
          dispatchError(dispatch, {
            message: 'Lot is deleted',
            params: {},
            type: 'Info',
          }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
