import React from 'react';
import { IReportTopSeller } from '../../../interfaces/auctions/IReportAuction';
import { connect } from 'react-redux';
import TopAccount from './TopAccount';
import { ContentDesc, ContentDescInline } from '../../../common/typos';
import { ImageIcon } from '../../../common/images/Image.styles';

interface IAuctionResultProps {
  topSellers: IReportTopSeller[];
}

function TopSellers(props: IAuctionResultProps) {
  return (
    <>
      {props.topSellers.map((seller: IReportTopSeller, index: number) => (
        <ContentDesc key={index}>
          <TopAccount accountID={seller.accountID} />
          <ContentDescInline>{seller.total}</ContentDescInline>
          <ImageIcon className={'small'} src={'/icons/medal.png'} />
        </ContentDesc>
      ))}
    </>
  );
}

export default connect(() => ({}), {})(TopSellers);
