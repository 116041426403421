import {
  IAppRecommendationPublic,
  IAppRecommendationView,
} from '../../../interfaces/communication/IAppRecommendation';
import {
  IParamsItemView,
  RenderElementFunction,
  RenderFormFunction,
  RenderItemPublicFunction,
  RenderItemViewFunction,
} from '../../../common/common';
import { RecommendationItemModeration } from './RecommendationItemModeration';
import RecommendationForm from './RecommendationForm';
import { RecommendationBatchActions } from './RecommendationBatchActions';
import React from 'react';
import { ItemView } from '../../../common/moderation/ItemView';

export const renderRecommendationItemPublic: RenderItemPublicFunction<
  IAppRecommendationPublic
> = (
  item: IAppRecommendationPublic,
  inlineBlock?: JSX.Element,
  ParamsItemView?: IParamsItemView,
) => {
  return (
    <RecommendationItemModeration
      recommendation={item}
      showInline={true}
      inlineBlock={inlineBlock}
      ParamsItemView={ParamsItemView}
    />
  );
};

export const renderRecommendationForm: RenderFormFunction<
  IAppRecommendationPublic
> = (
  isDisabled: undefined | boolean,
  item: undefined | IAppRecommendationPublic,
  cbCancel: () => void,
  cbDelete?: (partnerID: string) => void,
) => {
  return (
    <RecommendationForm
      isDisabled={isDisabled}
      recommendation={item}
      cbCancel={cbCancel}
      cbDelete={cbDelete}
    />
  );
};

export const defaultRecommendationItemView: IAppRecommendationView = {
  name: true,
  type: true,
  emails: true,
  url: true,
  social: true,
  geo: false,
  parsedID: false,
  categories: false,
  createdAt: false,
  engagedAt: false,
};

export const renderRecommendationItemView: RenderItemViewFunction = (
  cb: (params: IAppRecommendationView) => void,
) => {
  return (
    <ItemView
      cb={(params: Record<string, boolean>) =>
        cb(params as IAppRecommendationView)
      }
      defaultParam={defaultRecommendationItemView}
    />
  );
};

export const renderRecommendationBatchActions: RenderElementFunction<
  IAppRecommendationPublic
> = (IDs: string[], set: Record<string, IAppRecommendationPublic>) => {
  return <RecommendationBatchActions IDs={IDs} set={set} />;
};
