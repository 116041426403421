import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import {
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetOptionsResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  loadAnalyticsCronCache,
  loadAnalyticsCronMain,
  saveTechCronCache,
} from './techCron.actions';
import {
  loadAnalyticsDbCache,
  loadAnalyticsDbMain,
  loadAnalyticsDbOptions,
  saveTechDbCache,
} from './techDb.actions';
import {
  loadAnalyticsRouteCache,
  loadAnalyticsRouteMain,
  loadAnalyticsRouteOptions,
  saveTechRouteCache,
} from './techRoute.actions';
import {
  loadAnalyticsRetentionCache,
  loadAnalyticsRetentionMain,
  saveAnalyticsRetentionCache,
} from './behavioralRetention.actions';
import { loadAUAnalytics } from './au.analytics.actions';
import { IRetentionGetResponse } from '../../interfaces/analytics/IRetention';
import {
  loadErrors,
  loadErrorsCache,
  saveErrorsCache,
} from '../system/system.actions';
import {
  IErrorLogGetCacheResponse,
  IErrorLogGetData,
} from '../../interfaces/system/IErrorLog';

export const loadAnalyticsMain =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetResponse
      | IErrorLogGetData
      | IRetentionGetResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAUAnalytics(from, to)(dispatch, getState);
    loadAnalyticsRetentionMain(from, to)(dispatch, getState);
    loadAnalyticsCronMain(from, to)(dispatch, getState);
    loadAnalyticsDbMain(from, to)(dispatch, getState);
    loadAnalyticsRouteMain(from, to)(dispatch, getState);
    loadErrors(from, to)(dispatch, getState);
  };

export const loadAnalyticsOptions =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetOptionsResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsDbOptions(from, to)(dispatch, getState);
    loadAnalyticsRouteOptions(from, to)(dispatch, getState);
  };

export const loadAnalyticsCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IErrorLogGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsRetentionCache(hardReload)(dispatch, getState);
    loadAnalyticsCronCache(hardReload)(dispatch, getState);
    loadAnalyticsDbCache(hardReload)(dispatch, getState);
    loadAnalyticsRouteCache(hardReload)(dispatch, getState);
    loadErrorsCache(hardReload)(dispatch, getState);
  };

export const saveAnalyticsCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsRetentionCache()(dispatch, getState);
    saveTechCronCache()(dispatch, getState);
    saveTechDbCache()(dispatch, getState);
    saveTechRouteCache()(dispatch, getState);
    saveErrorsCache()(dispatch, getState);
  };
