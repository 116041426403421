import styled from 'styled-components';
import { colors } from './common/colors';
import { device, margins, sizes } from './common/sizes';

export const AppBlock = styled.div`
  background-color: ${colors.background};
  min-height: 100vh;
  color: ${colors.main};
`;

export const AppContent = styled.div`
  min-height: calc(100vh - 135px);
  box-sizing: border-box;

  width: ${sizes.full};
  padding: 0 20px;
  display: block;
  position: relative;
  margin: auto;
  text-align: left;
  overflow: auto;

  @media ${device.mobileL} {
    width: 87%;
  }
  @media ${device.tablet} {
    width: 75%;
  }
  @media ${device.laptop} {
    width: 62.5%;
  }
`;

export const PresenterContent = styled.div`
  min-height: calc(100vh - 135px);
  box-sizing: border-box;

  width: ${sizes.full};
  display: block;
  position: relative;
  margin: auto;
  text-align: left;
  overflow: auto;

  @media ${device.mobileL} {
    width: 95%;
  }
  @media ${device.tablet} {
    width: 87%;
  }
  @media ${device.laptop} {
    width: 80%;
  }
`;

export const AppContentPlug = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  padding: 0 ${margins.block};
  width: ${sizes.full};
  box-sizing: border-box;

  @media ${device.mobileL} {
    width: 75%;
  }
  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.laptop} {
    width: 33%;
  }
`;
