import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { Block } from '../../../common/layout';
import { ContentBold, ContentHead, PageHead } from '../../../common/typos';
import { sendPageEvent } from '../../../utils/analytics';
import AccountSearch from '../../../components/account/AccountSearch';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import {
  getDonationsByAccountID,
  getDonationsByAuctionID,
} from '../../../actions/donation/donationAdmin.actions';
import AccountImage from '../../../components/account/AccountImage';
import { IDonation } from '../../../interfaces/donation/IDonationAccount';
import { Loading } from '../../../common/animation/Loading';
import { getAuctionList } from '../../../actions/auctions/auction.actions';
import { IAuction } from '../../../interfaces/auctions/IAuction';
import AccountItemMail from '../../../components/account/AccountItemMail';
import { SelectionPage } from '../../../common/moderation/SelectionPage';
import AuctionItemModeration from '../../../components/auctions/AuctionItemModeration';
import { AccountItemModeration } from '../../../components/account/AccountItemModeration';
import { DonationBillsItem } from '../../../components/donation/DonationBillsItem';
import ModerationMainHead from '../../../common/moderation/ModerationMainHead';
import { BlockPage, BlockPageContent } from '../../../common/styles';

interface IDonationBillsProps {
  getAuctionList: () => void;
  getDonationsByAccountID: (accountID: string) => void;
  getDonationsByAuctionID: (auctionID: string) => void;
  auctionsIDs: string[];
  auctionsByID: Record<string, IAuction>;
  accounts: IAccountSet;
  byAccountID: Record<string, IDonation[]>;
  byAuctionID: Record<string, IDonation[]>;
  isAdmin: boolean;
}

function DonationBills(props: IDonationBillsProps) {
  const [accountID, setAccountID] = useState<null | string>(null);
  const [auctionID, setAuctionID] = useState<null | string>(null);
  const [auctionAccountID, setAuctionAccountID] = useState<null | string>(null);
  const [auctionAccountIDs, setAuctionAccountIDs] = useState<string[]>([]);
  const [auction, setAuction] = useState<null | IAuction>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [donations, setDonations] = useState<null | IDonation[]>(null);
  const [filteredDonations, setFilteredDonations] = useState<
    null | IDonation[]
  >(null);

  useEffect(() => sendPageEvent('DonationBillsModeration'), []);
  useEffect(() => props.getAuctionList(), []);
  useEffect(() => {
    setDonations(null);
    if (!accountID) {
      return;
    }

    props.getDonationsByAccountID(accountID);
    setIsLoading(true);
  }, [accountID]);
  useEffect(() => {
    setDonations(null);
    setAuction((auctionID && props.auctionsByID[auctionID]) || null);

    if (!auctionID) {
      return;
    }

    props.getDonationsByAuctionID(auctionID);
    setIsLoading(true);
  }, [auctionID, props.auctionsByID]);
  useEffect(() => {
    setIsLoading(false);
    setAuctionID(null);
    setDonations((accountID && props.byAccountID[accountID]) || null);
  }, [accountID, props.byAccountID]);
  useEffect(() => {
    setAccountID(null);
    const accountIDSet: Record<string, boolean> = {};
    const donationsLocal: null | IDonation[] =
      (auctionID && props.byAuctionID[auctionID]) || null;
    if (donationsLocal) {
      donationsLocal.forEach((donation: IDonation) => {
        accountIDSet[donation.buyerID] = true;
      });
    }

    setDonations(donationsLocal);
    setAuctionAccountIDs(Object.keys(accountIDSet));
    setIsLoading(false);
  }, [auctionID, props.byAuctionID]);

  const saveAuctionAccountID = (accountID: string): void => {
    setAuctionAccountID(accountID || null);
    if (!donations || !accountID) {
      setFilteredDonations(null);
      return;
    }

    setFilteredDonations(
      donations.filter((donation: IDonation) => donation.buyerID === accountID)
    );
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />

        <PageHead>Donation Bills</PageHead>

        <ContentHead>Select Auction:</ContentHead>
        <SelectionPage
          renderItemPublic={(auction: IAuction) => (
            <AuctionItemModeration auction={auction} showInline={true} />
          )}
          data={{
            set: props.auctionsByID,
            list: {
              load: props.getAuctionList,
              IDs: props.auctionsIDs || [],
            },
          }}
          isAdmin={props.isAdmin}
          action={{
            showInline: true,
            hideSelected: true,
          }}
          selection={{
            cbSelected: setAuctionID,
            selectedIDs: auctionID ? [auctionID] : null,
          }}
        />

        {auction && <AuctionItemModeration auction={auction} />}

        <SelectionPage
          renderItemPublic={(account: IAccount) => (
            <AccountItemModeration account={account} showInline={true} />
          )}
          data={{
            set: props.accounts,
            list: {
              IDs: auctionAccountIDs || [],
            },
          }}
          isAdmin={props.isAdmin}
          action={{
            showInline: true,
            hideSelected: true,
          }}
          selection={{
            cbSelected: saveAuctionAccountID,
            selectedIDs: auctionAccountID ? [auctionAccountID] : null,
          }}
        />

        <ContentHead>By Account:</ContentHead>
        <AccountSearch
          mode={'account'}
          cbSelected={(accountID: string) => setAccountID(accountID)}
          selectedIDs={(accountID && [accountID]) || null}
        />

        {accountID && (
          <Block>
            <AccountImage accountID={accountID} size={'S'} />

            <AccountItemMail accountID={accountID} />

            {isLoading && <Loading />}
          </Block>
        )}

        {(filteredDonations || donations || []).map((donation: IDonation) => (
          <DonationBillsItem donation={donation} key={donation.id} />
        ))}

        {accountID && (!donations || !donations[0]) && !isLoading && (
          <Block>
            <ContentBold>Account has no donations</ContentBold>
          </Block>
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    accounts: state.Account.accounts.set,
    auctionsIDs: state.Auction.auctions.IDs,
    auctionsByID: state.Auction.auctions.set,
    byAccountID: state.Donation.byAccountID,
    byAuctionID: state.Donation.byAuctionID,
  }),
  {
    getAuctionList,
    getDonationsByAccountID,
    getDonationsByAuctionID,
  }
)(DonationBills);
