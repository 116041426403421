import styled from 'styled-components';
import { margins } from '../sizes';

export const Display = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${margins.block};
`;

export const Expired = styled.div`
  text-align: center;
  padding: ${margins.blockX2};
`;
