import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  EMAIL_LOG_ACTION,
  EMAIL_LOG_LOAD_BATCH,
  EMAIL_LOG_LOAD_IDS,
  EMAIL_LOG_LOAD_SET,
  MAILING_ACTION,
  MAILING_LOAD_BATCH,
  MAILING_LOAD_IDS,
  MAILING_LOAD_PREVIEW,
  MAILING_LOAD_SET,
  MAILING_LOAD_SETTINGS,
  RECOMMENDATION_ACTION,
  RECOMMENDATION_LOAD_BATCH,
  RECOMMENDATION_LOAD_IDS,
  RECOMMENDATION_LOAD_SET,
  RECOMMENDATION_SEARCH_LOAD,
  TEMPLATE_PARAMS_ACTION,
  TEMPLATE_PARAMS_LOAD_BATCH,
  TEMPLATE_PARAMS_LOAD_IDS,
  TEMPLATE_PARAMS_LOAD_SET,
  TEMPLATE_PARAMS_SEARCH_LOAD,
} from '../../actions/types';
import { ActionCase, IStateCommunication } from '../../interfaces/system/IState';
import { IMailingSet, IMailingSettingsResponse } from '../../interfaces/communication/IMailing';
import {
  IAppRecommendationGroups,
  IAppRecommendationSet,
  IAppReportLotSearchData,
} from '../../interfaces/communication/IAppRecommendation';
import { IEmailLogBatchData, IEmailLogSet } from '../../interfaces/communication/IEmailLog';
import { ITemplateParamsSet } from '../../interfaces/communication/ITemplateParams';
import { DataBatchKey, DataBatchKeyExtend, DataBatchWide } from '../../interfaces/system/data';

const initialStateCommunication: IStateCommunication = {
  Mailing: {
    set: {},
    mailingIDs: [],
    limit: 10,
    cnt: null,
    settings: null,
    preview: null,
    byBatchKey: {},
    actions: [],
  },
  Recommendation: {
    set: {},
    IDs: [],
    limit: 10,
    byBatchKey: {},
    IDsBySearch: {},
    groups: null,
    actions: [],
  },
  TemplateParams: {
    IDs: [],
    IDsBySearch: {},
    set: {},
    actions: [],
    byBatchKey: {},
    limit: 10,
  },
  Log: {
    set: {},
    logIDs: [],
    limit: 10,
    cnt: null,
    byBatchKey: {},
    actions: [],
  },
};

export const communicationReducer: Reducer = createReducer(
  initialStateCommunication,
  (builder: ActionReducerMapBuilder<IStateCommunication>) => {
    // mailing action
    builder.addCase<string, ActionCase<IMailingSet>>(
      MAILING_LOAD_SET,
      (state: Draft<IStateCommunication>, action: ActionCase<IMailingSet>) => {
        Object.keys(action.payload).map((mailingID: string) => {
          state.Mailing.set[mailingID] = action.payload[mailingID];
        });
      },
    );
    builder.addCase<string, ActionCase<string[]>>(
      MAILING_LOAD_IDS,
      (state: Draft<IStateCommunication>, action: ActionCase<string[]>) => {
        state.Mailing.mailingIDs = action.payload;
      },
    );
    builder.addCase<string, ActionCase<IMailingSettingsResponse>>(
      MAILING_LOAD_SETTINGS,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<IMailingSettingsResponse>,
      ) => {
        state.Mailing.settings = action.payload;
      },
    );
    builder.addCase<string, ActionCase<DataBatchKey<string>>>(
      MAILING_LOAD_BATCH,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<DataBatchKey<string>>,
      ) => {
        const { cnt, items, batchKey } = action.payload;

        state.Mailing.cnt = cnt;
        const { byBatchKey } = state.Mailing;
        if (!byBatchKey[batchKey]) {
          byBatchKey[batchKey] = {
            loaded: 0,
            IDs: [],
            cnt: 0,
          };
        }
        const batchSet: DataBatchWide = byBatchKey[batchKey];
        batchSet.loaded += items.length;
        batchSet.IDs = batchSet.IDs.concat(items);
        batchSet.cnt = cnt;
      },
    );
    builder.addCase<string, ActionCase<string>>(
      MAILING_LOAD_PREVIEW,
      (state: Draft<IStateCommunication>, action: ActionCase<string>) => {
        state.Mailing.preview = action.payload;
      },
    );
    builder.addCase<string, ActionCase<string>>(
      MAILING_ACTION,
      (state: Draft<IStateCommunication>, action: ActionCase<string>) => {
        state.Mailing.actions.push(action.payload);
      },
    );
    // recommendation action
    builder.addCase<string, ActionCase<string[]>>(
      RECOMMENDATION_LOAD_IDS,
      (state: Draft<IStateCommunication>, action: ActionCase<string[]>) => {
        state.Recommendation.IDs = action.payload;
      },
    );
    builder.addCase<string, ActionCase<IAppRecommendationSet>>(
      RECOMMENDATION_LOAD_SET,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<IAppRecommendationSet>,
      ) => {
        const set: IAppRecommendationSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.Recommendation.set[id] = set[id];
        });
      },
    );
    builder.addCase<
      string,
      ActionCase<DataBatchKeyExtend<string, IAppRecommendationGroups>>
    >(
      RECOMMENDATION_LOAD_BATCH,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<DataBatchKeyExtend<string, IAppRecommendationGroups>>,
      ) => {
        const { cnt, items, batchKey, data } = action.payload;

        const { byBatchKey } = state.Recommendation;
        state.Recommendation.groups = data;
        if (!byBatchKey[batchKey]) {
          byBatchKey[batchKey] = {
            loaded: 0,
            IDs: [],
            cnt: 0,
          };
        }
        const batchSet: DataBatchWide = byBatchKey[batchKey];
        batchSet.loaded += items.length;
        batchSet.IDs = batchSet.IDs.concat(items);
        batchSet.cnt = cnt;
      },
    );
    builder.addCase<string, ActionCase<IAppReportLotSearchData>>(
      RECOMMENDATION_SEARCH_LOAD,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<IAppReportLotSearchData>,
      ) => {
        const { search, recommendationIDs } = action.payload;
        state.Recommendation.IDsBySearch[search] = recommendationIDs;
      },
    );
    builder.addCase<string, ActionCase<string>>(
      RECOMMENDATION_ACTION,
      (state: Draft<IStateCommunication>, action: ActionCase<string>) => {
        state.Recommendation.actions.unshift(action.payload);
      },
    );
    // template params action
    builder.addCase<string, ActionCase<string[]>>(
      TEMPLATE_PARAMS_LOAD_IDS,
      (state: Draft<IStateCommunication>, action: ActionCase<string[]>) => {
        state.TemplateParams.IDs = action.payload;
      },
    );
    builder.addCase<string, ActionCase<ITemplateParamsSet>>(
      TEMPLATE_PARAMS_LOAD_SET,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<ITemplateParamsSet>,
      ) => {
        const set: ITemplateParamsSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.TemplateParams.set[id] = set[id];
        });
      },
    );
    builder.addCase<string, ActionCase<DataBatchKey<string>>>(
      TEMPLATE_PARAMS_LOAD_BATCH,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<DataBatchKey<string>>,
      ) => {
        const { cnt, items, batchKey } = action.payload;

        const { byBatchKey } = state.TemplateParams;
        if (!byBatchKey[batchKey]) {
          byBatchKey[batchKey] = {
            loaded: 0,
            IDs: [],
            cnt: 0,
          };
        }
        const batchSet: DataBatchWide = byBatchKey[batchKey];
        batchSet.loaded += items.length;
        batchSet.IDs = batchSet.IDs.concat(items);
        batchSet.cnt = cnt;
      },
    );
    builder.addCase<string, ActionCase<IAppReportLotSearchData>>(
      TEMPLATE_PARAMS_SEARCH_LOAD,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<IAppReportLotSearchData>,
      ) => {
        const { search, recommendationIDs } = action.payload;
        state.TemplateParams.IDsBySearch[search] = recommendationIDs;
      },
    );
    builder.addCase<string, ActionCase<string>>(
      TEMPLATE_PARAMS_ACTION,
      (state: Draft<IStateCommunication>, action: ActionCase<string>) => {
        state.TemplateParams.actions.unshift(action.payload);
      },
    );
    // emailLog action
    builder.addCase<string, ActionCase<IEmailLogSet>>(
      EMAIL_LOG_LOAD_SET,
      (state: Draft<IStateCommunication>, action: ActionCase<IEmailLogSet>) => {
        Object.keys(action.payload).map((logID: string) => {
          state.Log.set[logID] = action.payload[logID];
        });
      },
    );
    builder.addCase<string, ActionCase<string[]>>(
      EMAIL_LOG_LOAD_IDS,
      (state: Draft<IStateCommunication>, action: ActionCase<string[]>) => {
        state.Log.logIDs = action.payload;
      },
    );
    builder.addCase<string, ActionCase<IEmailLogBatchData>>(
      EMAIL_LOG_LOAD_BATCH,
      (
        state: Draft<IStateCommunication>,
        action: ActionCase<IEmailLogBatchData>,
      ) => {
        const { cnt, items, batchKey } = action.payload;

        state.Log.cnt = cnt;
        const { byBatchKey } = state.Log;
        if (!byBatchKey[batchKey]) {
          byBatchKey[batchKey] = {
            loaded: 0,
            IDs: [],
            cnt: 0,
          };
        }
        const batchSet: DataBatchWide = byBatchKey[batchKey];
        batchSet.loaded += items.length;
        batchSet.IDs = batchSet.IDs.concat(items);
        batchSet.cnt = cnt;
      },
    );
    builder.addCase<string, ActionCase<string>>(
      EMAIL_LOG_ACTION,
      (state: Draft<IStateCommunication>, action: ActionCase<string>) => {
        state.Log.actions.push(action.payload);
      },
    );
  },
);
