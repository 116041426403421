import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { NOTIFICATION_VIEW, NOTIFICATIONS_IDS_LOAD, NOTIFICATIONS_ONE_LOAD, NOTIFICATIONS_SET_LOAD } from '../types';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAccountNotification,
  IAccountNotificationUpdateBody,
  INotificationIDsResponse,
  INotificationOneResponse,
  INotificationSet,
  INotificationSetResponse,
} from '../../interfaces/account/IAccountNotification';
import { IServiceError } from '../../interfaces/system/IError';
import { CacheRequests } from '../../service/cacheRequests';

const { notifications } = apiConfig.endpoints.account;
const cacheByID: CacheRequests = new CacheRequests();

let getNotificationsInterval: any;

export const getNotificationsIDs =
  () =>
    (
      dispatch: IDispatch<string[] | INotificationSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.account) {
        return;
      }

      if (!getNotificationsInterval) {
        getNotificationsInterval = setInterval(() => {
          getNotificationsIDs()(dispatch, getState);
        }, 5000);
      }

      const { Lot, Report } = getState();
      if (Lot.short.currentLotID || Report.lot || Report.auctionLive) {
        return;
      }

      callApi<INotificationIDsResponse>(notifications.ids, {})
        .then((data: INotificationIDsResponse) => {
          getNotificationsSet(data.IDs)(dispatch, getState);
          dispatch({ type: NOTIFICATIONS_IDS_LOAD, payload: data.IDs });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState, true),
        );
    };

export const getNotificationsOne =
  (notificationID: string) =>
    (
      dispatch: IDispatch<null | IAccountNotification | IServiceError>,
      getState: () => IState,
    ) => {
      const toLoad: string[] = [];
      const set: INotificationSet = getState().Notification.set;
      if (!set[notificationID]) {
        toLoad.push(notificationID);
      }
      if (!toLoad[0]) {
        return;
      }

      const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }

      callApi<INotificationOneResponse>(notifications.one, { notificationID })
        .then((data: INotificationOneResponse) =>
          dispatch({ type: NOTIFICATIONS_ONE_LOAD, payload: data.notification }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

const getNotificationsSet =
  (notificationIDs: string[]) =>
    (
      dispatch: IDispatch<INotificationSet | IServiceError>,
      getState: () => IState,
    ) => {
      const toLoad: string[] = [];
      const set: INotificationSet = getState().Notification.set;
      notificationIDs.forEach((notificationID: string) => {
        if (!set[notificationID]) {
          toLoad.push(notificationID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }

      callApi<INotificationSetResponse>(notifications.main, {
        notificationIDs: IDsToLoad.join(','),
      })
        .then((data: INotificationSetResponse) =>
          dispatch({ type: NOTIFICATIONS_SET_LOAD, payload: data.set }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const incrementNotificationView =
  () => (dispatch: IDispatch<null | IServiceError>, getState: () => IState) => {
    if (getState().Notification.isViewIncremented) {
      return;
    }

    callApi(notifications.main, {}, {})
      .then(() => dispatch({ type: NOTIFICATION_VIEW, payload: null }))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState),
      );
  };

export const readNotification =
  (notificationID: null | string) =>
    (
      dispatch: IDispatch<string[] | INotificationSet | IServiceError>,
      getState: () => IState,
    ) => {
      const body: IAccountNotificationUpdateBody = {
        notificationID,
        action: 'read',
      };

      callApi(notifications.main, {}, body, 'POST')
        .then(() => getNotificationsIDs()(dispatch, getState))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const hideNotification =
  (notificationID: null | string) =>
    (
      dispatch: IDispatch<string[] | INotificationSet | IServiceError>,
      getState: () => IState,
    ) => {
      const body: IAccountNotificationUpdateBody = {
        notificationID,
        action: 'hide',
      };

      callApi(notifications.main, {}, body, 'POST')
        .then(() => getNotificationsIDs()(dispatch, getState))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
