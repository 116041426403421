import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import { FormsFields } from '../../../common/forms/forms';
import { declineProduct } from '../../../actions/moderation/admin-shop-moderation.actions';

interface IDraftFormDeclineProps {
  productDraftID: string;
  isDisabled?: boolean;
  declineProduct: (productDraftID: string, reason: string) => void;
  cbCancel: () => void;
  actions: string[];
  errors: string[];
}

function DraftFormDecline(props: IDraftFormDeclineProps) {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields] = useState<IFormFields<{ reason: string }>>({
    reason: {
      ...FormsFields.description,
    },
  });

  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbDelete = () => {
    props.declineProduct(
      props.productDraftID,
      (fields.reason && fields.reason.$value) || '',
    );
  };

  return (
    <FormBlock>
      <FormCommon
        placeholderTitle={'auction draft'}
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          // cbUpdate,
          cbDelete,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: true,
          isSending,
          // editActionTitle: 'Create product',
          removeActionTitle: 'Decline product',
        }}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    errors: state.System.errors,
  }),
  {
    declineProduct,
  },
)(DraftFormDecline);
