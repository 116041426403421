import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { IReportAuctionUpdate } from '../../interfaces/auctions/IReportAuction';
import AuctionResult from '../../components/auctions/reports/ResultAuction';
import { AppContent } from '../../App.styles';
import ResultAuctionAdminActions from '../../components/auctions/reports/ResultAuctionAdminActions';
import { getGifSrc, Gif } from '../../common/animation/Gif';
import { sendPageEvent } from '../../utils/analytics';
import { IAuction } from '../../interfaces/auctions/IAuction';

interface IAuctionResultPageProps {
  isAdmin: boolean;
  currentLotID: undefined | null | string;
  reportAuctionLive: undefined | null | IReportAuctionUpdate;
  auctionNext: undefined | null | IAuction;
}

function ResultAuctionPage(props: IAuctionResultPageProps) {
  const navigate = useNavigate();
  useEffect(() => sendPageEvent('ResultAuction'), []);
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    if (props.currentLotID) {
      navigate(appConfig.routes.live.auction);
    } else if (props.auctionNext === null) {
      navigate(appConfig.routes.live.resultFinal);
    }
  }, [props.currentLotID, props.auctionNext]);

  const [gifSrc, setGifSrc] = useState<null | string>(null);
  useEffect(() => {
    const src = getGifSrc('resultAuction');
    setGifSrc(src);
    setTimeout(() => setGifSrc(null), 3000);
  }, []);

  return (
    <AppContent>
      <AuctionResult />
      {gifSrc && <Gif src={gifSrc} />}
      {props.isAdmin && <ResultAuctionAdminActions />}
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    currentLotID: state.Lot.short.currentLotID,
    reportAuctionLive: state.Report.auctionLive,
    auctionNext: state.Auction.auctionNext,
  }),
  {},
)(ResultAuctionPage);
