import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { ButtonMain } from '../../common/styles';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageDesc,
} from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../common/animation/Loading';
import AuctionInfo from '../../components/auctions/AuctionInfo';
import { getAuctionIDByPath } from '../../utils/archive';
import {
  IAuction,
  IAuctionDonationItem,
} from '../../interfaces/auctions/IAuction';
import { appConfig } from '../../config/app.config';
import { sendPageEvent } from '../../utils/analytics';
import { Block } from '../../common/layout';
import { L } from '../../common/locale';

interface IArchiveAuctionDonationsPageProps {
  IDByPath: Record<string, string>;
  ByID: Record<string, IAuction>;
}

function ArchiveAuctionDonationsPage(props: IArchiveAuctionDonationsPageProps) {
  const navigate = useNavigate();
  const { auctionPath } = useParams();

  const [auctionID, setAuctionID] = useState<null | string>(null);
  const [auction, setAuction] = useState<null | IAuction>(null);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  useEffect(() => sendPageEvent('ArchiveAuctionDonations'), []);
  useEffect(() => {
    if (!Object.keys(props.IDByPath).length) {
      return;
    }

    const id: null | string = getAuctionIDByPath(auctionPath, props.IDByPath);
    setAuctionID(id);
    if (id && props.ByID[id]) {
      setAuction(props.ByID[id]);
      setIsComplete(props.ByID[id].isComplete);
    }
  }, [props.IDByPath]);

  const goToShop = () => {
    navigate(appConfig.routes.shopProducts.list);
  };

  return (
    <AppContent>
      <Block>
        <AuctionInfo showAllActions={false} hideDonationLink={true} />

        {auctionID ? (
          <>
            {isComplete ? (
              <>
                <br />
                <PageDesc>
                  <L k={'AuctionsDonations'} />
                </PageDesc>

                {auction && auction.donations && (
                  <>
                    {auction.donations.map(
                      (donation: IAuctionDonationItem, index: number) => (
                        <Block key={index}>
                          <ContentDesc>
                            <ContentBold>{donation.amount}</ContentBold> -{' '}
                            {donation.target}
                          </ContentDesc>
                        </Block>
                      )
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <br />
                <br />
                <ContentHead>
                  <L k={'AuctionsDonationsPlace'} />
                </ContentHead>

                <br />
                <ButtonMain onClick={goToShop}>
                  <L k={'ActionGoToShop'} />
                </ButtonMain>
              </>
            )}
          </>
        ) : (
          <Loading size={'big'} />
        )}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    ByID: state.Auction.auctions.set,
    IDByPath: state.Auction.auctions.IDByPath,
  }),
  {}
)(ArchiveAuctionDonationsPage);
