import React, { useEffect, useRef, useState } from 'react';
import {
  AppHeader,
  AppHeaderBlock,
  MenuBlock,
  MenuItemAdmin,
} from './Header.styles';
import { NavLink, useNavigate } from 'react-router-dom';
import { appConfig } from '../config/app.config';
import {
  ContentDescInline,
  ContentLi,
  ContentUl,
  PageHead,
} from '../common/typos';
import { connect } from 'react-redux';
import { IState } from '../interfaces/system/IState';
import Notifications from './Notifications';
import { getNotificationsIDs } from '../actions/account/notification.actions';
import { IAuction, IAuctionMode } from '../interfaces/auctions/IAuction';
import { colors } from '../common/colors';
import { IAccount } from '../interfaces/account/IAccount';
import { savePathBeforeLogin, signOut } from '../actions/account/user.actions';
import { setContainerWidth } from '../actions/system/system.actions';
import { ActionLink } from '../common/styles';
import { Block } from '../common/layout';
import { IProductPreviewOrder } from '../interfaces/shop/IProduct';
import { loadProductsBatchNext } from '../actions/shop/product.actions';
import {
  getAuctionMode,
  getAuctionNextWeekEnd,
} from '../actions/auctions/auction.actions';
import { loadPartnerSellerByAccountID } from '../actions/account/partnerSeller.actions';
import { ImageIcon } from '../common/images/Image.styles';
import { DataBatchWide } from '../interfaces/system/data';
import { checkIsExample, getPlatformHeader, getRewriteConfig } from '../config/platform.config';
import i18next from 'i18next';
import { L } from '../common/locale';

interface IHeaderProps {
  getAuctionMode: () => void;
  getNotificationsIDs: () => void;
  getAuctionNextWeekEnd: () => void;
  setContainerWidth: (width: number) => void;
  loadProductsBatchNext: (order: IProductPreviewOrder) => any;
  loadPartnerSellerByAccountID: (accountID: string) => void;
  cntNew: number;
  cntAll: number;
  notificationIDs: string[];
  isAdmin: boolean;
  auctionNext: undefined | null | IAuction;
  errors: string[];
  account: null | IAccount;
  batch: Record<IProductPreviewOrder, DataBatchWide>;
  weekLotIDs: undefined | string[];
  partnerIDByAccountID: Record<string, null | string>;
  auctionMode: undefined | null | IAuctionMode;
}

function Header(props: IHeaderProps) {
  const headerRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [marginTop, setMarginTop] = useState<number>(0);
  const [marginRight, setMarginRight] = useState<number>(0);
  const [isActiveNotifications, setIsActiveNotifications] =
    useState<boolean>(false);
  const [isActiveLeft, setIsActiveLeft] = useState<boolean>(false);
  const [isActiveRight, setIsActiveRight] = useState<boolean>(false);
  const [isActiveLanguage, setIsActiveLanguage] = useState<boolean>(false);
  const [isExample] = useState<boolean>(checkIsExample());
  const [isPartner, setIsPartner] = useState<boolean>(false);

  useEffect(() => {
    if (props.errors[0] && !props.account) {
      savePathBeforeLogin(window.location.pathname);
      navigate(appConfig.routes.main.login);
    }
  }, [props.errors, props.account]);
  useEffect(() => {
    if (
      !props.notificationIDs ||
      (!props.notificationIDs[0] && isActiveNotifications)
    ) {
      setIsActiveNotifications(false);
    }
  }, [props.notificationIDs]);
  useEffect(() => {
    props.getAuctionMode();
    props.getAuctionNextWeekEnd();
    props.getNotificationsIDs();
    if (props.batch.new.loaded === 0) {
      props.loadProductsBatchNext('new');
    }

    if (props.account) {
      props.loadPartnerSellerByAccountID(props.account.id);
    }
  }, [props.account]);
  useEffect(() => {
    if (headerRef.current) {
      setMarginTop(-headerRef.current.clientHeight / 2 + 15);
      // setMarginRight((window.innerWidth - headerRef.current.clientWidth) / 2);
      props.setContainerWidth(headerRef.current.clientWidth);
    }
  }, [headerRef]);
  useEffect(() => {
    if (props.auctionMode === undefined) {
      return;
    }

    if (
      props.auctionMode === 'reportFinal' ||
      props.auctionMode === 'weekAuction'
    ) {
      setShowMenu(true);
    } else if (props.auctionMode && !isExample) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [props.auctionMode]);
  useEffect(() => {
    if (!props.account) {
      return;
    }
    const partnerID: null | string =
      props.partnerIDByAccountID[props.account.id];
    if (partnerID) {
      setIsPartner(true);
    }
  }, [props.partnerIDByAccountID]);

  const toggleLeft = () => {
    setIsActiveRight(false);
    setIsActiveNotifications(false);
    setIsActiveLeft(!isActiveLeft);
  };
  const toggleRight = () => {
    setIsActiveLeft(false);
    setIsActiveNotifications(false);
    setIsActiveRight(!isActiveRight);
  };
  const toggleNotifications = () => {
    setIsActiveLeft(false);
    setIsActiveRight(false);
    setIsActiveNotifications(!isActiveNotifications);
  };

  const goToHome = () => {
    if (
      props.auctionMode &&
      props.auctionMode !== 'weekAuction' &&
      props.auctionMode !== 'reportFinal' &&
      !isExample
    ) {
      return;
    }

    if (props.auctionMode == 'weekAuction' && getRewriteConfig()?.hardRedirectToWeek) {
      navigate(appConfig.routes.week.auction);
      return;
    }

    navigate(appConfig.routes.main.home);
  };
  const goToLogin = () => {
    savePathBeforeLogin(window.location.pathname);
    navigate(appConfig.routes.main.login);
  };

  const changeLanguage = (lng: 'ua' | 'en') => {
    i18next.changeLanguage(lng);
    setIsActiveLanguage(false);
  };

  return (
    <AppHeaderBlock>
      <Block>
        <AppHeader ref={headerRef} className={showMenu ? 'full' : 'empty'}>
          {showMenu &&
            (!getRewriteConfig()?.hardRedirectToWeek || props.isAdmin) &&
            (
              <Block
                className={
                  'flex noWidth ' + (props.auctionMode && isExample ? 'hide' : '')
                }
              >
                {!isActiveLeft && (
                  <ImageIcon
                    src="/icons/menu.png"
                    alt="user"
                    onClick={() => toggleLeft()}
                    className={'clickable'}
                  />
                )}
                {isActiveLeft && (
                  <ImageIcon
                    src="/icons/cancel.png"
                    alt="user"
                    onClick={() => toggleLeft()}
                    className={'clickable'}
                  />
                )}
              </Block>
            )}

          {showMenu && !(!getRewriteConfig()?.hardRedirectToWeek || props.isAdmin) && (
            <Block className={'flex noWidth'} />)}

          <PageHead
            style={{ margin: '1rem', padding: 0 }}
            className="clickable"
            onClick={goToHome}
          >
            {getPlatformHeader()}
          </PageHead>

          {showMenu && (
            <Block
              className={
                'flex noWidth ' + (props.auctionMode && isExample ? 'hide' : '')
              }
            >
              <>
                {!isActiveLanguage && (
                  <ActionLink onClick={() => setIsActiveLanguage(true)}>
                    {i18next.language}
                  </ActionLink>
                )}

                {isActiveLanguage && (
                  <ContentUl className={'noMargin'}>
                    <ContentLi>
                      <ActionLink
                        onClick={() => changeLanguage('ua')}
                        className={i18next.language === 'ua' ? 'main' : ''}
                      >
                        ua
                      </ActionLink>
                    </ContentLi>

                    <ContentLi>
                      <ActionLink
                        onClick={() => changeLanguage('en')}
                        className={i18next.language === 'en' ? 'main' : ''}
                      >
                        en
                      </ActionLink>
                    </ContentLi>
                  </ContentUl>
                )}
              </>

              {/*{props.account && (
                <ContentDescInline>
                  hi, {props.account.name.split(' ')[0]}
                </ContentDescInline>
              )}*/}
              {!props.account && (
                <ContentDescInline onClick={goToLogin}>
                  <ActionLink>log in</ActionLink>
                </ContentDescInline>
              )}

              {props.cntAll !== 0 && (
                <div style={{ float: 'left', display: 'flex' }}>
                  {props.cntNew === 0 && (
                    <ImageIcon
                      onClick={() => toggleNotifications()}
                      src="/icons/bell_empty.png"
                      alt="user"
                      className={'clickable'}
                    />
                  )}
                  {props.cntNew !== 0 && (
                    <ContentDescInline style={{ color: colors.danger }}>
                      {props.cntNew}
                    </ContentDescInline>
                  )}
                  {props.cntNew !== 0 && (
                    <ImageIcon
                      onClick={() => toggleNotifications()}
                      src="/icons/bell_data.png"
                      alt="user"
                      className={'clickable'}
                    />
                  )}
                </div>
              )}
              {(!getRewriteConfig()?.hardRedirectToWeek || props.isAdmin) &&
                <ImageIcon
                  src="/icons/user.png"
                  alt="user"
                  onClick={() => toggleRight()}
                  className={'clickable'}
                />}
            </Block>
          )}
        </AppHeader>

        {showMenu && (
          <MenuBlock
            onClick={() => toggleLeft()}
            className={isActiveLeft ? 'active' : ''}
            style={{
              marginTop: marginTop + 'px',
            }}
          >
            {!getRewriteConfig()?.hideLiveAuction
              && <>
                {props.auctionNext ? (
                  <NavLink to={appConfig.routes.live.auction} className="main">
                    <L k={'MenuAuctionLive'} />
                  </NavLink>
                ) : (
                  <ActionLink className="disabled">
                    <L k={'MenuAuctionLive'} />
                  </ActionLink>
                )}
              </>
            }
            {/*todo check week auction is ready*/}
            {props.weekLotIDs && props.weekLotIDs[0] ? (
              <NavLink to={appConfig.routes.week.auction} className="main">
                <L k={'MenuAuctionWeek'} />
              </NavLink>
            ) : (
              <ActionLink className="disabled">
                <L k={'MenuAuctionWeek'} />
              </ActionLink>
            )}
            {!getRewriteConfig()?.hideHall
              && <NavLink to={appConfig.routes.hall.legends}>
                <L k={'MenuHall'} />
              </NavLink>
            }
            {!getRewriteConfig()?.hideAuctions
              && <NavLink to={appConfig.routes.auctions.list}>
                <L k={'MenuAuctions'} />
              </NavLink>
            }
            <NavLink to={appConfig.routes.shopProducts.list}>
              <L k={'MenuShop'} />
            </NavLink>
            {/*{props.auctionNext && (*/}
            {!getRewriteConfig()?.hideAddLot
              && <NavLink to={appConfig.routes.shop.addLot}>
                <L k={'ButtonLotAdd'} />
              </NavLink>
            }
            {/*)}*/}
            {props.isAdmin && (
              <MenuItemAdmin>
                <NavLink to={appConfig.routes.admin.main}>
                  <L k={'MenuModeration'} />
                </NavLink>
              </MenuItemAdmin>
            )}
            {!isExample && props.isAdmin && (
              <MenuItemAdmin>
                <NavLink to={appConfig.routes.analytics.main}>
                  <L k={'MenuAnalytics'} />
                </NavLink>
              </MenuItemAdmin>
            )}
          </MenuBlock>
        )}

        {showMenu && (
          <MenuBlock
            onClick={() => toggleRight()}
            className={isActiveRight ? 'active' : ''}
            style={{
              marginTop: marginTop + 'px',
              // right: marginRight + 'px',
              right: 0,
            }}
          >
            <NavLink to={appConfig.routes.account.myLots.main}>
              <L k={'MenuMyLot'} />
            </NavLink>
            <NavLink to={appConfig.routes.donations.main}>
              <L k={'MenuMyDonations'} />
            </NavLink>
            <NavLink to={appConfig.routes.account.settings}>
              <L k={'MenuSettings'} />
            </NavLink>
            {isPartner && (
              <MenuItemAdmin>
                <NavLink to={appConfig.routes.account.partnerSettings}>
                  <L k={'MenuPartnerSettings'} />
                </NavLink>
              </MenuItemAdmin>
            )}
            {props.account && (
              <NavLink to={appConfig.routes.main.login} onClick={signOut}>
                <L k={'MenuLogout'} />
              </NavLink>
            )}
          </MenuBlock>
        )}
        {showMenu && (
          <MenuBlock
            className={
              isActiveNotifications ? 'active notifications' : 'notifications'
            }
            style={{
              marginTop: marginTop + 'px',
              right: marginRight + 'px',
            }}
          >
            <Notifications />
          </MenuBlock>
        )}
      </Block>
    </AppHeaderBlock>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    auctionNext: state.Auction.auctionNext,
    cntNew: state.Notification.cntNew,
    cntAll: state.Notification.cntAll,
    notificationIDs: state.Notification.IDs,
    errors: state.System.errors,
    account: state.Account.account,
    batch: state.ShopProduct.batchPreview.batch,
    weekLotIDs: state.Lot.short.WeekIDs,
    partnerIDByAccountID: state.Account.partnerSeller.IDByAccountID,
    auctionMode: state.Auction.auctionMode,
  }),
  {
    getAuctionMode,
    getAuctionNextWeekEnd,
    getNotificationsIDs,
    setContainerWidth,
    loadProductsBatchNext,
    loadPartnerSellerByAccountID,
  },
)(Header);
