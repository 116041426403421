import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { DONATION_LOAD } from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import { dispatchError, dispatchErrorAnAuth } from '../../service/error';
import {
  IDonation,
  IDonationAccountBillBody,
  IDonationAccountListResponse,
} from '../../interfaces/donation/IDonationAccount';

const { donationAccount } = apiConfig.endpoints.archiveDonation;

export const getDonationInfo =
  () =>
    (
      dispatch: IDispatch<IDonation[] | IServiceError>,
      getState: () => IState,
    ) => {
      callApi<IDonationAccountListResponse>(donationAccount)
        .then((data: IDonationAccountListResponse) =>
          dispatch({ type: DONATION_LOAD, payload: data.donations }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const setDonationBill =
  (auctionID: string, donationID: string, imageBase64: string) =>
    (
      dispatch: IDispatch<string | IDonation[] | IServiceError>,
      getState: () => IState,
    ) => {
      const { account } = getState().Account;
      if (!account) {
        return dispatchErrorAnAuth(dispatch, 'setDonationBill');
      }

      const body: IDonationAccountBillBody = {
        auctionID,
        donationID,
        imageBase64,
      };
      callApi(donationAccount, {}, body)
        .then(() => getDonationInfo()(dispatch, getState))
        .then(() =>
          dispatchError(dispatch, {
            message: 'Your donation is uploaded',
            params: {},
            type: 'Info',
          }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
