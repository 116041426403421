import React, { HTMLInputTypeAttribute, useState } from 'react';
import { ContentDescInline } from '../typos';
import { ErrorInfo, Input, TextAreaLong, TextAreaShort } from './Form.styles';

interface IFormFieldProps {
  isDisabled?: boolean;
  mode: 'short' | 'long' | 'input';
  modeInput?: HTMLInputTypeAttribute;
  defaultValue?: null | string;
  saveValue: (value: string) => void;
  valueError: null | string;
  //
  title?: string;
  placeholder?: string;
  //
  validationShouldExists?: number;
  validationMin?: number;
  validationMax?: number;
  customValidation?: () => null | string;
}

export function FormField(props: IFormFieldProps) {
  const [updatedValue, setUpdatedValue] = useState<null | string>(null);

  const saveValue = (value: string) => {
    setUpdatedValue(value);
    props.saveValue(value);
  };

  return (
    <>
      {props.title && (
        <ContentDescInline>
          {props.title[0].toUpperCase() + props.title.slice(1)}:{' '}
        </ContentDescInline>
      )}
      {props.valueError && <ErrorInfo>{props.valueError}</ErrorInfo>}
      {props.mode === 'input' && (
        <Input
          type={props.modeInput || 'text'}
          value={
            updatedValue !== null ? updatedValue : props.defaultValue || ''
          }
          placeholder={props.placeholder}
          onChange={(e) => saveValue(e.target.value)}
          disabled={props.isDisabled}
        />
      )}
      {props.mode === 'short' && (
        <TextAreaShort
          value={
            updatedValue !== null ? updatedValue : props.defaultValue || ''
          }
          placeholder={props.placeholder}
          onChange={(e) => saveValue(e.target.value)}
          disabled={props.isDisabled}
        />
      )}
      {props.mode === 'long' && (
        <TextAreaLong
          value={
            updatedValue !== null ? updatedValue : props.defaultValue || ''
          }
          placeholder={props.placeholder}
          onChange={(e) => saveValue(e.target.value)}
          disabled={props.isDisabled}
        />
      )}
    </>
  );
}
