import styled from 'styled-components';
import { colors } from '../common/colors';
import { device, margins, sizes, sizesBorder } from '../common/sizes';
import { fonts } from '../common/typos';
import { shadows, transitions } from '../common/styles';

export const AppHeaderBlock = styled.div`
  width: ${sizes.full};
  display: block;
  margin: auto;
  text-align: left;
  //padding-bottom: 150px; // fix covering gifs

  @media ${device.mobileL} {
    width: 87%;
  }
  @media ${device.tablet} {
    width: 75%;
  }
  @media ${device.laptop} {
    width: 62.5%;
  }
`;

export const AppHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  background-color: ${colors.background};
  padding-block-start: ${margins.block};
  padding-block-end: ${margins.block};
  text-align: center;

  h1 {
    margin: 1rem 0;
  }

  &.empty {
    display: block;
  }
`;

export const MenuBlock = styled.div`
  z-index: 999;
  box-shadow: ${shadows.main};
  min-width: ${sizes.widthS};
  max-width: ${sizes.widthM};
  min-height: 50px;
  position: absolute;
  background-color: ${colors.background};
  border-radius: ${sizesBorder.radius};
  padding: ${margins.block};
  visibility: hidden;
  opacity: 0;
  transition: ${transitions.visibility};

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.notifications {
    width: ${sizes.widthM};
  }

  & a {
    text-decoration: none;
    margin-bottom: ${margins.block};
    display: block;
    color: ${colors.main};

    &.active {
      color: ${colors.main};
      font-weight: ${fonts.weightM};
    }

    &.main {
      font-weight: ${fonts.weightL};
      text-decoration: underline;
    }

    &.disabled {
      color: ${colors.greyBorder};
      cursor: inherit;
    }
  }
`;
export const MenuItemAdmin = styled.div`
  border: ${sizesBorder.widthM} solid ${colors.danger};
  color: ${colors.danger};
  border-radius: ${sizesBorder.radius};
  padding: ${margins.item};

  & a {
    margin-bottom: 0;
    color: ${colors.danger};

    &.active {
      color: ${colors.danger};
      font-weight: ${fonts.weightM};
    }
  }
`;
