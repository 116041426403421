import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { ActionButton, BlockActions } from '../../common/styles';
import { IState } from '../../interfaces/system/IState';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../common/animation/Loading';
import { loadArchiveReportLot } from '../../actions/archive/archiveReportLot.actions';
import { IArchiveReportLot } from '../../interfaces/archive/IArchiveReportLot';
import { appConfig } from '../../config/app.config';
import AuctionInfo from '../../components/auctions/AuctionInfo';
import { getComplexPathFromPath } from '../../utils/archive';
import { sendPageEvent } from '../../utils/analytics';
import ReportLotItem from '../../components/archive/ReportLotItem';
import { ILotType } from '../../interfaces/auctions/ILotPublic';
import { Block } from '../../common/layout';

interface IReportLotPageProps {
  lotType: ILotType;
  loadArchiveReportLot: (auctionPath: string, reportPath: string) => void;
  set: Record<string, IArchiveReportLot>;
  IDByComplexPath: Record<string, string>;
}

function ReportLotPage(props: IReportLotPageProps) {
  const navigate = useNavigate();
  const { auctionPath, reportPath } = useParams();
  const [lotID, setLotID] = useState<null | string>(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  useEffect(() => {
    if (props.lotType === 'Week') {
      sendPageEvent('ArchiveWeekLot');
    } else {
      sendPageEvent('ArchiveReportLot');
    }
  }, []);

  useEffect(() => {
    if (lotID) {
      return;
    }
    if (!auctionPath) {
      return navigate(appConfig.routes.auctions.list);
    }
    if (!reportPath) {
      if (props.lotType === 'Week') {
        return navigate(appConfig.routes.auctions.weekLotsList);
      } else {
        return navigate(appConfig.routes.auctions.lotsList);
      }
    }

    const complexPath: string = getComplexPathFromPath(auctionPath, reportPath);
    const reportID: undefined | string = props.IDByComplexPath[complexPath];

    if (reportID && props.set[reportID]) {
      setLotID(reportID);
    } else if (!isLoad) {
      setIsLoad(true);
      props.loadArchiveReportLot(auctionPath, reportPath);
    }
  }, [props.IDByComplexPath, props.set]);

  const goToReportsList = () => {
    if (!auctionPath) {
      return;
    }
    const path = (
      props.lotType === 'Week'
        ? appConfig.routes.auctions.weekLotsList
        : appConfig.routes.auctions.lotsList
    ).replace(appConfig.params.auctionPath, auctionPath);
    navigate(path);
  };

  return (
    <AppContent>
      <Block>
        <AuctionInfo />

        {lotID && (
          <Block>
            <ReportLotItem
              lotID={lotID}
              showAccounts={true}
              isNameOnTop={true}
            />
          </Block>
        )}

        <BlockActions>
          <ActionButton onClick={() => goToReportsList()}>
            Show all reports
          </ActionButton>
        </BlockActions>

        {!lotID && <Loading size={'big'} />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Archive.reportLots.set,
    IDByComplexPath: state.Archive.reportLots.IDByComplexPath,
  }),
  {
    loadArchiveReportLot,
  }
)(ReportLotPage);
