import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import { IProductDraft, IProductLink } from '../../../interfaces/shop/IProductDraft';
import { FormsFields } from '../../../common/forms/forms';
import { Block } from '../../../common/layout';
import { createProductOnBehalfModeration } from '../../../actions/moderation/admin-shop-moderation.actions';

interface IDraftFormOnBehalfProps {
  auctionID: string;
  sellerID: null | string;
  isDisabled?: boolean;
  createProductOnBehalfModeration: (
    auctionID: string,
    sellerID: string,
    name: string,
    description: string,
    isHide: boolean,
    imagesBase64: string[],
    links: undefined | null | IProductLink[],
  ) => void;

  cbCancel: () => void;
  actions: string[];
  errors: string[];
}

function DraftFormOnBehalf(props: IDraftFormOnBehalfProps) {
  const FormCreateRef = useRef<HTMLDivElement>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields] = useState<IFormFields<IProductDraft>>({
    name: {
      ...FormsFields.name,
    },
    description: {
      ...FormsFields.description,
    },
    links: {
      ...FormsFields.params,
      isRequired: false,
      $valueRecordLength: 1,
    },
    imagesURL: {
      ...FormsFields.imageURL,
      max: 3,
    },
  });

  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.errors]);

  const cbCreate = () => {
    setIsSending(true);
    props.createProductOnBehalfModeration(
      props.auctionID,
      props.sellerID || '',
      (fields.name && fields.name.$value) || '',
      (fields.description && fields.description.$value) || '',
      false,
      (fields.imagesURL && fields.imagesURL.$valueList) || [],
      (fields.links &&
        fields.links.$valueRecord &&
        fields.links.$valueRecord[0] && [
          {
            name: fields.links.$valueRecord[0].key,
            url: fields.links.$valueRecord[0].value,
          },
        ]) ||
      null,
    );
  };

  return (
    <FormBlock>
      <ContentHead>Create lot on Behalf</ContentHead>

      <Block ref={FormCreateRef}>
        <FormCommon
          placeholderTitle={'auction draft'}
          fields={fields}
          cb={{
            cbCancel: props.cbCancel,
            cbCreate,
          }}
          action={{
            isDisabled: props.isDisabled,
            isUpdate: false,
            isSending,
            createActionTitle: 'Create product',
            editActionTitle: 'Accept changes',
            removeActionTitle: 'Decline product',
          }}
        />
      </Block>
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    errors: state.System.errors,
  }),
  {
    createProductOnBehalfModeration,
  },
)(DraftFormOnBehalf);
