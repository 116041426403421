import React from 'react';
import { connect } from 'react-redux';
import { broadcastReportAuction } from '../../../actions/auctions/reportAdmin.actions';
import { ILotType } from '../../../interfaces/auctions/ILotPublic';
import { AdminAction, AdminActionBlock } from '../live/Lot.styles';
import { L } from '../../../common/locale';

interface IResultLotAdminActionsProps {
  broadcastReportAuction: (lotType: ILotType) => void;
}

function ResultLotAdminActions(props: IResultLotAdminActionsProps) {
  function sendAuction() {
    props.broadcastReportAuction('Live');
  }

  return (
    <AdminActionBlock>
      <AdminAction onClick={sendAuction}>
        <L k={'ReportsCurrentLiveShort'} />
      </AdminAction>
    </AdminActionBlock>
  );
}

export default connect(() => ({}), {
  broadcastReportAuction,
})(ResultLotAdminActions);
