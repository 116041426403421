import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import {
  IParamsItemView,
  RenderBatchFilterFunction,
  RenderFormFunction,
  RenderItemPublicFunction,
  RenderItemViewFunction,
  RenderOpenItemFunction,
} from '../../../common/common';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import { loadMailingBatch, prepareMailingBatchKey } from '../../../actions/communication/mailing.actions';
import {
  IMailingBatchFiltersParams,
  IMailingPublic,
  IMailingSet,
  IMailingView,
} from '../../../interfaces/communication/IMailing';
import { DataBatchWide } from '../../../interfaces/system/data';
import { IMailingStatus, IMailingStatusList } from '../../../interfaces/communication/IMailingLog';
import { IEmailMode, IEmailModeMailingList } from '../../../interfaces/communication/IEmailLog';
import MailingForm from '../../../components/communication/Mailing/MailingForm';
import { MailingItemModeration } from '../../../components/communication/Mailing/MailingItemModeration';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../../config/app.config';
import { ItemView } from '../../../common/moderation/ItemView';
import { BatchFilters, BatchFiltersItem } from '../../../common/moderation/BatchFilters';

interface ICommunicationMailingProps {
  loadMailingBatch: (
    status: null | IMailingStatus,
    mode: null | IEmailMode,
  ) => void;
  isAdmin: boolean;
  set: IMailingSet;
  byBatchKey: Record<string, DataBatchWide>;
  actions: string[];
  errors: string[];
}

function CommunicationMailing(props: ICommunicationMailingProps) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<IMailingBatchFiltersParams>();
  const [batchKey, setBatchKey] = useState<string>(
    prepareMailingBatchKey(null, null),
  );

  useEffect(() => sendPageEvent('CommunicationMailing'), []);

  const $params: Partial<
    Record<keyof IMailingBatchFiltersParams, BatchFiltersItem>
  > = {
    status: {
      value: null,
      values: IMailingStatusList,
    },
    mode: {
      value: null,
      values: IEmailModeMailingList,
    },
  };

  const getBatchKey = (): string => {
    const filtersLocal: IMailingBatchFiltersParams = {
      status: $params.status?.value as IMailingStatus,
      mode: $params.mode?.value as IEmailMode,
    };
    setFilters(filtersLocal);

    const batchKeyLocal: string = prepareMailingBatchKey(
      filtersLocal.status,
      filtersLocal.mode,
    );

    setBatchKey(batchKeyLocal);
    return batchKeyLocal;
  };
  useEffect(() => {
    getBatchKey();
  }, []);
  const defaultParamsItemView: IMailingView = {
    mode: true,
    status: true,
    params: false,
    sendAt: true,
    createdAt: false,
    updatedAt: false,
    accountIDs: false,
  };

  const loadBatch = (extraLimit?: number) => {
    if (!filters) {
      props.loadMailingBatch(null, null);
      return;
    }

    props.loadMailingBatch(filters.status, filters.mode);
  };

  const renderBatchFilter: RenderBatchFilterFunction = () => {
    return (
      <BatchFilters $params={$params} cb={() => setBatchKey(getBatchKey())} />
    );
  };

  const renderForm: RenderFormFunction<IMailingPublic> = (
    isDisabled: undefined | boolean,
    item: undefined | IMailingPublic,
    cbCancel: () => void,
    cbDelete?: (partnerID: string) => void,
  ) => {
    return (
      <MailingForm
        isDisabled={isDisabled}
        mailing={item}
        cbCancel={cbCancel}
        cbDelete={cbDelete}
      />
    );
  };

  const renderItemPublic: RenderItemPublicFunction<IMailingPublic> = (
    item: IMailingPublic,
    inlineBlock?: JSX.Element,
    ParamsItemView?: IParamsItemView,
  ) => {
    return (
      <MailingItemModeration
        mailing={item}
        showInline={true}
        inlineBlock={inlineBlock}
        ParamsItemView={ParamsItemView}
      />
    );
  };

  const renderItemView: RenderItemViewFunction = (
    cb: (params: IMailingView) => void,
  ) => {
    return (
      <ItemView
        cb={(params: Record<string, boolean>) => cb(params as IMailingView)}
        defaultParam={defaultParamsItemView}
      />
    );
  };

  const openMailing: RenderOpenItemFunction = (itemID: string): void => {
    const path: string =
      appConfig.routes.Moderation.communication.mailingOne.replace(
        appConfig.params.itemID,
        itemID,
      );
    navigate(path);
  };

  return (
    <ModerationPage
      pageTitle={'Mailing moderation'}
      itemName={'this mailing'}
      render={{
        renderForm,
        renderItemPublic,
        renderItemView,
      }}
      data={{
        set: props.set,
        actions: props.actions,
        errors: props.errors,
        batch: {
          batchKey,
          byBatchKey: props.byBatchKey,
          loadBatch,
          renderBatchFilter,
        },
      }}
      cb={{
        cbView: openMailing,
      }}
      isAdmin={props.isAdmin}
      action={{
        showInline: true,
      }}
      ParamsItemView={defaultParamsItemView}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    set: state.Communication.Mailing.set,
    byBatchKey: state.Communication.Mailing.byBatchKey,
    actions: state.Communication.Mailing.actions,
    errors: state.System.errors,
  }),
  {
    loadMailingBatch,
  },
)(CommunicationMailing);
