import React, { useEffect, useState } from 'react';
import { ContentDesc } from '../../../common/typos';
import { IProductPreview } from '../../../interfaces/shop/IProduct';
import {
  AnalyticsIconBlock,
  AnalyticsIconItem,
  FoundationTooltip,
  PreviewBlock,
} from '../shop.styles';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import {
  setLike,
  setView,
} from '../../../actions/shop/productAnalytics.actions';
import {
  IProductAnalytics,
  IProductAnalyticsByAccount,
} from '../../../interfaces/shop/IProductAnalytics';
import { appConfig } from '../../../config/app.config';
import { IAccountSet } from '../../../interfaces/account/IAccount';
import { Loading } from '../../../common/animation/Loading';
import { loadProductsByIDs } from '../../../actions/shop/product.actions';
import { Image, ImageIcon } from '../../../common/images/Image.styles';

interface IProductPreviewItemProps {
  noView?: boolean;
  fullWidth?: boolean;
  hideAnalytics?: boolean;
  productID: string;
  cnt: number;
  loadProductsByIDs: (previewIDs: string[]) => void;
  setView: (productID: string, mode: 'catalog' | 'details') => void;
  setLike: (productID: string) => void;
  analyticsByProductID: Record<string, null | IProductAnalytics>;
  analyticsByProductIDSelf: Record<string, null | IProductAnalyticsByAccount>;
  foundations: string[];
  sellerByProduct: Record<string, string>;
  accounts: IAccountSet;
  set: Record<string, IProductPreview>;
}

function ProductPreviewItem(props: IProductPreviewItemProps) {
  const navigate = useNavigate();
  const [analyticsProduct, setAnalyticsProduct] =
    useState<null | IProductAnalytics>(null);
  const [analyticsSelf, setAnalyticsSelf] =
    useState<null | IProductAnalyticsByAccount>(null);
  const [isFoundation, setIsFoundation] = useState<boolean>(false);
  const [foundation, setFoundation] = useState<null | string>(null);
  const [classNames, setClassNames] = useState<string>('');
  const [product, setProduct] = useState<null | IProductPreview>(null);

  useEffect(() => {
    if (!props.noView) {
      props.setView(props.productID, 'catalog');
    }
    resetClassnames(false);
  }, []);

  useEffect(() => {
    const product: null | IProductPreview = props.set[props.productID] || null;
    if (product) {
      setProduct(product);
    } else {
      props.loadProductsByIDs([props.productID]);
    }
  }, []);
  useEffect(
    () => setProduct(props.set[props.productID] || null),
    [props.set, props.productID]
  );

  useEffect(() => {
    if (!props.noView) {
      props.setView(props.productID, 'catalog');
    }
    resetClassnames(false);
  }, []);

  useEffect(
    () => resetAnalytics(),
    [props.analyticsByProductID, props.analyticsByProductIDSelf]
  );
  // todo uncomment without seller as a foundation
  // useEffect(() => {
  //   if (isFoundation) {
  //     return;
  //   }
  //   if (props.foundations.indexOf(props.productID) !== -1) {
  //     resetClassnames(true);
  //     setIsFoundation(true);
  //   }
  //   setFoundation(props.sellerByProduct[props.productID]);
  // }, [props.sellerByProduct, props.productID, props.foundations]);
  //
  // useEffect(() => {
  //   // if (foundation) {
  //   //   return;
  //   // }
  //   const { sellerByProduct, productID, accounts } = props;
  //   const sellerID: string = sellerByProduct[productID];
  //   const seller: IAccount = accounts[sellerID];
  //   if (!seller) {
  //     return;
  //   }
  //   setFoundation(seller.name);
  // }, [props.sellerByProduct, props.foundations, props.accounts]);

  const resetClassnames = (localIsFoundation: boolean) => {
    setClassNames(
      'cnt-' +
        props.cnt +
        (!props.noView && (isFoundation || localIsFoundation)
          ? ' foundation'
          : '')
    );
  };

  const resetAnalytics = () => {
    const id: string = props.productID;
    setAnalyticsProduct(props.analyticsByProductID[id] || null);
    setAnalyticsSelf(props.analyticsByProductIDSelf[id] || null);
  };

  const goToProduct = () => {
    if (!product) {
      return;
    }
    navigate(
      appConfig.routes.shopProducts.one.replace(
        `${appConfig.params.accountPath}/${appConfig.params.productPath}`,
        product.route
      )
    );
  };

  return (
    <PreviewBlock
      onClick={goToProduct}
      style={{
        width: props.fullWidth
          ? '100%'
          : 'calc(' + Math.round(100 / props.cnt) + '% - 5px)',
      }}
      className={classNames}
    >
      {product ? (
        <>
          {!props.noView && foundation && (
            <FoundationTooltip className="tooltip">
              *lot by {foundation}
            </FoundationTooltip>
          )}

          {!props.hideAnalytics && (
            <AnalyticsIconBlock>
              <AnalyticsIconItem>
                {(analyticsSelf && analyticsSelf.isLiked && (
                  <ImageIcon
                    className={'small'}
                    src="/icons/like-full.png"
                    alt="view"
                  />
                )) || (
                  <ImageIcon
                    className={'small'}
                    src="/icons/like.png"
                    alt="view"
                  />
                )}
              </AnalyticsIconItem>
            </AnalyticsIconBlock>
          )}

          <Image
            className={'radius'}
            src={product.imageURL}
            alt={product.name}
          />
          <ContentDesc>{product.name}</ContentDesc>
        </>
      ) : (
        <Loading size="big" />
      )}
    </PreviewBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.ShopProduct.batchPreview.set,
    analyticsByProductID: state.ShopProductAnalytics.byProductID,
    analyticsByProductIDSelf: state.ShopProductAnalytics.byProductIDSelf,
    foundations: state.ShopProduct.foundation.productIDs,
    sellerByProduct: state.ShopProduct.foundation.sellerByProduct,
    accounts: state.Account.accounts.set,
  }),
  {
    loadProductsByIDs,
    setView,
    setLike,
  }
)(ProductPreviewItem);
