import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { AUCTION_LOAD, AUCTION_MODE_LOAD, AUCTION_NEXT_WEEK_END_LOAD } from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import {
  IAuctionDataSet,
  IAuctionListResponse,
  IAuctionModeResponse,
  IAuctionNextWeekEndResponse,
} from '../../interfaces/auctions/IAuction';

const { main, mode, byPath, weekEnd } = apiConfig.endpoints.auctions.auction;

export const getAuctionMode =
  () =>
    (
      dispatch: IDispatch<IAuctionModeResponse | IServiceError>,
      getState: () => IState,
    ) => {
      callApi<IAuctionModeResponse>(mode)
        .then((data: IAuctionModeResponse) =>
          dispatch({ type: AUCTION_MODE_LOAD, payload: data }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const getAuctionNextWeekEnd =
  () =>
    (
      dispatch: IDispatch<null | string | IServiceError>,
      getState: () => IState,
    ) => {
      callApi<IAuctionNextWeekEndResponse>(weekEnd)
        .then((data: IAuctionNextWeekEndResponse) =>
          dispatch({ type: AUCTION_NEXT_WEEK_END_LOAD, payload: data.date }),
        )
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const getAuctionByPath =
  (auctionPath: string) =>
    (
      dispatch: IDispatch<IAuctionDataSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (getState().Auction.auctions.IDByPath[auctionPath]) {
        return;
      }

      callApi<IAuctionListResponse>(byPath, { auctionPath })
        .then((data: IAuctionListResponse) => {
          const payload: IAuctionDataSet = {
            auctionPath,
            auctions: data.auctions,
          };
          dispatch({ type: AUCTION_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const getAuctionList =
  () =>
    (
      dispatch: IDispatch<IAuctionDataSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (getState().Auction.auctions.IDs[0]) {
        return;
      }

      callApi<IAuctionListResponse>(main)
        .then((data: IAuctionListResponse) => {
          const payload: IAuctionDataSet = {
            auctionPath: null,
            auctions: data.auctions,
          };
          dispatch({ type: AUCTION_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
