import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import {
  ActionButton,
  BlockActions,
  BlockPage,
  BlockPageContent,
  ButtonMain,
} from '../../../common/styles';
import { Block } from '../../../common/layout';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../../common/typos';
import { sendPageEvent } from '../../../utils/analytics';
import { IAccountSet } from '../../../interfaces/account/IAccount';
import { getAuctionList } from '../../../actions/auctions/auction.actions';
import { IAuction } from '../../../interfaces/auctions/IAuction';
import {
  deleteDonationsBank,
  getDonationsBank,
} from '../../../actions/donation/donationBank.actions';
import { IDonationBankDetails } from '../../../interfaces/donation/IDonationBank';
import { SelectionPage } from '../../../common/moderation/SelectionPage';
import AuctionItemModeration from '../../../components/auctions/AuctionItemModeration';
import { BankDetailsItem } from '../../../components/donation/BankDetailsItem';
import BankDetailsForm from '../../../components/donation/BankDetailsForm';
import ModerationMainHead from '../../../common/moderation/ModerationMainHead';

interface IBankDetailsProps {
  getAuctionList: () => void;
  getDonationsBank: (auctionID: string) => void;
  deleteDonationsBank: (auctionID: string, detailsID: string) => void;
  accounts: IAccountSet;
  isAdmin: boolean;
  auctionsIDs: string[];
  auctionsByID: Record<string, IAuction>;
  detailsByAuctionID: Record<string, IDonationBankDetails[]>;
  errors: string[];
}

function BankDetails(props: IBankDetailsProps) {
  const [auctionID, setAuctionID] = useState<null | string>(null);
  const [auction, setAuction] = useState<null | IAuction>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<null | IDonationBankDetails[]>(null);
  const [detailsToEdit, setDetailsToEdit] =
    useState<null | IDonationBankDetails>(null);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isRemove, setIsRemove] = useState<boolean>(false);

  useEffect(() => sendPageEvent('DonationBankModeration'), []);
  useEffect(() => props.getAuctionList(), []);
  useEffect(() => {
    setAuction((auctionID && props.auctionsByID[auctionID]) || null);

    if (!auctionID) {
      return;
    }

    setIsLoading(true);
  }, [auctionID, props.auctionsByID]);
  useEffect(() => {
    setIsLoading(false);
    const detailsLocal: IDonationBankDetails[] | null =
      (auctionID && props.detailsByAuctionID[auctionID]) || null;

    setDetails(
      detailsLocal
        ? detailsLocal
            .map(
              (i: IDonationBankDetails): IDonationBankDetails => ({
                ...i,
                sellerID: i.sellerID || '',
              })
            )
            .sort((a: IDonationBankDetails, b: IDonationBankDetails) =>
              (a.sellerID || '') > (b.sellerID || '') ? -1 : 1
            )
        : null
    );
  }, [auctionID, props.detailsByAuctionID]);
  useEffect(() => {
    if (!isEdit) {
      return;
    }

    setIsLoading(false);
    cbCancel();
  }, [props.detailsByAuctionID]);
  useEffect(() => {
    if (!isEdit) {
      return;
    }

    setIsLoading(false);
  }, [props.errors]);

  const addBankDetails = () => {
    setIsEdit(true);
  };
  const editBankDetails = (detailsToEditLocal: IDonationBankDetails) => {
    setDetailsToEdit(detailsToEditLocal);
    setIsEdit(true);
  };
  const removeBankDetails = (detailsToEditLocal: IDonationBankDetails) => {
    setDetailsToEdit(detailsToEditLocal);
    setIsRemove(true);
    setIsEdit(false);
  };

  const cbCancel = () => {
    setDetailsToEdit(null);
    setIsEdit(false);
    setIsRemove(false);
  };

  const remove = () => {
    if (!detailsToEdit) {
      return;
    }

    props.deleteDonationsBank(detailsToEdit.auctionID, detailsToEdit.id);
    setIsLoading(true);
  };

  const saveAuctionID = (auctionIDLocal: string): void => {
    setAuction(null);
    setAuctionID(auctionIDLocal);
    props.getDonationsBank(auctionIDLocal);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />

        <PageHead>Donation Bank details</PageHead>

        <ContentHead>Select Auction:</ContentHead>
        <SelectionPage
          renderItemPublic={(auction: IAuction) => (
            <AuctionItemModeration auction={auction} showInline={true} />
          )}
          data={{
            set: props.auctionsByID,
            list: {
              load: props.getAuctionList,
              IDs: props.auctionsIDs || [],
            },
          }}
          isAdmin={props.isAdmin}
          action={{
            showInline: true,
            hideSelected: true,
          }}
          selection={{
            cbSelected: saveAuctionID,
            selectedIDs: auctionID ? [auctionID] : null,
          }}
        />

        {auction && <AuctionItemModeration auction={auction} />}

        {details &&
          details.map((item: IDonationBankDetails) => (
            <Block key={item.id}>
              <BankDetailsItem details={item} />
              {!isEdit && !isRemove && (
                <BlockActions>
                  <ActionButton
                    onClick={() => editBankDetails(item)}
                    className="success"
                  >
                    Edit
                  </ActionButton>
                  <ActionButton
                    onClick={() => removeBankDetails(item)}
                    className="fail"
                  >
                    Remove
                  </ActionButton>
                </BlockActions>
              )}

              {isRemove && detailsToEdit && detailsToEdit.id === item.id && (
                <BlockActions>
                  <ButtonMain onClick={remove} className="fail">
                    Remove Bank Details
                  </ButtonMain>
                  <ButtonMain onClick={cbCancel} className="success">
                    Cancel
                  </ButtonMain>
                </BlockActions>
              )}
            </Block>
          ))}

        {auction && !isLoading && !details && (
          <Block>
            <ContentDesc>
              <ContentBold>No Bank details yet</ContentBold>
            </ContentDesc>
          </Block>
        )}

        {auction && (
          <BlockActions>
            <ActionButton onClick={addBankDetails}>
              Add Bank details
            </ActionButton>
          </BlockActions>
        )}

        {isEdit && auctionID && (
          <BankDetailsForm
            bankDetails={detailsToEdit}
            auctionID={auctionID}
            isDisabled={isLoading}
            cbCancel={cbCancel}
            cbDelete={() =>
              detailsToEdit ? removeBankDetails(detailsToEdit) : null
            }
          />
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
    isAdmin: state.Account.isAdmin,
    auctionsIDs: state.Auction.auctions.IDs,
    auctionsByID: state.Auction.auctions.set,
    detailsByAuctionID: state.Donation.detailsByAuctionID,
    errors: state.System.errors,
  }),
  {
    getAuctionList,
    getDonationsBank,
    deleteDonationsBank,
  }
)(BankDetails);
