import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_ALL_LOAD,
  SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LIST_LOAD,
  SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LOAD,
  SHOP_PRODUCT_ANALYTICS_LOAD,
} from '../types';
import { DataBatch } from '../../interfaces/system/data';
import {
  IProductAnalyticsByAccount,
  IProductAnalyticsByAccountAllResponse,
  IProductAnalyticsByAccountResponse,
  IProductAnalyticsByAccountSet,
  IProductAnalyticsListByAccountResponse,
  IProductAnalyticsResponse,
  IProductAnalyticsSet,
} from '../../interfaces/shop/IProductAnalytics';
import { IServiceError } from '../../interfaces/system/IError';
import { CacheRequests } from '../../service/cacheRequests';
import { CacheCnt } from '../../service/cacheCnt';

const { byProduct, byAccount, byAccountAll, byAccountList, view, like } =
  apiConfig.endpoints.shop.productAnalytics;

const cacheByIDs: CacheRequests = new CacheRequests();

const viewCntCatalog: CacheCnt = new CacheCnt('ViewCatalog', 5);
const viewCntDetails: CacheCnt = new CacheCnt('ViewDetail', 2);

export const getAnalytics =
  (productID: string, hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      IProductAnalyticsByAccountSet | IProductAnalyticsSet | IServiceError
    >,
    getState: () => IState
  ) => {
    getAnalyticsByProduct(productID)(dispatch, getState);
    if (!getState().Account.account) {
      return;
    }
    const toLoad: string[] = cacheByIDs.getToLoad([productID]);
    if (!toLoad[0] && !hardReload) {
      return;
    }

    callApi<IProductAnalyticsByAccountResponse>(byAccount, {
      productID: hardReload ? productID : toLoad[0],
    })
      .then((data: IProductAnalyticsByAccountResponse) => {
        const payload: IProductAnalyticsByAccountSet = {
          productID,
          ...data,
        };
        dispatch({ type: SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LOAD, payload });

        getAnalyticsByProduct(productID)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const getAnalyticsAll =
  () =>
  (
    dispatch: IDispatch<IProductAnalyticsByAccount[] | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.account) {
      return;
    }
    callApi<IProductAnalyticsByAccountAllResponse>(byAccountAll)
      .then((data: IProductAnalyticsByAccountAllResponse) =>
        dispatch({
          type: SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_ALL_LOAD,
          payload: data.analytics,
        })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const getAnalyticsList =
  (productIDs: string[]) =>
  (
    dispatch: IDispatch<IProductAnalyticsByAccount[] | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.account) {
      return;
    }
    callApi<IProductAnalyticsListByAccountResponse>(byAccountList, {
      productIDs: productIDs.join(','),
    })
      .then((data: IProductAnalyticsListByAccountResponse) =>
        dispatch({
          type: SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LIST_LOAD,
          payload: data.analyticsList,
        })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

const getAnalyticsByProduct =
  (productID: string) =>
  (
    dispatch: IDispatch<IProductAnalyticsSet | IServiceError>,
    getState: () => IState
  ) => {
    callApi<IProductAnalyticsResponse>(byProduct, {
      productID,
    })
      .then((data: IProductAnalyticsResponse) => {
        const payload: IProductAnalyticsSet = {
          productID,
          ...data,
        };
        dispatch({ type: SHOP_PRODUCT_ANALYTICS_LOAD, payload });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const setView =
  (productID: string, mode: 'catalog' | 'details') =>
  (
    dispatch: IDispatch<
      IProductAnalyticsByAccountSet | IProductAnalyticsSet | IServiceError
    >,
    getState: () => IState
  ) => {
    getAnalytics(productID)(dispatch, getState);
    if (mode === 'catalog' && viewCntCatalog.inc(productID)) {
      return;
    } else if (mode === 'details' && viewCntDetails.inc(productID)) {
      return;
    }

    callApi<DataBatch<string>>(view, { productID }).catch(
      (error: Error | IApiError) => catchError(dispatch, error, getState)
    );
  };

export const setLike =
  (productID: string) =>
  (
    dispatch: IDispatch<
      IProductAnalyticsByAccountSet | IProductAnalyticsSet | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.account) {
      return;
    }

    callApi<DataBatch<string>>(like, { productID })
      .then(() => getAnalytics(productID, true)(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
