import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { Block } from '../../../common/layout';
import { currencyConvert } from '../../../utils/price';
import { IReportLot } from '../../../interfaces/auctions/IReportLot';
import { ILotBuyer } from '../../../interfaces/auctions/ILotPublic';
import { loadAccountsSet } from '../../../actions/account/accounts.actions';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
  ContentHead,
  PageHead,
} from '../../../common/typos';
import { Image } from '../../../common/images/Image.styles';
import AccountImage from '../../account/AccountImage';
import AccountItemMail from '../../account/AccountItemMail';
import { L } from '../../../common/locale';

interface ILotResultProps {
  report: undefined | null | IReportLot;
}

function ResultLot(props: ILotResultProps) {
  return (
    <Block>
      <PageHead>
        <L k={'ReportsLot'} />
      </PageHead>

      {props.report && (
        <>
          <Image
            className={'XL'}
            src={props.report.imagesURL[0]}
            alt={props.report.name}
          />
          <ContentHead>{props.report.name}</ContentHead>

          <ContentDesc>
            <L k={'ReportsLotSold'} />{' '}
            <ContentBold>
              {props.report.Total || props.report.price} ($
              {currencyConvert(props.report.Total || props.report.price || 0)})
            </ContentBold>
          </ContentDesc>

          <ContentDesc>
            {props.report.bidCnt} <L k={'AnalyticsBids'} />
          </ContentDesc>

          {props.report.buyerList &&
            props.report.buyerList.map((buyer: ILotBuyer) => (
              <Block key={buyer.buyerID}>
                <AccountImage
                  accountID={buyer.buyerID}
                  size={'S'}
                  isInline={true}
                />

                <ContentDescInline>
                  <L k={'ReportsLotThanks'} />{' '}
                  <AccountItemMail
                    accountID={buyer.buyerID}
                    showName={true}
                    showInline={true}
                  />{' '}
                  <L k={'ReportsLotDonation'} />
                </ContentDescInline>
              </Block>
            ))}

          <ContentDesc>
            <L k={'ReportsLotThanks'} />{' '}
            <AccountItemMail
              accountID={props.report.sellerID}
              showName={true}
              showInline={true}
            />{' '}
            <L k={'ReportsLotLot'} />
          </ContentDesc>
        </>
      )}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    report: state.Report.lot,
  }),
  {
    loadAccountsSet,
  }
)(ResultLot);
