import React, { useEffect, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

interface IProductGalleryProps {
  productImagesURL: string[];
  hideButtons?: boolean;
}

export function ProductGallery(props: IProductGalleryProps) {
  const [images, setImages] = useState<ReactImageGalleryItem[]>();

  useEffect(() => {
    setImages(
      (props.productImagesURL &&
        props.productImagesURL.map((imagesURL: string) => ({
          original: imagesURL,
        }))) ||
      [],
    );
  }, []);

  return (
    <>
      {images && (
        <ImageGallery
          items={images}
          showNav={!props.hideButtons}
          autoPlay={true}
          showBullets={!props.hideButtons}
          showFullscreenButton={false}
        />
      )}
    </>
  );
}
