import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { IServiceError } from '../../interfaces/system/IError';
import {
  IRetentionGetParams,
  IRetentionGetResponse,
} from '../../interfaces/analytics/IRetention';
import { ANALYTICS_AU_LOAD } from '../types';
import { CacheRequests } from '../../service/cacheRequests';

const { retention } = apiConfig.endpoints.analytics;

const cacheByID: CacheRequests = new CacheRequests();

export const loadAUAnalytics =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<IRetentionGetResponse | IServiceError>,
    getState: () => IState
  ) => {
    const params: IRetentionGetParams = { from, to };

    const loadID: string = `${from}-${to}`;
    const IDsToLoad: string[] = cacheByID.getToLoad([loadID]);
    if (!IDsToLoad[0]) {
      return;
    }

    callApi<IRetentionGetResponse>(retention.main, params)
      .then((data: IRetentionGetResponse) =>
        dispatch({ type: ANALYTICS_AU_LOAD, payload: data })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
