import { ITranslationSimpleFormTypes } from './_.types';
import { ContentBold, ContentDesc, ContentHead } from '../../common/typos';
import React from 'react';
import { IDonationBank } from '../../interfaces/donation/IDonationAccount';
import { Block } from '../../common/layout';
import { getFullPrice } from '../../utils/price';
import { Image } from '../../common/images/Image.styles';

export const translationFormEN: ITranslationSimpleFormTypes = {
  FormPartnerSellerTitleCreate: 'New PartnerSeller creation',
  FormPartnerSellerTitleUpdate: 'PartnerSeller update',
  FormAuctionTitleCreate: 'New Auction creation',
  FormAuctionTitleUpdate: 'Auction update',
  FormMailingTitleCreate: 'New Mailing creation',
  FormMailingTitleUpdate: 'Mailing update',
  FormRecommendationShort: 'recommendation',
  FormRecommendationTitleCreate: 'New Recommendation creation',
  FormRecommendationTitleUpdate: 'Recommendation update',
  FormTemplateParamsTitleShort: 'mailing template',
  FormTemplateParamsTitleCreate: 'New TemplateParams creation',
  FormTemplateParamsTitleUpdate: 'TemplateParams update',
  FormBankDetailsTitleCreate: 'Bank details creation',
  FormBankDetailsTitleUpdate: 'Bank details update',
  FormBankDetailsMain: 'Main Auction Bank details',
  FormBankDetailsFoundation: 'Foundation Name:',
  FormBankDetailsDonation: 'Donation link:',
  FormBankDetailsDonationPreview: 'It will shown as:',
  FormDonationDetails: 'Donation details:',
  FormDonationName: 'Donation:',
  FormDonationLink: 'Link to make payment:',
  FormDonationScreenshots: 'Payment screenshots:',

  FormReportLabelSold: 'Sold by:',
  FormReportLabelFrom: 'From:',

  FormLabelCreated: 'Created at',
  FormLabelUpdated: 'Updated at',
  FormLabelSend: 'Send at',

  FormPlaceHolderExample: 'Example:',
  FormPlaceHolderNameEmail: 'name or email',
  FormPlaceHolderPartnerSeller: 'partner',
  FormPlaceHolderAuction: 'auction',
  FormPlaceHolderDonation: 'donation',

  FormFilterMailing: 'Filter on mailing:',
  FormFilterAccount: 'Filter on account:',
  FormFilterMode: 'Filter on mode:',
  FormFilterEngagement: 'Filter on engagement:',
  FormFilterSocial: 'Filter on social:',
  FormFilterEmail: 'Filter on email:',
  FormFilterParsed: 'Filter on parsed items property:',

  FormActionSelectSeller: 'Select from sellers:',
  FormActionSelectSellerNotEmpty: 'Seller should be selected',
  FormActionSelectAccounts: 'Accounts selection',
  FormActionSelectParams: 'Selection params',
  FormActionOpenMailing: 'open mailing',
  FormActionEditEmailSubject: 'Edit email subject',
  FormActionEditParam: 'Edit param',
  FormActionPreviewEmail: 'Preview email:',
  FormRoleAdmin: 'See as Admin',
  FormRoleDonator: 'See as Donator',

  FormActionAdd: 'Add',
  FormActionEdit: 'Edit',
  FormActionRemove: 'Remove',
  FormActionShowPreview: 'Show Preview',
  FormActionHidePreview: 'Hide preview',
  FormActionOpenLinkAll: 'open all links',
  FormActionOpenLinkUrl: 'open url',
  FormActionOpenLinkSocial: 'open social',
  FormActionOpenLinkInst: 'open inst',
};
