export function getAuctionIDByPath(
  auctionPath: undefined | string,
  IDByPath: Record<string, string>
): null | string {
  if (!auctionPath) {
    return null;
  }
  return IDByPath[auctionPath] || null;
}

export function getComplexPathFromPath(
  firstPath: string,
  secondPath: string
): string {
  return `${firstPath}_${secondPath}`;
}
