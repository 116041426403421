import React from 'react';
import { ContentBold, ContentDesc, ContentDescInline, ContentHead } from '../../../common/typos';
import { Block } from '../../../common/layout';
import {
  IAppRecommendationPublic,
  IAppRecommendationSocial,
  IAppRecommendationView,
} from '../../../interfaces/communication/IAppRecommendation';
import { IParamsItemView } from '../../../common/common';
import { ActionLink } from '../../../common/styles';
import { prepareAuctionDate } from '../../../utils/renderParts';

interface IAppRecommendationItemModerationProps {
  recommendation: IAppRecommendationPublic;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
  ParamsItemView?: IParamsItemView;
}

export function RecommendationItemModeration(
  props: IAppRecommendationItemModerationProps,
) {
  const recommendation: IAppRecommendationPublic = props.recommendation;
  const view: null | IAppRecommendationView = props.ParamsItemView
    ? (props.ParamsItemView as IAppRecommendationView)
    : null;

  const goTo = (url: string) => {
    window.open(url, '_blank');
  };

  const renderSocialItem = (
    social: IAppRecommendationSocial,
    name: 'linkedin' | 'fb' | 'x' | 'inst' | 't',
  ): JSX.Element => {
    return (
      <>
        {social[name] && (
          <>
            <ActionLink onClick={() => goTo(social[name] || '')}>
              open {name}
            </ActionLink>
            {'. '}
          </>
        )}
      </>
    );
  };
  const renderSocial = (): JSX.Element => {
    const social: null | IAppRecommendationSocial =
      recommendation.social || null;

    if (!social) {
      return <></>;
    }

    return (
      <>
        {renderSocialItem(social, 'linkedin')}
        {renderSocialItem(social, 'fb')}
        {renderSocialItem(social, 'inst')}
        {renderSocialItem(social, 'x')}
        {renderSocialItem(social, 't')}
      </>
    );
  };

  return (
    <>
      {props.showInline && (
        <>
          {!view && (
            <ContentDesc>
              <ContentBold>{recommendation.name}</ContentBold>
              {' - '}
              <ContentDescInline>{recommendation.geo}</ContentDescInline>{' '}
              <ContentDescInline>({recommendation.type}). </ContentDescInline>
              <ContentDescInline>
                {recommendation.emails.join(', ')}
              </ContentDescInline>
              {props.inlineBlock}
            </ContentDesc>
          )}
          {view && (
            <ContentDesc>
              {view.name && <ContentBold>{recommendation.name}</ContentBold>}
              {view.name && ' - '}
              {view.geo && (
                <ContentDescInline>{recommendation.geo}. </ContentDescInline>
              )}
              {view.type && (
                <ContentDescInline>({recommendation.type}). </ContentDescInline>
              )}
              {view.emails && (
                <ContentDescInline>
                  {recommendation.emails.join(', ')}
                  {'. '}
                </ContentDescInline>
              )}
              {view.url && (
                <>
                  <ActionLink onClick={() => goTo(recommendation.url)}>
                    url
                  </ActionLink>
                  {'. '}
                </>
              )}
              {view.social && renderSocial()}

              {/*parsedID: boolean;*/}

              {view.categories &&
                (recommendation.categories ? (
                  <ContentDescInline>
                    {recommendation.categories.join(', ')}
                  </ContentDescInline>
                ) : (
                  'No categories. '
                ))}
              {view.createdAt && (
                <ContentDescInline>
                  {prepareAuctionDate(recommendation.createdAt)}
                  {'. '}
                </ContentDescInline>
              )}
              {view.engagedAt &&
                (recommendation.engagedAt ? (
                  <ContentDescInline>
                    {prepareAuctionDate(recommendation.engagedAt)}
                  </ContentDescInline>
                ) : (
                  'Not Engaged. '
                ))}
              {props.inlineBlock}
            </ContentDesc>
          )}
        </>
      )}
      {!props.showInline && (
        <Block>
          <ContentHead>{recommendation.name}</ContentHead>
          <ContentDesc>{recommendation.type}</ContentDesc>
          <ContentDesc>{recommendation.emails.join(',')}</ContentDesc>
          {/*todo make link*/}
          <ContentDesc>{recommendation.url}</ContentDesc>
          {/*<ContentDesc>{recommendation.social}</ContentDesc>*/}
          <ContentDesc>{recommendation.geo}</ContentDesc>
          <ContentDesc>{recommendation.categories}</ContentDesc>
        </Block>
      )}
    </>
  );
}
