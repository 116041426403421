import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { EMAIL_LOG_ACTION, EMAIL_LOG_LOAD_BATCH, EMAIL_LOG_LOAD_IDS, EMAIL_LOG_LOAD_SET, ITEM_LOADED } from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';

import {
  IEmailLogBatchBody,
  IEmailLogBatchData,
  IEmailLogIDsResponse,
  IEmailLogSet,
  IEmailLogSetResponse,
  IEmailMode,
} from '../../interfaces/communication/IEmailLog';
import { CacheRequests } from '../../service/cacheRequests';
import { DataBatch } from '../../interfaces/system/data';

const { ids, set, batch } = apiConfig.endpoints.communication.log;

export function prepareBatchKeyEmailLog(
  mailingID: null | string,
  accountID: null | string,
  mode: null | IEmailMode,
): string {
  return JSON.stringify({
    mailingID,
    accountID,
    mode,
  });
}

const cacheByID: CacheRequests = new CacheRequests();

export const loadEmailLogSet =
  (logIDs: string[]) =>
    (
      dispatch: IDispatch<string | IEmailLogSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      if (!logIDs || !logIDs[0]) {
        return;
      }
      const byLogID: IEmailLogSet = getState().Communication.Log.set;
      const toLoad: string[] = [];
      logIDs.forEach((logID: string) => {
        if (!byLogID[logID]) {
          toLoad.push(logID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }

      callApi<IEmailLogSetResponse>(set, {
        logIDs: IDsToLoad.join(','),
      })
        .then((data: IEmailLogSetResponse) => {
          dispatch({ type: EMAIL_LOG_LOAD_SET, payload: data.set });
          dispatch({ type: EMAIL_LOG_ACTION, payload: ITEM_LOADED });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadEmailLogIDs =
  () =>
    (
      dispatch: IDispatch<string | string[] | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      callApi<IEmailLogIDsResponse>(ids)
        .then((data: IEmailLogIDsResponse) => {
          dispatch({
            type: EMAIL_LOG_LOAD_IDS,
            payload: data.logIDs,
          });
          dispatch({ type: EMAIL_LOG_ACTION, payload: ITEM_LOADED });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const loadEmailLogBatch =
  (
    mailingID: null | string,
    accountID: null | string,
    mode: null | IEmailMode,
  ) =>
    (
      dispatch: IDispatch<
        string | IEmailLogSet | IEmailLogBatchData | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      const batchKey: string = prepareBatchKeyEmailLog(
        mailingID,
        accountID,
        mode,
      );
      const { limit, byBatchKey } = getState().Communication.Log;
      const body: IEmailLogBatchBody = {
        limit,
        skip: (byBatchKey[batchKey] && byBatchKey[batchKey].loaded) || 0,
        mailingID,
        accountID,
        mode,
      };

      callApi<DataBatch<string>>(batch, {}, body, 'POST')
        .then((data: DataBatch<string>) => {
          const payload: IEmailLogBatchData = {
            ...data,
            batchKey,
          };

          loadEmailLogSet(data.items)(dispatch, getState);
          dispatch({
            type: EMAIL_LOG_LOAD_BATCH,
            payload,
          });
          dispatch({ type: EMAIL_LOG_ACTION, payload: ITEM_LOADED });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
