import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../../utils/analytics';
import { ContentDesc, ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { ErrorInfo, FormBlock } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import {
  IPartnerSellerPublic,
  IPartnerSellerSet,
} from '../../../interfaces/account/IPartnerSeller';
import {
  createPartnerSeller,
  loadPartnerSellerByAccountID,
  updatePartnerSeller,
} from '../../../actions/account/partnerSeller.actions';
import { FormsFields } from '../../../common/forms/forms';
import { IAccount } from '../../../interfaces/account/IAccount';
import { Block } from '../../../common/layout';
import AccountSelect from '../AccountSelect';
import { L } from '../../../common/locale';
import { useTranslation } from 'react-i18next';

interface IPartnerSellerFormProps {
  partner?: null | IPartnerSellerPublic;
  isDisabled?: boolean;
  loadPartnerSellerByAccountID: (accountID: string) => void;
  createPartnerSeller: (
    name: string,
    imgURL: null | string,
    accountID: string,
    description: null | string,
    donationLink: null | string,
    instagramLink: null | string
  ) => void;
  updatePartnerSeller: (
    partnerID: string,
    name: string,
    imgURL: null | string,
    description: null | string,
    donationLink: null | string,
    instagramLink: null | string
  ) => void;
  cbCancel: () => void;
  cbDelete?: (partnerID: string) => void;
  set: IPartnerSellerSet;
  account: null | IAccount;
  actions: string[];
  errors: string[];
}

function PartnerSellerForm(props: IPartnerSellerFormProps) {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [accountID, setAccountID] = useState<null | string>(null);
  const [accountIDFailed, setAccountIDFailed] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormFields<IPartnerSellerPublic>>({
    name: {
      ...FormsFields.name,
    },
    imgURL: {
      ...FormsFields.imageURL,
      isRequired: false,
    },
    description: {
      ...FormsFields.description,
      isRequired: false,
    },
    donationLink: {
      ...FormsFields.name,
      placeholder:
        t('FormPlaceHolderExample') +
        " `<a href='https://prytulafoundation.org/en/donation' target='_blank'>Prytula Foundation</a>`",
      isRequired: false,
    },
    instagramLink: {
      ...FormsFields.name,
      placeholder: t('FormPlaceHolderExample') + ' `petro.nikolaychuk`',
      isRequired: false,
    },
  });

  useEffect(() => sendEvent('Open-PartnerSellerForm'), []);
  useEffect(() => {
    if (!props.partner) {
      return;
    }

    if (fields.name) {
      fields.name.$value = props.partner.name;
    }
    if (fields.imgURL) {
      fields.imgURL.$valueList = props.partner.imgURL
        ? [props.partner.imgURL]
        : [];
    }
    if (fields.description) {
      fields.description.$value = props.partner.description;
    }
    if (fields.donationLink) {
      fields.donationLink.$value = props.partner.donationLink;
    }
    if (fields.instagramLink) {
      fields.instagramLink.$value = props.partner.instagramLink;
    }
    setFields({ ...fields });
  }, [props.partner]);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbCreate = () => {
    if (!accountID) {
      setAccountIDFailed(true);
      return;
    }

    setIsSending(true);
    props.createPartnerSeller(
      (fields.name && fields.name.$value) || '',
      (fields.imgURL &&
        fields.imgURL.$valueList &&
        fields.imgURL.$valueList[0]) ||
        null,
      accountID, // select users
      (fields.description && fields.description.$value) || null,
      (fields.donationLink && fields.donationLink.$value) || null,
      (fields.instagramLink && fields.instagramLink.$value) || null
    );
  };
  const cbUpdate = () => {
    if (!props.partner) {
      return;
    }

    setIsSending(true);
    props.updatePartnerSeller(
      props.partner.id,
      (fields.name && fields.name.$value) || '',
      (fields.imgURL &&
        fields.imgURL.$valueList &&
        fields.imgURL.$valueList[0]) ||
        accountID ||
        null,
      (fields.description && fields.description.$value) || null,
      (fields.donationLink && fields.donationLink.$value) || null,
      (fields.instagramLink && fields.instagramLink.$value) || null
    );
  };
  const cbDelete = () => {
    if (!props.partner || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.partner.id);
  };
  const cbValidation = () => {
    if (!props.partner && !accountID) {
      setAccountIDFailed(true);
    }
  };

  const saveAccountID = (accountIDLocal: string) => {
    setAccountID(accountIDLocal);
    setAccountIDFailed(false);
    props.loadPartnerSellerByAccountID(accountIDLocal);
  };

  const renderElements = (): JSX.Element => {
    return (
      <>
        {/* Account */}
        {!props.partner && (
          <Block>
            <ContentDesc>
              <L k={'FormActionSelectSeller'} />
            </ContentDesc>
            <AccountSelect
              mode={'seller'}
              cbAccount={saveAccountID}
              selectedIDs={accountID ? [accountID] : null}
            />
            {accountIDFailed && (
              <ErrorInfo>
                <L k={'FormActionSelectSellerNotEmpty'} />
              </ErrorInfo>
            )}
          </Block>
        )}
      </>
    );
  };

  return (
    <FormBlock>
      {!props.partner && (
        <ContentHead>
          <L k={'FormPartnerSellerTitleCreate'} />
        </ContentHead>
      )}
      {props.partner && (
        <ContentHead>
          <L k={'FormPartnerSellerTitleUpdate'} />
        </ContentHead>
      )}

      <FormCommon
        placeholderTitle={t('FormPlaceHolderPartnerSeller')}
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          cbCreate,
          cbUpdate,
          cbDelete,
          cbValidation,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: !!props.partner,
          isSending,
        }}
        renderElements={renderElements}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Account.partnerSeller.set,
    actions: state.Account.partnerSeller.actions,
    account: state.Account.account,
    errors: state.System.errors,
  }),
  {
    createPartnerSeller,
    updatePartnerSeller,
    loadPartnerSellerByAccountID,
  }
)(PartnerSellerForm);
