import React, { useEffect, useState } from 'react';
import { LotSellerText } from './Lot.styles';
import { currencyConvert } from '../../../utils/price';
import { Block } from '../../../common/layout';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { ILotShort, ILotStatus } from '../../../interfaces/auctions/ILotPublic';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import {
  ContentDesc,
  ContentDescInline,
  ContentHead,
} from '../../../common/typos';
import { ProductGallery } from '../../shop/product/ProductGallery';
import { getElementId } from '../../../utils/layout.utils';
import { L } from '../../../common/locale';

interface ILotItemProps {
  lot: ILotShort;
  skipStatus?: boolean;
  accounts: IAccountSet;
  status: ILotStatus;
  statusByLotID: Record<string, ILotStatus>;
}

function LotItem(props: ILotItemProps) {
  const [sellerInfo, setSellerInfo] = useState<null | string>();
  const [status, setStatus] = useState<null | ILotStatus>(null);

  useEffect(() => {
    const seller: null | IAccount = props.lot
      ? props.accounts[props.lot.sellerID] || null
      : null;
    setSellerInfo(seller ? seller.name : null);
  }, [props.lot, props.accounts]);

  useEffect(() => {
    const lotID: string = props.lot.id;
    setStatus(props.status || props.statusByLotID[lotID]);
  }, [props.status, props.statusByLotID]);

  return (
    <Block id={getElementId('block-auction-lot')}>
      <Block className={(!props.skipStatus && status) + ' widthXL'}>
        <ProductGallery productImagesURL={props.lot.imagesURL} />
      </Block>

      {props.lot.byAlias && (
        <LotSellerText className={(!props.skipStatus && status) || ''}>
          <L k={'ProductByShort'} /> {props.lot.byAlias}
        </LotSellerText>
      )}
      {!props.lot.byAlias && sellerInfo && (
        <LotSellerText className={(!props.skipStatus && status) || ''}>
          <L k={'ProductByShort'} /> {sellerInfo}
        </LotSellerText>
      )}

      <ContentHead>{props.lot.name}</ContentHead>
      <ContentDesc>{props.lot.description}</ContentDesc>
      {props.lot.winCnt !== 1 && (
        <ContentDesc className="success">
          * {props.lot.winCnt} <L k={'AuctionBiddersMultiple'} />
        </ContentDesc>
      )}
      <Block>
        <ContentDesc>
          <L k={'AuctionBidCurrent'} />
        </ContentDesc>
        <ContentHead>
          {props.lot.price}{' '}
          <ContentDescInline>
            (${currencyConvert(props.lot.price)})
          </ContentDescInline>
          {!props.skipStatus && status === 'win' && (
            <ContentDescInline className="win right bigger">
              <L k={'AuctionStatusWin'} />
            </ContentDescInline>
          )}
          {!props.skipStatus && status === 'lost' && (
            <ContentDescInline className="lost right bigger">
              <L k={'AuctionStatusLost'} />
            </ContentDescInline>
          )}
        </ContentHead>
      </Block>
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
    status: state.Bid.status,
    statusByLotID: state.Bid.statusByLotID,
  }),
  {},
)(LotItem);
