import React from 'react';
import { Block } from '../../common/layout';
import { ContentBold, ContentDesc, ContentHead } from '../../common/typos';
import {
  IDonation,
  IDonationBank,
} from '../../interfaces/donation/IDonationAccount';
import { getFullPrice } from '../../utils/price';
import { Image } from '../../common/images/Image.styles';
import { L } from '../../common/locale';

interface IDonationBillsItemProps {
  donation: IDonation;
}

export function DonationBillsItem(props: IDonationBillsItemProps) {
  return (
    <Block>
      <ContentHead>
        <L k={'FormDonationDetails'} />
      </ContentHead>
      {props.donation.details.map((details: IDonationBank, index: number) => (
        <Block key={index}>
          <ContentDesc>
            <L k={'FormDonationName'} />{' '}
            <ContentBold>{getFullPrice(details.price)}</ContentBold>
          </ContentDesc>
          <ContentDesc>
            <L k={'FormDonationLink'} />{' '}
            <ContentDesc
              dangerouslySetInnerHTML={{ __html: details.link }}
            ></ContentDesc>
          </ContentDesc>
        </Block>
      ))}

      <ContentDesc>
        <L k={'FormDonationScreenshots'} />
      </ContentDesc>
      {props.donation.imagesURL &&
        props.donation.imagesURL.map((imageURL: string) => (
          <Image src={imageURL} alt="donation bill" key={imageURL} />
        ))}
    </Block>
  );
}
