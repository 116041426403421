import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { CountDown } from '../../../common/animation/CountDown';
import { getAuctionNextWeekEnd } from '../../../actions/auctions/auction.actions';

interface IWeekCountDownProps {
  getAuctionNextWeekEnd: () => void;
  weekEnd: undefined | null | string;
}

function WeekCountDown(props: IWeekCountDownProps) {
  useEffect(() => props.getAuctionNextWeekEnd(), []);

  return <CountDown targetDate={props.weekEnd} />;
}

export default connect(
  (state: IState) => ({
    weekEnd: state.Auction.weekEnd,
  }),
  {
    getAuctionNextWeekEnd,
  }
)(WeekCountDown);
