import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { FOUNDATION_IDS_LOADED } from '../types';
import {
  IAccountFoundationIDsResponse,
  IAccountSet,
} from '../../interfaces/account/IAccount';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { IServiceError } from '../../interfaces/system/IError';
import { loadAccountsSet } from './accounts.actions';

const { account } = apiConfig.endpoints;

export const loadFoundationIDs =
  () =>
  (
    dispatch: IDispatch<IAccountSet | string[] | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IAccountFoundationIDsResponse>(account.foundations, {})
      .then((data: IAccountFoundationIDsResponse) => {
        dispatch({ type: FOUNDATION_IDS_LOADED, payload: data.foundationIDs });
        loadAccountsSet(data.foundationIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
