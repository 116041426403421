import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import {
  IAuctionCreateBody,
  IAuctionCreateResponse,
  IAuctionDataSet,
  IAuctionDonationItem,
  IAuctionListResponse,
  IAuctionUpdateBody,
} from '../../interfaces/auctions/IAuction';
import { dispatchError, dispatchErrorAnAuth } from '../../service/error';
import {
  AUCTION_ACTION,
  AUCTION_LOAD,
  ITEM_CREATED,
  ITEM_LOADED,
  ITEM_REMOVED,
  ITEM_UPDATED,
} from '../types';

const { main } = apiConfig.endpoints.auctions.auction;

const reloadAuctions = (
  dispatch: IDispatch<string | IAuctionDataSet | IServiceError>,
  getState: () => IState
) => {
  if (!getState().Account.isAdmin) {
    return;
  }

  callApi<IAuctionListResponse>(main)
    .then((data: IAuctionListResponse) => {
      const payload: IAuctionDataSet = {
        auctionPath: null,
        auctions: data.auctions,
      };
      dispatch({ type: AUCTION_LOAD, payload });
      dispatch({ type: AUCTION_ACTION, payload: ITEM_LOADED });
    })
    .catch((error: Error | IApiError) => catchError(dispatch, error, getState));
};

export const deleteAuction =
  (auctionID: string) =>
  (
    dispatch: IDispatch<string | IAuctionDataSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi(main, { auctionID }, undefined, 'DELETE')
      .then(() =>
        dispatchError(dispatch, {
          message: 'Auction is removed',
          params: {},
          type: 'Info',
        })
      )
      .then(() => {
        const payload: IAuctionDataSet = {
          auctionPath: null,
          auctions: [],
        };
        dispatch({ type: AUCTION_LOAD, payload });
        dispatch({ type: AUCTION_ACTION, payload: ITEM_REMOVED });
      })
      .then(() => reloadAuctions(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const createAuction =
  (
    name: null | string,
    description: null | string,
    ind: null | number,
    dateStart: null | string,
    dateAuction: null | string,
    image: null | string,
    imageQrCode: null | string,
    linkCalendar: null | string,
    linkBroadcast: null | string
  ) =>
  (
    dispatch: IDispatch<string | IAuctionDataSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const { account } = getState().Account;
    if (!account) {
      return dispatchErrorAnAuth(dispatch, 'createAuction');
    }

    const body: IAuctionCreateBody = {};
    if (name) {
      body.name = name;
    }
    if (description) {
      body.description = description;
    }
    if (ind) {
      body.ind = ind;
    }
    if (dateStart) {
      body.dateStart = dateStart;
    }
    if (dateAuction) {
      body.dateAuction = dateAuction;
    }
    if (image) {
      body.image = image;
    }
    if (imageQrCode) {
      body.imageQrCode = imageQrCode;
    }
    if (linkCalendar) {
      body.linkCalendar = linkCalendar;
    }
    if (linkBroadcast) {
      body.linkBroadcast = linkBroadcast;
    }
    callApi<IAuctionCreateResponse>(main, {}, body)
      .then(() =>
        dispatchError(dispatch, {
          message: 'Auction is created',
          params: {},
          type: 'Info',
        })
      )
      .then(() => dispatch({ type: AUCTION_ACTION, payload: ITEM_CREATED }))
      .then(() => reloadAuctions(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const updateAuction =
  (
    auctionID: string,
    name: null | string,
    description: null | string,
    ind: null | number,
    dateStart: null | string,
    dateAuction: null | string,
    dateWeek: null | string,
    image: null | string,
    imageQrCode: null | string,
    donations: null | IAuctionDonationItem[],
    linkCalendar: null | string,
    linkBroadcast: null | string
  ) =>
  (
    dispatch: IDispatch<string | IAuctionDataSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const { account } = getState().Account;
    if (!account) {
      return dispatchErrorAnAuth(dispatch, 'updateAuction');
    }

    const body: IAuctionUpdateBody = {
      auctionID,
      donations,
    };
    if (name) {
      body.name = name;
    }
    if (description) {
      body.description = description;
    }
    if (ind) {
      body.ind = ind;
    }
    if (dateStart) {
      body.dateStart = dateStart;
    }
    if (dateAuction) {
      body.dateAuction = dateAuction;
    }
    if (dateWeek) {
      body.dateWeek = dateWeek;
    }
    if (image) {
      body.image = image;
    }
    if (imageQrCode) {
      body.imageQrCode = imageQrCode;
    }
    if (linkCalendar) {
      body.linkCalendar = linkCalendar;
    }
    if (linkBroadcast) {
      body.linkBroadcast = linkBroadcast;
    }
    callApi(main, {}, body, 'POST')
      .then(() =>
        dispatchError(dispatch, {
          message: 'Auction is updated',
          params: {},
          type: 'Info',
        })
      )
      .then(() => dispatch({ type: AUCTION_ACTION, payload: ITEM_UPDATED }))
      .then(() => reloadAuctions(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
