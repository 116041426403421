import React, { useEffect, useState } from 'react';
import { FormBlock } from '../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../common/forms/FormCommon';
import { FormsFields } from '../../common/forms/forms';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { setDonationBill } from '../../actions/donation/donationAccount.actions';
import { IDonationAccountBillCreate } from '../../interfaces/donation/IDonationAccount';

interface IDonationBillsFormProps {
  auctionID: string;
  donationID: string;
  setDonationBill: (
    auctionID: string,
    donationID: string,
    imageBase64: string
  ) => void;
  cbCancel?: () => void;
  actions: string[];
  errors: string[];
}

function DonationBillsForm(props: IDonationBillsFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields] = useState<IFormFields<IDonationAccountBillCreate>>({
    imageBase64: {
      ...FormsFields.imageURL,
      params: {
        image: {
          isDraggable: true,
          autoSelect: true,
          noCrop: true,
        },
      },
    },
  });

  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbCreate = () => {
    setIsSending(true);
    props.setDonationBill(
      props.auctionID,
      props.donationID,
      (fields.imageBase64 &&
        fields.imageBase64.$valueList &&
        fields.imageBase64.$valueList[0]) ||
        ''
    );
  };

  return (
    <FormBlock>
      <FormCommon
        fields={fields}
        cb={{
          cbCreate,
          cbCancel: props.cbCancel,
        }}
        action={{
          isUpdate: false,
          isSending,
        }}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    actions: state.Donation.actions,
    errors: state.System.errors,
  }),
  {
    setDonationBill,
  }
)(DonationBillsForm);
