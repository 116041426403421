import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { Block } from '../../common/layout';
import { ContentHead } from '../../common/typos';
import { sendPageEvent } from '../../utils/analytics';
import PartnerSellerFormSelfNew from '../../components/account/PartnerSeller/PartnerSellerFormSelf';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { L } from '../../common/locale';

interface IPartnerSetting {}

function PartnerSetting(props: IPartnerSetting) {
  const navigate = useNavigate();
  useEffect(() => sendPageEvent('PartnerSetting'), []);

  const goToHome = () => {
    navigate(appConfig.routes.main.home);
  };
  return (
    <AppContent>
      <Block>
        <ContentHead>
          <L k={'AccountSettingsPartner'} />
        </ContentHead>

        <PartnerSellerFormSelfNew cbCancel={goToHome} />
      </Block>
    </AppContent>
  );
}

export default connect((state: IState) => ({}), {})(PartnerSetting);
