import React from 'react';
import { Block } from '../layout';
import { ContentDesc } from '../typos';
import { FormFieldSelect } from '../forms/FormFieldSelect';

export interface BatchFiltersItem {
  title?: string;
  value: null | string;
  nullNameBeforeSelect?: string;
  values: string[];
}

interface IBatchFiltersProps {
  $params: Record<string, BatchFiltersItem>;
  cb: () => void;
}

export function BatchFilters(props: IBatchFiltersProps) {
  const keys: string[] = Object.keys(props.$params);

  const setValue = (key: string, values: string[]) => {
    const item: BatchFiltersItem = props.$params[key];
    const value: null | string = values[0];
    props.$params[key].value = item.nullNameBeforeSelect === value ? null : value;
    props.cb();
  };

  const renderItem = (key: string): JSX.Element => {
    const item: BatchFiltersItem = props.$params[key];
    if (!item) {
      return <></>;
    }

    return (
      <Block key={key}>
        <ContentDesc>{item.title || 'Filter on ' + key + ':'}</ContentDesc>
        <FormFieldSelect
          isDisabled={false}
          valueError={null}
          saveValue={(values: string[]) => setValue(key, values)}
          defaultValue={item.value ? [item.value] : null}
          nullNameBeforeSelect={item.nullNameBeforeSelect}
          values={item.values}
        />
      </Block>
    );
  };

  return (
    <Block className='article'>
      {keys.map((key: string) => renderItem(key))}
    </Block>
  );
}
