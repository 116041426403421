import {
  getFromLocal,
  KeyLocal,
  removeFromLocal,
  setToLocal,
} from '../../service/localStorage';
import { IPlatformRole } from '../../interfaces/Example/IRole';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { ACCOUNT_ROLE_SET } from '../types';
import { IServiceError } from '../../interfaces/system/IError';
import { dispatchError } from '../../service/error';
import { getNotificationsIDs } from './notification.actions';
import {
  IAccountNotification,
  INotificationSet,
} from '../../interfaces/account/IAccountNotification';
import { checkIsExample } from '../../config/platform.config';

const key: KeyLocal = 'PlatformRole';

export function getPlatformRole(): IPlatformRole {
  const role: null | string = getFromLocal(key);

  if (role === null) {
    setToLocal(key, 'admin');
  }

  if (role === 'admin' || role === null) {
    return 'admin';
  } else if (role === 'donator') {
    return 'donator';
  } else if (role === 'presenter') {
    return 'presenter';
  } else {
    return '';
  }
}

export const setPlatformRole =
  (role: IPlatformRole) =>
  (
    dispatch: IDispatch<
      IPlatformRole | string[] | INotificationSet | IServiceError
    >,
    getState: () => IState
  ): void => {
    if (!checkIsExample() || !getState().Account.account) {
      return;
    }

    setToLocal(key, role);
    dispatch({ type: ACCOUNT_ROLE_SET, payload: role });

    const err: IServiceError = {
      type: 'Info',
      message: `You are ${role} from now!`,
      params: { access: ' Menu is changed and access to moderation.' },
    };
    dispatchError(dispatch, err);
    getNotificationsIDs()(dispatch, getState);
  };

export function removePlatformRole() {
  removeFromLocal(key);
}

export function filterNotificationsToRole(
  notifications: IAccountNotification[],
  isAdmin: boolean
): IAccountNotification[] {
  if (checkIsExample()) {
    return [...notifications];
  } else {
    return notifications.filter(
      (notification: IAccountNotification) =>
        !notification.isAdmin || (notification.isAdmin && isAdmin)
    );
  }
}
