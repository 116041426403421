import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { ACCOUNT_ACTION, ITEM_UPDATED } from '../types';
import {
  IAccount,
  IAccountAuthResponse,
  IAccountBodyUpdate,
} from '../../interfaces/account/IAccount';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { IServiceError } from '../../interfaces/system/IError';
import { IPlatformRole } from '../../interfaces/Example/IRole';
import { INotificationSet } from '../../interfaces/account/IAccountNotification';
import { dispatchError } from '../../service/error';
import { checkIsLogged } from './user.actions';

const { main } = apiConfig.endpoints.account;

export const updateAccount =
  (image: null | string, name: null | string) =>
  (
    dispatch: IDispatch<
      | IAccount
      | IPlatformRole
      | string
      | string[]
      | INotificationSet
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const body: IAccountBodyUpdate = {};
    if (image) {
      body.image = image;
    }
    if (name) {
      body.name = name;
    }

    callApi<IAccountAuthResponse>(main, {}, body, 'POST')
      .then(() => dispatch({ type: ACCOUNT_ACTION, payload: ITEM_UPDATED }))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Profile is updated',
          params: {},
          type: 'Info',
        })
      )
      .then(() => checkIsLogged()(dispatch, getState))
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
