import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  NOTIFICATION_VIEW,
  NOTIFICATIONS_FOR_MODERATION_LOAD,
  NOTIFICATIONS_IDS_LOAD,
  NOTIFICATIONS_ONE_LOAD,
  NOTIFICATIONS_SET_LOAD,
} from '../../actions/types';
import { ActionCase, IStateNotification } from '../../interfaces/system/IState';
import { IAccountNotification, INotificationSet } from '../../interfaces/account/IAccountNotification';

const initialStateNotification: IStateNotification = {
  isViewIncremented: false,
  IDs: [],
  set: {},
  cntAll: 0,
  cntNew: 0,
  moderation: [],
};

function reCount(state: Draft<IStateNotification>) {
  let cnt: number = 0;
  state.IDs.forEach((id: string) => {
    const notification: null | IAccountNotification = state.set[id] || null;
    if (notification && !notification.isRead) {
      cnt++;
    }
  });
  state.cntNew = cnt;
  if (cnt) {
    state.cntAll = state.IDs.length;
    state.isViewIncremented = false;
  }
}

export const notificationReducer: Reducer = createReducer(
  initialStateNotification,
  (builder: ActionReducerMapBuilder<IStateNotification>) => {
    builder.addCase<string, ActionCase<string[]>>(
      NOTIFICATIONS_IDS_LOAD,
      (state: Draft<IStateNotification>, action: ActionCase<string[]>) => {
        state.IDs = action.payload;
        reCount(state);
      },
    );
    builder.addCase<string, ActionCase<IAccountNotification>>(
      NOTIFICATIONS_ONE_LOAD,
      (
        state: Draft<IStateNotification>,
        action: ActionCase<IAccountNotification>,
      ) => {
        state.set[action.payload.id] = action.payload;
        reCount(state);
      },
    );
    builder.addCase<string, ActionCase<INotificationSet>>(
      NOTIFICATIONS_SET_LOAD,
      (
        state: Draft<IStateNotification>,
        action: ActionCase<INotificationSet>,
      ) => {
        const set: INotificationSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.set[id] = set[id];
        });

        reCount(state);
      },
    );
    builder.addCase<string, ActionCase<void>>(
      NOTIFICATION_VIEW,
      (state: Draft<IStateNotification>) => {
        state.isViewIncremented = true;
      },
    );
    builder.addCase<string, ActionCase<IAccountNotification[]>>(
      NOTIFICATIONS_FOR_MODERATION_LOAD,
      (
        state: Draft<IStateNotification>,
        action: ActionCase<IAccountNotification[]>,
      ) => {
        state.moderation = action.payload;
      },
    );
  },
);
