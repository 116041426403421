import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import {
  IDonationBankDetails,
  IDonationBankDetailsUpdate,
} from '../../interfaces/donation/IDonationBank';
import { ContentDesc, ContentHead } from '../../common/typos';
import { ErrorInfo, FormBlock } from '../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../common/forms/FormCommon';
import { FormsFields } from '../../common/forms/forms';
import { sendEvent } from '../../utils/analytics';
import { Block } from '../../common/layout';
import {
  getDonationsBank,
  setDonationsBank,
  updateDonationsBank,
} from '../../actions/donation/donationBank.actions';
import AccountSelect from '../account/AccountSelect';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface IBankDetailsForm {
  auctionID: string;
  bankDetails: null | IDonationBankDetails;
  isDisabled?: boolean;
  getDonationsBank: (auctionID: string) => void;
  setDonationsBank: (
    auctionID: string,
    sellerID: null | string, // null - is main bank details
    foundationName: null | string,
    link: string
  ) => void;
  updateDonationsBank: (
    detailsID: string,
    auctionID: string,
    sellerID: null | string, // null - is main bank details
    foundationName: null | string,
    link: string
  ) => void;
  cbCancel: () => void;
  cbDelete?: (detailsID: string) => void;
  actions: string[];
  errors: string[];
}

function BankDetailsForm(props: IBankDetailsForm) {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [accountID, setAccountID] = useState<null | string>(null);
  const [accountIDFailed, setAccountIDFailed] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormFields<IDonationBankDetailsUpdate>>(
    {
      foundationName: {
        ...FormsFields.name,
        isRequired: false,
        placeholder: t('FormPlaceHolderExample') + ' `Prytula Foundation`',
      },
      link: {
        ...FormsFields.name,
        isRequired: false,
        max: 500,
        placeholder:
          t('FormPlaceHolderExample') +
          " `<a href='https://prytulafoundation.org/en/donation' target='_blank'>Prytula Foundation</a>`",
      },
    }
  );

  useEffect(() => sendEvent('Open-AuctionForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.bankDetails) {
      return;
    }

    if (fields.foundationName) {
      fields.foundationName.$value = props.bankDetails.foundationName;
    }
    if (fields.link) {
      fields.link.$value = props.bankDetails.link;
    }

    setFields({ ...fields });
  }, [props.bankDetails]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbCreate = () => {
    setIsSending(true);
    props.setDonationsBank(
      props.auctionID,
      accountID,
      (fields.foundationName && fields.foundationName.$value) || '',
      (fields.link && fields.link.$value) || ''
    );
  };
  const cbUpdate = () => {
    if (!props.bankDetails) {
      return;
    }

    setIsSending(true);
    props.updateDonationsBank(
      props.bankDetails.id,
      props.auctionID,
      accountID,
      (fields.foundationName && fields.foundationName.$value) || null,
      (fields.link && fields.link.$value) || ''
    );
  };
  const cbDelete = () => {
    if (!props.bankDetails || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.bankDetails.id);
  };

  const saveAccountID = (accountIDLocal: string) => {
    setAccountID(accountIDLocal);
    setAccountIDFailed(false);
  };

  const renderElements = (): JSX.Element => {
    return (
      <Block>
        <ContentDesc>
          <L k={'FormActionSelectSeller'} />
        </ContentDesc>
        <AccountSelect
          mode={'seller'}
          cbAccount={saveAccountID}
          selectedIDs={accountID ? [accountID] : null}
        />
        {accountIDFailed && <ErrorInfo>Seller should be selected</ErrorInfo>}
      </Block>
    );
  };

  return (
    <FormBlock>
      {!props.bankDetails && (
        <ContentHead>
          <L k={'FormBankDetailsTitleCreate'} />
        </ContentHead>
      )}
      {props.bankDetails && (
        <ContentHead>
          <L k={'FormBankDetailsTitleUpdate'} />
        </ContentHead>
      )}

      <FormCommon
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          cbCreate,
          cbUpdate,
          cbDelete,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: !!props.bankDetails,
          isSending,
        }}
        renderElements={renderElements}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    actions: state.Donation.actions,
    errors: state.System.errors,
  }),
  {
    getDonationsBank,
    setDonationsBank,
    updateDonationsBank,
  }
)(BankDetailsForm);
