import React, { useEffect, useState } from 'react';
import { BlockLiveIsStarted, Counts, Item } from './LiveIsStarted.styles';
import { connect } from 'react-redux';
import { ContentDesc, ContentHead } from '../common/typos';
import { IState } from '../interfaces/system/IState';
import { ActionButton } from '../common/styles';
import { useNavigate } from 'react-router-dom';
import { loadLotOne } from '../actions/auctions/lotPublic.actions';
import {
  checkPageIsInAdminPages,
  checkPageIsInAuctionMode,
  getAuctionPath,
} from '../utils/auctionLive';
import { loadBidSet } from '../actions/auctions/bid.actions';
import { IAuctionMode } from '../interfaces/auctions/IAuction';
import { ILotType } from '../interfaces/auctions/ILotPublic';
import { IAccount } from '../interfaces/account/IAccount';
import {
  getPathBeforeLogin,
  removePathBeforeLogin,
  savePathBeforeLogin,
} from '../actions/account/user.actions';
import { appConfig } from '../config/app.config';
import { getToken } from '../actions/account/auth.actions';
import { checkIsExample, getRewriteConfig } from '../config/platform.config';

interface ILiveIsStartedProps {
  loadLotOne: (lotType: ILotType, lotID: null | string) => void;
  loadBidSet: (lotType: ILotType, lotID: string, bidIDs: string[]) => void;
  account: null | IAccount;
  currentLotID: undefined | null | string;
  bidIDsByLotID: Record<string, string[]>;
  auctionMode: undefined | null | IAuctionMode;
}

let timer: any;

function LiveIsStarted(props: ILiveIsStartedProps) {
  const navigate = useNavigate();
  const lotType: ILotType = 'Live';
  const [isShowLive, setIsShowLive] = useState<boolean>(false);
  const [isShowWeek, setIsShowWeek] = useState<boolean>(false);
  const [timerType] = useState<'keep' | 'redirect'>(
    checkIsExample() ? 'keep' : 'redirect'
  );
  const [seconds, setSeconds] = useState<number>(30);

  useEffect(() => {
    if (props.auctionMode === undefined) {
      return;
    }

    if (!props.auctionMode) {
      // reset
      if (timer) {
        clearTimeout(timer);
      }
      setIsShowWeek(false);
      setIsShowLive(false);
      return;
    }

    if (checkPageIsInAuctionMode(props.auctionMode)) {
      setShowFalse();
      return;
    }

    if (checkPageIsInAdminPages()) {
      setShowFalse();
      return;
    }

    if (checkIsExample()) {
      initCountDown();
      if (props.auctionMode === 'weekAuction') {
        setIsShowWeek(true);
      } else {
        setIsShowLive(true);
      }
    } else {
      if (props.auctionMode === 'weekAuction') {
        if(getRewriteConfig()?.hardRedirectToWeek){
          goToWeek();
        } else {
          setIsShowWeek(true);
          initCountDown();
        }
      } else {
        goToLive();
      }
    }
  }, [props.auctionMode, props.account]);
  useEffect(
    () => props.loadLotOne(lotType, props.currentLotID || null),
    [props.currentLotID]
  );
  useEffect(() => {
    if (props.currentLotID) {
      props.loadBidSet(
        lotType,
        props.currentLotID,
        props.bidIDsByLotID[props.currentLotID]
      );
    }
  }, [props.currentLotID, props.bidIDsByLotID]);

  const initCountDown = () => {
    const newTime = seconds === 30 ? 30 : 10;
    setSeconds(newTime);
    countDown(newTime, true);
  };
  const countDown = (lSec: number, showFirst?: boolean) => {
    if (!showFirst && !timer) {
      setSeconds(0);
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      if (lSec > 0) {
        countDown(lSec - 1);
      } else {
        completeTimer();
      }

      setSeconds(lSec);
    }, 1000);
  };

  const completeTimer = () => {
    if (timerType === 'keep') {
      setShowFalse();
    } else if (props.auctionMode === 'weekAuction') {
      goToWeek();
    } else if (isShowLive) {
      goToLive();
    } else if (isShowWeek) {
      goToWeek();
    }
  };

  const goToLive = () => {
    if (!props.account) {
      if (!getPathBeforeLogin() || !getToken()) {
        return;
      }
      savePathBeforeLogin(window.location.pathname);
      navigate(appConfig.routes.main.login);
      return;
    }

    setShowFalse();
    const path: null | string = getAuctionPath(props.auctionMode);
    if (path) {
      removePathBeforeLogin();
      navigate(path);
    }
  };
  const goToWeek = () => {
    setShowFalse();
    const path: null | string = getAuctionPath(props.auctionMode);
    if (path) {
      navigate(path);
    }
  };
  const keepHere = () => {
    setShowFalse();
  };

  const setShowFalse = () => {
    setIsShowLive(false);
    setIsShowWeek(false);

    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  };

  return (
    <BlockLiveIsStarted>
      {isShowLive && (
        <Item>
          <ContentHead>Live Auction is started</ContentHead>
          <ContentDesc>Would you like to go to Live Auction?</ContentDesc>

          <ActionButton className="success" onClick={() => goToLive()}>
            {timerType === 'redirect' && <Counts>{seconds}</Counts>}
            Go to Live
          </ActionButton>
          <ActionButton className="fail" onClick={keepHere}>
            {timerType === 'keep' && <Counts>{seconds}</Counts>}
            Keep here
          </ActionButton>
        </Item>
      )}
      {isShowWeek && (
        <Item>
          <ContentHead>Weekly Auction is on</ContentHead>
          <ContentDesc>Would you like to go to Week Auction?</ContentDesc>

          <ActionButton className="success" onClick={goToWeek}>
            {timerType === 'redirect' && <Counts>{seconds}</Counts>}
            Go to Weekly Auction
          </ActionButton>
          <ActionButton className="fail" onClick={keepHere}>
            {timerType === 'keep' && <Counts>{seconds}</Counts>}
            Stay here
          </ActionButton>
        </Item>
      )}
    </BlockLiveIsStarted>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    currentLotID: state.Lot.short.currentLotID,
    bidIDsByLotID: state.Bid.IDsByLotID,
    auctionMode: state.Auction.auctionMode,
  }),
  {
    loadLotOne,
    loadBidSet,
  }
)(LiveIsStarted);
