import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { Loading } from '../../common/animation/Loading';
import {
  IHallLegend,
  IHallLegendOrder,
} from '../../interfaces/hall/IHallLegends';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { ContentDesc, ContentHead } from '../../common/typos';
import AccountImage from '../account/AccountImage';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { LegendItemPrice } from './LegendItemPrice';
import { makePathFromName } from '../../utils/routes';
import { Block } from '../../common/layout';

interface ILegendItemProps {
  legend: IHallLegend;
  order: IHallLegendOrder;
  accounts: IAccountSet;
}

function LegendItem(props: ILegendItemProps) {
  const navigate = useNavigate();

  const goToLegend = () => {
    const { accountID } = props.legend;
    const account = props.accounts[accountID];
    if (!account) {
      return;
    }

    const accountPath: string = makePathFromName(account.name);
    const path = appConfig.routes.hall.legendByName.replace(
      appConfig.params.accountPath,
      accountPath
    );
    navigate(path);
  };

  return (
    <Block className={'flex clickable'} onClick={goToLegend}>
      <AccountImage accountID={props.legend.accountID} size={'S'} />
      <Block>
        <ContentHead>
          {props.accounts[props.legend.accountID] ? (
            <>Name: {props.accounts[props.legend.accountID].name} </>
          ) : (
            <>
              <>Name: {props.legend.accountID.slice(0, 3)} </>{' '}
              <Loading size={'small'} />
            </>
          )}
        </ContentHead>

        {props.order === 'buyTotal' ? (
          <>
            <LegendItemPrice price={props.legend.buyTotal} title={'Donated'} />
            <LegendItemPrice price={props.legend.sellTotal} title={'Sold'} />
          </>
        ) : (
          <>
            <LegendItemPrice price={props.legend.sellTotal} title={'Sold'} />
            <LegendItemPrice price={props.legend.buyTotal} title={'Donated'} />
          </>
        )}

        <ContentDesc>Bids: {props.legend.bidTotal}</ContentDesc>
      </Block>
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
  }),
  {}
)(LegendItem);
