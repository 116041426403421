export const socketRoutes = {
  auth: {
    connect: 'auth/connect',
  },
  lot: {
    win: 'lot/win',
    freshBid: 'lot/freshBid',
    restart: 'lot/restart',
    next: 'lot/next',
    winError: 'lot/win/error',
  },
  bid: {
    updates: 'bid/updates',
  },
  report: {
    auction: 'report/auction',
    final: 'report/final',
  },
  presenter: {
    on: 'presenter',
    report: 'presenter/report',
  },
  week: {
    bid: 'week/bid',
    complete: 'week/complete',
  },
};
