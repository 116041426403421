import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { loadAccountsSet } from '../../actions/account/accounts.actions';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { Loading } from '../../common/animation/Loading';

interface IAccountItemMailProps {
  loadAccountsSet: (accountIDs: string[]) => void;
  accountID?: string;
  set: IAccountSet;
  showName?: boolean;
  showInline?: boolean;
}

function AccountItemMail(props: IAccountItemMailProps): JSX.Element {
  const [label, setLabel] = useState<null | string>(null);

  useEffect(() => {
    if (props.accountID && !props.set[props.accountID]) {
      props.loadAccountsSet([props.accountID]);
    }
  }, []);

  useEffect(() => {
    const account: null | IAccount =
      (props.accountID && props.set[props.accountID]) || null;
    if (!account) {
      return setLabel(null);
    }

    setLabel(
      props.showName ? account.name : account.name + ' - ' + account.mail,
    );
  }, [props.set, props.accountID]);

  return !props.accountID ? (
    <></>
  ) : label ? (
    props.showInline ? (
      <ContentDescInline>{label}</ContentDescInline>
    ) : (
      <ContentDesc>{label}</ContentDesc>
    )
  ) : (
    <Loading size='small' />
  );
}

export default connect(
  (state: IState) => ({
    set: state.Account.accounts.set,
  }),
  {
    loadAccountsSet,
  },
)(AccountItemMail);
