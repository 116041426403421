import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { appConfig } from '../../config/app.config';
import { AppContent } from '../../App.styles';
import { sendPageEvent } from '../../utils/analytics';
import { Block } from '../../common/layout';
import { Loading } from '../../common/animation/Loading';
import LotItem from '../../components/auctions/live/LotItem';
import Bids from '../../components/auctions/live/Bids';
import WeekBidWatcher from '../../components/auctions/week/WeekBidWatcher';
import { PageHead } from '../../common/typos';
import WeekCountDown from '../../components/auctions/week/WeekCountDown';
import {
  ILotShort,
  ILotShortSet,
  ILotType,
} from '../../interfaces/auctions/ILotPublic';
import { loadLotIDs } from '../../actions/auctions/lotPublic.actions';
import MakeBid from '../../components/auctions/live/MakeBid';
import { L } from '../../common/locale';
import CheckIsLogged from '../../components/account/auth/CheckIsLogged';

interface IAuctionWeekItemPageProps {
  loadLotIDs: (lotType: ILotType) => void;
  weekLotIDs: undefined | string[];
  lotSet: ILotShortSet;
}

function AuctionWeekItemPage(props: IAuctionWeekItemPageProps) {
  const navigate = useNavigate();
  const { productPath } = useParams();
  const [lotName, setLotName] = useState<null | string>(null);
  const [lot, setLot] = useState<null | ILotShort>(null);

  useEffect(() => sendPageEvent('AuctionWeekItem'), []);
  useEffect(() => props.loadLotIDs('Week'), []);
  useEffect(() => {
    if (!productPath) {
      return navigate(appConfig.routes.week.auction);
    }

    setLotName(decodeURIComponent(productPath));
  }, []);
  useEffect(() => {
    const { lotSet, weekLotIDs } = props;
    if (weekLotIDs) {
      weekLotIDs.forEach((lotID: string) => {
        const lotLocal: null | ILotShort = lotSet[lotID];
        if (lotLocal && lotLocal.name === lotName) {
          setLot(lotLocal);
          return;
        }
      });
    }
  }, [props.weekLotIDs, props.lotSet]);

  const goToWeekAuction = (): void => {
    navigate(appConfig.routes.week.auction);
  };

  return (
    <AppContent>
      <Block>
        <CheckIsLogged />
        <PageHead className="clickable" onClick={goToWeekAuction}>
          <L k={'MenuAuctionWeekAllLots'} />
        </PageHead>
        <WeekCountDown />
        <WeekBidWatcher />
        {lot && (
          <>
            <LotItem lot={lot} />
            <MakeBid lot={lot} lotType={'Week'} />
            <Bids lotID={lot.id} lotType={'Week'} />
          </>
        )}

        {!lot && <Loading size="big" />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    weekLotIDs: state.Lot.short.WeekIDs,
    lotSet: state.Lot.short.set,
  }),
  {
    loadLotIDs,
  },
)(AuctionWeekItemPage);
