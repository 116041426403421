import { appConfig } from '../config/app.config';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { IAuction } from '../interfaces/auctions/IAuction';

export function makePathFromName(name: string): string {
  return name.replace(/ /g, '_');
}

// what if name has _
export function makeNameFromPath(path: string): string {
  return path.replace(/_/g, ' ');
}

export function makeReportPathFromOrder(order: number): string {
  const path = 'report-';
  if (order < 10) {
    return path + '00' + order;
  } else if (order < 100) {
    return path + '0' + order;
  } else {
    return path + order;
  }
}

export const navigateToAuction = (
  auction: IAuction,
  navigate: NavigateFunction,
) => {
  if (!auction) {
    return;
  }
  const auctionPath = makePathFromName(auction.name);
  navigate(
    appConfig.routes.auctions.one.replace(
      appConfig.params.auctionPath,
      auctionPath,
    ),
  );
};

export const navigateToAuctionDonation = (
  auction: IAuction,
  navigate: NavigateFunction,
) => {
  if (!auction) {
    return;
  }
  const auctionPath = makePathFromName(auction.name);
  navigate(
    appConfig.routes.auctions.donations.replace(
      appConfig.params.auctionPath,
      auctionPath,
    ),
  );
};
