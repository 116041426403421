import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  SHOP_ANALYTICS_LOAD,
  SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_ALL_LOAD,
  SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LIST_LOAD,
  SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LOAD,
  SHOP_PRODUCT_ANALYTICS_LOAD,
} from '../../actions/types';
import { ActionCase, IStateShopProductAnalytics } from '../../interfaces/system/IState';
import {
  IProductAnalyticsByAccount,
  IProductAnalyticsByAccountSet,
  IProductAnalyticsSet,
} from '../../interfaces/shop/IProductAnalytics';
import { IShopAnalytics } from '../../interfaces/shop/IShopAnalytics';

const initialStateProductAnalytics: IStateShopProductAnalytics = {
  byProductID: {},
  byProductIDSelf: {},
  shopAnalytics: undefined,
  likedProductIDs: null,
  willBidProductIDs: null,
};

export const shopProductAnalyticsReducer: Reducer = createReducer(
  initialStateProductAnalytics,
  (builder: ActionReducerMapBuilder<IStateShopProductAnalytics>) => {
    builder.addCase<string, ActionCase<IProductAnalyticsSet>>(
      SHOP_PRODUCT_ANALYTICS_LOAD,
      (
        state: Draft<IStateShopProductAnalytics>,
        action: ActionCase<IProductAnalyticsSet>,
      ) => {
        const { productID, analytics } = action.payload;
        state.byProductID[productID] = analytics[0] || null;
      },
    );
    builder.addCase<string, ActionCase<IProductAnalyticsByAccountSet>>(
      SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LOAD,
      (
        state: Draft<IStateShopProductAnalytics>,
        action: ActionCase<IProductAnalyticsByAccountSet>,
      ) => {
        const { productID, analytics } = action.payload;
        state.byProductIDSelf[productID] = analytics;
      },
    );
    builder.addCase<string, ActionCase<IProductAnalyticsByAccount[]>>(
      SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_ALL_LOAD,
      (
        state: Draft<IStateShopProductAnalytics>,
        action: ActionCase<IProductAnalyticsByAccount[]>,
      ) => {
        const likedList: string[] = [];
        const willBidList: string[] = [];
        action.payload.forEach((item: IProductAnalyticsByAccount) => {
          const { productID, isLiked, willBid } = item;
          if (isLiked) {
            likedList.push(productID);
          }
          if (willBid) {
            willBidList.push(productID);
          }
        });

        state.likedProductIDs = likedList;
        state.willBidProductIDs = willBidList;
      },
    );
    builder.addCase<string, ActionCase<IProductAnalyticsByAccount[]>>(
      SHOP_PRODUCT_ANALYTICS_BY_ACCOUNT_LIST_LOAD,
      (
        state: Draft<IStateShopProductAnalytics>,
        action: ActionCase<IProductAnalyticsByAccount[]>,
      ) => {
        action.payload.forEach((analytics: IProductAnalyticsByAccount) => {
          const { productID } = analytics;
          state.byProductIDSelf[productID] = analytics;
        });
      },
    );
    builder.addCase<string, ActionCase<null | IShopAnalytics>>(
      SHOP_ANALYTICS_LOAD,
      (
        state: Draft<IStateShopProductAnalytics>,
        action: ActionCase<null | IShopAnalytics>,
      ) => {
        state.shopAnalytics = action.payload;
      },
    );
  },
);
