import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  ACCOUNT_LOADED,
  AUCTION_MODE_LOAD,
  BID_ADDED,
  BID_ADDED_COMPLETED,
  BID_IDS_LAST_LOAD,
  BID_IDS_LOAD,
  BID_SET_LOAD,
  LOT_SHORT_CURRENT_ID_LOAD,
  LOT_WIN_CNT_SET,
} from '../../actions/types';
import { ActionCase, IStateBid } from '../../interfaces/system/IState';
import { IBid, IBidIDsData, IBidSet, IBidSetData, IBidUpdate } from '../../interfaces/auctions/IBid';
import { IAccount } from '../../interfaces/account/IAccount';
import { ILotStatus } from '../../interfaces/auctions/ILotPublic';
import { IAuctionModeResponse } from '../../interfaces/auctions/IAuction';

const initialStateBid: IStateBid = {
  byID: {},
  IDsByLotID: {},
  accountID: null,
  currentLotID: null,
  lotWinCntSet: {},
  status: null,
  // week
  statusByLotID: {},
  IDsLast: [],
  updates: [],
};

function getStatus(
  bids: IBid[],
  accountID: null | string,
  lotWinCnt: number,
): null | 'win' | 'lost' {
  if (!accountID) {
    return null;
  }

  for (let i = 0, len = lotWinCnt + 3; i < len; i++) {
    const bid: undefined | IBid = bids[i];
    if (!bid) {
      return null;
    }

    if (bid.accountID === accountID) {
      return i < lotWinCnt ? 'win' : 'lost';
    }
  }

  return null;
}

function updateStatus($state: Draft<IStateBid>) {
  const lotID: null | string = $state.currentLotID;
  const bidIDs: null | string[] = lotID ? $state.IDsByLotID[lotID] : null;
  const bids: IBid[] = [];
  if (bidIDs) {
    bidIDs.forEach((bidID: string) => {
      const bid: undefined | IBid = $state.byID[bidID];
      if (bid) {
        bids.push(bid);
      }
    });
  }

  $state.status = getStatus(
    bids,
    $state.accountID,
    (lotID && $state.lotWinCntSet[lotID]) || 1,
  );
}

function updateStatusByLotID(
  lotID: string,
  accountID: null | string,
  $IDsByLotID: Record<string, string[]>,
  $statusByLotID: Record<string, ILotStatus>,
  $byID: IBidSet,
  $lotWinCntSet: Record<string, number>,
): void {
  const bids: IBid[] = [];
  if (!$IDsByLotID[lotID]) {
    $IDsByLotID[lotID] = [];
  }

  $IDsByLotID[lotID].forEach((id: string) => {
    if ($byID[id]) {
      bids.push($byID[id]);
    }
  });
  $statusByLotID[lotID] = getStatus(
    bids,
    accountID,
    (lotID && $lotWinCntSet[lotID]) || 1,
  );
}

function updateStatusByLotSet(
  SetLotID: Record<string, true>,
  accountID: null | string,
  $IDsByLotID: Record<string, string[]>,
  $statusByLotID: Record<string, ILotStatus>,
  $byID: IBidSet,
  $lotWinCntSet: Record<string, number>,
): void {
  Object.keys(SetLotID).map((lotID: string) =>
    updateStatusByLotID(
      lotID,
      accountID,
      $IDsByLotID,
      $statusByLotID,
      $byID,
      $lotWinCntSet,
    ),
  );
}

export const bidReducer: Reducer = createReducer(
  initialStateBid,
  (builder: ActionReducerMapBuilder<IStateBid>) => {
    // account cases
    builder.addCase<string, ActionCase<IAccount>>(
      ACCOUNT_LOADED,
      (state: Draft<IStateBid>, action: ActionCase<IAccount>) => {
        state.accountID = action.payload.id;
      },
    );
    // Auction cases
    builder.addCase<string, ActionCase<IAuctionModeResponse>>(
      AUCTION_MODE_LOAD,
      (state: Draft<IStateBid>, action: ActionCase<IAuctionModeResponse>) => {
        state.currentLotID = action.payload.lotID;
      },
    );
    // Lot cases
    builder.addCase<string, ActionCase<null | string>>(
      LOT_SHORT_CURRENT_ID_LOAD,
      (state: Draft<IStateBid>, action: ActionCase<null | string>) => {
        state.currentLotID = action.payload;
      },
    );
    builder.addCase<string, ActionCase<Record<string, number>>>(
      LOT_WIN_CNT_SET,
      (state: Draft<IStateBid>, action: ActionCase<Record<string, number>>) => {
        const set: Record<string, number> = action.payload;
        Object.keys(set).forEach((lotID: string) => {
          state.lotWinCntSet[lotID] = set[lotID] || 1;
        });
        updateStatus(state);
      },
    );
    // bids cases
    builder.addCase<string, ActionCase<IBidSetData>>(
      BID_SET_LOAD,
      (state: Draft<IStateBid>, action: ActionCase<IBidSetData>) => {
        const { lotType, lotID, set } = action.payload;
        const SetLotID: Record<string, true> = {};
        if (lotID) {
          SetLotID[lotID] = true;
        }

        Object.keys(set).forEach((bidID: string) => {
          const bid: IBid = set[bidID];
          SetLotID[bid.lotID] = true;
          state.byID[bidID] = bid;
        });

        if (lotType === 'Live') {
          updateStatus(state);
        } else {
          updateStatusByLotSet(
            SetLotID,
            state.accountID,
            state.IDsByLotID,
            state.statusByLotID,
            state.byID,
            state.lotWinCntSet,
          );
        }
      },
    );
    builder.addCase<string, ActionCase<IBidIDsData>>(
      BID_IDS_LOAD,
      (state: Draft<IStateBid>, action: ActionCase<IBidIDsData>) => {
        const { lotID, bidIDs, lotType } = action.payload;
        state.IDsByLotID[lotID] = bidIDs;
        if (lotType === 'Live') {
          updateStatus(state);
        }
      },
    );
    builder.addCase<string, ActionCase<IBidUpdate>>(
      BID_ADDED,
      (state: Draft<IStateBid>, action: ActionCase<IBidUpdate>) => {
        const { lotID, bidID, lotType } = action.payload;
        if (!state.IDsByLotID[lotID]) {
          state.IDsByLotID[lotID] = [];
        }

        state.IDsByLotID[lotID].unshift(bidID);
        if (lotType === 'Live') {
          updateStatus(state);
        } else {
          state.updates.unshift(action.payload);
        }
      },
    );
    builder.addCase<string, ActionCase<IBidIDsData>>(
      BID_IDS_LAST_LOAD,
      (state: Draft<IStateBid>, action: ActionCase<IBidIDsData>) => {
        const { bidIDs } = action.payload;
        state.IDsLast = bidIDs;
      },
    );
    builder.addCase<string, ActionCase<null>>(
      BID_ADDED_COMPLETED,
      (state: Draft<IStateBid>) => {
        state.updates.pop();
      },
    );
  },
);
