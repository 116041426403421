import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { Block } from '../../common/layout';
import { appConfig } from '../../config/app.config';
import { loadProductsByPath } from '../../actions/shop/product.actions';
import ProductItem from '../../components/shop/product/ProductItem';
import { IProduct } from '../../interfaces/shop/IProduct';
import { Loading } from '../../common/animation/Loading';
import { getComplexPathFromPath } from '../../utils/archive';
import { sendPageEvent } from '../../utils/analytics';

interface IProductPageProps {
  loadProductsByPath: (accountPath: string, productPath: string) => any;
  IDsByComplexPath: Record<string, string[]>;
  set: Record<string, IProduct>;
}

function ProductPage(props: IProductPageProps) {
  const navigate = useNavigate();
  const { accountPath, productPath } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<null | IProduct[]>(null); // multiple products with one name

  useEffect(() => sendPageEvent('Product'), []);
  useEffect(() => {
    if (productPath && accountPath) {
      props.loadProductsByPath(accountPath, productPath);
    } else {
      goToCatalog();
    }
  }, []);

  useEffect(() => {
    if (!accountPath || !productPath) {
      return goToCatalog();
    }

    const productsLocal: IProduct[] = [];
    const complexPath: string = getComplexPathFromPath(
      accountPath,
      productPath
    );
    const IDs: undefined | string[] = props.IDsByComplexPath[complexPath];
    if (!IDs) {
      return;
    }

    IDs.forEach((id: string) => {
      productsLocal.push(props.set[id]);
    });

    setIsLoading(false);
    setProducts(productsLocal);
  }, [props.set]);

  const goToCatalog = () => {
    navigate(appConfig.routes.shopProducts.list);
  };

  return (
    <AppContent>
      <Block>
        {isLoading && <Loading />}
        {products &&
          products.map((product: IProduct) => (
            <ProductItem product={product} key={product.id} />
          ))}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    IDsByComplexPath: state.ShopProduct.products.IDsByComplexPath,
    set: state.ShopProduct.products.set,
  }),
  {
    loadProductsByPath,
  }
)(ProductPage);
