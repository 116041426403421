import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { broadcastReportAuction } from '../../actions/auctions/reportAdmin.actions';
import { IState } from '../../interfaces/system/IState';
import LotItem from '../../components/auctions/live/LotItem';
import Bids from '../../components/auctions/live/Bids';
import { appConfig } from '../../config/app.config';
import { IAccount } from '../../interfaces/account/IAccount';
import { IReportLot } from '../../interfaces/auctions/IReportLot';
import { IReportAuctionUpdate } from '../../interfaces/auctions/IReportAuction';
import { AppContent, AppContentPlug } from '../../App.styles';
import { StartSoon } from '../../common/announcement/StartSoon';
import { sendPageEvent } from '../../utils/analytics';
import { adminLotActivate } from '../../actions/auctions/lotAdmin.actions';
import {
  ILotShort,
  ILotShortSet,
  ILotType,
} from '../../interfaces/auctions/ILotPublic';
import { Loading } from '../../common/animation/Loading';
import { loadLotCnt } from '../../actions/auctions/lotPublic.actions';
import { ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAuction } from '../../interfaces/auctions/IAuction';
import MakeBid from '../../components/auctions/live/MakeBid';
import LotAdminActions from '../../components/auctions/live/LotAdminActions';
import {
  AdminAction,
  AdminActionBlock,
} from '../../components/auctions/live/Lot.styles';
import { L } from '../../common/locale';

interface IAuctionLivePageProps {
  adminLotActivate: (lotType: ILotType) => void;
  broadcastReportAuction: (lotType: ILotType) => void;
  loadLotCnt: (lotType: ILotType) => void;
  account: null | IAccount;
  reportAuctionLive: undefined | null | IReportAuctionUpdate;
  reportLot: undefined | null | IReportLot;
  isAdmin: boolean;
  lotSet: ILotShortSet;
  lotCnt: null | number;
  currentLotID: undefined | null | string;
  auctionNext: undefined | null | IAuction;
}

function AuctionLivePage(props: IAuctionLivePageProps) {
  const navigate = useNavigate();
  const lotType: ILotType = 'Live';
  const [lot, setLot] = useState<null | ILotShort>(null);
  useEffect(() => sendPageEvent('AuctionLive'), []);
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    if (props.isAdmin && props.lotCnt === null) {
      props.loadLotCnt(lotType);
    }
  }, [props.isAdmin, props.lotCnt]);
  useEffect(() => {
    setLot(props.currentLotID ? props.lotSet[props.currentLotID] : null);
  }, [props.currentLotID, props.lotSet]);
  useEffect(() => {
    if (!props.currentLotID && props.reportLot) {
      navigate(appConfig.routes.live.resultLot);
    } else if (!props.currentLotID && props.reportAuctionLive) {
      navigate(appConfig.routes.live.resultAuction);
    } else {
      // todo show before start message
    }
  }, [props.currentLotID, props.reportAuctionLive, props.reportLot]);

  return (
    <AppContent>
      {!props.currentLotID && (
        <AppContentPlug>
          <Block>
            <StartSoon auctionNext={props.auctionNext} />
            <AdminActionBlock>
              {props.isAdmin && (
                <>
                  {props.lotCnt === null && <Loading />}
                  {props.lotCnt === 0 && (
                    <>
                      <ContentHead className="fail">
                        <L k={'AuctionAdminNoLots'} />
                      </ContentHead>
                    </>
                  )}
                  {(props.lotCnt && props.lotCnt > 0 && (
                    <>
                      <AdminAction
                        onClick={() => props.adminLotActivate(lotType)}
                      >
                        <L k={'ActionStart'} />
                      </AdminAction>

                      <AdminAction
                        onClick={() => props.broadcastReportAuction(lotType)}
                      >
                        <L k={'ActionReport'} />
                      </AdminAction>
                    </>
                  )) ||
                    ''}
                </>
              )}
            </AdminActionBlock>
          </Block>
        </AppContentPlug>
      )}

      {props.currentLotID && (
        <>
          {lot ? (
            <>
              <LotItem lot={lot} />
              <MakeBid lot={lot} lotType={'Live'} />
              {props.isAdmin && <LotAdminActions />}
            </>
          ) : (
            <Loading size="big" />
          )}
        </>
      )}

      {lot && (
        <>
          <Bids lotID={lot.id} lotType={'Live'} />
        </>
      )}
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    currentLotID: state.Lot.short.currentLotID,
    lotSet: state.Lot.short.set,
    lotCnt: state.Lot.cnt,
    account: state.Account.account,
    auctionNext: state.Auction.auctionNext,
    reportAuctionLive: state.Report.auctionLive,
    reportLot: state.Report.lot,
    isAdmin: state.Account.isAdmin,
  }),
  {
    adminLotActivate,
    broadcastReportAuction,
    loadLotCnt,
  }
)(AuctionLivePage);
