import React, { useEffect, useState } from 'react';
import { Block } from '../../common/layout';
import {
  ContentDesc,
  ContentDescInline,
  ContentHead,
} from '../../common/typos';
import {
  IBaseAnalyticsItem,
  IBaseAnalyticsModelCreate,
} from '../../interfaces/analytics/IBaseAnalyticsModel';
import { Order } from '../../common/forms/Order';

interface ITechCacheWrapperProps {
  items: IBaseAnalyticsModelCreate<undefined | string, undefined | string>[];
}

type ITechCacheOrder = 'value' | 'param' | 'min' | 'max' | 'total' | 'cnt';
const techCacheOrderList: ITechCacheOrder[] = [
  'value',
  'param',
  'min',
  'max',
  'total',
  'cnt',
];

export function AnalyticsCacheItems(props: ITechCacheWrapperProps) {
  const [items, setItems] = useState<IBaseAnalyticsItem[]>([]);
  const [order, setOrder] = useState<ITechCacheOrder>('max');

  useEffect(() => {
    setItems(
      Object.assign([], props.items).sort(
        (a: IBaseAnalyticsItem, b: IBaseAnalyticsItem): number => {
          if (order === 'value' || order === 'param') {
            return (a[order] || '') > (b[order] || '') ? -1 : 1;
          } else {
            return a.item[order] > b.item[order] ? -1 : 1;
          }
        }
      )
    );
  }, [props, order]);

  return (
    <Block>
      <Order
        itemsSimple={techCacheOrderList}
        order={order}
        cb={setOrder}
        // isSmall={true}
      />

      {items.map((item: IBaseAnalyticsItem) => (
        <ContentDesc>
          <ContentDescInline>
            {item.date} - {item.value} - {item.param} -{' '}
          </ContentDescInline>
          <ContentDescInline>
            {item.item.min}...
            {item.item.max}
            {' / '}
            {item.item.cnt} ({item.item.total} all /{' '}
            {Math.round(item.item.total / item.item.cnt)} avg)
          </ContentDescInline>
        </ContentDesc>
      ))}
    </Block>
  );
}
