import React, { useEffect, useState } from 'react';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { loadAccountsSet } from '../../../actions/account/accounts.actions';
import { ContentDescInline } from '../../../common/typos';

interface ITopAccountProps {
  loadAccountsSet: (accountIDs: string[]) => void;
  accountID: string;
  accounts: IAccountSet;
}

function TopAccount(props: ITopAccountProps) {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [account, setAccount] = useState<null | IAccount>(null);
  useEffect(() => {
    const accountLocal: null | IAccount = props.accounts[props.accountID];
    setAccount(accountLocal);
    if (!accountLocal && !isLoad) {
      setIsLoad(true);
      props.loadAccountsSet([props.accountID]);
    }
  }, [props.accounts, props.accountID]);

  return (
    <ContentDescInline>
      {account ? account.name : props.accountID.slice(0, 5)} -{' '}
    </ContentDescInline>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
  }),
  {
    loadAccountsSet,
  },
)(TopAccount);
