import React, { useEffect, useState } from 'react';
import { ContentDescInline } from '../typos';
import { ErrorInfo } from './Form.styles';
import { ImagesLoader } from '../images/ImagesLoader';

interface IFormFieldImageProps {
  isDisabled?: boolean;
  defaultValue: null | string[];
  saveValue: (images: string[]) => void;
  title: string;
  limit: number;
  valueError: null | string;
  isValidForm: boolean;
  params?: {
    isDraggable: boolean;
    autoSelect: boolean;
    noCrop: boolean;
  };
}

export function FormFieldImage(props: IFormFieldImageProps) {
  const [updatedValue, setUpdatedValue] = useState<null | string[]>(null);

  useEffect(() => setUpdatedValue(props.defaultValue), [props.defaultValue]);

  const saveValue = (imagesBase64: string[]) => {
    setUpdatedValue(imagesBase64);
    props.saveValue(imagesBase64);
  };

  return (
    <>
      {props.title && (
        <ContentDescInline>
          {props.title[0].toUpperCase() + props.title.slice(1)}:{' '}
        </ContentDescInline>
      )}
      {props.valueError && <ErrorInfo>{props.valueError}</ErrorInfo>}
      <ImagesLoader
        isDisabled={props.isDisabled}
        imagesToEdit={updatedValue}
        cbImages={(imagesBase64: string[]) => saveValue(imagesBase64)}
        limit={props.limit}
        isDraggable={props.params?.isDraggable || true}
        autoSelect={props.params?.autoSelect || false}
        noCrop={props.params?.noCrop || false}
        hardReset={true}
      />
    </>
  );
}
