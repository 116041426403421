import React from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IProductPreview } from '../../../interfaces/shop/IProduct';
import { IState } from '../../../interfaces/system/IState';
import { Block } from '../../../common/layout';
import ProductPreviewItem from './ProductPreviewItem';

interface ICatalogCarouselProps {
  noView: boolean;
  hideAnalytics?: boolean;
  previewIDs: string[];
  set: Record<string, IProductPreview>;
}

function CatalogCarousel(props: ICatalogCarouselProps) {
  return (
    <Block className={'carousel'}>
      <Carousel autoPlay={true} showThumbs={false}>
        {props.previewIDs.slice(0, 10).map((previewID: string) => (
          <Block key={previewID} className={'left'}>
            {props.set[previewID] && (
              <ProductPreviewItem
                noView={props.noView}
                hideAnalytics={props.hideAnalytics}
                fullWidth={true}
                productID={previewID}
                cnt={1}
                key={previewID}
              />
            )}
          </Block>
        ))}
      </Carousel>
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    set: state.ShopProduct.batchPreview.set,
  }),
  {}
)(CatalogCarousel);
