import React from 'react';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
  ContentHead,
} from '../../../common/typos';
import { Block } from '../../../common/layout';
import {
  IPartnerSellerPublic,
  IPartnerSellerView,
} from '../../../interfaces/account/IPartnerSeller';
import { IParamsItemView } from '../../../common/common';
import {
  prepareAuctionDate,
  renderAuctionDate,
} from '../../../utils/renderParts';
import { ImagePerson } from '../../../common/images/ImagePerson';
import { ActionLink } from '../../../common/styles';
import { ImageIcon } from '../../../common/images/Image.styles';
import { useTranslation } from 'react-i18next';

interface IPartnerSellerItemModerationProps {
  partner: IPartnerSellerPublic;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
  ParamsItemView?: IParamsItemView;
}

export function PartnerSellerItemModeration(
  props: IPartnerSellerItemModerationProps
) {
  const { t } = useTranslation();
  const partner: IPartnerSellerPublic = props.partner;
  const view: null | IPartnerSellerView = props.ParamsItemView
    ? (props.ParamsItemView as IPartnerSellerView)
    : null;

  return (
    <>
      {props.showInline && (
        <>
          {!view && (
            <ContentDesc>
              <ContentBold>{partner.name}</ContentBold>
              {' - '}
              <ContentDescInline>{partner.description}</ContentDescInline>{' '}
              {props.inlineBlock}
            </ContentDesc>
          )}
          {view && (
            <ContentDesc>
              {view.name && <ContentBold>{partner.name}</ContentBold>}
              {view.description && (
                <ContentDescInline>{partner.description}. </ContentDescInline>
              )}
              {view.donationLink && partner.donationLink && (
                <ContentDescInline
                  dangerouslySetInnerHTML={{ __html: partner.donationLink }}
                ></ContentDescInline>
              )}
              {view.donationLink && partner.donationLink && (
                <ContentDescInline
                  dangerouslySetInnerHTML={{ __html: partner.donationLink }}
                ></ContentDescInline>
              )}
              {view.instagramLink && partner.instagramLink && (
                <ActionLink
                  href={'https://www.instagram.com/' + partner.instagramLink}
                  target="_blank"
                  style={{
                    display: 'flex !important',
                    alignItems: 'center',
                  }}
                >
                  <ImageIcon
                    className={'small inline'}
                    src="/icons/insta.png"
                  />
                  @{partner.instagramLink}
                </ActionLink>
              )}
              {view.updatedAt && partner.updatedAt && (
                <ContentDescInline>
                  UpdatedAt: {prepareAuctionDate(partner.updatedAt)}.
                </ContentDescInline>
              )}
              {view.createdAt && partner.createdAt && (
                <ContentDescInline>
                  CreatedAt: {prepareAuctionDate(partner.createdAt)}.
                </ContentDescInline>
              )}
              {props.inlineBlock}
            </ContentDesc>
          )}
        </>
      )}

      {!props.showInline && (
        <>
          {!view && (
            <Block>
              <ContentHead>{partner.name}</ContentHead>
              <ContentDesc>{partner.description}</ContentDesc>
              {props.inlineBlock}
            </Block>
          )}
          {view && (
            <Block>
              {view.imgURL && (
                <ImagePerson imgURL={partner.imgURL} name={partner.name} />
              )}
              {view.name && <ContentHead>{partner.name}</ContentHead>}
              {view.description && (
                <ContentDesc>{partner.description}</ContentDesc>
              )}
              {view.donationLink && partner.donationLink && (
                <ContentDesc
                  dangerouslySetInnerHTML={{ __html: partner.donationLink }}
                ></ContentDesc>
              )}

              {view.donationLink && partner.donationLink && (
                <ContentDesc
                  dangerouslySetInnerHTML={{ __html: partner.donationLink }}
                ></ContentDesc>
              )}
              {view.instagramLink && partner.instagramLink && (
                <ActionLink
                  href={'https://www.instagram.com/' + partner.instagramLink}
                  target="_blank"
                  style={{
                    display: 'flex !important',
                    alignItems: 'center',
                  }}
                >
                  <ImageIcon
                    className={'small inline'}
                    src="/icons/insta.png"
                  />
                  @{partner.instagramLink}
                </ActionLink>
              )}
              {view.updatedAt &&
                partner.updatedAt &&
                renderAuctionDate(partner.updatedAt, {
                  label: t('FormLabelUpdated'),
                })}
              {view.createdAt &&
                partner.createdAt &&
                renderAuctionDate(partner.createdAt, {
                  label: t('FormLabelCreated'),
                })}
              {props.inlineBlock}
            </Block>
          )}
        </>
      )}
    </>
  );
}
