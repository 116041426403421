import React, { useEffect, useState } from 'react';
import { IAuction, IAuctionTotals } from '../../interfaces/auctions/IAuction';
import { ContentBold, ContentDesc, PageDesc } from '../../common/typos';
import { LegendItemPrice } from '../hall/LegendItemPrice';
import { L } from '../../common/locale';

interface IAuctionTotalsProps {
  auction: IAuction;
  isBig: boolean;
}

export function AuctionTotals(props: IAuctionTotalsProps) {
  const [auction] = useState(props.auction);
  const [totals, setTotals] = useState<null | IAuctionTotals>();

  useEffect(() => {
    setTotals({
      totalDonated:
        ((auction.live && auction.live.total) || 0) +
        ((auction.week && auction.week.total) || 0),
      totalFollowers:
        ((auction.live && auction.live.followers) || 0) +
        ((auction.week && auction.week.followers) || 0),
      totalBids:
        ((auction.live && auction.live.bidCnt) || 0) +
        ((auction.week && auction.week.bidCnt) || 0),
      totalSoldLots:
        ((auction.live && auction.live.lotCnt) || 0) +
        ((auction.week && auction.week.lotCnt) || 0),
    });
  }, [auction]);

  return (
    <>
      {(props.isBig && (
        <PageDesc>
          <L k={'ReportsTotal'} />
        </PageDesc>
      )) || (
        <ContentDesc>
          <ContentBold>
            <L k={'ReportsTotal'} />
          </ContentBold>
        </ContentDesc>
      )}

      {auction && totals && (
        <>
          <LegendItemPrice
            price={totals.totalDonated || 0}
            title={'Donated total'}
            isBold={true}
          />
          <ContentDesc>
            <L k={'AccountLotsOrderSold'} />: {totals.totalSoldLots}
          </ContentDesc>

          {totals.totalFollowers !== 0 && (
            <ContentDesc>
              <L k={'ReportsAttenders'} /> {totals.totalFollowers}
            </ContentDesc>
          )}
          <ContentDesc>
            <L k={'ReportsBids'} /> {totals.totalBids}
          </ContentDesc>
        </>
      )}

      {auction && auction.shop && (
        <>
          {(props.isBig && (
            <PageDesc>
              <L k={'ReportsTotalShop'} />
            </PageDesc>
          )) || (
            <ContentDesc>
              <ContentBold>
                <L k={'ReportsTotalShop'} />
              </ContentBold>
            </ContentDesc>
          )}

          <ContentDesc>
            <L k={'ReportsLots'} /> {auction.shop.products}
          </ContentDesc>
          <ContentDesc>
            <L k={'ReportsViewsAll'} /> {auction.shop.views}
          </ContentDesc>
          <ContentDesc>
            <L k={'ReportsViewsUnique'} /> {auction.shop.viewsUnique}
          </ContentDesc>
          <ContentDesc>
            <L k={'ReportsLikes'} /> {auction.shop.likes}
          </ContentDesc>
          <ContentDesc>
            <L k={'ReportsSellers'} /> {auction.shop.sellers}
          </ContentDesc>
          <ContentDesc>
            <L k={'ReportsFollowers'} /> {auction.shop.followers}
          </ContentDesc>
        </>
      )}
    </>
  );
}
