import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import LotAuctionItem from './LotAuctionItem';
import ReactDragListView from 'react-drag-listview';
import { ActionButton, ActionLink, BlockActions } from '../../../common/styles';
import { resortLots } from '../../../actions/auctions/lotModeration.actions';
import { ILotIDs, ILotSortItem } from '../../../interfaces/auctions/ILotAdmin';
import { ILotType } from '../../../interfaces/auctions/ILotPublic';
import { Block } from '../../../common/layout';
import { ImageIcon } from '../../../common/images/Image.styles';
import { InputDrag } from '../../../common/forms/Form.styles';

interface ILotAuctionItemsProps {
  lotType: ILotType;
  resortLots: (lotType: ILotType, lotSort: ILotSortItem[]) => void;
  liveLotIDs: ILotIDs[];
  weekLotIDs: ILotIDs[];
}

function LotAuctionItems(props: ILotAuctionItemsProps) {
  const [moderationIDs, setModerationIDs] = useState<ILotIDs[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [manualSortInd, setManualSortInd] = useState<null | number>(null);
  const [manualIndex, setManualIndex] = useState<string>('');

  useEffect(
    () => setInitialSort(),
    [props.lotType, props.liveLotIDs, props.weekLotIDs]
  );

  const setInitialSort = () => {
    setModerationIDs(
      props.lotType === 'Live' ? props.liveLotIDs : props.weekLotIDs
    );
  };

  const reSort = (fromIndex: number, toIndex: number) => {
    if (!moderationIDs) {
      return;
    }

    const local: ILotIDs[] = [...moderationIDs];
    const item = local.splice(fromIndex, 1)[0];
    local.splice(toIndex, 0, item);

    setModerationIDs(local);
    setIsDisabled(false);
  };

  const saveSort = () => {
    if (!moderationIDs) {
      return;
    }

    setIsDisabled(true);
    props.resortLots(
      props.lotType,
      moderationIDs.map((lotIDs: ILotIDs, index) => ({
        lotID: lotIDs.lotID,
        order: index + 1,
      }))
    );
  };

  const cancelSort = () => {
    setInitialSort();
    setIsDisabled(true);
  };

  const initManualSort = (index: number) => {
    setManualSortInd(index);
    setManualIndex((index + 1).toString());
  };

  const cancelManualSort = () => {
    setManualSortInd(null);
    setManualIndex('');
  };

  const saveManualSort = () => {
    if (!manualSortInd) {
      return cancelManualSort();
    }
    reSort(manualSortInd, Number(manualIndex) - 1);
    cancelManualSort();
  };

  return (
    <Block>
      <ReactDragListView
        onDragEnd={reSort}
        nodeSelector={'.handle'}
        handleSelector={'a'}
      >
        {moderationIDs &&
          moderationIDs.map((lotIDs: ILotIDs, index: number) => (
            <Block key={lotIDs.lotID} className="handle">
              <a href="#" className="drag">
                <ImageIcon
                  className={'small'}
                  src="/icons/sort.png"
                  alt="sort"
                />
                drag to sort
              </a>
              <Block className={'flexTop'}>
                <ActionLink onClick={() => initManualSort(index)}>
                  {index + 1}
                </ActionLink>
                {manualSortInd === index && (
                  <>
                    <InputDrag
                      type="number"
                      value={manualIndex}
                      onChange={(e) => setManualIndex(e.target.value)}
                    />
                    <Block>
                      <ImageIcon
                        src="/icons/save.png"
                        alt="save"
                        onClick={saveManualSort}
                      />
                      <ImageIcon
                        src="/icons/cancel-red.png"
                        alt="cancel"
                        onClick={cancelManualSort}
                      />
                    </Block>
                  </>
                )}

                <LotAuctionItem
                  lotID={lotIDs.lotID}
                  productID={lotIDs.productID}
                  lotType={props.lotType}
                />
              </Block>
            </Block>
          ))}
      </ReactDragListView>

      <BlockActions>
        <ActionButton
          onClick={() => saveSort()}
          disabled={isDisabled}
          className="success"
        >
          Save sorting
        </ActionButton>

        <ActionButton
          onClick={() => cancelSort()}
          disabled={isDisabled}
          className="fail"
        >
          Cancel
        </ActionButton>
      </BlockActions>
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    liveLotIDs: state.Lot.moderation.LiveIDs,
    weekLotIDs: state.Lot.moderation.WeekIDs,
  }),
  {
    resortLots,
  }
)(LotAuctionItems);
