import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { PageHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { loadLegendsNext } from '../../actions/hall/hallLegends.actions';
import {
  IHallLegend,
  IHallLegendOrder,
} from '../../interfaces/hall/IHallLegends';
import LegendItem from '../../components/hall/LegendItem';
import { getAuctionList } from '../../actions/auctions/auction.actions';
import { sendPageEvent } from '../../utils/analytics';
import { Order } from '../../common/forms/Order';
import { Batch } from '../../common/moderation/Batch';
import { DataBatchWide } from '../../interfaces/system/data';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface IHallLegendsPageProps {
  getAuctionList: () => any;
  loadLegendsNext: (order: IHallLegendOrder) => any;
  batchCnt: null | number;
  batch: Record<string, DataBatchWide>;
  set: Record<string, IHallLegend>;
  IDByPath: Record<string, string[]>;
}

function HallLegendsPage(props: IHallLegendsPageProps) {
  const { t } = useTranslation();
  const [order, setOrder] = useState<IHallLegendOrder>('buyTotal');

  useEffect(() => sendPageEvent('Hall'), []);
  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'HallTitle'} />
        </PageHead>
        <Order
          items={[
            {
              value: 'buyTotal',
              title: t('ReportsLotOrderBuyTotal'),
            },
            {
              value: 'sellTotal',
              title: t('ReportsLotOrderSellTotal'),
            },
          ]}
          order={order}
          cb={setOrder}
        />

        <Batch
          type={'public'}
          set={props.set}
          batch={{
            batchKey: order,
            byBatchKey: props.batch,
            loadBatch: () => props.loadLegendsNext(order),
          }}
          renderItemPublic={(legend: IHallLegend) => (
            <LegendItem legend={legend} order={order} />
          )}
          isAdmin={false}
        />
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Hall.set,
    IDByPath: state.Hall.IDByPath,
    batch: state.Hall.batch,
    batchCnt: state.Hall.batchCnt,
  }),
  { loadLegendsNext, getAuctionList }
)(HallLegendsPage);
