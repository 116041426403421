import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { REPORT_AUCTION_LOAD } from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IReportAuctionData, IReportAuctionResponse } from '../../interfaces/auctions/IReportAuction';
import { IAccountSet } from '../../interfaces/account/IAccount';
import { IServiceError } from '../../interfaces/system/IError';
import { ILotType } from '../../interfaces/auctions/ILotPublic';

export const loadReportAuction =
  (lotType: ILotType) =>
    (
      dispatch: IDispatch<
        null | IReportAuctionData | IAccountSet | IServiceError
      >,
      getState: () => IState,
    ) => {
      callApi<IReportAuctionResponse>(
        apiConfig.endpoints.auctions.report.auction,
        { lotType },
      )
        .then((data: IReportAuctionResponse) => {
          const payload: IReportAuctionData = {
            lotType,
            report: data.report,
          };
          dispatch({ type: REPORT_AUCTION_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
