import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import {
  ITEM_CREATED,
  ITEM_LOADED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  PARTNER_SELLER_ACTION,
  PARTNER_SELLER_LOAD_BATCH,
  PARTNER_SELLER_LOAD_BY_ID,
  PARTNER_SELLER_LOAD_BY_PATH,
  PARTNER_SELLER_LOAD_IDS,
  PARTNER_SELLER_LOAD_SET,
  PARTNER_SELLER_SEARCH,
} from '../types';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { CacheRequests } from '../../service/cacheRequests';
import { IServiceError } from '../../interfaces/system/IError';
import {
  IPartnerSellerBatchBody,
  IPartnerSellerByID,
  IPartnerSellerByPath,
  IPartnerSellerCreateBody,
  IPartnerSellerIDsResponse,
  IPartnerSellerOneResponse,
  IPartnerSellerSet,
  IPartnerSellerSetResponse,
  IPartnerSellerUpdateBody,
} from '../../interfaces/account/IPartnerSeller';
import { DataBatch, DataSearch } from '../../interfaces/system/data';
import { IAccount } from '../../interfaces/account/IAccount';
import { dispatchError } from '../../service/error';

const { partnerSeller } = apiConfig.endpoints.account;

const cacheByID: CacheRequests = new CacheRequests();
const cacheByAccountPath: CacheRequests = new CacheRequests();
const cacheByAccountID: CacheRequests = new CacheRequests();

export const loadPartnerSellerIDs =
  (hardReload?: boolean) =>
  (
    dispatch: IDispatch<string | string[] | IPartnerSellerSet | IServiceError>,
    getState: () => IState
  ) => {
    const { Account } = getState();
    if (!hardReload && Account.partnerSeller.IDs[0]) {
      return;
    }

    callApi<IPartnerSellerIDsResponse>(partnerSeller.ids)
      .then((data: IPartnerSellerIDsResponse) => {
        loadPartnerSellerSet(data.partnerIDs)(dispatch, getState);

        dispatch({
          type: PARTNER_SELLER_LOAD_IDS,
          payload: data.partnerIDs,
        });
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_LOADED });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadPartnerSellerByPath =
  (accountPath: string) =>
  (
    dispatch: IDispatch<string | IPartnerSellerByPath | IServiceError>,
    getState: () => IState
  ) => {
    if (cacheByAccountPath.checkOneIsLoaded(accountPath)) {
      return;
    }

    callApi<IPartnerSellerOneResponse>(partnerSeller.oneByPath, { accountPath })
      .then((data: IPartnerSellerOneResponse) => {
        const payload: IPartnerSellerByPath = {
          path: accountPath,
          partner: data.partner,
        };

        dispatch({ type: PARTNER_SELLER_LOAD_BY_PATH, payload });
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_LOADED });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadPartnerSellerByAccountID =
  (accountID: string) =>
  (
    dispatch: IDispatch<string | IPartnerSellerByID | IServiceError>,
    getState: () => IState
  ) => {
    if (cacheByAccountID.checkOneIsLoaded(accountID)) {
      return;
    }

    callApi<IPartnerSellerOneResponse>(partnerSeller.oneByID, { accountID })
      .then((data: IPartnerSellerOneResponse) => {
        const payload: IPartnerSellerByID = {
          accountID,
          partner: data.partner,
        };

        dispatch({ type: PARTNER_SELLER_LOAD_BY_ID, payload });
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_LOADED });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadPartnerSellerOne =
  (partnerSellerID: string, hardReload: boolean) =>
  (
    dispatch: IDispatch<string | IPartnerSellerSet | IServiceError>,
    getState: () => IState
  ) => {
    loadPartnerSellerSet([partnerSellerID], hardReload)(dispatch, getState);
  };

export const loadPartnerSellerSet =
  (partnerSellerIDs: string[], hardReload?: boolean) =>
  (
    dispatch: IDispatch<string | IPartnerSellerSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    if (!partnerSellerIDs || !partnerSellerIDs[0]) {
      return;
    }
    const byPartnerSellerID: IPartnerSellerSet =
      getState().Account.partnerSeller.set;
    let IDsToLoad: string[] = [];
    if (!hardReload) {
      const toLoad: string[] = [];
      partnerSellerIDs.forEach((partnerSellerID: string) => {
        if (!byPartnerSellerID[partnerSellerID]) {
          toLoad.push(partnerSellerID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      IDsToLoad = cacheByID.getToLoad(toLoad);
    }
    if (!IDsToLoad[0]) {
      return;
    }
    callApi<IPartnerSellerSetResponse>(partnerSeller.main, {
      accountIDs: IDsToLoad.join(','),
    })
      .then((data: IPartnerSellerSetResponse) => {
        dispatch({ type: PARTNER_SELLER_LOAD_SET, payload: data.set });
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_LOADED });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadPartnerSellerBatch =
  () =>
  (
    dispatch: IDispatch<
      string | IPartnerSellerSet | DataBatch<string> | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const { limit, loaded } = getState().Account.partnerSeller;
    const body: IPartnerSellerBatchBody = {
      limit,
      skip: loaded || 0,
    };

    callApi<DataBatch<string>>(partnerSeller.batch, {}, body, 'POST')
      .then((data: DataBatch<string>) => {
        const payload: DataBatch<string> = {
          ...data,
        };

        loadPartnerSellerSet(data.items)(dispatch, getState);

        dispatch({
          type: PARTNER_SELLER_LOAD_BATCH,
          payload,
        });
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_LOADED });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const searchPartnerSeller =
  (search: string) =>
  (
    dispatch: IDispatch<
      string | DataSearch<string> | IPartnerSellerSet | IServiceError
    >,
    getState: () => IState
  ) => {
    if (search.length < 1) {
      return;
    }
    if (getState().Account.partnerSeller.IDsBySearch[search] !== undefined) {
      return;
    }

    callApi<IPartnerSellerIDsResponse>(partnerSeller.search, { search })
      .then((data: IPartnerSellerIDsResponse) => {
        const payload: DataSearch<string> = {
          search,
          items: data.partnerIDs,
        };

        dispatch({ type: PARTNER_SELLER_SEARCH, payload });
        loadPartnerSellerSet(data.partnerIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const updatePartnerSeller =
  (
    partnerID: string,
    name: string,
    imgURL: null | string,
    description: null | string,
    donationLink: null | string,
    instagramLink: null | string
  ) =>
  (
    dispatch: IDispatch<string | IPartnerSellerSet | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IPartnerSellerUpdateBody = {
      partnerID,
      name,
      imgURL,
      description,
      donationLink,
      instagramLink,
    };

    callApi(partnerSeller.main, {}, body, 'POST')
      .then(() =>
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_UPDATED })
      )
      .then(() => loadPartnerSellerOne(partnerID, true)(dispatch, getState))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Partner is updated',
          params: {},
          type: 'Info',
        })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const updatePartnerSellerSelf =
  (
    name: string,
    imgURL: null | string,
    description: null | string,
    instagramLink: null | string
  ) =>
  (
    dispatch: IDispatch<string | IPartnerSellerSet | IServiceError>,
    getState: () => IState
  ) => {
    const account: null | IAccount = getState().Account.account;
    if (!account) {
      return;
    }
    const partnerID: null | string =
      getState().Account.partnerSeller.IDByAccountID[account.id] || null;
    if (!partnerID) {
      return;
    }

    const body: IPartnerSellerUpdateBody = {
      partnerID,
      name,
      imgURL,
      description,
      instagramLink,
    };

    callApi(partnerSeller.main, {}, body, 'POST')
      .then(() =>
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_UPDATED })
      )
      .then(() => loadPartnerSellerOne(partnerID, true)(dispatch, getState))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Your Partner profile is updated',
          params: {},
          type: 'Info',
        })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const createPartnerSeller =
  (
    name: string,
    imgURL: null | string,
    accountID: string,
    description: null | string,
    donationLink: null | string,
    instagramLink: null | string
  ) =>
  (dispatch: IDispatch<string | IServiceError>, getState: () => IState) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IPartnerSellerCreateBody = {
      name,
      imgURL,
      accountID,
      description,
      donationLink,
      instagramLink,
    };
    callApi(partnerSeller.main, {}, body)
      .then(() =>
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_CREATED })
      )
      .then(() =>
        dispatchError(dispatch, {
          message: 'Partner is created',
          params: {},
          type: 'Info',
        })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const deletePartnerSeller =
  (partnerSellerID: string) =>
  (dispatch: IDispatch<string | IServiceError>, getState: () => IState) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi(partnerSeller.main, { partnerSellerID }, undefined, 'DELETE')
      .then(() =>
        dispatch({ type: PARTNER_SELLER_ACTION, payload: ITEM_REMOVED })
      )
      .then(() =>
        dispatchError(dispatch, {
          message: 'Partner is removed',
          params: {},
          type: 'Info',
        })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
