import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { ActionButton, BlockActions, ButtonMain } from '../../common/styles';
import { ContentHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../common/animation/Loading';
import AuctionInfo from '../../components/auctions/AuctionInfo';
import { getAuctionIDByPath } from '../../utils/archive';
import { IAuction } from '../../interfaces/auctions/IAuction';
import { appConfig } from '../../config/app.config';
import { sendPageEvent } from '../../utils/analytics';
import { AuctionTotals } from '../../components/auctions/AuctionTotals';
import { Block } from '../../common/layout';
import { Carousel } from 'react-responsive-carousel';
import { ReportUserItem } from '../../components/archive/ReportUserItem';
import {
  loadReportUsersBatchNext,
  prepareReportUsersBatchKey,
} from '../../actions/archive/archiveReportUser.actions';
import {
  IArchiveReportUser,
  IReportUserOrder,
} from '../../interfaces/archive/IArchiveReportUser';
import { DataBatchWide } from '../../interfaces/system/data';
import { makePathFromName } from '../../utils/routes';
import {
  IArchiveReportLot,
  IArchiveReportLotOrder,
} from '../../interfaces/archive/IArchiveReportLot';
import { ILotType } from '../../interfaces/auctions/ILotPublic';
import {
  loadArchiveReportLotBatchNext,
  prepareReportLotBatchKey,
} from '../../actions/archive/archiveReportLot.actions';
import ReportLotItem from '../../components/archive/ReportLotItem';
import { L } from '../../common/locale';

interface IArchiveAuctionPageProps {
  loadArchiveReportLotBatchNext: (
    lotType: ILotType,
    auctionID: string,
    reportLotOrder: IArchiveReportLotOrder
  ) => void;
  loadReportUsersBatchNext: (
    auctionID: string,
    order: IReportUserOrder
  ) => void;

  IDByPath: Record<string, string>;
  auctionByID: Record<string, IAuction>;

  reportLotsByBatchKey: Record<string, DataBatchWide>;
  reportLotsSet: Record<string, IArchiveReportLot>;
  reportUsersByBatchKey: Record<string, DataBatchWide>;
  reportUsersSet: Record<string, IArchiveReportUser>;
}

function ArchiveAuctionPage(props: IArchiveAuctionPageProps) {
  const navigate = useNavigate();
  const { auctionPath } = useParams();

  const [auctionID, setAuctionID] = useState<null | string>(null);
  const [auction, setAuction] = useState<null | IAuction>(null);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const [reportLotOrder] = useState<IArchiveReportLotOrder>('Total');
  const [reportLotsLiveBatchKey, setReportLotsLiveBatchKey] =
    useState<string>('');
  const [reportLotsWeekBatchKey, setReportLotsWeekBatchKey] =
    useState<string>('');
  const [reportLotsLiveIDs, setReportLotsLiveIDs] = useState<string[]>([]);
  const [reportLotsWeekIDs, setReportLotsWeekIDs] = useState<string[]>([]);
  const [reportUserOrder] = useState<IReportUserOrder>('buyTotal');
  const [reportUsersBatchKey, setReportUsersBatchKey] = useState<string>('');
  const [reportUserIDs, setReportUserIDs] = useState<string[]>([]);

  useEffect(() => sendPageEvent('ArchiveAuction'), []);
  useEffect(() => {
    if (!Object.keys(props.IDByPath).length) {
      return;
    }

    const id: null | string = getAuctionIDByPath(auctionPath, props.IDByPath);
    setAuctionID(id);
    if (id && props.auctionByID[id]) {
      setAuction(props.auctionByID[id]);
      setIsComplete(props.auctionByID[id].isComplete);
    }
  }, [props.IDByPath]);
  useEffect(() => {
    if (!auctionID) {
      return;
    }

    setReportLotsLiveBatchKey(
      prepareReportLotBatchKey('Live', auctionID, reportLotOrder)
    );
    setReportLotsWeekBatchKey(
      prepareReportLotBatchKey('Week', auctionID, reportLotOrder)
    );
  }, [auctionID, reportLotOrder]);
  useEffect(() => {
    if (!auctionID) {
      return;
    }

    setReportUsersBatchKey(
      prepareReportUsersBatchKey(auctionID, reportUserOrder)
    );
  }, [auctionID]);
  useEffect(() => {
    if (!auctionID) {
      return;
    }

    if (
      !props.reportLotsByBatchKey[reportLotsLiveBatchKey] ||
      !props.reportLotsByBatchKey[reportLotsLiveBatchKey].loaded
    ) {
      props.loadArchiveReportLotBatchNext('Live', auctionID, reportLotOrder);
    }
    if (
      !props.reportLotsByBatchKey[reportLotsWeekBatchKey] ||
      !props.reportLotsByBatchKey[reportLotsWeekBatchKey].loaded
    ) {
      props.loadArchiveReportLotBatchNext('Week', auctionID, reportLotOrder);
    }
  }, [
    auctionID,
    reportLotOrder,
    reportLotsLiveBatchKey,
    reportLotsWeekBatchKey,
    props.reportLotsByBatchKey,
  ]);
  useEffect(() => {
    if (!auctionID) {
      return;
    }
    if (
      props.reportUsersByBatchKey[reportUsersBatchKey] &&
      props.reportUsersByBatchKey[reportUsersBatchKey].loaded
    ) {
      return;
    }

    props.loadReportUsersBatchNext(auctionID, reportUserOrder);
  }, [auctionID, reportUsersBatchKey, props.reportUsersByBatchKey]);
  useEffect(() => {
    setReportLotsLiveIDs(
      (props.reportLotsByBatchKey[reportLotsLiveBatchKey] &&
        props.reportLotsByBatchKey[reportLotsLiveBatchKey].IDs) ||
        []
    );
    setReportLotsWeekIDs(
      (props.reportLotsByBatchKey[reportLotsWeekBatchKey] &&
        props.reportLotsByBatchKey[reportLotsWeekBatchKey].IDs) ||
        []
    );
  }, [
    reportLotsLiveBatchKey,
    reportLotsWeekBatchKey,
    props.reportLotsByBatchKey,
  ]);
  useEffect(() => {
    setReportUserIDs(
      (props.reportUsersByBatchKey[reportUsersBatchKey] &&
        props.reportUsersByBatchKey[reportUsersBatchKey].IDs) ||
        []
    );
  }, [props.reportUsersByBatchKey, reportUsersBatchKey]);

  const goToShop = () => {
    navigate(appConfig.routes.shopProducts.list);
  };

  const goToReportsList = (lotType: ILotType) => {
    if (!auctionID) {
      return;
    }
    const { name } = props.auctionByID[auctionID];
    const auctionPath = makePathFromName(name);
    const path = (
      (lotType === 'Week' && appConfig.routes.auctions.weekLotsList) ||
      appConfig.routes.auctions.lotsList
    ).replace(appConfig.params.auctionPath, auctionPath);
    navigate(path);
  };

  const goToAccountsList = () => {
    if (!auctionID) {
      return;
    }
    const { name } = props.auctionByID[auctionID];
    const auctionPath = makePathFromName(name);
    const path = appConfig.routes.auctions.usersList.replace(
      appConfig.params.auctionPath,
      auctionPath
    );
    navigate(path);
  };

  return (
    <AppContent>
      <Block>
        <AuctionInfo showAllActions={true} />

        {auctionID && isComplete && auction && (
          <>
            <AuctionTotals auction={auction} isBig={true} />

            {reportLotsLiveIDs[0] && (
              <>
                <ContentHead>
                  <L k={'AuctionLotsLive'} /> ({reportLotsLiveIDs.length})
                </ContentHead>
                <Block className={'carousel'}>
                  <Carousel autoPlay={true} showThumbs={false}>
                    {reportLotsLiveIDs.slice(0, 10).map((lotID: string) => (
                      <Block key={lotID} className={'left'}>
                        <ReportLotItem lotID={lotID} />
                      </Block>
                    ))}
                  </Carousel>
                </Block>
                <ActionButton onClick={() => goToReportsList('Live')}>
                  <L k={'AuctionLotsLiveAll'} />
                </ActionButton>
              </>
            )}

            {reportLotsWeekIDs[0] && (
              <>
                <ContentHead>
                  <L k={'AuctionLotsWeek'} /> ({reportLotsWeekIDs.length})
                </ContentHead>
                <Block className={'carousel'}>
                  <Carousel autoPlay={true} showThumbs={false}>
                    {reportLotsWeekIDs.slice(0, 10).map((lotID: string) => (
                      <Block key={lotID} className={'left'}>
                        <ReportLotItem lotID={lotID} />
                      </Block>
                    ))}
                  </Carousel>
                </Block>
                <ActionButton onClick={() => goToReportsList('Week')}>
                  <L k={'AuctionLotsWeekAll'} />
                </ActionButton>
              </>
            )}

            <ContentHead>Top Auction donators:</ContentHead>
            <Block className={'carousel'}>
              <Carousel autoPlay={true} showThumbs={false}>
                {reportUserIDs.slice(0, 10).map((id: string) => (
                  <Block key={id} className={'left'}>
                    {props.reportUsersSet[id] && (
                      <ReportUserItem
                        isClickable={true}
                        showInline={false}
                        reportUser={props.reportUsersSet[id]}
                        order={reportUserOrder}
                      />
                    )}
                  </Block>
                ))}
              </Carousel>
            </Block>

            <BlockActions>
              <ActionButton onClick={() => goToAccountsList()}>
                <L k={'AuctionDonatorsAll'} />
              </ActionButton>
            </BlockActions>
          </>
        )}
        {auctionID && (!isComplete || !auction) && (
          <>
            <ContentHead>
              <L k={'AuctionLotsEmpty'} />{' '}
            </ContentHead>
            <ButtonMain onClick={goToShop}>
              <L k={'ActionGoToShop'} />
            </ButtonMain>
          </>
        )}
        {!auctionID && <Loading size={'big'} />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    auctionByID: state.Auction.auctions.set,
    IDByPath: state.Auction.auctions.IDByPath,
    reportLotsByBatchKey: state.Archive.reportLots.byBatchKey,
    reportLotsSet: state.Archive.reportLots.set,
    reportUsersByBatchKey: state.Archive.reportUsers.byBatchKey,
    reportUsersSet: state.Archive.reportUsers.set,
  }),
  {
    loadArchiveReportLotBatchNext,
    loadReportUsersBatchNext,
  }
)(ArchiveAuctionPage);
