import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Block } from '../../common/layout';
import { IState } from '../../interfaces/system/IState';
import { sendPageEvent } from '../../utils/analytics';
import { ActionButton, BlockPage, BlockPageContent } from '../../common/styles';
import AnalyticsMainHead from '../../common/moderation/AnalyticsMainHead';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';

interface IMainAnalyticsProps {}

function MainAnalytics(props: IMainAnalyticsProps) {
  const navigation = useNavigate();

  useEffect(() => sendPageEvent('MainAnalytics'), []);

  const goToTech = () => {
    navigation(appConfig.routes.Analytics.tech);
  };

  const goToCache = () => {
    navigation(appConfig.routes.Analytics.cache);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <AnalyticsMainHead />
        <Block>
          <ActionButton onClick={goToTech}>Tech</ActionButton>
        </Block>
        <Block>
          <ActionButton onClick={goToCache}>Cache</ActionButton>
        </Block>
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect((state: IState) => ({}), {})(MainAnalytics);
