import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import {
  BlockActions,
  BlockPage,
  BlockPageContent,
  ButtonMain,
} from '../../../common/styles';
import { Block } from '../../../common/layout';
import { ContentHead } from '../../../common/typos';
import { sendPageEvent } from '../../../utils/analytics';
import AccountSearch from '../../../components/account/AccountSearch';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import {
  loadAdminIDs,
  updateAdmin,
} from '../../../actions/account/admins.actions';
import AccountImage from '../../../components/account/AccountImage';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { setError } from '../../../actions/system/system.actions';
import AccountItemMail from '../../../components/account/AccountItemMail';
import ModerationMainHead from '../../../common/moderation/ModerationMainHead';
import { SelectionPage } from '../../../common/moderation/SelectionPage';
import { RenderItemPublicFunction } from '../../../common/common';
import { AccountItemModeration } from '../../../components/account/AccountItemModeration';
import { Switch } from '../../../common/forms/Switch';
import { checkIsExample } from '../../../config/platform.config';

interface IAdminsModerationProps {
  setError: (message: string) => void;
  loadAdminIDs: () => void;
  updateAdmin: (accountID: string, isAdmin: boolean) => void;
  isAdmin: boolean;
  adminIDs: null | string[];
  accounts: IAccountSet;
}

function AdminsModeration(props: IAdminsModerationProps) {
  const navigation = useNavigate();
  const [accountID, setAccountID] = useState<null | string>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => sendPageEvent('AdminsModeration'), []);
  useEffect(() => {
    if (checkIsExample()) {
      props.setError('Admin Moderation is not able at Example Auction');
      return navigation(appConfig.routes.admin.main);
    }
    setIsLoading(false);
    setAccountID(null);
  }, [props.adminIDs]);
  useEffect(
    () =>
      setIsAdmin(
        (props.adminIDs && accountID && props.adminIDs.includes(accountID)) ||
          false
      ),
    [accountID]
  );

  const save = () => {
    if (!accountID) {
      return;
    }

    props.updateAdmin(accountID, isAdmin);
    setIsLoading(true);
  };

  const reset = () => {
    setAccountID(null);
  };

  const renderItemPublic: RenderItemPublicFunction<IAccount> = (
    item: IAccount,
    inlineBlock?: JSX.Element
  ) => {
    return (
      <AccountItemModeration
        account={item}
        showInline={true}
        inlineBlock={inlineBlock}
      />
    );
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />

        <SelectionPage
          renderItemPublic={renderItemPublic}
          data={{
            set: props.accounts,
            list: {
              load: props.loadAdminIDs,
              IDs: props.adminIDs || [],
            },
          }}
          isAdmin={props.isAdmin}
          action={{
            showInline: true,
            hideSelected: true,
          }}
          selection={{
            cbSelected: setAccountID,
            selectedIDs: accountID ? [accountID] : null,
          }}
        />

        <Block className={isLoading ? 'disabled' : ''}>
          <ContentHead>Search and select account:</ContentHead>
          <AccountSearch
            mode={'account'}
            cbSelected={(accountID: string) => setAccountID(accountID)}
            selectedIDs={(accountID && [accountID]) || null}
          />
        </Block>

        {accountID && (
          <Block>
            <ContentHead>Update account</ContentHead>

            <AccountImage accountID={accountID} size={'S'} />

            <AccountItemMail accountID={accountID} />
            <Switch
              isChecked={isAdmin}
              cb={setIsAdmin}
              options={['Regular account', 'Is admin']}
            />

            <BlockActions>
              <ButtonMain onClick={save} disabled={isLoading}>
                Save changes
              </ButtonMain>
              <ButtonMain onClick={reset} disabled={isLoading}>
                Cancel
              </ButtonMain>
            </BlockActions>
          </Block>
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    adminIDs: state.Account.adminIDs,
    accounts: state.Account.accounts.set,
  }),
  {
    setError,
    loadAdminIDs,
    updateAdmin,
  }
)(AdminsModeration);
