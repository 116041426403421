import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { IProductDraft, IProductDraftSet } from '../../../interfaces/shop/IProductDraft';
import { sendEvent } from '../../../utils/analytics';
import { IParamsItemView, RenderFormFunction, RenderItemPublicFunction } from '../../../common/common';
import DraftItemModeration from '../../../components/shop/draft/DraftItemModeration';
import DraftForm from '../../../components/shop/draft/DraftForm';
import DraftFormDecline from '../../../components/shop/draft/DraftFormDecline';
import { loadProductModerationBatchNext } from '../../../actions/moderation/admin-shop-moderation.actions';
import { ModerationPageEdit } from '../../../common/moderation/ModerationPageEdit';

interface IShopModerationEditEditProps {
  editProductID: null | string;
  draftIDByProductID: Record<string, string>;
  set: IProductDraftSet;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function ShopProductsEdit(props: IShopModerationEditEditProps) {
  const [draftID, setDraftID] = useState<null | string>(null);

  useEffect(() => sendEvent('Open-ShopModerationEdit'), []);
  useEffect(
    () =>
      setDraftID(
        props.editProductID
          ? props.draftIDByProductID[props.editProductID]
          : null,
      ),
    [props.editProductID, props.draftIDByProductID],
  );

  const renderItemPublic: RenderItemPublicFunction<IProductDraft> = (
    item: IProductDraft,
    inlineBlock?: JSX.Element,
    ParamsItemView?: IParamsItemView,
  ) => {
    return (
      <DraftItemModeration
        draft={item}
        showInline={false}
        inlineBlock={inlineBlock}
        ParamsItemView={ParamsItemView}
      />
    );
  };

  const renderForm: RenderFormFunction<IProductDraft> = (
    isDisabled: undefined | boolean,
    item: undefined | IProductDraft,
    cbCancel: () => void,
  ) => {
    return (
      <DraftForm
        isDisabled={isDisabled}
        draft={item}
        cbCancel={cbCancel}
        actions={props.actions}
      />
    );
  };

  const renderFormRemove: RenderFormFunction<IProductDraft> = (
    isDisabled: undefined | boolean,
    item: undefined | IProductDraft,
    cbCancel: () => void,
  ) => {
    if (!item) {
      return <></>;
    }

    return (
      <DraftFormDecline
        isDisabled={isDisabled}
        actions={props.actions}
        cbCancel={cbCancel}
        productDraftID={item.id}
      />
    );
  };

  const cbCancel = () => {
    window.history.back();
  };

  return (
    <ModerationPageEdit
      itemID={draftID}
      pageTitle={'Shop products moderation'}
      itemName={'this products'}
      render={{
        renderItemPublic,
        renderForm,
        renderFormRemove,
      }}
      dataEdit={{
        set: props.set,
        actions: props.actions,
        errors: props.errors,
      }}
      isAdmin={props.isAdmin}
      cb={{
        cbCancel,
      }}
    />
  );
}

export default connect(
  (state: IState) => ({
    editProductID: state.ShopModeration.products.editProductID,
    draftIDByProductID: state.ShopProduct.drafts.IDByProductID,
    set: state.ShopProduct.drafts.set,
    isAdmin: state.Account.isAdmin,
    actions: state.ShopProduct.drafts.actions,
    errors: state.System.errors,
  }),
  {
    loadProductModerationBatchNext,
  },
)(ShopProductsEdit);
