import styled from 'styled-components';
import { margins, sizes, sizesBorder } from '../../common/sizes';
import { shadows } from '../../common/styles';
import { colors } from '../../common/colors';

export const PreviewBlock = styled.div`
  position: relative;
  cursor: pointer;
  max-width: ${sizes.widthL};
  display: inline-block;
  float: left;
  padding-right: ${margins.item};
  box-sizing: border-box;

  &:hover {
    & .tooltip {
      display: block;
    }
  }

  &.cnt-1 {
    max-width: ${sizes.widthL};
    display: block;
    //margin: auto; // todo check when products > 1
  }

  &.foundation {
    border: ${sizesBorder.widthL} solid ${colors.win};
    border-radius: ${sizesBorder.radius};
    box-sizing: border-box;
  }
`;

export const FoundationTooltip = styled.div`
  display: none;
  position: absolute;
  margin-top: -${margins.blockX2};
  box-shadow: ${shadows.main};
  padding: ${margins.item} ${margins.block};
  background-color: ${colors.background};
  border-radius: ${sizesBorder.radius};
  z-index: 9;
`;

export const AnalyticsIconBlock = styled.div`
  width: ${sizes.full};
  position: absolute;
  right: 10px;
  top: 10px;

  & div {
    display: block;
    background-color: rgba(0, 37, 62, 0.5);
    border-radius: ${sizesBorder.radius};
    margin-right: ${margins.item};
    padding: 3px;
  }

  & img {
    filter: invert(100%);
    margin: 0;
    float: left;
  }

  &:hover {
  }
`;

export const AnalyticsIconItem = styled.div`
  display: flex;
  align-items: center;
  float: right;
  margin-left: ${margins.item};

  &.clickable {
    cursor: pointer;
  }

  &.left {
    float: left;
    margin-right: ${margins.item};
  }
`;
