import React from 'react';
import { ContentBold, ContentDesc, ContentDescInline } from '../../common/typos';
import { currencyConvert } from '../../utils/price';

interface ILegendItemPriceProps {
  price: number;
  title: string;
  isBold?: boolean;
}

export function LegendItemPrice(props: ILegendItemPriceProps) {
  const renderPrice = () => {
    return (
      <>
        <ContentDescInline>{props.price}</ContentDescInline>
        {props.price !== 0 && (
          <ContentDescInline>
            {' '}
            (${currencyConvert(props.price)})
          </ContentDescInline>
        )}
      </>
    );
  };

  return (
    <ContentDesc>
      <ContentDescInline>{props.title}</ContentDescInline>
      <ContentDescInline>: </ContentDescInline>
      {props.isBold && props.price !== 0 ? (
        <ContentBold>{renderPrice()}</ContentBold>
      ) : (
        renderPrice()
      )}
    </ContentDesc>
  );
}
