import React from 'react';
import { Trans } from 'react-i18next';
import { ContentBold } from './typos';
import {
  ITranslationAllTypes,
  ITranslationSimpleFormTypes,
} from '../config/locales/_.types';

interface ILProps {
  k: keyof ITranslationAllTypes | keyof ITranslationSimpleFormTypes;
}

export function L(props: ILProps) {
  return (
    <Trans
      i18nKey={props.k}
      components={{ bold: <ContentBold />, line: <br /> }}
    ></Trans>
  );
}
