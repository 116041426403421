import { ITranslationSimpleFormTypes } from './_.types';

export const translationFormUA: ITranslationSimpleFormTypes = {
  FormPartnerSellerTitleCreate: 'Зареєструвати нового партнера',
  FormPartnerSellerTitleUpdate: 'Оновити інформацію про партнера',
  FormAuctionTitleCreate: 'Зареєструвати новий аукціон',
  FormAuctionTitleUpdate: 'Оновити інформацію про аукціон',
  FormMailingTitleCreate: 'Зареєструвати нову розсилку',
  FormMailingTitleUpdate: 'Оновити інформацію про розсилку',
  FormRecommendationShort: 'рекомендація',
  FormRecommendationTitleCreate: 'Зареєструвати рекомендацію',
  FormRecommendationTitleUpdate: 'Оновити рекомендацію',
  FormTemplateParamsTitleShort: 'темплейт листа пошти',
  FormTemplateParamsTitleCreate: 'Додати темплейт листа пошти',
  FormTemplateParamsTitleUpdate: 'Оновити темплейт листа пошти',
  FormBankDetailsTitleCreate: 'Зареєструвати банківські реквізити',
  FormBankDetailsTitleUpdate: 'Оновити банківські реквізити',
  FormBankDetailsMain: 'Основні банківські реквізити аукціону',
  FormBankDetailsFoundation: 'Назва фонду:',
  FormBankDetailsDonation: 'Посилання для донатів:',
  FormBankDetailsDonationPreview: 'Це буде показано як:',
  FormDonationDetails: 'Інформація прро донати:',
  FormDonationName: 'Донати:',
  FormDonationLink: 'Посилання для здійснення платежу:',
  FormDonationScreenshots: 'Скріншот платежу донату:',
  FormRoleAdmin: 'Перегляд адміністратора',
  FormRoleDonator: 'Перегляд донатора',

  FormReportLabelSold: 'Продано за:',
  FormReportLabelFrom: 'Від:',

  FormLabelCreated: 'Створено',
  FormLabelUpdated: 'Оновлено',
  FormLabelSend: 'Відправлено',

  FormPlaceHolderExample: 'Наприклад:',
  FormPlaceHolderNameEmail: 'імʼя або пошта',
  FormPlaceHolderPartnerSeller: 'партнер',
  FormPlaceHolderAuction: 'аукціон',
  FormPlaceHolderDonation: 'донат',

  FormFilterMailing: 'Фільтр по розсилці:',
  FormFilterAccount: 'Фільтр по користувачу:',
  FormFilterMode: 'Фільтр по типу:',
  FormFilterEngagement: 'Фільтр по взаємодії:',
  FormFilterSocial: 'Фільтр по соціальній взаємодії:',
  FormFilterEmail: 'Фільтр по пошті:',
  FormFilterParsed: 'Фільтрувати за властивістю аналізованих елементів:',

  FormActionSelectSeller: 'Обрати продавця:',
  FormActionSelectSellerNotEmpty: 'Потрібно обрати продавця',
  FormActionSelectAccounts: 'Обрати користувачів',
  FormActionSelectParams: 'Обрати параметри',
  FormActionOpenMailing: 'відкрити розсилку',
  FormActionEditEmailSubject: 'Редагувати тему листа',
  FormActionEditParam: 'Редагувати параметр',
  FormActionPreviewEmail: 'Попередній перегляд листа:',

  FormActionAdd: 'Додати',
  FormActionEdit: 'Редагувати',
  FormActionRemove: 'Видалити',
  FormActionShowPreview: 'Показати попередній перегляд',
  FormActionHidePreview: 'Приховати попередній перегляд',
  FormActionOpenLinkAll: 'відкрити всі посилання',
  FormActionOpenLinkUrl: 'відкрита URL-адреса',
  FormActionOpenLinkSocial: 'відкрита соціальна мережа',
  FormActionOpenLinkInst: 'відкритий інст',
};
