import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { DONATION_ADMIN_LOAD } from '../types';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import {
  IDonation,
  IDonationAccountListResponse,
  IDonationAdminSet,
} from '../../interfaces/donation/IDonationAccount';
import { loadAccountsSet } from '../account/accounts.actions';
import { IAccountSet } from '../../interfaces/account/IAccount';

const { admin } = apiConfig.endpoints.archiveDonation;

export const getDonationsByAccountID =
  (accountID: string) =>
    (
      dispatch: IDispatch<IDonationAdminSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      callApi<IDonationAccountListResponse>(admin.byAccountID, { accountID })
        .then((data: IDonationAccountListResponse) => {
          const payload: IDonationAdminSet = {
            auctionID: null,
            accountID,
            donations: data.donations,
          };
          dispatch({ type: DONATION_ADMIN_LOAD, payload });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const getDonationsByAuctionID =
  (auctionID: string) =>
    (
      dispatch: IDispatch<IDonationAdminSet | IAccountSet | IServiceError>,
      getState: () => IState,
    ) => {
      if (!getState().Account.isAdmin) {
        return;
      }
      callApi<IDonationAccountListResponse>(admin.byAuctionID, { auctionID })
        .then((data: IDonationAccountListResponse) => {
          const payload: IDonationAdminSet = {
            auctionID,
            accountID: null,
            donations: data.donations,
          };
          dispatch({ type: DONATION_ADMIN_LOAD, payload });
          const accountIDSet: Record<string, boolean> = {};
          data.donations.forEach((donation: IDonation) => {
            accountIDSet[donation.buyerID] = true;
          });

          loadAccountsSet(Object.keys(accountIDSet))(dispatch, getState);
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
