import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import TopDonators from './TopDonators';
import { Block } from '../../../common/layout';
import { currencyConvert } from '../../../utils/price';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../../common/typos';
import { IReportAuctionUpdate } from '../../../interfaces/auctions/IReportAuction';
import { L } from '../../../common/locale';

interface IResultFinalProps {
  currentLotID: undefined | null | string;
  reportAuctionLive: undefined | null | IReportAuctionUpdate;
}

function ResultFinal(props: IResultFinalProps) {
  return (
    <Block>
      <PageHead>
        <L k={'ReportsTotal'} />
      </PageHead>

      {props.reportAuctionLive && (
        <>
          <ContentDesc>
            <L k={'HallSortDonated'} />{' '}
            <ContentBold>
              {props.reportAuctionLive.total} ($
              {currencyConvert(props.reportAuctionLive.total)}){' '}
            </ContentBold>
          </ContentDesc>

          <ContentHead>
            <L k={'AuctionResultThankYou'} />{' '}
          </ContentHead>

          <br />
          <ContentHead>
            <L k={'AuctionResultBank'} />{' '}
          </ContentHead>

          <TopDonators
            topDonators={props.reportAuctionLive.topDonators}
          ></TopDonators>
          <br />
          <br />
          <ContentHead>
            <L k={'AuctionResultShare'} />{' '}
          </ContentHead>
        </>
      )}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    currentLotID: state.Lot.short.currentLotID,
    reportAuctionLive: state.Report.auctionLive,
  }),
  {}
)(ResultFinal);
