import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { ActionButton, BlockActions } from '../../common/styles';
import { Block } from '../../common/layout';
import { IState } from '../../interfaces/system/IState';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../common/animation/Loading';
import { appConfig } from '../../config/app.config';
import AuctionInfo from '../../components/auctions/AuctionInfo';
import { getComplexPathFromPath } from '../../utils/archive';
import { loadReportUserByPath } from '../../actions/archive/archiveReportUser.actions';
import { IArchiveReportUser } from '../../interfaces/archive/IArchiveReportUser';
import { sendPageEvent } from '../../utils/analytics';
import { ReportUserItem } from '../../components/archive/ReportUserItem';
import { ContentBold } from '../../common/typos';
import ReportLotItem from '../../components/archive/ReportLotItem';
import { IParamsItemView } from '../../common/common';
import { L } from '../../common/locale';

interface IReportUserPageProps {
  loadReportUserByPath: (auctionPath: string, accountPath: string) => void;
  set: Record<string, IArchiveReportUser>;
  IDByComplexPath: Record<string, string[]>;
}

function ReportUserPage(props: IReportUserPageProps) {
  const navigate = useNavigate();
  const { auctionPath, accountPath } = useParams();
  const [reports, setReports] = useState<null | IArchiveReportUser[]>(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const view: IParamsItemView = {
    sellerID: false,
    buyerList: false,
  };

  useEffect(() => sendPageEvent('ArchiveReportUser'), []);
  useEffect(() => {
    if (reports) {
      return;
    }
    if (!auctionPath) {
      return navigate(appConfig.routes.auctions.list);
    }
    if (!accountPath) {
      return navigate(appConfig.routes.auctions.usersList);
    }

    const complexPath: string = getComplexPathFromPath(
      auctionPath,
      accountPath
    );
    const reportIDs: undefined | string[] = props.IDByComplexPath[complexPath];
    const preReports: IArchiveReportUser[] = [];
    if (reportIDs) {
      reportIDs.forEach((reportID: string) => {
        const preReport: undefined | IArchiveReportUser = props.set[reportID];
        if (preReport) {
          preReports.push(preReport);
        }
      });
    }

    if (preReports[0]) {
      setReports(preReports);
    } else if (!isLoad) {
      setIsLoad(true);
      props.loadReportUserByPath(auctionPath, accountPath);
    }
  }, [props.IDByComplexPath, props.set]);

  const goToReportsList = () => {
    if (!auctionPath) {
      return;
    }
    const path = appConfig.routes.auctions.usersList.replace(
      appConfig.params.auctionPath,
      auctionPath
    );
    navigate(path);
  };

  return (
    <AppContent>
      <Block>
        <AuctionInfo />
        {reports &&
          reports.map((report: IArchiveReportUser) => (
            <Block key={report.id}>
              <ReportUserItem
                showInline={false}
                reportUser={report}
                order={'buyTotal'}
                isNameOnTop={true}
              />
              {(report.lotsSold[0] ||
                (report.weekLotsSold && report.weekLotsSold[0])) && (
                <>
                  <br />
                  <ContentBold>
                    <L k={'AccountLotsOrderSold'} />
                  </ContentBold>
                </>
              )}

              <Block className={'noMargin'}>
                {report.lotsSold[0] &&
                  report.lotsSold.map((lotID: string) => (
                    <ReportLotItem
                      lotID={lotID}
                      key={lotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
              </Block>
              <Block className={'noMargin'}>
                {report.weekLotsSold &&
                  report.weekLotsSold[0] &&
                  report.weekLotsSold.map((weekLotID: string) => (
                    <ReportLotItem
                      lotID={weekLotID}
                      key={weekLotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
              </Block>
              <Block className={'noMargin'}>
                {(report.lotsBought[0] ||
                  (report.weekLotsBought && report.weekLotsBought[0])) && (
                  <>
                    <br />
                    <ContentBold>
                      <L k={'AccountLotsOrderDonated'} />
                    </ContentBold>
                  </>
                )}
              </Block>
              <Block className={'noMargin'}>
                {report.lotsBought[0] &&
                  report.lotsBought.map((lotID: string) => (
                    <ReportLotItem
                      lotID={lotID}
                      key={lotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
              </Block>
              <Block className={'noMargin'}>
                {report.weekLotsBought &&
                  report.weekLotsBought[0] &&
                  report.weekLotsBought.map((weekLotID: string) => (
                    <ReportLotItem
                      lotID={weekLotID}
                      key={weekLotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
              </Block>
            </Block>
          ))}

        <BlockActions>
          <ActionButton onClick={() => goToReportsList()}>
            <L k={'ReportsLotAll'} />
          </ActionButton>
        </BlockActions>

        {!reports && <Loading size={'big'} />}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Archive.reportUsers.set,
    IDByComplexPath: state.Archive.reportUsers.IDByComplexPath,
  }),
  {
    loadReportUserByPath,
  }
)(ReportUserPage);
