import React, { useEffect, useState } from 'react';
import { AppFooter, AppFooterLink } from './Footer.styles';
import { connect } from 'react-redux';
import { IState } from '../interfaces/system/IState';
import { IAuctionMode } from '../interfaces/auctions/IAuction';
import { getRewriteConfig } from '../config/platform.config';

interface IFooterProps {
  auctionMode: undefined | null | IAuctionMode;
}

function Footer(props: IFooterProps) {
  const [isLive, setIsLive] = useState<boolean>(false);

  useEffect(() => {
    setIsLive(
      (props.auctionMode &&
        ['lot', 'reportLot', 'reportAuction', 'reportFinal'].includes(
          props.auctionMode,
        )) ||
      false,
    );
  }, [props.auctionMode]);

  return (
    <AppFooter>
      {
        !getRewriteConfig()?.hideFooterSign &&
        !isLive && (
          <AppFooterLink href="https://create.auction" target="_blank">
            powered by CREATE.AUCTION
          </AppFooterLink>
        )}
    </AppFooter>
  );
}

export default connect(
  (state: IState) => ({
    auctionMode: state.Auction.auctionMode,
  }),
  {},
)(Footer);
