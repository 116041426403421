import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAuctionList } from '../../actions/auctions/auction.actions';
import { AppContent } from '../../App.styles';
import { ActionButton } from '../../common/styles';
import { ContentDesc, ContentDescInline, PageHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { IAuction } from '../../interfaces/auctions/IAuction';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { sendPageEvent } from '../../utils/analytics';
import { Block } from '../../common/layout';
import AuctionItemModeration from '../../components/auctions/AuctionItemModeration';
import { L } from '../../common/locale';

interface IArchiveAuctionsPageProps {
  getAuctionList: () => any;
  auctionByID: Record<string, IAuction>;
  auctionIDs: string[];
}

function ArchiveAuctionsPage(props: IArchiveAuctionsPageProps) {
  const navigate = useNavigate();
  const [auctionsCompleted, setAuctionsCompleted] = useState<IAuction[]>([]);
  const [auctionsUpcoming, setAuctionsUpcoming] = useState<IAuction[]>([]);

  useEffect(() => sendPageEvent('ArchiveAuctions'), []);
  useEffect(() => props.getAuctionList(), []);

  useEffect(() => {
    const localCompleted: IAuction[] = [];
    const localUpcoming: IAuction[] = [];

    props.auctionIDs.forEach((auctionID: string) => {
      const auction: null | IAuction = props.auctionByID[auctionID] || null;
      if (!auction) {
        return;
      }

      if (auction.isComplete) {
        localCompleted.push(auction);
      } else {
        localUpcoming.push(auction);
      }

      localCompleted.sort((a, b) => ((a.ind || 0) > (b.ind || 0) ? 1 : -1));
      localUpcoming.sort((a, b) => ((a.ind || 0) > (b.ind || 0) ? -1 : 1));

      setAuctionsCompleted(localCompleted);
      setAuctionsUpcoming(localUpcoming);
    });
  }, [props.auctionByID]);

  const goToShop = () => {
    navigate(appConfig.routes.shopProducts.list);
  };

  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'AuctionsTitle'} />
        </PageHead>
        {auctionsUpcoming[0] && (
          <>
            <ContentDesc className="success">
              <ContentDescInline style={{ fontSize: '2rem' }}>
                •
              </ContentDescInline>
              <L k={'AuctionsTitleUpcomingShort'} />
            </ContentDesc>
            {auctionsUpcoming.map((auction: IAuction) => (
              <AuctionItemModeration
                auction={auction}
                key={auction.id}
                isClickable={true}
                showInline={false}
              />
            ))}
            <ActionButton onClick={goToShop}>
              <L k={'ActionGoToShop'} />
            </ActionButton>
          </>
        )}

        {auctionsCompleted[0] && (
          <>
            <ContentDesc>
              <L k={'AuctionsTitleCompletedShort'} />
            </ContentDesc>
            {auctionsCompleted.map((auction: IAuction) => (
              <AuctionItemModeration
                auction={auction}
                key={auction.id}
                isClickable={true}
                showInline={false}
              />
            ))}
          </>
        )}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    auctionIDs: state.Auction.auctions.IDs,
    auctionByID: state.Auction.auctions.set,
  }),
  { getAuctionList }
)(ArchiveAuctionsPage);
