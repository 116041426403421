import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IState } from '../../../interfaces/system/IState';
import { IBid, IBidSet } from '../../../interfaces/auctions/IBid';
import { loadBidIDs } from '../../../actions/auctions/bid.actions';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { Block } from '../../../common/layout';
import { currencyConvert } from '../../../utils/price';
import { appConfig } from '../../../config/app.config';
import { Gif, operateWithGif } from '../../../common/animation/Gif';
import { ILotStatus, ILotType } from '../../../interfaces/auctions/ILotPublic';
import { ContentDesc, ContentDescInline } from '../../../common/typos';

interface IBiddersProps {
  lotType: ILotType;
  loadBidIDs: (lotType: ILotType, currentLotID?: string) => void;
  set: IBidSet;
  bidIDsByLotID: Record<string, string[]>;
  currentLotID: null | string;
  status: ILotStatus;
  account: null | IAccount;
  users: IAccountSet;
  lotID: string;
}

function Bids(props: IBiddersProps) {
  const location = useLocation();
  const isPresenterPage = appConfig.routes.live.presenter === location.pathname;

  const [bids, setBids] = useState<IBid[]>([]);
  const [gifSrc, setGifSrc] = useState<null | string>(null);
  const [prevStatus, setPrevStatus] = useState<ILotStatus>(null);
  const [timeoutHolder, setTimeoutHolder] = useState<any>(null);

  useEffect(() => props.loadBidIDs(props.lotType, props.lotID), []);
  useEffect(() => {
    setPrevStatus(props.status);
    if (props.status === 'win') {
      operateWithGif('win', setGifSrc, timeoutHolder, setTimeoutHolder);
    } else if (props.status === 'lost' && prevStatus === 'win') {
      operateWithGif('lost', setGifSrc, timeoutHolder, setTimeoutHolder);
    }
  }, [props.status]);

  useEffect(() => {
    const { currentLotID, bidIDsByLotID, set } = props;
    const lotID: null | string = props.lotID || currentLotID;
    const bidIDs: null | string[] = lotID ? bidIDsByLotID[lotID] : null;
    const bidsLocal: IBid[] = [];
    if (bidIDs) {
      bidIDs.forEach((bidID: string) => {
        const bid: undefined | IBid = set[bidID];
        if (bid) {
          bidsLocal.push(bid);
        }
      });
    }
    setBids(bidsLocal);
  }, [props.bidIDsByLotID, props.currentLotID, props.set]);

  function renderUser(accountID: string) {
    const user: IAccount = props.users[accountID];
    return (
      <ContentDescInline>
        {user ? user.name : accountID && accountID.slice(0, 5) + '...'}
      </ContentDescInline>
    );
  }

  function renderBlock(bid: IBid, index: number) {
    const isSelf =
      props.account && bid.accountID === props.account.id && !isPresenterPage;

    return (
      <ContentDesc className={isSelf ? 'success' : ''} key={index}>
        {renderUser(bid.accountID)}
        <ContentDescInline> - </ContentDescInline>
        <ContentDescInline>
          {bid.price}
          <ContentDescInline>
            {' '}
            (${currencyConvert(bid.price)})
          </ContentDescInline>
        </ContentDescInline>
      </ContentDesc>
    );
  }

  return (
    <Block>
      {!isPresenterPage && gifSrc && <Gif status={props.status} src={gifSrc} />}

      {bids.slice(0, 10).map((bid: IBid, index) => renderBlock(bid, index))}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    bidIDsByLotID: state.Bid.IDsByLotID,
    set: state.Bid.byID,
    currentLotID: state.Bid.currentLotID,
    status: state.Bid.status,
    account: state.Account.account,
    users: state.Account.accounts.set,
  }),
  {
    loadBidIDs,
  }
)(Bids);
