import React from 'react';
import { Block } from '../../common/layout';
import { ContentBold, ContentDesc } from '../../common/typos';
import { IDonationBankDetails } from '../../interfaces/donation/IDonationBank';
import AccountItemMail from '../account/AccountItemMail';
import { L } from '../../common/locale';

interface IDonationBankDetailsItemProps {
  details: IDonationBankDetails;
}

export function BankDetailsItem(props: IDonationBankDetailsItemProps) {
  return (
    <Block>
      {props.details.sellerID && (
        <AccountItemMail accountID={props.details.sellerID} />
      )}
      {!props.details.sellerID && (
        <ContentDesc>
          <ContentBold>
            <L k={'FormBankDetailsMain'} />
          </ContentBold>
        </ContentDesc>
      )}

      <ContentDesc>
        <L k={'FormBankDetailsFoundation'} /> {props.details.foundationName}
      </ContentDesc>
      <ContentDesc>
        <L k={'FormBankDetailsDonation'} /> {props.details.link}
      </ContentDesc>

      <br />
      <ContentDesc>
        <L k={'FormBankDetailsDonationPreview'} />
      </ContentDesc>
      <ContentDesc
        dangerouslySetInnerHTML={{ __html: props.details.link }}
      ></ContentDesc>
    </Block>
  );
}
