import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  IProductDraft,
  IProductDraftCreateBody,
  IProductDraftCreateResponse,
  IProductDraftCreateSet,
  IProductDraftDeleteQuery,
  IProductDraftResponse,
  IProductDraftUpdateBody,
  IProductLink,
} from '../../interfaces/shop/IProductDraft';
import {
  ITEM_CREATED,
  ITEM_LOADED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  SHOP_PRODUCT_DRAFT_ACTION,
  SHOP_PRODUCT_DRAFT_CREATED,
  SHOP_PRODUCT_DRAFT_EDIT,
  SHOP_PRODUCT_DRAFT_LOAD,
  SHOP_PRODUCT_DRAFT_UPDATED,
} from '../types';
import { IServiceError } from '../../interfaces/system/IError';
import { getNotificationsIDs } from '../account/notification.actions';
import { INotificationSet } from '../../interfaces/account/IAccountNotification';
import { getAuctionMode } from '../auctions/auction.actions';
import { IAuctionModeResponse } from '../../interfaces/auctions/IAuction';
import { loadProductsByIDs } from './product.actions';
import { IProductPreview } from '../../interfaces/shop/IProduct';
import { IProductAnalyticsByAccount } from '../../interfaces/shop/IProductAnalytics';
import { setError } from '../system/system.actions';

const { productDraft } = apiConfig.endpoints.shop;

export const initEditDraft =
  (draft: null | IProductDraft) =>
    (dispatch: IDispatch<null | IProductDraft>) => {
      dispatch({ type: SHOP_PRODUCT_DRAFT_EDIT, payload: draft });
    };

export const loadProductsDraft =
  (hardReload?: boolean) =>
    (
      dispatch: IDispatch<
        | string
        | IProductDraft[]
        | IProductPreview[]
        | IProductAnalyticsByAccount[]
        | IServiceError
      >,
      getState: () => IState,
    ) => {
      if (!hardReload && getState().ShopProduct.drafts.IDs[0]) {
        return;
      }

      callApi<IProductDraftResponse>(productDraft.main)
        .then((data: IProductDraftResponse) => {
          const productIDs: string[] = [];
          data.products.forEach((draft: IProductDraft) => {
            if (draft.productID) {
              productIDs.push(draft.productID);
            }
          });
          if (productIDs[0]) {
            loadProductsByIDs(productIDs)(dispatch, getState);
          }
          dispatch({ type: SHOP_PRODUCT_DRAFT_ACTION, payload: ITEM_LOADED });
          dispatch({ type: SHOP_PRODUCT_DRAFT_LOAD, payload: data.products });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const reloadByProductID =
  (productID: string) =>
    (
      dispatch: IDispatch<string | IProductDraft[] | IServiceError>,
      getState: () => IState,
    ) => {
      callApi<IProductDraftResponse>(productDraft.byProductID, {
        productID,
      })
        .then((data: IProductDraftResponse) => {
          dispatch({ type: SHOP_PRODUCT_DRAFT_ACTION, payload: ITEM_LOADED });
          dispatch({ type: SHOP_PRODUCT_DRAFT_LOAD, payload: data.products });
        })
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const updateProductDraft =
  (
    productDraftID: string,
    auctionID: string,
    name: null | string,
    description: null | string,
    images: null | string[],
    isHide: null | boolean,
    baseName: string,
    links: undefined | null | IProductLink[],
  ) =>
    (
      dispatch: IDispatch<
        | string
        | IProductDraftCreateSet
        | string[]
        | INotificationSet
        | IServiceError
      >,
      getState: () => IState,
    ) => {
      const body: IProductDraftUpdateBody = {
        productDraftID,
        auctionID,
      };
      if (name) {
        body.name = name;
      }
      if (description) {
        body.description = description;
      }
      if (images) {
        body.images = images;
      }
      if (isHide !== null) {
        body.isHide = isHide;
      }
      if (links !== undefined) {
        body.links = links || undefined;
      }

      callApi<IProductDraftResponse>(productDraft.main, {}, body, 'POST')
        .then(() => {
          const payload: IProductDraftCreateSet = {
            name: name || baseName,
            productDraftID,
          };

          dispatch({ type: SHOP_PRODUCT_DRAFT_ACTION, payload: ITEM_UPDATED });
          dispatch({ type: SHOP_PRODUCT_DRAFT_UPDATED, payload });
        })
        .then(() => getNotificationsIDs()(dispatch, getState))
        .then(() => setError('Your lot is updated')(dispatch))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const createProductDraft =
  (
    name: string,
    description: string,
    imagesBase64: string[],
    links: undefined | IProductLink[],
  ) =>
    (
      dispatch: IDispatch<
        | string
        | null
        | IAuctionModeResponse
        | IProductDraftCreateSet
        | string[]
        | INotificationSet
        | IServiceError
      >,
      getState: () => IState,
    ) => {
      const { auctionNext } = getState().Auction;
      const body: IProductDraftCreateBody = {
        name,
        description,
        imagesBase64,
      };
      if (auctionNext) {
        body.auctionID = auctionNext.id;
      }
      if (links) {
        body.links = links;
      }

      callApi<IProductDraftCreateResponse>(productDraft.main, {}, body)
        .then((data: IProductDraftCreateResponse) => {
          const payload: IProductDraftCreateSet = {
            name,
            productDraftID: data.productDraftID,
          };

          dispatch({ type: SHOP_PRODUCT_DRAFT_ACTION, payload: ITEM_CREATED });
          dispatch({ type: SHOP_PRODUCT_DRAFT_CREATED, payload });
        })
        .then(() => getNotificationsIDs()(dispatch, getState))
        .then(() => getAuctionMode()(dispatch, getState))
        .then(() => setError('Your lot is created')(dispatch))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };

export const deleteProductDraft =
  (itemID: string) =>
    (
      dispatch: IDispatch<
        | string
        | IProductDraftCreateSet
        | string[]
        | INotificationSet
        | IProductDraft[]
        | IProductPreview[]
        | IProductAnalyticsByAccount[]
        | IServiceError
      >,
      getState: () => IState,
    ) => {
      const query: IProductDraftDeleteQuery = {
        itemID,
      };

      callApi<IProductDraftResponse>(
        productDraft.main,
        query,
        undefined,
        'DELETE',
      )
        .then(() =>
          dispatch({ type: SHOP_PRODUCT_DRAFT_ACTION, payload: ITEM_REMOVED }),
        )
        .then(() => getNotificationsIDs()(dispatch, getState))
        .then(() => loadProductsDraft(true)(dispatch, getState))
        .then(() => setError('Your lot has been removed')(dispatch))
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
