import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import {
  IProductDraft,
  IProductLink,
} from '../../../interfaces/shop/IProductDraft';
import { FormsFields } from '../../../common/forms/forms';
import DraftFormDecline from './DraftFormDecline';
import { Block } from '../../../common/layout';
import { scrollToForm } from '../../../common/moderation/Moderation';
import {
  createProductModeration,
  updateProductModeration,
} from '../../../actions/moderation/admin-shop-moderation.actions';

interface IDraftFormProps {
  draft?: IProductDraft;
  isDisabled?: boolean;
  createProductModeration: (
    auctionID: string,
    productDraftID: string,
    sellerID: string,
    name: string,
    description: string,
    isHide: boolean,
    imagesURL: string[],
    links: undefined | null | IProductLink[]
  ) => void;
  updateProductModeration: (
    productID: string,
    productDraftID: string,
    name: null | string,
    description: null | string,
    isHide: null | boolean,
    imagesURL: null | string[],
    links: undefined | null | IProductLink[]
  ) => void;
  cbCancel: () => void;
  actions: string[];
  errors: string[];
}

function DraftForm(props: IDraftFormProps) {
  const FormCreateRef = useRef<HTMLDivElement>(null);
  const FormDeclineRef = useRef<HTMLDivElement>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isDeclined, setIsDeclined] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormFields<IProductDraft>>({
    name: {
      ...FormsFields.name,
    },
    description: {
      ...FormsFields.description,
    },
    links: {
      ...FormsFields.params,
      isRequired: false,
      $valueRecordLength: 1,
    },
    imagesURL: {
      ...FormsFields.imageURL,
      max: 3,
    },
  });

  useEffect(() => {
    if (!props.draft) {
      return;
    }

    if (fields.name) {
      fields.name.$value = props.draft.name;
    }
    if (fields.description) {
      fields.description.$value = props.draft.description;
    }
    if (fields.links) {
      fields.links.$valueRecord = [
        {
          key:
            props.draft.links && props.draft.links[0]
              ? props.draft.links[0].name
              : '',
          keyName: '* Link name',
          value:
            props.draft.links && props.draft.links[0]
              ? props.draft.links[0].url
              : '',
          valueName: '* Link url',
        },
      ];
    }
    if (fields.imagesURL) {
      fields.imagesURL.$valueList = props.draft.imagesURL || [];
    }

    setFields({ ...fields });
  }, [props.draft]);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.errors]);

  const cbCreate = () => {
    if (!props.draft) {
      return;
    }

    setIsSending(true);
    props.createProductModeration(
      props.draft.auctionID,
      props.draft.id,
      props.draft.sellerID,
      (fields.name && fields.name.$value) || props.draft.name,
      (fields.description && fields.description.$value) ||
        props.draft.description,
      false,
      (fields.imagesURL && fields.imagesURL.$valueList) ||
        props.draft.imagesURL,
      (fields.links &&
        fields.links.$valueRecord &&
        fields.links.$valueRecord[0] && [
          {
            name: fields.links.$valueRecord[0].key,
            url: fields.links.$valueRecord[0].value,
          },
        ]) ||
        null
    );
  };
  const cbUpdate = () => {
    if (!props.draft) {
      return;
    }

    setIsSending(true);
    props.updateProductModeration(
      props.draft.productID || '',
      props.draft.id,
      (fields.name && fields.name.$value) || props.draft.name,
      (fields.description && fields.description.$value) ||
        props.draft.description,
      null,
      (fields.imagesURL && fields.imagesURL.$valueList) ||
        props.draft.imagesURL,
      (fields.links &&
        fields.links.$valueRecord &&
        fields.links.$valueRecord[0] && [
          {
            name: fields.links.$valueRecord[0].key,
            url: fields.links.$valueRecord[0].value,
          },
        ]) ||
        null
    );
  };
  const cbDelete = () => {
    setIsDeclined(true);
    scrollToForm(FormDeclineRef);
  };
  const cbCancelDelete = () => {
    setIsDeclined(false);
    scrollToForm(FormCreateRef);
  };

  return (
    <FormBlock>
      {(!props.draft || !props.draft.productID) && (
        <ContentHead>New Lot creation</ContentHead>
      )}
      {props.draft && props.draft.productID && (
        <ContentHead>Lot update</ContentHead>
      )}

      <Block ref={FormCreateRef}>
        <FormCommon
          placeholderTitle={'auction draft'}
          fields={fields}
          cb={{
            cbCancel: props.cbCancel,
            cbUpdate:
              props.draft && props.draft.productID ? cbUpdate : undefined,
            cbCreate:
              !props.draft || !props.draft.productID ? cbCreate : undefined,
            cbDelete,
          }}
          action={{
            isDisabled: isDeclined || props.isDisabled,
            isUpdate: (props.draft && !!props.draft.productID) || false,
            isSending,
            createActionTitle: 'Create product',
            editActionTitle: 'Accept changes',
            removeActionTitle: 'Decline product',
          }}
        />
      </Block>

      {props.draft && isDeclined && (
        <Block ref={FormDeclineRef}>
          <DraftFormDecline
            cbCancel={cbCancelDelete}
            actions={props.actions}
            productDraftID={props.draft.id}
          />
        </Block>
      )}
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    errors: state.System.errors,
  }),
  {
    createProductModeration,
    updateProductModeration,
  }
)(DraftForm);
