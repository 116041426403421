import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import {
  IPartnerSellerPublic,
  IPartnerSellerSet,
} from '../../../interfaces/account/IPartnerSeller';
import {
  loadPartnerSellerByAccountID,
  updatePartnerSellerSelf,
} from '../../../actions/account/partnerSeller.actions';
import { FormsFields } from '../../../common/forms/forms';
import { IAccount } from '../../../interfaces/account/IAccount';
import { useTranslation } from 'react-i18next';

interface IPartnerSellerFormSelfProps {
  cbCancel: () => void;
  isDisabled?: boolean;
  loadPartnerSellerByAccountID: (accountID: string) => void;
  updatePartnerSellerSelf: (
    name: string,
    imgURL: null | string,
    description: null | string,
    instagramLink: null | string
  ) => void;
  set: IPartnerSellerSet;
  IDByAccountID: Record<string, null | string>;
  account: null | IAccount;
  actions: string[];
  errors: string[];
}

function PartnerSellerFormSelf(props: IPartnerSellerFormSelfProps) {
  const { t } = useTranslation();
  const [partner, setPartner] = useState<null | IPartnerSellerPublic>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [accountID, setAccountID] = useState<null | string>(null);
  const [fields, setFields] = useState<IFormFields<IPartnerSellerPublic>>({
    name: {
      ...FormsFields.name,
    },
    imgURL: {
      ...FormsFields.imageURL,
      isRequired: false,
    },
    description: {
      ...FormsFields.description,
      isRequired: false,
    },
    // donationLink: {
    //   ...FormsFields.name,
    //   placeholder:
    //     "Example: `<a href='https://prytulafoundation.org/en/donation' target='_blank'>Prytula Foundation</a>`",
    //   isRequired: false,
    // },
    instagramLink: {
      ...FormsFields.name,
      placeholder: t('FormPlaceHolderExample') + ' `petro.nikolaychuk`',
      isRequired: false,
    },
  });

  useEffect(() => sendEvent('Open-PartnerSellerFormSelf'), []);

  useEffect(() => {
    if (!props.account) {
      return;
    }

    const partnerID: null | string =
      props.IDByAccountID[props.account.id] || null;
    const partnerLocal: null | IPartnerSellerPublic =
      (partnerID && props.set[partnerID]) || null;
    setAccountID(props.account.id);
    setPartner(partnerLocal);
  }, [props.set, props.account]);

  useEffect(() => {
    if (!partner) {
      return;
    }

    if (fields.name) {
      fields.name.$value = partner.name;
    }
    if (fields.imgURL) {
      fields.imgURL.$valueList = partner.imgURL ? [partner.imgURL] : [];
    }
    if (fields.description) {
      fields.description.$value = partner.description;
    }
    // if (fields.donationLink) {
    //   fields.donationLink.$value = partner.donationLink;
    // }
    if (fields.instagramLink) {
      fields.instagramLink.$value = partner.instagramLink;
    }

    setFields({ ...fields });
  }, [partner]);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const cbUpdate = () => {
    if (!partner) {
      return;
    }

    setIsSending(true);
    props.updatePartnerSellerSelf(
      (fields.name && fields.name.$value) || '',
      (fields.imgURL &&
        fields.imgURL.$valueList &&
        fields.imgURL.$valueList[0]) ||
        accountID ||
        null,
      (fields.description && fields.description.$value) || null,
      // (fields.donationLink && fields.donationLink.$value) || null,
      (fields.instagramLink && fields.instagramLink.$value) || null
    );
  };

  return (
    <FormBlock>
      <FormCommon
        placeholderTitle={'partner'}
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          cbUpdate,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: true,
          isSending,
        }}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Account.partnerSeller.set,
    IDByAccountID: state.Account.partnerSeller.IDByAccountID,
    actions: state.Account.partnerSeller.actions,
    account: state.Account.account,
    errors: state.System.errors,
  }),
  {
    updatePartnerSellerSelf,
    loadPartnerSellerByAccountID,
  }
)(PartnerSellerFormSelf);
