import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { LOT_SHORT_CURRENT_ID_LOAD } from '../types';
import { IServiceError } from '../../interfaces/system/IError';
import { ILotIDResponse, ILotType } from '../../interfaces/auctions/ILotPublic';
import { IAuction } from '../../interfaces/auctions/IAuction';

const { lotsAdmin } = apiConfig.endpoints.auctions;

export const adminLotActivate =
  (lotType: ILotType) =>
  (
    dispatch: IDispatch<null | string | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<ILotIDResponse>(lotsAdmin.start, { lotType })
      .then((data: ILotIDResponse) =>
        dispatch({ type: LOT_SHORT_CURRENT_ID_LOAD, payload: data.lotID })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const adminLotWin =
  (lotType: ILotType) =>
  (dispatch: IDispatch<string | IServiceError>, getState: () => IState) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const auctionNext: undefined | null | IAuction =
      getState().Auction.auctionNext;
    const auctionID: null | string = auctionNext ? auctionNext.id : null;
    const lotID: undefined | null | string = getState().Lot.short.currentLotID;
    if (!lotID || !auctionID) {
      return;
    }

    callApi(lotsAdmin.win, { lotID, lotType, auctionID }).catch(
      (error: Error | IApiError) => catchError(dispatch, error, getState)
    );
  };

export const adminLotNext =
  (lotType: ILotType) =>
  (dispatch: IDispatch<string | IServiceError>, getState: () => IState) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const auctionNext: undefined | null | IAuction =
      getState().Auction.auctionNext;
    const auctionID: null | string = auctionNext ? auctionNext.id : null;
    const lotID: undefined | null | string = getState().Lot.short.currentLotID;
    if (!lotID || !auctionID) {
      return;
    }

    callApi(lotsAdmin.nextLot, { lotID, lotType, auctionID }).catch(
      (error: Error | IApiError) => catchError(dispatch, error, getState)
    );
  };

export const adminLotRestart =
  (lotType: ILotType) =>
  (dispatch: IDispatch<string | IServiceError>, getState: () => IState) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const auctionNext: undefined | null | IAuction =
      getState().Auction.auctionNext;
    const auctionID: null | string = auctionNext ? auctionNext.id : null;
    const lotID: undefined | null | string = getState().Lot.short.currentLotID;
    if (!lotID || !auctionID) {
      return;
    }

    callApi(lotsAdmin.restart, { lotID, lotType, auctionID }).catch(
      (error: Error | IApiError) => catchError(dispatch, error, getState)
    );
  };

export const goToLotFromAuction =
  (lotType: ILotType) =>
  (
    dispatch: IDispatch<null | number | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const auctionNext: undefined | null | IAuction =
      getState().Auction.auctionNext;
    const auctionID: null | string = auctionNext ? auctionNext.id : null;
    if (!auctionID) {
      return;
    }

    callApi(lotsAdmin.nextStep, { lotType, auctionID }).catch(
      (error: Error | IApiError) => catchError(dispatch, error, getState)
    );
  };
