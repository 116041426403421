import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  API_ERROR,
  API_ERROR_POP,
  SET_LOGIN_ACTION,
  SYSTEM_ERROR_LOAD,
  SYSTEM_ERROR_LOAD_CACHE,
  SYSTEM_SET_WIDTH,
} from '../../actions/types';
import { ActionCase, IStateSystem } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import { getWidthSize } from '../../utils/catalog';
import {
  IErrorLogGetCacheResponse,
  IErrorLogGetData,
} from '../../interfaces/system/IErrorLog';

const initialStateSystem: IStateSystem = {
  errors: [],
  infos: [],
  clientWidth: null,
  clientWidthSize: null,
  loginAction: null,
  errorGroup: {
    mainByPeriod: {},
    cache: null,
  },
};

export const systemReducer: Reducer = createReducer(
  initialStateSystem,
  (builder: ActionReducerMapBuilder<IStateSystem>) => {
    builder.addCase<string, ActionCase<IServiceError>>(
      API_ERROR,
      (state: Draft<IStateSystem>, action: ActionCase<IServiceError>) => {
        state.errors.unshift(action.payload.message);

        const { message, params, type } = action.payload;
        let isExists: boolean = false;
        state.infos.forEach((err: IServiceError) => {
          if (err.message === message) {
            isExists = true;
          }
        });
        if (isExists) {
          return;
        }

        const paramsClean: Record<string, string> = {};
        Object.keys(params).map((key: string) => {
          paramsClean[key] =
            typeof params[key] === 'string'
              ? params[key]
              : JSON.stringify(params[key]);
        });

        if (paramsClean['description'] && paramsClean['description'] === '{}') {
          delete paramsClean['description'];
        }

        state.infos.unshift({ message, params: paramsClean, type });
      }
    );
    builder.addCase<string, ActionCase<void>>(
      API_ERROR_POP,
      (state: Draft<IStateSystem>) => {
        state.errors.pop();
        state.infos.pop();
      }
    );
    builder.addCase<string, ActionCase<number>>(
      SYSTEM_SET_WIDTH,
      (state: Draft<IStateSystem>, action: ActionCase<number>) => {
        const size: number = action.payload;
        state.clientWidth = size;
        state.clientWidthSize = getWidthSize(size);
      }
    );
    builder.addCase<string, ActionCase<string>>(
      SET_LOGIN_ACTION,
      (state: Draft<IStateSystem>, action: ActionCase<string>) => {
        state.loginAction = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IErrorLogGetData>>(
      SYSTEM_ERROR_LOAD,
      (state: Draft<IStateSystem>, action: ActionCase<IErrorLogGetData>) => {
        const { logs, from, to } = action.payload;
        state.errorGroup.mainByPeriod[`${from}-${to}`] = logs;
      }
    );
    builder.addCase<string, ActionCase<IErrorLogGetCacheResponse>>(
      SYSTEM_ERROR_LOAD_CACHE,
      (
        state: Draft<IStateSystem>,
        action: ActionCase<IErrorLogGetCacheResponse>
      ) => {
        state.errorGroup.cache = action.payload.logs;
      }
    );
  }
);
