import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IState,
  IStateLotAdminAction,
} from '../../../interfaces/system/IState';
import { BlockActions, ButtonMain } from '../../../common/styles';
import { deleteLot } from '../../../actions/auctions/lotModeration.actions';
import { ITEM_UPDATED } from '../../../actions/types';
import { ContentBold, ContentDesc } from '../../../common/typos';
import { ILotShort, ILotType } from '../../../interfaces/auctions/ILotPublic';
import { Block } from '../../../common/layout';

interface ILotFormRemoveProps {
  lotType: ILotType;
  lot: ILotShort;
  deleteLot: (lotType: ILotType, lotID: string) => void;
  cb: () => void;
  adminActions: IStateLotAdminAction[];
  errors: string[];
}

function LotFormRemove(props: ILotFormRemoveProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    if (
      props.adminActions[0] &&
      props.adminActions[0].action === ITEM_UPDATED
    ) {
      props.cb();
    } else {
      setIsLoading(false);
    }
  }, [props.adminActions, props.errors]);

  const sendCreate = () => {
    setIsLoading(true);
    props.deleteLot(props.lotType, props.lot.id);
  };

  const sendClose = () => {
    props.cb();
  };

  return (
    <Block className={'moderation'}>
      <Block>
        <ContentDesc>
          Are you sure want to remove{' '}
          <ContentBold>{props.lot.name}</ContentBold>{' '}
        </ContentDesc>
      </Block>
      <BlockActions>
        <ButtonMain onClick={() => sendCreate()} className="fail">
          Remove lot
        </ButtonMain>
        <ButtonMain onClick={sendClose} className="success">
          Cancel
        </ButtonMain>
      </BlockActions>
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    adminActions: state.Lot.moderation.actions,
    errors: state.System.errors,
  }),
  {
    deleteLot,
  }
)(LotFormRemove);
