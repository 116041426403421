import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import {
  IParamsItemView,
  RenderFormFunction,
  RenderItemPublicFunction,
  RenderItemViewFunction,
} from '../../../common/common';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import { ItemView } from '../../../common/moderation/ItemView';
import { loadPartnerSellerBatch } from '../../../actions/account/partnerSeller.actions';
import {
  IPartnerSellerPublic,
  IPartnerSellerSet,
  IPartnerSellerView,
} from '../../../interfaces/account/IPartnerSeller';
import { PartnerSellerItemModeration } from '../../../components/account/PartnerSeller/PartnerSellerItemModeration';
import PartnerSellerForm from '../../../components/account/PartnerSeller/PartnerSellerForm';

interface IPartnerSellerProps {
  loadPartnerSellerBatch: () => void;
  isAdmin: boolean;
  set: IPartnerSellerSet;
  IDs: string[];
  cnt: null | number;
  loaded: null | number;
  actions: string[];
  errors: string[];
}

function PartnerSeller(props: IPartnerSellerProps) {
  useEffect(() => sendPageEvent('PartnerSeller'), []);
  const defaultParamsItemView: IPartnerSellerView = {
    name: true,
    description: false,
    imgURL: true,
    donationLink: true,
    instagramLink: true,
    accountID: false,
    createdAt: false,
    updatedAt: false,
  };

  const loadBatch = () => {
    props.loadPartnerSellerBatch();
  };

  const renderForm: RenderFormFunction<IPartnerSellerPublic> = (
    isDisabled: undefined | boolean,
    item: undefined | IPartnerSellerPublic,
    cbCancel: () => void,
    cbDelete?: (partnerID: string) => void,
  ) => {
    return (
      <PartnerSellerForm
        isDisabled={isDisabled}
        partner={item}
        cbCancel={cbCancel}
        cbDelete={cbDelete}
      />
    );
  };

  const renderItemPublic: RenderItemPublicFunction<IPartnerSellerPublic> = (
    item: IPartnerSellerPublic,
    inlineBlock?: JSX.Element,
    ParamsItemView?: IParamsItemView,
  ) => {
    return (
      <PartnerSellerItemModeration
        partner={item}
        showInline={true}
        inlineBlock={inlineBlock}
        ParamsItemView={ParamsItemView}
      />
    );
  };

  const renderItemView: RenderItemViewFunction = (
    cb: (params: IPartnerSellerView) => void,
  ) => {
    return (
      <ItemView
        cb={(params: Record<string, boolean>) =>
          cb(params as IPartnerSellerView)
        }
        defaultParam={defaultParamsItemView}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'PartnerSeller moderation'}
      itemName={'this partner'}
      render={{
        renderForm,
        renderItemPublic,
        renderItemView,
      }}
      data={{
        set: props.set,
        actions: props.actions,
        errors: props.errors,
        batch: {
          batchKey: '',
          batchData: {
            IDs: props.IDs,
            cnt: props.cnt,
            loaded: props.loaded,
          },
          loadBatch,
        },
      }}
      isAdmin={props.isAdmin}
      action={{ showInline: true }}
      ParamsItemView={defaultParamsItemView}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    set: state.Account.partnerSeller.set,
    IDs: state.Account.partnerSeller.IDs,
    cnt: state.Account.partnerSeller.cnt,
    loaded: state.Account.partnerSeller.loaded,
    actions: state.Account.partnerSeller.actions,
    errors: state.System.errors,
  }),
  {
    loadPartnerSellerBatch,
  },
)(PartnerSeller);
