import React, { useEffect, useState } from 'react';
import { AppContent } from '../../App.styles';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../common/typos';
import { Loading } from '../../common/animation/Loading';
import { ActionButton } from '../../common/styles';
import { Block } from '../../common/layout';
import { sendPageEvent } from '../../utils/analytics';
import { getDonationInfo } from '../../actions/donation/donationAccount.actions';
import {
  IDonation,
  IDonationBank,
  IDonationLot,
} from '../../interfaces/donation/IDonationAccount';
import { IState } from '../../interfaces/system/IState';
import { connect } from 'react-redux';
import { getFullPrice } from '../../utils/price';
import { getAuctionList } from '../../actions/auctions/auction.actions';
import { Image } from '../../common/images/Image.styles';
import DonationBillsForm from '../../components/donation/DonationBillsForm';
import { IParamsItemView } from '../../common/common';
import ReportLotItem from '../../components/archive/ReportLotItem';
import { L } from '../../common/locale';

interface IDonationPage {
  getDonationInfo: () => void;
  getAuctionList: () => any;
  donations: null | IDonation[];
}

function DonationPage(props: IDonationPage) {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [donationEdit, setDonationEdit] = useState<null | IDonation>(null);
  const view: IParamsItemView = {
    sellerID: false,
    buyerList: false,
  };

  useEffect(() => sendPageEvent('Donation'), []);
  useEffect(() => props.getDonationInfo(), []);
  useEffect(() => props.getAuctionList(), []);
  useEffect(() => {
    setIsSending(false);
    setDonationEdit(null);
  }, [props.donations]);
  const initForm = (donation: IDonation) => {
    setDonationEdit(donation);
  };

  const handleCancel = () => {
    setDonationEdit(null);
    setIsSending(false);
  };

  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'AccountDonationTitle'} />
        </PageHead>
        {props.donations &&
          props.donations.map((donation: IDonation) => (
            <Block key={donation.id}>
              <Block>
                <>
                  {donation.liveLots[0] && (
                    <ContentHead>
                      <L k={'AccountLotsLiveTitle'} />
                    </ContentHead>
                  )}
                  {donation.liveLots.map((donationLot: IDonationLot) => (
                    <ReportLotItem
                      lotID={donationLot.lotID}
                      key={donationLot.lotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
                </>
                <>
                  {donation.weekLots[0] && (
                    <ContentHead>
                      <L k={'AccountLotsWeekTitle'} />
                    </ContentHead>
                  )}
                  {donation.weekLots.map((donationLot: IDonationLot) => (
                    <ReportLotItem
                      lotID={donationLot.lotID}
                      key={donationLot.lotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
                </>
              </Block>
              <Block>
                <ContentHead>
                  <L k={'AccountDonationTitleDetails'} />
                </ContentHead>
                {donation.details.map(
                  (details: IDonationBank, index: number) => (
                    <Block key={index}>
                      <ContentDesc>
                        <L k={'AccountDonationItem'} />{' '}
                        <ContentBold>{getFullPrice(details.price)}</ContentBold>
                      </ContentDesc>
                      <ContentDesc>
                        <L k={'AccountDonationLink'} />{' '}
                        <ContentDesc
                          dangerouslySetInnerHTML={{ __html: details.link }}
                        ></ContentDesc>
                      </ContentDesc>
                    </Block>
                  )
                )}
              </Block>
              <Block>
                <ContentHead>
                  <L k={'AccountDonationScreenshot'} />
                </ContentHead>
                {donation.imagesURL.map((imageURL: string) => (
                  <Image src={imageURL} alt="payment screenshot" sizes={'S'} />
                ))}
                <ActionButton
                  onClick={() => initForm(donation)}
                  disabled={
                    (donationEdit && donationEdit.id === donation.id) || false
                  }
                >
                  <L k={'AccountDonationScreenshotMake'} />
                </ActionButton>
                {isSending && <Loading size={'big'} />}

                {donationEdit && donationEdit.id === donation.id && (
                  <DonationBillsForm
                    cbCancel={handleCancel}
                    auctionID={donation.auctionID}
                    donationID={donation.id}
                  />
                )}
              </Block>
            </Block>
          ))}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    donations: state.Donation.donations,
  }),
  {
    getDonationInfo,
    getAuctionList,
  }
)(DonationPage);
