import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  DONATION_ACTION,
  DONATION_ADMIN_LOAD,
  DONATION_BANK_LOAD,
  DONATION_LOAD,
} from '../../actions/types';
import { ActionCase, IStateDonation } from '../../interfaces/system/IState';
import {
  IDonation,
  IDonationAdminSet,
} from '../../interfaces/donation/IDonationAccount';
import { IDonationBankDetailsSet } from '../../interfaces/donation/IDonationBank';

const initialStateDonation: IStateDonation = {
  detailsByAuctionID: {},
  donations: null,
  byAccountID: {},
  byAuctionID: {},
  actions: [],
};

export const donationReducer: Reducer = createReducer(
  initialStateDonation,
  (builder: ActionReducerMapBuilder<IStateDonation>) => {
    builder.addCase<string, ActionCase<IDonation[]>>(
      DONATION_LOAD,
      (state: Draft<IStateDonation>, action: ActionCase<IDonation[]>) => {
        state.donations = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IDonationAdminSet>>(
      DONATION_ADMIN_LOAD,
      (state: Draft<IStateDonation>, action: ActionCase<IDonationAdminSet>) => {
        const { auctionID, accountID, donations } = action.payload;
        if (auctionID) {
          state.byAuctionID[auctionID] = donations;
        }
        if (accountID) {
          state.byAccountID[accountID] = donations;
        }
      }
    );
    builder.addCase<string, ActionCase<IDonationBankDetailsSet>>(
      DONATION_BANK_LOAD,
      (
        state: Draft<IStateDonation>,
        action: ActionCase<IDonationBankDetailsSet>
      ) => {
        const { auctionID, details } = action.payload;
        state.detailsByAuctionID[auctionID] = details;
      }
    );
    builder.addCase<string, ActionCase<string>>(
      DONATION_ACTION,
      (state: Draft<IStateDonation>, action: ActionCase<string>) => {
        state.actions.push(action.payload);
      }
    );
  }
);
