import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { ActionButton, Tooltip } from '../../common/styles';
import { Block } from '../../common/layout';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../common/typos';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { IState } from '../../interfaces/system/IState';
import { loadProductModerationBatchNext } from '../../actions/moderation/admin-shop-moderation.actions';
import { sendPageEvent } from '../../utils/analytics';
import { getAuctionList } from '../../actions/auctions/auction.actions';
import { IProductPreviewOrder } from '../../interfaces/shop/IProduct';
import { loadProductsBatchNext } from '../../actions/shop/product.actions';
import { ILotIDs } from '../../interfaces/auctions/ILotAdmin';
import { loadAdminIDs } from '../../actions/account/admins.actions';
import { loadAccountsBatch } from '../../actions/account/accounts.actions';
import { loadPartnerSellerIDs } from '../../actions/account/partnerSeller.actions';
import { loadMailingIDs } from '../../actions/communication/mailing.actions';
import { loadRecommendationIDs } from '../../actions/communication/recommendation.actions';
import { DataBatchWide } from '../../interfaces/system/data';
import { checkIsExample } from '../../config/platform.config';

interface IMainModerationProps {
  getAuctionList: () => void;
  loadAdminIDs: () => void;
  loadAccountsBatch: (loadOnce?: boolean) => void;
  loadProductModerationBatchNext: () => void;
  loadProductsBatchNext: (order: IProductPreviewOrder) => any;
  loadPartnerSellerIDs: (hardReload?: boolean) => void;
  loadRecommendationIDs: () => void;
  loadMailingIDs: () => void;
  draftLoaded: number;
  draftCnt: null | number;
  auctionIDs: string[];
  liveLotIDs: ILotIDs[];
  weekLotIDs: ILotIDs[];
  batch: Record<IProductPreviewOrder, DataBatchWide>;
  adminIDs: null | string[];
  isAdmin: boolean;
  byBatchKey: Record<string, DataBatchWide>;
  partnerIDs: null | string[];
  IDs: string[];
  mailingIDs: string[];
}

function MainModeration(props: IMainModerationProps) {
  const navigation = useNavigate();

  const [lotCntLive, setLotCntLive] = useState<null | string>(null);
  const [lotCntWeek, setLotCntWeek] = useState<null | string>(null);
  const [IsExample] = useState<boolean>(checkIsExample());

  useEffect(() => sendPageEvent('MainModeration'), []);
  useEffect(() => {
    props.getAuctionList();
    props.loadAdminIDs();
    props.loadAccountsBatch(true);
    if (props.draftLoaded === 0) {
      props.loadProductModerationBatchNext();
    }
    props.loadPartnerSellerIDs();
    props.loadMailingIDs();
    props.loadRecommendationIDs();
  }, [props.isAdmin]);
  useEffect(() => {
    if (props.batch.new.loaded === 0) {
      props.loadProductsBatchNext('new');
    }
  }, []);

  useEffect(() => {
    if (!props.batch.new) {
      return;
    }

    setLotCntLive(
      `${props.liveLotIDs.length} live lots / ${
        props.batch.new.cnt ||
        0 - props.liveLotIDs.length - props.weekLotIDs.length
      } available / ${props.batch.new.cnt} products in shop`
    );
  }, [props.liveLotIDs, props.batch.new]);
  useEffect(() => {
    if (!props.batch.new) {
      return;
    }

    setLotCntWeek(
      `${props.weekLotIDs.length} week lots / ${
        props.batch.new.cnt ||
        0 - props.liveLotIDs.length - props.weekLotIDs.length
      } available / ${props.batch.new.cnt} products in shop`
    );
  }, [props.weekLotIDs, props.batch.new]);

  const goToShopModeration = () => {
    navigation(appConfig.routes.Moderation.shop.products);
  };
  const goToShopModerationOnBehalf = () => {
    navigation(appConfig.routes.Moderation.shop.onBehalf);
  };
  const goToAuctionModeration = () => {
    navigation(appConfig.routes.Moderation.auctions);
  };

  const goToPartnerSellers = () => {
    navigation(appConfig.routes.Moderation.partners.seller);
  };

  const goToCommunicationMailing = () => {
    navigation(appConfig.routes.Moderation.communication.mailing);
  };
  const goToCommunicationUsers = () => {
    navigation(appConfig.routes.Moderation.communication.users);
  };
  const goToCommunicationRecommendations = () => {
    navigation(appConfig.routes.Moderation.communication.recommendation);
  };
  const goToCommunicationLogs = () => {
    navigation(appConfig.routes.Moderation.communication.logs);
  };
  const goToCommunicationTemplateParams = () => {
    navigation(appConfig.routes.Moderation.communication.templateParams);
  };

  const goToMainModeration = () => {
    if (IsExample) {
      return;
    }
    navigation(appConfig.routes.account.moderation);
  };
  const goToLiveModeration = () => {
    navigation(appConfig.routes.live.moderation);
  };
  const goToWeekModeration = () => {
    navigation(appConfig.routes.week.moderation);
  };
  const goToDonationsBills = () => {
    navigation(appConfig.routes.donations.moderationBills);
  };
  const goToDonationsBank = () => {
    navigation(appConfig.routes.donations.moderationBank);
  };

  return (
    <AppContent>
      <Block>
        <PageHead>Admin Moderation </PageHead>

        <Block>
          <ActionButton onClick={goToAuctionModeration}>
            <Tooltip className="tooltip">
              <ContentDesc>
                * Auction information include donation cash flow
              </ContentDesc>
            </Tooltip>
            Auctions ({props.auctionIDs.length})
          </ActionButton>
        </Block>
        <Block>
          <ActionButton onClick={goToMainModeration} disabled={IsExample}>
            Admins {props.adminIDs ? '(' + props.adminIDs.length + ')' : ''}
          </ActionButton>
          <ActionButton onClick={goToCommunicationUsers} disabled={IsExample}>
            Users{' '}
            {props.byBatchKey['']
              ? ' (' + props.byBatchKey[''].cnt + ') '
              : ' '}
          </ActionButton>
          <ActionButton onClick={goToPartnerSellers} disabled={IsExample}>
            Partners
            {props.partnerIDs !== null
              ? ' (' + props.partnerIDs.length + ')'
              : ''}
          </ActionButton>
          <ActionButton onClick={goToCommunicationRecommendations}>
            Recommendations{' '}
            {props.IDs && props.IDs[0] ? ' (' + props.IDs.length + ')' : ''}
          </ActionButton>
        </Block>
        <Block>
          <ContentHead>Communication:</ContentHead>
          <ActionButton onClick={goToCommunicationMailing} disabled={IsExample}>
            Send emails{' '}
            {props.mailingIDs && props.mailingIDs[0]
              ? '(' + props.mailingIDs.length + ' mailings)'
              : ''}
          </ActionButton>
          <Block>
            <ActionButton onClick={goToCommunicationLogs} disabled={IsExample}>
              See email logs
            </ActionButton>
            <ActionButton
              onClick={goToCommunicationTemplateParams}
              disabled={IsExample}
            >
              Prepare Email Templates
            </ActionButton>
          </Block>
        </Block>
        <Block>
          <ContentHead>Shop:</ContentHead>
          <ActionButton onClick={goToShopModeration}>
            Create lots from draft ({props.draftCnt})
          </ActionButton>
          <ActionButton
            onClick={goToShopModerationOnBehalf}
            disabled={IsExample}
          >
            <Tooltip className="tooltip">
              <ContentDesc>
                * Seller will be notified about its product
              </ContentDesc>
            </Tooltip>
            Create lot <ContentBold>on behalf</ContentBold>
          </ActionButton>
        </Block>
        <Block>
          <ContentHead>Auction:</ContentHead>
          <ActionButton onClick={goToLiveModeration}>
            {lotCntLive && (
              <Tooltip className="tooltip">
                <ContentDesc>* {lotCntLive}</ContentDesc>
              </Tooltip>
            )}
            <ContentBold>Live</ContentBold> Auction lots
          </ActionButton>
          <ActionButton onClick={goToWeekModeration}>
            {lotCntWeek && (
              <Tooltip className="tooltip">
                <ContentDesc>* {lotCntWeek}</ContentDesc>
              </Tooltip>
            )}
            <ContentBold>Week</ContentBold> Auction lots
          </ActionButton>
        </Block>
        <Block>
          <ContentHead>After Auction:</ContentHead>
          <ActionButton onClick={goToDonationsBank}>
            Donation - bank details
          </ActionButton>
          <ActionButton onClick={goToDonationsBills}>
            Donation - Bills
          </ActionButton>
        </Block>
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    adminIDs: state.Account.adminIDs,
    isAdmin: state.Account.isAdmin,
    byBatchKey: state.Account.accounts.byBatchKey,
    partnerIDs: state.Account.partnerSeller.IDs,
    mailingIDs: state.Communication.Mailing.mailingIDs,
    IDs: state.Communication.Recommendation.IDs,
    draftLoaded: state.ShopModeration.products.loaded,
    draftCnt: state.ShopModeration.products.cnt,
    auctionIDs: state.Auction.auctions.IDs,
    liveLotIDs: state.Lot.moderation.LiveIDs,
    weekLotIDs: state.Lot.moderation.WeekIDs,
    batch: state.ShopProduct.batchPreview.batch,
  }),
  {
    loadAdminIDs,
    getAuctionList,
    loadAccountsBatch,
    loadProductModerationBatchNext,
    loadProductsBatchNext,
    loadPartnerSellerIDs,
    loadRecommendationIDs,
    loadMailingIDs,
  }
)(MainModeration);
