import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  CATALOG_ORDER_SET,
  SHOP_FOUNDATION_LOAD,
  SHOP_PRODUCT_BATCH_LOAD,
  SHOP_PRODUCT_CLEAR,
  SHOP_PRODUCT_DETAILS_LOAD,
  SHOP_PRODUCT_DRAFT_ACTION,
  SHOP_PRODUCT_DRAFT_CREATED,
  SHOP_PRODUCT_DRAFT_EDIT,
  SHOP_PRODUCT_DRAFT_LOAD,
  SHOP_PRODUCT_DRAFT_UPDATED,
  SHOP_PRODUCT_LOAD,
  SHOP_PRODUCT_PREVIEW_LOAD,
  SHOP_PRODUCT_SEARCH,
} from '../../actions/types';
import { ActionCase, IStateShopProduct } from '../../interfaces/system/IState';
import {
  DataSearchSetProduct,
  IProduct,
  IProductListSet,
  IProductPreview,
  IProductPreviewOrder,
  IProductSeller,
} from '../../interfaces/shop/IProduct';
import {
  IProductDraft,
  IProductDraftCreateSet,
} from '../../interfaces/shop/IProductDraft';
import { getComplexPathFromPath } from '../../utils/archive';
import {
  DataBatchKeySpecific,
  DataBatchWide,
} from '../../interfaces/system/data';

const initialStateProduct: IStateShopProduct = {
  products: {
    IDsBySeller: {},
    IDsByComplexPath: {},
    set: {},
    sellerIDByPath: {},
  },
  batchPreview: {
    limit: 10,
    set: {},
    IDsBySearch: {},
    batch: {
      new: {
        cnt: 0,
        loaded: 0,
        IDs: [],
      },
      popular: {
        cnt: 0,
        loaded: 0,
        IDs: [],
      },
      liked: {
        cnt: 0,
        loaded: 0,
        IDs: [],
      },
    },
  },
  drafts: {
    edit: null,
    set: {},
    IDByName: {},
    IDByProductID: {},
    IDs: [],
    actions: [],
  },
  order: null,
  foundation: {
    IDsBySeller: {},
    sellerIDs: [],
    productIDs: [],
    sellerByProduct: {},
  },
};

export const shopProductReducer: Reducer = createReducer(
  initialStateProduct,
  (builder: ActionReducerMapBuilder<IStateShopProduct>) => {
    builder.addCase<string, ActionCase<null | IProductPreviewOrder>>(
      CATALOG_ORDER_SET,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<null | IProductPreviewOrder>
      ) => {
        state.order = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IProductDraft[]>>(
      SHOP_PRODUCT_DRAFT_LOAD,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<IProductDraft[]>
      ) => {
        const { set, IDByProductID } = state.drafts;
        const IDsLocal: string[] = [];
        action.payload.forEach((draft: IProductDraft) => {
          const { id, productID } = draft;

          IDsLocal.push(id);
          set[id] = draft;
          if (productID) {
            IDByProductID[productID] = id;
          }
        });
        state.drafts.IDs = IDsLocal;
      }
    );
    builder.addCase<string, ActionCase<IProductDraftCreateSet>>(
      SHOP_PRODUCT_DRAFT_CREATED,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<IProductDraftCreateSet>
      ) => {
        const { IDByName } = state.drafts;
        const { name, productDraftID } = action.payload;
        IDByName[name] = productDraftID;
        state.drafts.set = {};
        state.drafts.IDs = [];
      }
    );
    builder.addCase<string, ActionCase<IProductDraftCreateSet>>(
      SHOP_PRODUCT_DRAFT_UPDATED,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<IProductDraftCreateSet>
      ) => {
        const { name, productDraftID } = action.payload;
        state.drafts.IDByName = {};
        state.drafts.IDByName[name] = productDraftID;
        state.drafts.set = {};
        state.drafts.IDs = [];
      }
    );
    builder.addCase<string, ActionCase<null | IProductDraft>>(
      SHOP_PRODUCT_DRAFT_EDIT,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<null | IProductDraft>
      ) => {
        state.drafts.edit = action.payload;
      }
    );
    builder.addCase<string, ActionCase<string>>(
      SHOP_PRODUCT_DRAFT_ACTION,
      (state: Draft<IStateShopProduct>, action: ActionCase<string>) => {
        state.drafts.actions.push(action.payload);
      }
    );
    builder.addCase<string, ActionCase<IProductListSet>>(
      SHOP_PRODUCT_LOAD,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<IProductListSet>
      ) => {
        const { IDsBySeller, IDsByComplexPath, set } = state.products;
        const { accountPath, productPath, productList } = action.payload;
        const IDs: string[] = [];
        const sellerIDs: string[] = [];
        productList.forEach((product: IProduct) => {
          const { id, sellerID } = product;
          IDs.push(id);
          sellerIDs.push(sellerID);
          set[id] = product;
        });

        if (accountPath && productPath) {
          const complexPath: string = getComplexPathFromPath(
            accountPath,
            productPath
          );

          IDsByComplexPath[complexPath] = IDs;
        } else if (accountPath) {
          IDsBySeller[accountPath] = IDs;
        }

        if (accountPath) {
          state.products.sellerIDByPath[accountPath] = sellerIDs;
        }
      }
    );
    builder.addCase<string, ActionCase<IProduct>>(
      SHOP_PRODUCT_DETAILS_LOAD,
      (state: Draft<IStateShopProduct>, action: ActionCase<IProduct>) => {
        state.products.set[action.payload.id] = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IProductPreview[]>>(
      SHOP_PRODUCT_PREVIEW_LOAD,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<IProductPreview[]>
      ) => {
        const { set } = state.batchPreview;
        action.payload.forEach((product: IProductPreview) => {
          const { id } = product;
          set[id] = product;
        });
      }
    );
    builder.addCase<
      string,
      ActionCase<DataBatchKeySpecific<string, IProductPreviewOrder>>
    >(
      SHOP_PRODUCT_BATCH_LOAD,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<DataBatchKeySpecific<string, IProductPreviewOrder>>
      ) => {
        const { batchKey, cnt, items } = action.payload;
        if (!state.batchPreview.batch[batchKey]) {
          state.batchPreview.batch[batchKey] = {
            IDs: [],
            loaded: 0,
            cnt,
          };
        }
        const $batch: DataBatchWide = state.batchPreview.batch[batchKey];
        $batch.loaded += items.length;
        $batch.cnt = cnt;
        $batch.IDs = $batch.IDs.concat(items);
      }
    );
    builder.addCase<string, ActionCase<DataSearchSetProduct>>(
      SHOP_PRODUCT_SEARCH,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<DataSearchSetProduct>
      ) => {
        const { search, items } = action.payload;
        const { IDsBySearch } = state.batchPreview;
        IDsBySearch[search] = items;
      }
    );
    builder.addCase<string, ActionCase<void>>(
      SHOP_PRODUCT_CLEAR,
      (state: Draft<IStateShopProduct>) => {
        state.batchPreview.batch = {
          new: {
            cnt: 0,
            loaded: 0,
            IDs: [],
          },
          popular: {
            cnt: 0,
            loaded: 0,
            IDs: [],
          },
          liked: { cnt: 0, loaded: 0, IDs: [] },
        };
        state.batchPreview.set = {};
        state.batchPreview.IDsBySearch = {};
      }
    );
    builder.addCase<string, ActionCase<IProductSeller[]>>(
      SHOP_FOUNDATION_LOAD,
      (
        state: Draft<IStateShopProduct>,
        action: ActionCase<IProductSeller[]>
      ) => {
        const { IDsBySeller, sellerByProduct } = state.foundation;
        const list: string[] = [];
        action.payload.forEach((productSeller: IProductSeller) => {
          const { productID, sellerID } = productSeller;
          if (!IDsBySeller[sellerID]) {
            IDsBySeller[sellerID] = [];
          }
          IDsBySeller[sellerID].push(productID);
          list.push(productID);
          sellerByProduct[productID] = sellerID;
        });
        state.foundation.sellerIDs = Object.keys(IDsBySeller);
        state.foundation.productIDs = list;
      }
    );
  }
);
