import React from 'react';
import { ErrorBlock, ErrorItem } from './Errors.styles';
import { connect } from 'react-redux';
import { IState } from '../interfaces/system/IState';
import { IServiceError } from '../interfaces/system/IError';
import { ContentDesc, ContentHead } from '../common/typos';

interface IErrorsProps {
  errors: IServiceError[];
}

function Errors(props: IErrorsProps) {
  return (
    <ErrorBlock>
      {props.errors.map((error: IServiceError, index: number) => (
        <ErrorItem key={index} className={error.type === 'Info' ? 'info' : ''}>
          <ContentHead dangerouslySetInnerHTML={{ __html: error.message }} />
          <ContentDesc>
            {Object.keys(error.params).map((key: string, index: number) => (
              <ContentDesc key={index}>
                {key !== '' ? key + ' - ' : ''}
                {error.params[key]}
              </ContentDesc>
            ))}
          </ContentDesc>
        </ErrorItem>
      ))}
    </ErrorBlock>
  );
}

export default connect(
  (state: IState) => ({
    errors: state.System.infos,
  }),
  {},
)(Errors);
