import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import { sendPageEvent } from '../../../utils/analytics';
import { IAuction } from '../../../interfaces/auctions/IAuction';
import DraftFormOnBehalf from '../../../components/shop/draft/DraftFormOnBehalf';
import AccountSelect from '../../../components/account/AccountSelect';
import ModerationMainHead from '../../../common/moderation/ModerationMainHead';
import { BlockPage, BlockPageContent } from '../../../common/styles';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';

interface IShopProductsOnBehalfProps {
  auctionNext: undefined | null | IAuction;
  actions: string[];
}

function ShopProductsOnBehalf(props: IShopProductsOnBehalfProps) {
  const navigation = useNavigate();

  const [accountID, setAccountID] = useState<null | string>(null);
  const [auctionID, setAuctionID] = useState<string>('');
  useEffect(() => sendPageEvent('ShopModerationOnBehalf'), []);
  useEffect(() => {
    if (props.auctionNext) {
      setAuctionID(props.auctionNext.id);
    }
  }, [props.auctionNext]);

  const cbCancel = () => {
    navigation(appConfig.routes.Moderation.main);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />

        <AccountSelect
          mode={'account'}
          cbAccount={setAccountID}
          hideBatch={true}
        />
        <DraftFormOnBehalf
          auctionID={auctionID}
          sellerID={accountID}
          cbCancel={cbCancel}
          actions={props.actions}
        />
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    auctionNext: state.Auction.auctionNext,
    actions: state.ShopProduct.drafts.actions,
  }),
  {}
)(ShopProductsOnBehalf);
