import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import {
  createProductDraft,
  deleteProductDraft,
  updateProductDraft,
} from '../../../actions/shop/productDraft.actions';
import {
  IProductDraft,
  IProductLink,
} from '../../../interfaces/shop/IProductDraft';
import { FormsFields } from '../../../common/forms/forms';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';

interface IDraftFormPublicProps {
  draft?: IProductDraft;
  isDisabled?: boolean;
  createProductDraft: (
    name: string,
    description: string,
    imagesBase64: string[],
    links: undefined | IProductLink[]
  ) => void;
  updateProductDraft: (
    productDraftID: string,
    auctionID: string,
    name: null | string,
    description: null | string,
    images: null | string[],
    isHide: null | boolean,
    baseName: string,
    links: undefined | null | IProductLink[]
  ) => void;
  deleteProductDraft: (itemID: string) => void;
  cbCancel?: () => void;
  cbComplete?: () => void;
  actions: string[];
  errors: string[];
}

function DraftFormPublic(props: IDraftFormPublicProps) {
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields, setFields] = useState<IFormFields<IProductDraft>>({
    name: {
      ...FormsFields.name,
    },
    description: {
      ...FormsFields.description,
    },
    links: {
      ...FormsFields.params,
      isRequired: false,
      $valueRecordLength: 1,
      $valueRecord: [
        {
          key: '',
          keyName: '* Link name',
          value: '',
          valueName: '* Link url',
        },
      ],
    },
    imagesURL: {
      ...FormsFields.imageURL,
      max: 3,
    },
  });

  useEffect(() => {
    if (!props.draft) {
      return;
    }

    if (fields.name) {
      fields.name.$value = props.draft.name;
    }
    if (fields.description) {
      fields.description.$value = props.draft.description;
    }
    if (fields.links) {
      fields.links.$valueRecord = [
        {
          key:
            props.draft.links && props.draft.links[0]
              ? props.draft.links[0].name
              : '',
          keyName: '* Link name',
          value:
            props.draft.links && props.draft.links[0]
              ? props.draft.links[0].url
              : '',
          valueName: '* Link url',
        },
      ];
    }
    if (fields.imagesURL) {
      fields.imagesURL.$valueList = props.draft.imagesURL || [];
    }

    setFields({ ...fields });
  }, [props.draft]);

  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    navigate(appConfig.routes.account.myLots.main);
    setIsSending(false);
    if (props.cbComplete) {
      props.cbComplete();
    }
  }, [props.errors]);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    navigate(appConfig.routes.account.myLots.main);
    setIsSending(false);
    if (props.cbComplete) {
      props.cbComplete();
    }
  }, [props.actions]);

  const cbCreate = () => {
    setIsSending(true);
    props.createProductDraft(
      (fields.name && fields.name.$value) || '',
      (fields.description && fields.description.$value) || '',
      (fields.imagesURL && fields.imagesURL.$valueList) || [],
      (fields.links &&
        fields.links.$valueRecord &&
        fields.links.$valueRecord[0] && [
          {
            name: fields.links.$valueRecord[0].key,
            url: fields.links.$valueRecord[0].value,
          },
        ]) ||
        undefined
    );
  };
  const cbUpdate = () => {
    if (!props.draft) {
      return;
    }
    setIsSending(true);
    props.updateProductDraft(
      props.draft.id,
      props.draft.auctionID,
      (fields.name && fields.name.$value) || null,
      (fields.description && fields.description.$value) || null,
      (fields.imagesURL && fields.imagesURL.$valueList) || null,

      (fields.isHide &&
        fields.isHide.$valueSelect &&
        Boolean(fields.isHide.$valueSelect[0])) ||
        null,
      props.draft.name,
      (fields.links &&
        fields.links.$valueRecord &&
        fields.links.$valueRecord[0] && [
          {
            name: fields.links.$valueRecord[0].key,
            url: fields.links.$valueRecord[0].value,
          },
        ]) ||
        null
    );
  };
  const cbDelete = () => {
    if (!props.draft) {
      return;
    }
    setIsSending(true);
    props.deleteProductDraft(props.draft.id);
  };

  return (
    <FormBlock>
      {!props.draft && <ContentHead>New Lot creation</ContentHead>}
      {props.draft && <ContentHead>Lot update</ContentHead>}

      <FormCommon
        placeholderTitle={'auction draft'}
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          cbCreate,
          cbUpdate,
          cbDelete,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: !!props.draft,
          isSending,
        }}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    actions: state.ShopProduct.drafts.actions,
    errors: state.System.errors,
  }),
  {
    createProductDraft,
    updateProductDraft,
    deleteProductDraft,
  }
)(DraftFormPublic);
