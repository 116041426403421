import styled from 'styled-components';
import { colors } from '../common/colors';
import { fonts, typos } from '../common/typos';
import { margins, sizes } from '../common/sizes';

export const AppFooter = styled.div`
  display: block;
  width: ${sizes.full};
  background-color: ${colors.background};
  padding: ${margins.block} 0;
  text-align: center;
  float: left;

  h1 {
    margin: ${margins.block} 0;
  }
`;

export const AppFooterLink = styled.a`
  ${typos.name};
  font-size: ${fonts.sizeLess};

  color: ${colors.main};
  cursor: pointer;
  margin: auto;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
