import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../../utils/analytics';
import { ContentBold, ContentDesc, ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock, FormGroup } from '../../../common/forms/Form.styles';
import FormCommon, { IFormFields } from '../../../common/forms/FormCommon';
import {
  IMailingPublic,
  IMailingSet,
  IMailingSettingsResponse,
} from '../../../interfaces/communication/IMailing';
import {
  createMailing,
  loadMailingPreview,
  loadMailingSettings,
  updateMailing,
} from '../../../actions/communication/mailing.actions';
import { FormsFields } from '../../../common/forms/forms';
import {
  IEmailMode,
  IEmailModeMailingList,
} from '../../../interfaces/communication/IEmailLog';
import { PlatformTypeList } from '../../../config/platform.config';
import { FormField } from '../../../common/forms/FormField';
import { FormFieldSelect } from '../../../common/forms/FormFieldSelect';
import { IAccount } from '../../../interfaces/account/IAccount';
import RecommendationSelect from '../Recommendation/RecommendationSelect';
import { ActionButton, BlockActions } from '../../../common/styles';
import { Loading } from '../../../common/animation/Loading';
import { BlockPreview } from '../communication.styles';
import AccountSelect from '../../account/AccountSelect';
import TemplateParamsSelect from '../TemplateParams/TemplateParamsSelect';
import { ParamsWrapper } from '../../../common/moderation/ParamsWrapper';
import {
  ITemplateParamsPublic,
  ITemplateParamsSet,
} from '../../../interfaces/communication/ITemplateParams';
import { useTranslation } from 'react-i18next';
import { L } from '../../../common/locale';

interface IMailingFormProps {
  mailing?: null | IMailingPublic;
  isDisabled?: boolean;
  loadMailingSettings: () => void;
  loadMailingPreview: (
    mode: IEmailMode,
    params: Record<string, string>,
    accountID: null | string
  ) => void;
  createMailing: (
    mode: IEmailMode,
    params: Record<string, string>,
    sendAt: null | string,
    accountIDs: null | string[]
  ) => void;
  updateMailing: (
    mailingID: string,
    params: null | Record<string, string>,
    sendAt: null | string,
    accountIDs?: null | string[]
  ) => void;
  cbCancel: () => void;
  cbDelete?: (mailingID: string) => void;
  set: IMailingSet;
  setTemplateParams: ITemplateParamsSet;
  settings: null | IMailingSettingsResponse;
  preview: null | string;
  account: null | IAccount;
  actions: string[];
  errors: string[];
}

const modeDefault: IEmailMode = IEmailModeMailingList[0];

function MailingForm(props: IMailingFormProps) {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [fields] = useState<IFormFields<IMailingPublic>>({
    mode: {
      mode: 'select',
      isRequired: true,
      defaultSelect: IEmailModeMailingList,
      $valueSelect: [modeDefault],
    },
    sendAt: {
      ...FormsFields.date,
    },
    // accountIDs: {
    //   ...FormsFields.date,
    // },
  });

  const [mode, setMode] = useState<null | IEmailMode>(modeDefault);
  const [params, setParams] = useState<Record<string, string>>({});
  const [isPreviewShow, setIsPreviewShow] = useState<boolean>(false);
  const [isPreviewRequested, setIsPreviewRequested] = useState<boolean>(false);
  const [accountIDs, setAccountIDs] = useState<null | string[]>(
    (props.account && [props.account.id]) || null
  );
  const [templateIDs, setTemplateIDs] = useState<null | string[]>(null);
  const [preview, setPreview] = useState<null | string>(null);

  useEffect(() => sendEvent('Open-MailingForm'), []);
  useEffect(() => props.loadMailingSettings(), []);
  useEffect(() => {
    const preParamsLocal: Record<string, string> =
      (props.settings && mode && props.settings.values[mode]) || {};
    setParams({
      subject: preParamsLocal['subject'] || '',
      text: preParamsLocal['text'] || '',
    });
  }, [props.settings, mode]);
  useEffect(() => {
    setAccountIDs(null);
    setIsPreviewShow(false);
  }, [mode]);

  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);
  useEffect(() => {
    if (!isPreviewRequested) {
      return;
    }
    setPreview(props.preview);
    setIsPreviewRequested(false);
  }, [props.preview]);

  const cbCreate = () => {
    setIsSending(true);
    props.createMailing(
      (fields.mode &&
        fields.mode.$valueSelect &&
        (fields.mode.$valueSelect[0] as IEmailMode)) ||
        modeDefault,
      params,
      (fields.sendAt &&
        fields.sendAt.$valueDate &&
        fields.sendAt.$valueDate.toISOString()) ||
        null,
      accountIDs
    );
  };
  const cbUpdate = () => {
    if (!props.mailing) {
      return;
    }

    setIsSending(true);
    props.updateMailing(
      props.mailing.id,
      params,
      (fields.sendAt &&
        fields.sendAt.$valueDate &&
        fields.sendAt.$valueDate.toISOString()) ||
        null,
      accountIDs
    );
  };
  const cbDelete = () => {
    if (!props.mailing || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.mailing.id);
  };
  const cbFieldUpdates = () => {
    const modeLocal: null | IEmailMode =
      (fields.mode &&
        fields.mode.$valueSelect &&
        (fields.mode.$valueSelect[0] as IEmailMode)) ||
      null;

    setMode(modeLocal);
  };

  const saveParams = (key: string, value: string) => {
    setIsPreviewShow(false);
    const paramsLocal: Record<string, string> = Object.assign({}, params);
    paramsLocal[key] = value;
    setParams(paramsLocal);
    setTemplateIDs(null);
  };
  const saveAccounts = (accountIDsLocal: null | string[]) => {
    setAccountIDs(accountIDsLocal);
    setIsPreviewShow(false);
    setPreview(null);
  };
  // useEffect(() => {
  //   if (props.mode === 'accounts') {
  //     saveAccountsMe();
  //   } else {
  //     saveAccountsAll();
  //   }
  // }, [props.mode]);
  // const saveAccountsMe = () => {
  //   props.cbAccounts((props.account && [props.account.id]) || null);
  // };
  // const saveAccountsAll = () => {
  //   props.cbAccounts(null);
  // };

  const requestPreview = () => {
    if (!mode) {
      return;
    }
    setIsPreviewShow(true);
    setIsPreviewRequested(true);
    props.loadMailingPreview(
      mode,
      params,
      (accountIDs && accountIDs[0]) || null
    );
  };

  const saveTemplate = (templateID: string) => {
    const template: ITemplateParamsPublic = props.setTemplateParams[templateID];
    if (!template) {
      return;
    }

    const paramsLocal = Object.assign({}, params);

    if (template.group === 'subject') {
      paramsLocal['subject'] = template.value;
    }
    if (template.group === 'text') {
      paramsLocal['text'] = template.value;
    }

    setTemplateIDs([templateID]);
    setParams(paramsLocal);
    setIsPreviewShow(false);
    setPreview(null);
  };

  const renderElements = (): JSX.Element => {
    return (
      <>
        {/* Subject */}
        {props.settings && props.settings.subjects && mode && (
          <FormField
            isDisabled={props.isDisabled}
            title={t('FormActionEditEmailSubject')}
            mode={'short'}
            saveValue={(value: string) => saveParams('subject', value)}
            valueError={null}
            defaultValue={
              params.subject || props.settings.subjects[mode] || null
            }
          />
        )}

        {/* Params */}
        {props.settings &&
          mode &&
          props.settings.params &&
          props.settings.params[mode].map((key: string) => (
            <FormGroup key={key}>
              {key !== '' && (
                <ContentDesc>
                  <L k={'FormActionEditParam'} />{' '}
                  <ContentBold>{key}</ContentBold>:
                </ContentDesc>
              )}
              {key !== 'addLastLot' && key !== 'partner' && key !== '' && (
                <FormField
                  isDisabled={props.isDisabled}
                  mode={'long'}
                  saveValue={(value: string) => saveParams(key, value)}
                  defaultValue={params[key]}
                  valueError={null}
                />
              )}
              {key === 'addLastLot' && (
                <FormFieldSelect
                  isDisabled={props.isDisabled}
                  valueError={null}
                  saveValue={(selects: string[]) => saveParams(key, selects[0])}
                  defaultValue={[params[key]]}
                  values={['true', 'false']}
                />
              )}
              {key === 'partner' && (
                <FormFieldSelect
                  isDisabled={props.isDisabled}
                  valueError={null}
                  saveValue={(selects: string[]) => saveParams(key, selects[0])}
                  defaultValue={[params[key]]}
                  values={PlatformTypeList}
                />
              )}
            </FormGroup>
          ))}

        <ParamsWrapper
          title={t('FormActionSelectAccounts')}
          renderParams={() => (
            <>
              {/* AccountIDs */}
              {mode === 'ToRecommendation' && (
                <RecommendationSelect
                  cbAccounts={saveAccounts}
                  selectedIDs={accountIDs}
                />
              )}
              {mode !== 'ToRecommendation' && (
                <AccountSelect
                  mode={'account'}
                  cbAccounts={saveAccounts}
                  selectedIDs={accountIDs}
                />
              )}
            </>
          )}
          initial={true}
        />

        <ParamsWrapper
          title={t('FormActionSelectParams')}
          renderParams={() => (
            <TemplateParamsSelect
              cbTemplateParams={saveTemplate}
              selectedIDs={templateIDs}
            />
          )}
          initial={true}
        />

        {isPreviewShow && (
          <ContentHead>
            <L k={'FormActionPreviewEmail'} />
          </ContentHead>
        )}
        {isPreviewShow && !preview && <Loading />}
        {isPreviewShow && preview && (
          <BlockPreview
            dangerouslySetInnerHTML={{ __html: preview }}
          ></BlockPreview>
        )}

        <BlockActions>
          {!isPreviewShow && (
            <ActionButton
              onClick={() => requestPreview()}
              disabled={isSending || props.isDisabled || isPreviewRequested}
            >
              <L k={'FormActionShowPreview'} />
            </ActionButton>
          )}

          {isPreviewShow && (
            <ActionButton
              onClick={() => setIsPreviewShow(false)}
              disabled={isSending || props.isDisabled || isPreviewRequested}
            >
              <L k={'FormActionHidePreview'} />
            </ActionButton>
          )}
        </BlockActions>
      </>
    );
  };

  return (
    <FormBlock>
      {!props.mailing && (
        <ContentHead>
          <L k={'FormMailingTitleCreate'} />
        </ContentHead>
      )}
      {props.mailing && (
        <ContentHead>
          <L k={'FormMailingTitleUpdate'} />
        </ContentHead>
      )}

      <FormCommon
        placeholderTitle={'auction mailing'}
        fields={fields}
        cb={{
          cbCancel: props.cbCancel,
          cbCreate,
          cbUpdate,
          cbDelete,
          cbFieldUpdates,
        }}
        action={{
          isDisabled: props.isDisabled,
          isUpdate: !!props.mailing,
          isSending,
        }}
        renderElements={renderElements}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Communication.Mailing.set,
    actions: state.Communication.Mailing.actions,
    preview: state.Communication.Mailing.preview,
    settings: state.Communication.Mailing.settings,
    setTemplateParams: state.Communication.TemplateParams.set,
    account: state.Account.account,
    errors: state.System.errors,
  }),
  {
    loadMailingSettings,
    loadMailingPreview,
    createMailing,
    updateMailing,
  }
)(MailingForm);
