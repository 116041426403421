import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  SYSTEM_ERROR_LOAD,
  SYSTEM_ERROR_LOAD_CACHE,
  SYSTEM_SET_WIDTH,
} from '../types';
import { dispatchError } from '../../service/error';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { apiConfig } from '../../config/api.config';
import { CacheRequests } from '../../service/cacheRequests';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IErrorLogGetCacheResponse,
  IErrorLogGetData,
  IErrorLogGetQuery,
  IErrorLogGetResponse,
  IErrorLogSaveCacheResonnse,
} from '../../interfaces/system/IErrorLog';

const { error, errorCache } = apiConfig.endpoints.system;

const cacheByID: CacheRequests = new CacheRequests();

export const setContainerWidth =
  (width: number) => (dispatch: IDispatch<number>) => {
    dispatch({ type: SYSTEM_SET_WIDTH, payload: width });
  };

export const loadErrors =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<IErrorLogGetData | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    const params: IErrorLogGetQuery = { from, to };
    if (cacheByID.checkOneIsLoaded(`${from}-${to}`)) {
      return;
    }

    callApi<IErrorLogGetResponse>(error, params)
      .then((data: IErrorLogGetResponse) => {
        const payload: IErrorLogGetData = {
          logs: data.logs,
          from,
          to,
        };
        dispatch({ type: SYSTEM_ERROR_LOAD, payload });
      })
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const loadErrorsCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      IErrorLogGetCacheResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!hardReload && cacheByID.checkOneIsLoaded(SYSTEM_ERROR_LOAD_CACHE)) {
      return;
    }

    callApi<IErrorLogGetCacheResponse>(errorCache)
      .then((data: IErrorLogGetCacheResponse) =>
        dispatch({ type: SYSTEM_ERROR_LOAD_CACHE, payload: data })
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };

export const setError =
  (message: string) => (dispatch: IDispatch<IServiceError>) => {
    dispatchError(dispatch, {
      message,
      params: {},
      type: 'Info',
    });
  };

export const saveErrorsCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    callApi<IErrorLogSaveCacheResonnse>(
      errorCache,
      undefined,
      undefined,
      'POST'
    )
      .then((data: IErrorLogSaveCacheResonnse) =>
        setError('Cache is saved ' + data.cnt)(dispatch)
      )
      .catch((error: Error | IApiError) =>
        catchError(dispatch, error, getState)
      );
  };
