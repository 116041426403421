import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AppContent } from '../../App.styles';
import { Block } from '../../common/layout';
import {
  initEditDraft,
  loadProductsDraft,
} from '../../actions/shop/productDraft.actions';
import { ContentDesc, PageHead } from '../../common/typos';
import {
  IProductDraft,
  IProductDraftSet,
} from '../../interfaces/shop/IProductDraft';
import { IAccount } from '../../interfaces/account/IAccount';
import { IArchiveReportUser } from '../../interfaces/archive/IArchiveReportUser';
import { getComplexPathFromPath } from '../../utils/archive';
import { loadReportUserAll } from '../../actions/archive/archiveReportUser.actions';
import { makePathFromName } from '../../utils/routes';
import { sendPageEvent } from '../../utils/analytics';
import { getAuctionList } from '../../actions/auctions/auction.actions';
import { getAnalyticsAll } from '../../actions/shop/productAnalytics.actions';
import { Loading } from '../../common/animation/Loading';
import DraftItemModeration from '../../components/shop/draft/DraftItemModeration';
import { appConfig } from '../../config/app.config';
import { ActionButton } from '../../common/styles';
import { useNavigate } from 'react-router-dom';
import { Order } from '../../common/forms/Order';
import { IParamsItemView } from '../../common/common';
import ReportLotItem from '../../components/archive/ReportLotItem';
import ModerationItemRows from '../../common/moderation/ModerationItemRows';
import { renderProductPreviewItem } from '../../components/shop/catalog/catalog';
import { useTranslation } from 'react-i18next';
import { L } from '../../common/locale';

interface ILotsPage {
  initEditDraft: (draft: IProductDraft) => void;
  loadProductsDraft: (hardReload?: boolean) => void;
  loadReportUserAll: (accountPath: string) => void;
  getAuctionList: () => void;
  getAnalyticsAll: () => void;
  set: IProductDraftSet;
  IDs: string[];
  account: IAccount | null;
  reportUsersSet: Record<string, IArchiveReportUser>;
  IDByComplexPath: Record<string, string[]>;
  likedProductIDs: null | string[];
}

type Order = 'liked' | 'sold' | 'bought' | 'created' | 'declined';

function LotsPage(props: ILotsPage) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [drafts, setDrafts] = useState<null | IProductDraft[]>(null);
  const [declined, setDeclined] = useState<null | IProductDraft[]>(null);
  const [order, setOrder] = useState<Order>('created');
  const [reports, setReports] = useState<null | IArchiveReportUser[]>(null);
  const [accountPath, setAccountPath] = useState<null | string>(null);
  const view: IParamsItemView = {
    sellerID: false,
    buyerList: false,
  };

  useEffect(() => sendPageEvent('Lots'), []);
  useEffect(() => {
    if (!props.account) {
      return;
    }

    const path: string = makePathFromName(props.account.name);
    setAccountPath(path);
    resetReports(path);
  }, [props.account]);

  useEffect(() => {
    if (!accountPath) {
      return;
    }

    if (!reports) {
      props.loadReportUserAll(accountPath);
    }
  }, [accountPath]);

  useEffect(() => {
    props.loadProductsDraft();
    props.getAuctionList();
    props.getAnalyticsAll();
  }, []);

  useEffect(() => {
    const { IDs, set } = props;
    const draftsAndDeclinedLocal: IProductDraft[] = IDs.map(
      (id) => set[id]
    ).filter((draft) => draft !== undefined && draft !== null);

    const draftsLocal: IProductDraft[] = draftsAndDeclinedLocal.filter(
      (draft: IProductDraft) => !draft.isDeclined
    );
    const declinedLocal: IProductDraft[] = draftsAndDeclinedLocal.filter(
      (draft: IProductDraft) => draft.isDeclined
    );

    setDrafts(draftsLocal);
    setDeclined(declinedLocal);
  }, [props.IDs, props.set]);

  useEffect(
    () => resetReports(),
    [props.IDByComplexPath, props.reportUsersSet]
  );

  const resetReports = (localAccountPath?: string) => {
    if (!accountPath && !localAccountPath) {
      return;
    }
    const complexPath: string = getComplexPathFromPath(
      '',
      accountPath || localAccountPath || ''
    );
    const reportIDs: undefined | string[] = props.IDByComplexPath[complexPath];
    const localReports: IArchiveReportUser[] = reportIDs
      ? reportIDs.map((id) => props.reportUsersSet[id]).filter(Boolean)
      : [];

    if (localReports.length > 0) {
      setReports(localReports);
    }
  };

  const changeOrder = (orderNew: Order) => {
    setOrder(orderNew);
  };

  const editLot = (draft: IProductDraft) => {
    props.initEditDraft(draft);
    navigate(appConfig.routes.account.myLots.edit);
  };

  return (
    <AppContent>
      <Block>
        <PageHead>
          <L k={'AccountLots'} />
        </PageHead>
        <Order
          groups={[
            [
              {
                value: 'liked',
                title: t('AccountLotsOrderLiked'),
              },

              {
                value: 'created',
                title: t('AccountLotsOrderCreated'),
              },
              // declined && declined[0] && ()
              {
                value: 'declined',
                title: t('AccountLotsOrderDeclined'),
              },
            ],
            [
              {
                value: 'sold',
                title: t('AccountLotsOrderSold'),
              },
              {
                value: 'bought',
                title: t('AccountLotsOrderDonated'),
              },
            ],
          ]}
          order={order}
          cb={setOrder}
        />

        <Block>
          {order === 'liked' &&
            (props.likedProductIDs ? (
              <ModerationItemRows
                itemIDs={props.likedProductIDs}
                renderItem={renderProductPreviewItem}
              />
            ) : (
              <Loading size="big" />
            ))}

          {order === 'created' && (
            <>
              {drafts && drafts[0] ? (
                drafts.map((draft: IProductDraft) => (
                  <DraftItemModeration
                    key={draft.id}
                    draft={draft}
                    inlineBlock={
                      <ActionButton
                        className="fail"
                        onClick={() => editLot(draft)}
                      >
                        <L k={'ButtonLotEdit'} />
                      </ActionButton>
                    }
                  />
                ))
              ) : (
                <ContentDesc>
                  <L k={'AccountLotsOrderCreatedEmpty'} />
                </ContentDesc>
              )}
            </>
          )}

          {order === 'declined' && (
            <>
              {declined && declined[0] ? (
                declined.map((draft: IProductDraft) => (
                  <Block key={draft.id}>
                    {draft.declineReason && (
                      <ContentDesc className="fail">
                        <L k={'AccountLotsOrderDeclinedReason'} />{' '}
                        {draft.declineReason}
                      </ContentDesc>
                    )}
                    <DraftItemModeration draft={draft} />
                  </Block>
                ))
              ) : (
                <ContentDesc>
                  <L k={'AccountLotsOrderDeclinedEmpty'} />
                </ContentDesc>
              )}
            </>
          )}

          {order === 'sold' &&
            reports &&
            reports.map((report: IArchiveReportUser, index: number) => (
              <div key={index}>
                {report.lotsSold[0] &&
                  report.lotsSold.map((lotID: string) => (
                    <ReportLotItem
                      key={lotID}
                      lotID={lotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
                {report.weekLotsSold &&
                  report.weekLotsSold[0] &&
                  report.weekLotsSold.map((weekLotID: string) => (
                    <ReportLotItem
                      lotID={weekLotID}
                      key={weekLotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}

                {!report.lotsSold[0] &&
                  (!report.weekLotsSold || !report.weekLotsSold[0]) && (
                    <ContentDesc>
                      <L k={'AccountLotsOrderSoldEmpty'} />
                    </ContentDesc>
                  )}
              </div>
            ))}

          {order === 'bought' &&
            reports &&
            reports.map((report: IArchiveReportUser, index: number) => (
              <div key={index}>
                {report.lotsBought[0] &&
                  report.lotsBought.map((lotID: string) => (
                    <ReportLotItem
                      key={lotID}
                      lotID={lotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}
                {report.weekLotsBought &&
                  report.weekLotsBought[0] &&
                  report.weekLotsBought.map((weekLotID: string) => (
                    <ReportLotItem
                      lotID={weekLotID}
                      key={weekLotID}
                      showInline={true}
                      ParamsItemView={view}
                    />
                  ))}

                {!report.lotsBought[0] &&
                  (!report.weekLotsBought || !report.weekLotsBought[0]) && (
                    <ContentDesc>
                      <L k={'AccountLotsOrderDonatedEmpty'} />
                    </ContentDesc>
                  )}
              </div>
            ))}
        </Block>
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    set: state.ShopProduct.drafts.set,
    IDs: state.ShopProduct.drafts.IDs,
    errors: state.System.errors,
    account: state.Account.account,
    reportUsersSet: state.Archive.reportUsers.set,
    IDByComplexPath: state.Archive.reportUsers.IDByComplexPath,
    likedProductIDs: state.ShopProductAnalytics.likedProductIDs,
  }),
  {
    loadProductsDraft,
    loadReportUserAll,
    getAuctionList,
    getAnalyticsAll,
    initEditDraft,
  }
)(LotsPage);
