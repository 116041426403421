import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import { IAppRecommendationBatchFiltersParams } from '../../../components/communication/Recommendation/RecommendationBatchFilters';
import {
  IParamsItemView,
  RenderFormFunction,
  RenderItemPublicFunction,
  RenderItemViewFunction,
} from '../../../common/common';
import { loadProductModerationBatchNext } from '../../../actions/moderation/admin-shop-moderation.actions';
import {
  IProductDraft,
  IProductDraftSet,
  IProductDraftView,
} from '../../../interfaces/shop/IProductDraft';
import { ItemView } from '../../../common/moderation/ItemView';
import DraftItemModeration from '../../../components/shop/draft/DraftItemModeration';
import DraftForm from '../../../components/shop/draft/DraftForm';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import DraftFormDecline from '../../../components/shop/draft/DraftFormDecline';

interface IShopProductsProps {
  loadProductModerationBatchNext: (hardReload?: boolean) => void;
  IDs: string[];
  set: IProductDraftSet;
  cnt: null | number;
  loaded: number;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function ShopProducts(props: IShopProductsProps) {
  const [filters, setFilters] = useState<IAppRecommendationBatchFiltersParams>({
    emails: null,
    categories: undefined,
    isEngaged: null,
    isParsed: null,
    socialView: null,
  });
  const defaultParamsItemView: IProductDraftView = {
    auctionID: false,
    productID: false,
    sellerID: true,
    name: true,
    description: true,
    imagesURL: true,
    links: false,
    isHide: false,
    isActive: false,
    isDeclined: false,
    declineReason: false,
    dateUpdated: false,
    dateCreated: false,
    dateModerated: false,
  };

  useEffect(() => sendPageEvent('moderation communication recommendation'), []);
  useEffect(() => {
    console.log(props);
  }, []);

  const loadBatch = (extraLimit?: number) => {
    // if (!filters) {
    //   return;
    // }

    props.loadProductModerationBatchNext();
  };

  const renderItemPublic: RenderItemPublicFunction<IProductDraft> = (
    item: IProductDraft,
    inlineBlock?: JSX.Element,
    ParamsItemView?: IParamsItemView
  ) => {
    return (
      <DraftItemModeration
        draft={item}
        showInline={false}
        inlineBlock={inlineBlock}
        ParamsItemView={ParamsItemView}
      />
    );
  };
  const renderForm: RenderFormFunction<IProductDraft> = (
    isDisabled: undefined | boolean,
    item: undefined | IProductDraft,
    cbCancel: () => void,
    cbDelete?: (partnerID: string) => void
  ) => {
    return (
      <DraftForm
        isDisabled={isDisabled}
        draft={item}
        cbCancel={cbCancel}
        actions={props.actions}
        // cbDelete={cbDelete}
      />
    );
  };

  const renderFormRemove: RenderFormFunction<IProductDraft> = (
    isDisabled: undefined | boolean,
    item: undefined | IProductDraft,
    cbCancel: () => void
  ) => {
    if (!item) {
      return <></>;
    }

    return (
      <DraftFormDecline
        isDisabled={isDisabled}
        actions={props.actions}
        cbCancel={cbCancel}
        productDraftID={item.id}
      />
    );
  };

  const renderItemView: RenderItemViewFunction = (
    cb: (params: IProductDraftView) => void
  ) => {
    return (
      <ItemView
        cb={(params: Record<string, boolean>) =>
          cb(params as IProductDraftView)
        }
        defaultParam={defaultParamsItemView}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'Shop products moderation'}
      itemName={'this products'}
      render={{
        renderItemPublic,
        renderForm,
        renderFormRemove,
        renderItemView,
      }}
      data={{
        set: props.set,
        actions: props.actions,
        errors: props.errors,
        batch: {
          batchKey: '',
          batchData: {
            IDs: props.IDs,
            cnt: props.cnt,
            loaded: props.loaded,
          },
          loadBatch,
        },
      }}
      isAdmin={props.isAdmin}
      action={{
        showInline: false,
        hideCreate: true,
        editActionTitle: 'Accept product',
        removeActionTitle: 'Decline product',
      }}
      ParamsItemView={defaultParamsItemView}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    cnt: state.ShopModeration.products.cnt,
    loaded: state.ShopModeration.products.loaded,
    set: state.ShopModeration.products.set,
    IDs: state.ShopModeration.products.IDs,
    actions: state.ShopProduct.drafts.actions,
    errors: state.System.errors,
  }),
  {
    loadProductModerationBatchNext,
  }
)(ShopProducts);
