import React, { useEffect, useState } from 'react';
import { ChartsBlock } from './analytics.styles';
import { BlockDates } from '../../common/styles';
import { ContentDesc } from '../../common/typos';
import { BlockHalf } from '../../common/layout';
import { Label } from '../../common/forms/Form.styles';
import DatePicker from 'react-datepicker';

interface IAnalyticsChartWrapperProps {
  cb: (from: string, to: string) => void;
}

export function ChartCalendar(props: IAnalyticsChartWrapperProps) {
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();

  useEffect(() => {
    const fromLocal: Date = new Date();
    fromLocal.setDate(fromLocal.getDate() - 30);
    setFrom(fromLocal);
    setTo(new Date());
  }, []);

  useEffect(() => {
    props.cb(
      from ? from.toISOString().slice(0, 10) : '',
      to ? to.toISOString().slice(0, 10) : ''
    );
  }, [from, to]);

  return (
    <ChartsBlock>
      <BlockHalf>
        <BlockDates>
          <Label>
            <ContentDesc>From date:</ContentDesc>
            <DatePicker
              selected={from}
              onChange={(date: Date) => setFrom(date)}
            />
          </Label>
        </BlockDates>
      </BlockHalf>
      <BlockHalf>
        <BlockDates>
          <Label>
            <ContentDesc>To date:</ContentDesc>
            <DatePicker selected={to} onChange={(date: Date) => setTo(date)} />
          </Label>
        </BlockDates>
      </BlockHalf>
    </ChartsBlock>
  );
}
