import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError } from '../../interfaces/system/IError';
import { ILotType } from '../../interfaces/auctions/ILotPublic';

const { report } = apiConfig.endpoints.auctions;

export const broadcastReportAuction =
  (lotType: ILotType) =>
    (dispatch: IDispatch<IServiceError>, getState: () => IState) => {
      if (!getState().Account.isAdmin) {
        return;
      }

      callApi(report.broadcastAuction, { lotType })
        .then(() => void 0)
        .catch((error: Error | IApiError) =>
          catchError(dispatch, error, getState),
        );
    };
