import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import TopDonators from './TopDonators';
import TopBidders from './TopBidders';
import TopSellers from './TopSellers';
import { Block } from '../../../common/layout';
import { currencyConvert } from '../../../utils/price';
import { IReportAuctionUpdate } from '../../../interfaces/auctions/IReportAuction';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../../common/typos';
import { L } from '../../../common/locale';

interface IAuctionResultProps {
  currentLotID: undefined | null | string;
  reportAuctionLive: undefined | null | IReportAuctionUpdate;
}

function ResultAuction(props: IAuctionResultProps) {
  return (
    <Block>
      <PageHead>
        <L k={'ReportsCurrentLive'} />
      </PageHead>

      {/*todo show something if not or redirect back to auction*/}
      {props.reportAuctionLive && (
        <>
          <ContentDesc>
            <L k={'HallSortDonated'} />{' '}
            <ContentBold>
              {props.reportAuctionLive.total} ($
              {currencyConvert(props.reportAuctionLive.total)}){' '}
            </ContentBold>
          </ContentDesc>
          <ContentDesc>
            {props.reportAuctionLive.bidCnt} <L k={'AnalyticsBids'} />
          </ContentDesc>

          <ContentHead>
            <L k={'ReportsLotOrderBuyTotal'} />
          </ContentHead>
          <TopDonators
            topDonators={props.reportAuctionLive.topDonators}
          ></TopDonators>

          <ContentHead>
            <L k={'ReportsLotOrderBidders'} />
          </ContentHead>
          <TopBidders
            topBidders={props.reportAuctionLive.topBidders}
          ></TopBidders>

          <ContentHead>
            <L k={'ReportsLotOrderSellTotal'} />
          </ContentHead>
          <TopSellers
            topSellers={props.reportAuctionLive.topSellers}
          ></TopSellers>
        </>
      )}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    currentLotID: state.Lot.short.currentLotID,
    reportAuctionLive: state.Report.auctionLive,
  }),
  {}
)(ResultAuction);
